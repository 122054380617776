<template>
  <b-card body-class="py-3">
    <h6 class="text-uppercase mb-3">
      <b>Variation du Turnover</b>
    </h6>
    <div>
      <highcharts :options="chart" />
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    chart: {
      type: Object,
      required: true
    }
  }
}
</script>
