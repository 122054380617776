import { render, staticRenderFns } from "./CompetenceTree.vue?vue&type=template&id=40c9201f&scoped=true&"
import script from "./CompetenceTree.vue?vue&type=script&lang=js&"
export * from "./CompetenceTree.vue?vue&type=script&lang=js&"
import style0 from "./CompetenceTree.vue?vue&type=style&index=0&id=40c9201f&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40c9201f",
  null
  
)

export default component.exports