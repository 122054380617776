<template>
  <div>
    <div class="mb-5">
      <h5 class="mb-4 text-uppercase">
        Repartition des Effectifs
      </h5>
      <b-card no-body style="overflow: hidden">
        <b-tabs
          card
          vertical
          pills
          justified
          nav-class="p-0 border-right"
          nav-wrapper-class="tabs-header-v-large"
          lazy
        >
          <b-tab v-for="i in tabs" :key="i.id" :title="i.name">
            <ChartTypeGender
              v-if="i.id === 'gender' && workforceCount"
              id="gender"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataGender"
              key-selects="genderSelects"
              key-set-selects="setGenderSelects"
              :selection="currentSelection"
              :allowed="perimeter"
              :labels="currentLabels"
              :scenario-metadata="scenarioMetadata"
              :scenario-target="scenarioTarget"
              @selection="setSelection"
            />
            <ChartTypeContrat
              v-if="i.id === 'contrat' && workforceCount"
              id="contrat"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataContrat"
              key-selects="contratSelects"
              key-set-selects="setContratSelects"
              :selection="currentSelection"
              :allowed="perimeter"
              :labels="currentLabels"
              :scenario-metadata="scenarioMetadata"
              :scenario-target="scenarioTarget"
              @selection="setSelection"
            />
            <ChartTypeAge
              v-if="i.id === 'age' && workforceCount"
              id="age"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataAge"
              key-selects="ageSelect"
              key-set-selects="setAgeSelect"
              :labels="currentLabels"
              :scenario-metadata="scenarioMetadata"
              :scenario-target="scenarioTarget"
            />
            <ChartTypeManager
              v-if="i.id === 'manager' && workforceCount"
              id="manager"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataManager"
              key-selects="managerSelects"
              key-set-selects="setManagerSelects"
              :selection="currentSelection"
              :allowed="perimeter"
              :labels="currentLabels"
              :scenario-metadata="scenarioMetadata"
              @selection="setSelection"
            />
            <ChartTypeSeniority
              v-if="i.id === 'seniority' && workforceCount"
              id="seniority"
              :key="workforceCount"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataSeniority"
              key-selects="senioritySelects"
              key-set-selects="setSenioritySelects"
              :selection="currentSelection"
              :labels="currentLabels"
              :scenario-metadata="scenarioMetadata"
              :scenario-target="scenarioTarget"
              @selection="setSelection"
            />
            <ChartTypeSeniorityGender
              v-if="i.id === 'seniorityGender' && workforceCount"
              id="senioritygender"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataSeniorityGender"
              key-selects="seniorityGenderSelects"
              key-set-selects="setSeniorityGenderSelects"
              :labels="currentLabels"
              :selection="currentSelection"
              :scenario-metadata="scenarioMetadata"
              :scenario-target="scenarioTarget"
            />
            <ChartTypeInternalExternal
              v-if="i.id === 'internalExternal' && workforceCount"
              id="internalExternal"
              :current-checks="activeScenariosIds"
              :data-effectifs="dataEffectifs"
              :g-filters="gFilters"
              key-getter="graphDataInternalExternal"
              key-selects="internalExternalSelects"
              key-set-selects="setInternalExternalSelects"
              :selection="currentSelection"
              :allowed="perimeter"
              :labels="currentLabels"
              :scenario-metadata="scenarioMetadata"
              :scenario-target="scenarioTarget"
              @selection="setSelection"
            />
            <NoData v-if="noDataTab(i) || !workforceCount" />
          </b-tab>
        </b-tabs>
      </b-card>
    </div>
    <div class="mb-4">
      <div class="card shadow-sm border-0">
        <b-tabs
          card
          pills
          fill
          nav-class="p-0 border-right"
          lazy
          class="tabs-quantitative"
        >
          <b-tab v-for="i in tabsQuantitative" :key="i.id" :title="i.name">
            <div v-if="i.id === 'evolution'">
              <tab-evolution
                :workforce-count="workforceCount"
                :scenario-metadata="scenarioMetadata"
              />
            </div>
            <div v-if="i.id === 'comparative'">
              <tab-comparative
                id="comparative"
                :workforce-count="workforceCount"
                :scenario-metadata="scenarioMetadata"
                :current-checks="activeScenariosIds"
                :g-filters="gFilters"
                key-selects="comparativesSelects"
                key-set-selects="setComparativesSelects"
                :selection="currentSelection"
                :perimeter-filters="perimeter"
                :labels="currentLabels"
                @selection="setSelectionComparative"
              />
            </div>
            <div v-if="i.id === 'analysis'">
              <tab-analysis
                :workforce-count="workforceCount"
                :scenario-metadata="scenarioMetadata"
              />
            </div>
          </b-tab>
        </b-tabs>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import ChartTypeGender from './effectifs/ChartTypeGender.vue';
import ChartTypeContrat from './effectifs/ChartTypeContrat.vue';
import ChartTypeAge from './effectifs/ChartTypeAge.vue';
import ChartTypeManager from './effectifs/ChartTypeManager.vue';
import ChartTypeSeniority from './effectifs/ChartTypeSeniority.vue';
import ChartTypeSeniorityGender from './effectifs/ChartTypeSeniorityGender.vue';
import ChartTypeInternalExternal from './effectifs/ChartTypeInternalExternal.vue';
import TabEvolution from './effectifs/forecasts/TabEvolution.vue';
import TabAnalysis from './effectifs/forecasts/TabAnalysis.vue';
import TabComparative from './effectifs/forecasts/TabComparative.vue';
import NoData from '../all/NoData.vue';


const tabs = [
  { id: 'gender', name: 'Mixité' },
  { id: 'contrat', name: 'Type de contrat' },
  { id: 'age', name: 'Age' },
  { id: 'manager', name: 'Manager' },
  { id: 'seniority', name: 'Ancienneté' },
  { id: 'seniorityGender', name: 'Ancienneté et Mixité' },
  { id: 'internalExternal', name: 'Internes/Externes' }, //New item
];

const tabsQuantitative = [
  { id: 'evolution', name: 'Evolution quantitative' },
  { id: 'comparative', name: 'Comparaison quantitative' },
  { id: 'analysis', name: `Analyse d'écarts par cause` },
]

export default {
  components: {
    NoData,
    ChartTypeGender,
    ChartTypeContrat,
    ChartTypeAge,
    ChartTypeManager,
    ChartTypeSeniority,
    ChartTypeSeniorityGender,
    ChartTypeInternalExternal,
    TabEvolution,
    TabAnalysis,
    TabComparative
},
  props: {
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
    perimeter: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      tabs,
      tabsQuantitative,
      currentSelection: [],
      currentLabels: [],
    };
  },
  computed: {
    ...mapState('analysis', {
      gFilters: (state) => state.filters,
      effectifs: (state) => state.effectifs,
    }),
    ...mapGetters('analysis/effectifs', {
      graphDataAge: 'graphDataAge',
      graphDataContrat: 'graphDataContrat',
      graphDataGender: 'graphDataGender',
      graphDataManager: 'graphDataManager',
      graphDataSeniority: 'graphDataSeniority',
      graphDataSeniorityGender: 'graphDataSeniorityGender',
      graphDataInternalExternal: 'graphDataInternalExternal',
    }),
    ...mapGetters('auth/basic', {
      scenarios: 'getScenarios',
      activeScenariosIds: 'getActiveScenariosIds',
      scenarioTarget: 'getScenarioTarget',
    }),
    dataEffectifs() {
      return {
        ...this.effectifs,
        graphDataAge: this.graphDataAge,
        graphDataContrat: this.graphDataContrat,
        graphDataGender: this.graphDataGender,
        graphDataManager: this.graphDataManager,
        graphDataSeniority: this.graphDataSeniority,
        graphDataSeniorityGender: this.graphDataSeniorityGender,
        graphDataInternalExternal: this.graphDataInternalExternal,
      };
    },
  },
  watch: {
    gFilters: {
      handler() {
        this.resetSelection();
        this.$forceUpdate();
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    noDataTab(i) {
      // eslint-disable-next-line
      return !['internalExternal','seniorityGender', 'seniority', 'manager', 'gender', 'contrat', 'age'].includes(
        i.id
      );
    },
    setSelectionComparative(selection) {
      if (selection && selection.length) {
        this.currentSelection = selection;
        if (typeof selection[0] === 'object') {
          this.setLabelsFromSelection(this.currentSelection);
        } else {
          this.setLabels(this.currentSelection);
        }
      }
    },
    setSelection(selection) {
      if (selection && selection.length) {
        this.currentSelection = selection;
        if (typeof selection[0] === 'object') {
          this.setLabelsFromSelection(this.currentSelection);
        } else {
          this.setLabels(this.currentSelection);
        }
      }
    },
    resetSelection() {
      this.currentSelection = [];
    },
    setLabels(selection) {
      // get ids
      // eslint-disable-next-line no-underscore-dangle
      const newIds = selection.map((t) => t._id);
      this.currentLabels = this.getFilteredList(this.gFilters.type)
        // eslint-disable-next-line no-underscore-dangle
        .filter((e) => newIds.includes(e._id))
        .map((e) => e.names && e.names[0].label);
    },
    setLabelsFromSelection(selection) {
      this.currentLabels = selection.map((e) => e.name || (e.names && e.names[0].label));
    },
  },
};
</script>
<style lang="scss" scoped>
.tabs-quantitative {
  ::v-deep .card-header {
    padding: 0 !important;
  }
  ::v-deep .nav-link{
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
