<template>
  <div>
    <div v-if="mergingScenarios.length" class="bg-primary-light text-right">
      <b-container>
        <b-row no-gutters>
          <b-col class="p-2">
            <MergeScenarioModal
              :count="mergingScenarios.length"
              :scenarios="mergingScenariosObjects"
              @cancel="cancelMerge"
              @remove="updateMergingScenarios"
              @success="saveScenario"
            />
          </b-col>
        </b-row>
      </b-container>
    </div>
    <div v-if="status === 'COMPARE' || comparingScenarios.length" class="bg-primary-light">
      <b-container>
        <b-row no-gutters>
          <b-col
            cols="6"
            md="6"
            lg="6"
            xl="6"
            class="p-2"
          >
            <div class="d-flex">
              <b-badge
                class="flex-fill mx-1 p-3 text-truncate w-25"
                :variant="comparisonFirstScenario ? 'secondary' : 'light'"
              >
                {{ comparisonFirstScenario ? comparisonFirstScenario.label : 'Premier scénario' }}
              </b-badge>
              <b-badge
                class="flex-fill mx-1 p-3 text-truncate w-25"
                :variant="comparisonSecondScenario ? 'secondary' : 'light'"
              >
                {{ comparisonSecondScenario ? comparisonSecondScenario.label : 'Second scénario' }}
              </b-badge>
            </div>
            <div>
              <small>La fonction comparaison fonctionne sur 2 scénarios
                ayant le même périmètre/concernant la même population.</small>
            </div>
          </b-col>
          <b-col class="p-2 text-right text-uppercase">
            <b-button class="text-uppercase" variant="link" @click="toggleStatus('SELECT')">
              Annuler
            </b-button>
            <b-button
              :disabled="comparingScenarios.length !== 2"
              class="text-uppercase"
              @click="goToComparison"
            >
              Voir la comparaison
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </div>
    <b-modal
      v-model="success"
      ok-only
      ok-title="FERMER ET RETOURNER À L’ACCUEIL"
      ok-variant="secondary"
      dialog-class="text-center"
      footer-class="justify-content-center border-0"
      header-class="border-0"
      @ok="success = false"
    >
      <div>
        <div class="text-secondary">
          <i class="fas fa-check-circle fa-2x" />
        </div>
        <div class="py-4">
          Votre scénario a été sauvegardé avec succès et vos
          <br>
          données sont en train d’être traitées.
        </div>
      </div>
    </b-modal>
    <div class="container mt-4">
      <div class="row mb-4">
        <div class="col">
          <h5>MES SCÉNARIOS</h5>
        </div>
        <div class="col-auto text-right">
          <b-button
            :disabled="status === 'COMPARE'"
            class="mr-2 text-uppercase"
            @click="toggleStatus('COMPARE')"
          >
            <i class="fal fa-plus-circle mr-2" />
            <span>Comparer</span>
          </b-button>
          <button
            v-if="user.scenariocreator"
            class="btn btn-secondary mr-2"
            type="button"
            @click="$router.push('/collab/scenario/new')"
          >
            <i class="fal fa-plus-circle mr-2" />
            <span>NOUVEAU SCÉNARIO</span>
          </button>
        </div>
      </div>
      <b-tabs>
        <b-tab :title="user.scenariocreator ? 'Mes créations' : 'Tendances'">
          <ScenariosListing
            v-if="myScenarios.length"
            :status="status"
            :scenarios="myScenarios"
            :users="users"
            :readonly="!user.scenariocreator"
            :merging-scenarios="mergingScenarios"
            :comparing-scenarios="comparingScenarios"
            :selectable-comparing-scenarios="comparingScenariosObjects"
            :selectable-merging-scenarios="selectableMergingScenariosObjects"
            @changed="loadData"
            @merge="setMergingScenario"
            @update-merge="updateMergingScenarios"
            @update-compare="updateComparingScenarios"
          />
        </b-tab>
        <b-tab title="Partagés avec moi">
          <ScenariosListing
            v-if="sharedWithMeScenarios.length"
            :status="status"
            :scenarios="sharedWithMeScenarios"
            :users="users"
            shared
            :readonly="!user.scenariocreator"
            :merging-scenarios="mergingScenarios"
            :comparing-scenarios="comparingScenarios"
            :selectable-comparing-scenarios="comparingScenariosObjects"
            :selectable-merging-scenarios="selectableMergingScenariosObjects"
            @changed="loadData"
            @merge="setMergingScenario"
            @update-merge="updateMergingScenarios"
            @update-compare="updateComparingScenarios"
          />
        </b-tab>
        <b-tab v-if="user.scenariocreator" title="Archivés">
          <ScenariosListing
            v-if="archivedScenarios.length"
            :status="status"
            :scenarios="archivedScenarios"
            :users="users"
            archive
            :readonly="!user.scenariocreator"
            @changed="loadData"
          />
        </b-tab>
      </b-tabs>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import ScenariosListing from '@/components/ScenariosListing.vue'
import MergeScenarioModal from '@/components/scenario/merge/MergeScenarioModal'
import { toggleItemInArray } from '@/services/helpers'
import {
  filterScenariosByFilter,
  getNullFilterScenarios,
  getScenariosIfFilterIsDifferent,
  mergeFilters
} from '@/services/scenarios'

export default {
  name: 'TableScenario',
  components: {
    ScenariosListing,
    MergeScenarioModal
  },
  data() {
    return {
      status: 'SELECT',
      mergingScenarios: [],
      comparingScenarios: [],
      allScenarios: [],
      archivedScenarios: [],
      users: [],
      intervalHandler: null,
      loading: false,
      success: false,
      filterScenariosByFilter
    };
  },
  computed: {
    comparisonFirstScenario () {
      return this.allScenarios.find(s => s.ID === this.comparingScenarios[0])
    },
    comparisonSecondScenario () {
      return this.allScenarios.find(s => s.ID === this.comparingScenarios[1])
    },
    myScenarios() {
      return this.allScenarios.filter((s) => s.owner === this.user.id || s.default);
    },
    sharedWithMeScenarios() {
      return this.allScenarios.filter((s) => s.owner !== this.user.id && !s.default);
    },
    mergingScenariosObjects () {
      return this.allScenarios.filter((s) => this.mergingScenarios.includes(s.ID))
    },
    comparingScenariosObjects () {
      return filterScenariosByFilter(this.comparisonFirstScenario, this.allScenarios)
    },
    selectableMergingScenariosObjects () {
      const mergedFilterFromAllMergingScenariosObjects = mergeFilters(this.mergingScenariosObjects)

      // on veut savoir si un scenario contient un filtre null
      // si c'est le cas, il faut rendre les scénarios avec un filtre null non sélectionnable
      const nullFilterScenarios = getNullFilterScenarios(
          this.mergingScenariosObjects,
          this.allScenarios
      )

      // on récupère les scénarios qui ne rentrent pas en conflit
      // avec au moins un attribut du filtre des scénarios déjà sélectionnés
      const filtered = getScenariosIfFilterIsDifferent(
        { filter: mergedFilterFromAllMergingScenariosObjects },
        this.allScenarios
      )

      // on remet les scénarios sélectionnés comme sélectionnables
      return this.allScenarios
        .filter((s) => {
          // pour tous les autres scénarios que ceux déjà sélectionnés
          // vérifier que les filtres ne soient pas identiques
          return (!filtered.map(sc => sc.ID).includes(s.ID)
                  && nullFilterScenarios.map(sc => sc.ID).includes(s.ID))
              || this.mergingScenarios.includes(s.ID)
        })
    },
    ...mapGetters({
      user: 'auth/currentUser',
    }),
  },
  async mounted() {
    await this.loadData()
    this.intervalHandler = setInterval(this.loadData, 30000)
  },
  beforeDestroy() {
    clearInterval(this.intervalHandler)
  },
  methods: {
    goToComparison () {
      if (this.comparisonFirstScenario.isReady && this.comparisonSecondScenario.isReady) {
        // eslint-disable-next-line max-len,vue/max-len
        this.$router.push(`/collab/analysis/${this.comparisonFirstScenario.ID}?default=${this.comparisonFirstScenario.default ? '1' : '0'}&id2=${this.comparisonSecondScenario.ID}`)
      }
    },
    toggleStatus (status) {
      this.status = status === this.status ? 'SELECT' : status
      this.mergingScenarios = []
      this.comparingScenarios = []
    },
    cancelMerge () {
      this.mergingScenarios = []
      this.toggleStatus('SELECT')
    },
    setMergingScenario (scenario) {
      this.toggleStatus('MERGE')
      this.mergingScenarios.push(scenario)
    },
    updateMergingScenarios (scenario) {
      toggleItemInArray(this.mergingScenarios, scenario)
      if (!this.mergingScenarios.length) {
        this.cancelMerge()
      }
    },
    updateComparingScenarios (scenario) {
      toggleItemInArray(this.comparingScenarios, scenario)
    },
    async loadData() {
      try {
        this.setLoaderStatus(true);
        this.allScenarios = await this.fetchScenarios();
        this.archivedScenarios = await this.fetchArchivedScenarios();
        this.users = await this.fetchUsers();
      } catch (err) {
        console.log('error', err);
      } finally {
        this.setLoaderStatus(false);
      }
    },
    async saveScenario (scenario) {
      try {
        this.loading = true
        await this.save(scenario)
        await this.loadData()
        this.success = true
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    ...mapActions({
      fetchScenarios: 'scenarios/fetchScenarios',
      fetchArchivedScenarios: 'scenarios/fetchArchivedScenarios',
      setLoaderStatus: 'app/setLoaderStatus',
      fetchUsers: 'user/fetchUsers',
      save: 'scenarios/saveScenario'
    }),
  }
};
</script>
