<template>
  <div>
    <b-button variant="primary-lighten" class="text-primary text-uppercase" @click="open">
      <i class="fa fa-pencil" />
      <span class="ml-2">Modifier par coût</span>
    </b-button>
    <b-modal
      v-model="show"
      ok-title="SAUVEGARDER"
      cancel-title="ANNULER"
      size="xl"
      header-class="border-0"
      footer-class="mt-2"
      content-class="rounded-xl p-4"
      modal-class="m-0 p-0"
      body-class="p-0"
      centered
    >
      <template #modal-header>
        <div>
          <h4>
            Modification hypothèses par coût
          </h4>
          <!-- eslint-disable max-len -->
          <p>
            Ce tableau affiche la valeur du coût par métier pour chaque année. Vous avez la possibilité de remplir et de modifier les valeurs du coût par métier.
          </p>
        </div>
        <span id="p-filter-budget-modal" style="position: relative; margin-top: 4px;">
          <i class="fal fa-question-circle" />
        </span>
        <b-popover
        target="p-filter-budget-modal"
        triggers="hover"
        class="custom-popover"
        :placement="left"
        >
          <strong class="popover-title">Modification des hypothèses de coûts par métier</strong><br>
          <div class="popover-content">
            Ce tableau vous permets de modifier les hypothèses de coûts
            par métier pour chaque année de votre scénario. La liste des
            métiers est basée sur votre périmètre affilié.
          </div>
        </b-popover>
        <b-btn-close @click="close" />
      </template>
      <b-container>
        <b-row class="mb-2">
          <b-col cols="7" />
          <b-col cols="5">
            <b-row>
              <b-col v-if="periodType === 'all'" class="text-center text-bold">
                2023
              </b-col>
              <b-col v-if="periodType === 'all'" class="text-center text-bold">
                2024
              </b-col>
              <b-col class="text-center text-bold">
                2025
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <div v-for="job in jobs" :key="job.id">
          <EditBudgetItem :index="job.id" :job="job" :period-type="periodType" />
        </div>
      </b-container>
    </b-modal>
  </div>
</template>
<script>
import EditBudgetItem from './EditBudgetItem.vue'
export default {
  components: {
    EditBudgetItem
  },
  props: {
    jobs: {
      type: Array,
      required: false,
      default: () => []
    },
    periodType: {
      type: String,
      required: false,
      default: 'all',
    },
  },
  data () {
    return {
      show: false
    }
  },
  computed: {
  },
  mounted () {
  },
  methods: {
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
  }
}
</script>
<style scoped>
  .custom-popover {
    width: auto !important;
    max-width: none !important;
    white-space: nowrap !important;
  }

  .popover-title {
    display: inline-block !important;
    font-weight: bold;
    overflow: hidden;
    font-size: 11px;
  }

  .popover-content {
    white-space: normal;
    text-align: justify;
    font-size: 11px
  }
</style>
