<template>
  <div v-if="hasData" :key="JSON.stringify(currentSelection)">
    <div class="row justify-content-between align-items-center mb-4">
      <div class="col-lg-5 mb-2">
        <main-filter-switcher
          :g-filters="gFilters"
          :selected-fn="selectMainFilterItem"
          :multi="true"
          :selection="selection"
          :allowed="allowed"
          :selection-disabled="true"
          :global-option="true"
          :global-selected-disabled="true"
          :object-value="true"
        />
      </div>
      <div class="col-auto">
        <ScenariosSelector
          :hidden="scenarioMetadata ? [] : ['target']"
          @changed="updateInternalExternalSeries"
        />
      </div>
    </div>
    <hr>
    <div class="form-row">
      <div class="col">
        <highcharts :options="chartInternalExternal" />
      </div>
      <div class="col-auto">
        <div class="mb-2">
          Internes/Externes
        </div>
        <div class="small">
          <div v-for="(i, idx) in categoriesInternalExternal" :key="i" class="form-row">
            <div class="col">
              <b-form-checkbox v-model="ctLegendRight" :value="i" name="fRigth">
                <span>{{ i }}</span>
              </b-form-checkbox>
            </div>
            <div class="col-auto">
              <i
                class="fas fa-circle ml-1 text-primary"
                :style="{ opacity: getOpacity(categoriesInternalExternal, idx) }"
              />
            </div>
          </div>
        </div>
        <DataLabelsSwitcher />
      </div>
    </div>
  </div>
  <div v-else>
    No data
  </div>
</template>
<script>
import { formatCostType } from '@/providers/helpers/functions';
import MainAnalysisFilterMixin from '@/mixins/MainAnalysisFilterMixin';
import SetChartSelectsMixin from '@/mixins/SetChartSelectsMixin';
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';

const chartTypeOne = (categories, showDataLabels, scenarioTarget) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: { enabled: false },
  xAxis: {
    categories,
  },
  yAxis: {
    title: null,
    labels: {
      formatter(item) {
        return `${item.value} %`;
      },
    },
  },
  tooltip: {
    formatter: function () {
      const { y, x, series, percentage } = this;
      const formattedValue = formatCostType(y, scenarioTarget);
      // eslint-disable-next-line vue/max-len
      return `<b>${x}<br>${series.name}</b> : <b>${formattedValue}</b> (${percentage.toFixed(0)}%)`;
    }
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
    series: {
      dataLabels: {
        enabled: showDataLabels,
        formatter: function () {
          const value = this.y;
          const formattedValue = formatCostType(value, scenarioTarget);
          return formattedValue
        }
      },
    },
  },
  series: [],
});

export default {
  name: 'ChartTypeInternalExternal',
  components: { ScenariosSelector, MainFilterSwitcher, DataLabelsSwitcher },
  mixins: [MainAnalysisFilterMixin, SetChartSelectsMixin],
  props: {
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
    dataKey: {
      type: String,
      required: false,
      default: () => 'data'
    }
  },
  data() {
    return {
      externalInternalSeries: [],
      ctLegendRight: [],
      refreshSeriesCallback: 'updateInternalExternalSeries',
    };
  },
  computed: {
    hasData() {
      return !!this.dataEffectifs[this.keyGetter];
    },
    categoriesInternalExternal() {
      if (!this.dataEffectifs?.[this.keyGetter]) {
        return []
      }
      const { categories } = this.dataEffectifs?.[this.keyGetter];
      return categories;
    },
    chartInternalExternal() {
      const chart = chartTypeOne(this.labels, this.showDataLabels,  this.scenarioTarget);
      chart.series = this.externalInternalSeries;
      return chart;
    },
  },
  watch: {
    ctLegendRight: {
      handler() {
        this.updateInternalExternalSeries(this.currentChecks);
      },
      deep: true,
      immediate: true,
    }
  },
  mounted() {
    this.ctLegendRight = this.categoriesInternalExternal;
  },
  methods: {
    getOpacity(items, idx) {
      const val = (1 / items.length) * (idx + 1);
      return val.toFixed(2);
    },
    updateInternalExternalSeries(scenarios) {
      if (!this.dataEffectifs?.[this.keyGetter]) {
        return
      }
      const data = this.dataEffectifs[this.keyGetter]?.[this.dataKey]
      this.externalInternalSeries = [];
      this.ctLegendRight.forEach((i) => {
        scenarios.forEach((entity) => {
          // eslint-disable-next-line
          if (data[entity][i.toLowerCase()]) this.externalInternalSeries.push(data[entity][i.toLowerCase()]);
        });
      });
    },
    setSelection(selection) {
      if (selection && selection.length) {
        this.currentSelection = selection;
        if (typeof selection[0] === 'object') {
          this.setLabelsFromSelection(this.currentSelection);
        } else {
          this.setLabels(this.currentSelection);
        }
      }
    },
    resetSelection() {
      this.currentSelection = [];
    },
    setLabels(selection) {
      // get ids
      // eslint-disable-next-line no-underscore-dangle
      const newIds = selection.map((t) => t._id);
      this.currentLabels = this.getFilteredList(this.gFilters.type)
        // eslint-disable-next-line no-underscore-dangle
        .filter((e) => newIds.includes(e._id))
        .map((e) => e.names && e.names[0].label);
    },
    setLabelsFromSelection(selection) {
      this.currentLabels = selection.map((e) => e.name || (e.names && e.names[0].label));
    },
  },
};
</script>
