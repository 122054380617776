const state = {
  currentTab: '',
};

const getters = {
  // eslint-disable-next-line
  graphDataCompetence(state, getters, { analysis }, rootGetters) {
    const { year } = analysis.filters;
    if (!year) return null;
    return rootGetters['analysis/yearStatsAll']?.competencyData?.summaryGraph;
  },
  // eslint-disable-next-line
  attritionsCompetence(state, getters, { analysis }, rootGetters) {
    const { year } = analysis.filters;
    if (!year) return null;
    return rootGetters['analysis/yearStatsAll']?.competencyData?.attritions;
  },
  // eslint-disable-next-line
  augmentationsCompetence(state, getters, { analysis }, rootGetters) {
    const { year } = analysis.filters;
    if (!year) return null;
    return rootGetters['analysis/yearStatsAll']?.competencyData?.augmentations;
  },
  // eslint-disable-next-line
  totalsCompetence(state, getters, { analysis }, rootGetters) {
    const { year } = analysis.filters;
    if (!year) return null;
    return rootGetters['analysis/yearStatsAll']?.competencyData?.totals;
  },
  // eslint-disable-next-line
  globalValuesCompetence(state, getters, { analysis }, rootGetters) {
    const { year } = analysis.filters;
    if (!year) return null;
    return rootGetters['analysis/yearStatsAll']?.competencyData?.globalValueMap;
  },
  // eslint-disable-next-line
  currentYearGlobalValuesCompetence(state, getters, { analysis }, rootGetters) {
    const { year } = analysis.filters;
    if (!year) return null;
    return rootGetters['analysis/currentYearStatsAll']?.competencyData?.globalValueMap;
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
