<template>
  <div class="mb-5">
    <div class="mb-5">
      <h6 class="font-weight-normal mb-3">
        Choisissez la période de vos hypothèses
      </h6>
      <div class="row align-items-stretch">
        <div v-for="t in types" :key="t.id" class="col">
          <CardValidate :data="t" :selected="selectedType" @selectType="select" />
        </div>
      </div>
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('previous')"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          v-if="!readonly"
          variant="link"
          class="text-uppercase"
          :disabled="!Object.keys(selectedType).length"
          @click="$emit('saveDraft', { period: selectedType.id, activeStep: 'PERIOD' })"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button
          class="btn btn-secondary text-uppercase"
          type="button"
          :disabled="!Object.keys(selectedType).length"
          @click="next"
        >
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CardValidate from '@/components/all/CardValidate.vue';

export default {
  components: {
    CardValidate,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: () => '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      selectedType: {},
      types: [
        {
          title: 'Sur les 3 prochaines années',
          desciption: 'Faites des hypothèses sur les 3 prochaines années de projection.',
          color: '#47fcb4',
          id: 'all',
        },
        {
          title: 'Sur la troisième et dernière année',
          desciption:
            'Faites des hypothèses sur la troisième et dernière année de projection uniquement.',
          color: '#af99ec',
          id: 'last',
        },
      ],
    };
  },
  mounted() {
    if (this.type) {
      this.selectedType = this.types.find((t) => t.id === this.type);
    }
  },
  methods: {
    select(type) {
      if (!this.readonly) {
        this.selectedType = type;
      }
    },
    next() {
      this.$emit('updatePeriod', this.selectedType);
    },
  },
};
</script>
