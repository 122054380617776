import {getArraysIntersection} from "@/services/helpers";

export const checkOverlapScenariosFilter = (scenarios) => {
  // check if many scenarios have same filters
  const mergedFilter = {}
  const conflictedScenarios = {}
  scenarios.forEach(scenario => {
    const others = scenarios.filter(sc => sc.ID !== scenario.ID)
    others.forEach(other => {
      Object.keys(scenario.filter).forEach(key => {
        if (scenario?.filter?.[key] && other?.filter?.[key]) {
          const intersection = getArraysIntersection(scenario.filter[key], other.filter[key])
          if (intersection.length) {
            // build array
            const conflict = [scenario, other].sort((a, b) => a.ID.localeCompare(b.ID))
            // conflictedScenarios.push({key, scenarios: [scenario, other]}) <- if we need key
            conflictedScenarios[`${conflict[0].ID}-${conflict[1].ID}`] = conflict
          }
        }
      })
    })
  })
  return Object.values(conflictedScenarios)
}

export const filterScenariosByFilter = (sourceScenario, scenarios) => {
  const others = scenarios.filter(sc => sc?.ID !== sourceScenario?.ID)
  return others.filter(other => {
    let conflicts = []
    sourceScenario?.filter && Object.keys(sourceScenario.filter).forEach(key => {
      if (sourceScenario?.filter?.[key]?.toString() === other?.filter?.[key]?.toString()) {
        conflicts.push(false)
      } else {
        conflicts.push(true)
      }
    })
    return conflicts.filter(Boolean).length === 0
  })
}

export const getNullFilterScenarios = (mergingScenarios, allScenarios) => {
  let flag = false

  // on vérifie si parmi les merging scénarios, au moins l'un d'entre eux contient un filtre null
  mergingScenarios.forEach(s => {
    const isNull = (key) => s?.filter?.[key] === null
    if (Object.keys(s.filter).every(isNull)) {
      flag = true
    }
  })

  if (flag) {
    // on retourne tous les scénarios sauf ceux qui ont un filtre null
    return allScenarios.filter(s => {
      if (s.filter) {
        const isNull = (key) => s?.filter?.[key] === null
        return !Object.keys(s.filter).every(isNull)
      } else {
        return true
      }
    })
  } else {
    // on retourne tous les scenarios
    return allScenarios
  }
}

export const getScenariosIfFilterIsDifferent = (sourceScenario, scenarios) => {
  const others = scenarios.filter(sc => sc?.ID !== sourceScenario?.ID)
  return others.filter(other => {
    let flag = true
    sourceScenario?.filter && Object.keys(sourceScenario.filter).forEach(key => {
      if (sourceScenario?.filter?.[key] && Array.isArray(sourceScenario?.filter?.[key])) {
        sourceScenario?.filter?.[key].forEach(value => {
          if (other?.filter?.[key]?.includes(value)) {
            flag = false
          }
        })
      } else if (sourceScenario?.filter?.[key]
          && sourceScenario?.filter?.[key] === other?.filter?.[key]) {
        flag = false
      }
    })
    return !flag
  })
}

export const mergeFilters = (scenarios) => {
  const newFilters = {}

  scenarios.forEach(scenario => {
    Object.keys(scenario.filter).forEach(key => {
      if (newFilters?.[key]) {
        newFilters[key] = [...newFilters[key], ...(scenario.filter[key] || [])]
      } else {
        newFilters[key] = scenario.filter[key]
      }
    })
  })

  return newFilters
}

export const checkOverlapScenariosType = (scenarios) => {
  // check if many scenarios have different types
  const conflictedScenarios = {}
  scenarios.filter(Boolean).forEach(scenario => {
    const others = scenarios.filter(sc => sc._id !== scenario._id)
    others.forEach(other => {
      if (scenario.scenario !== other.scenario) {
        const conflict = [scenario, other].sort((a, b) => a._id.localeCompare(b._id))
        conflictedScenarios[`${conflict[0]._id}-${conflict[1]._id}`] = conflict
      }
    })
  })
  return Object.values(conflictedScenarios)
}

export const buildMergedScenarioPayload = (scenarios, payload) => {
  // we merge scenarios values: yearGlobalRate, yearChangesMap, filter
  // we will sum values for yearChangesMap and filter values
  // we will compute the average for yearGlobalRate values
  const mergedScenario = {
    label: '',
    scenario: '',
    filter: {},
    yearGlobalRate: {},
    yearChangesMap: {}
  }

  scenarios.forEach(scenario => {
    // yearGlobalRate: compute average
    if (scenario?.yearGlobalRate) {
      Object.keys(scenario.yearGlobalRate).forEach(year => {
        if (mergedScenario.yearGlobalRate?.[year]) {
          Object.keys(mergedScenario.yearGlobalRate[year]).forEach(key => {
            mergedScenario.yearGlobalRate[year][key] += scenario.yearGlobalRate[year][key]
          })
        } else {
          mergedScenario.yearGlobalRate[year] = scenario.yearGlobalRate[year]
        }
      })
      Object.keys(scenario.yearGlobalRate).forEach(year => {
        Object.keys(mergedScenario.yearGlobalRate[year]).forEach(key => {
          // eslint-disable-next-line max-len,vue/max-len
          mergedScenario.yearGlobalRate[year][key] = mergedScenario.yearGlobalRate[year][key]/Object.keys(scenario.yearGlobalRate).length
        })
      })
    }

    // yearChangesMap: sum values
    if (scenario?.yearChangesMap) {
      Object.keys(scenario.yearChangesMap).forEach(year => {
        if (mergedScenario.yearChangesMap?.[year]) {
          mergedScenario.yearChangesMap[year] = [
            ...scenario.yearChangesMap[year],
            ...mergedScenario.yearChangesMap[year]
          ]
        } else {
          mergedScenario.yearChangesMap[year] = scenario.yearChangesMap[year]
        }
      })
    }

    // filter: sum values
    if (scenario?.filter) {
      if (!mergedScenario.filter) {
        mergedScenario.filter = scenario.filter
        Object.keys(mergedScenario.filter).forEach(key => {
          mergedScenario.filter[key] = []
        })
      } else {
        Object.keys(scenario.filter).forEach(key => {
          if (scenario.filter[key] && Object.keys(scenario.filter[key]).length) {
            mergedScenario.filter[key] = [
              ...scenario.filter[key],
              ...(mergedScenario.filter[key] || [])
            ]
          }
        })
      }
    }
  })

  return { ...mergedScenario, ...payload }
}