import GpHeader from './HeaderNavBar.vue';
import TableScenario from './TableScenario.vue';
import StepsValidate from './StepsValidate.vue';
import PageDetail from './PageDetail.vue';
import GapAnalysis from './GapAnalysis.vue';
import AnalysisDetail from './AnalysisDetail.vue';
import UserProfile from './UserProfile.vue';
import ArchivedScenarioStepper from './ArchivedScenarioStepper.vue';

// selectors
import JobSelector from './selectors/JobSelector.vue';
import UnitSelector from './selectors/UnitSelector.vue';
import SkillSelector from './selectors/SkillSelector.vue';

// shared
import AppLoader from './shared/AppLoader.vue';
import FullLoader from './shared/FullLoader.vue';

const components = {
  GpHeader,
  TableScenario,
  StepsValidate,
  PageDetail,
  GapAnalysis,
  AnalysisDetail,
  JobSelector,
  UnitSelector,
  SkillSelector,
  AppLoader,
  FullLoader,
  UserProfile,
  ArchivedScenarioStepper,
};

components.install = (Vue) => {
  // Register components
  Object.keys(components).forEach((componentName) => {
    const component = components[componentName];
    if (component && componentName !== 'install') {
      Vue.component(componentName, component);
    }
  });
};

export default components;
