<template>
  <div class="login-page">
    <div class="login-page__container">
      <div class="login-brand">
        <h2 class="login-brand__title">
          <i class="fa fa-analytics" />
          SWP
        </h2>
      </div>
      <div class="login-card">
        <div class="login-card__content">
          <h4 class="login-card__title">
            CONNEXION
          </h4>
          <form class="login-card__form" @submit.prevent="loginSubmit()">
            <div class="mb-4">
              <b-form-group label="Adresse mail" label-for="form-email">
                <b-form-input id="form-email" v-model="formAuth.username" required />
              </b-form-group>
            </div>
            <div class="mb-4">
              <b-form-group label="Mot de passe" label-for="form-pass">
                <b-form-input
                  id="form-pass"
                  v-model="formAuth.password"
                  type="password"
                  required
                />
              </b-form-group>
            </div>
            <button class="btn btn-primary login-card__form__btn" type="submit" :disabled="loading">
              <span v-if="loading">
                <i class="fas fa-spinner-third fa-spin" />
              </span>
              SE CONNECTER
            </button>
          </form>
          <p v-if="loginError" class="text-danger text-center mt-2">
            Les informations d'identification sont invalides
          </p>
          <p class="text-right my-1 xsmall text-muted mt-3">
            V: {{ appVersion }}
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Cookies from 'js-cookie';
import AuthService from '@/providers/services/auth';
import packageJson from '@/../package.json';

export default {
  data() {
    return {
      formAuth: {
        username: null,
        password: null,
      },
      loading: false,
      loginError: false,
      appVersion: packageJson.version,
    };
  },
  methods: {
    async loginSubmit() {
      if (this.formAuth.username && this.formAuth.password) {
        this.loading = true;
        try {
          const resp = await AuthService.login({
            username: this.formAuth.username,
            password: this.formAuth.password,
          });
          Cookies.set('gpec', resp.token);
          this.setToken(resp.token);
          this.setUser(resp);
          await Promise.all([
            this.loadBasicData(),
            this.getConfiguration()
          ]);
          this.$router.push('/collab');
        } catch (error) {
          // console.log('error: ', error);
          this.loginError = true;
          setTimeout(() => {
            this.loginError = false;
          }, 1500);
        } finally {
          this.loading = false;
        }
      }
    },
    ...mapActions({
      setToken: 'auth/setAuthToken',
      setUser: 'auth/setUser',
      loadBasicData: 'auth/basic/loadBasicData',
      getConfiguration: 'auth/getConfiguration',
    }),
  },
};
</script>
