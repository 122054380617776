<template>
  <div v-if="competence && competenceScore" class="align-items-center mb-3">
    <b-collapse :id="`collapse-${competence._id}${index}`" v-model="activeCollapse" class="mx-1">
      <div v-for="(itemChild1, idx1) in children" :key="idx1">
        <div v-if="globalValues[itemChild1._id]" class="card p-0 bg-light mb-2">
          <div v-if="globalValues[itemChild1._id]" class="">
            <div class="mb-2">
              <competence-tree-head
                :competence="itemChild1"
                :competence-score="globalValues[itemChild1._id]"
                :current-year-competence-score="currentYearGlobalValues[itemChild1._id]"
                :global-values="globalValues"
                :index="idx1"
                :scenario-key="scenarioKey"
                :max-depth="maxDepth"
              />
              <competence-tree-body
                v-if="activeCollapse"
                :competence="itemChild1"
                :competence-score="globalValues[itemChild1._id]"
                :current-year-global-values="currentYearGlobalValues"
                :global-values="globalValues"
                :index="idx1"
                :scenario-key="scenarioKey"
                :max-depth="maxDepth"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import CompetenceTreeHead from '@/components/analysis-tabs/competences/CompetenceTreeHead.vue';
// eslint-disable-next-line
import CompetenceTreeBody from '@/components/analysis-tabs/competences/CompetenceTreeBody.vue';

export default {
  name: 'CompetenceTreeBody',
  components: {
    CompetenceTreeHead,
    CompetenceTreeBody,
  },
  props: {
    competence: {
      type: Object,
      default() {
        return null;
      },
    },
    competenceScore: {
      type: Object,
      default() {
        return null;
      },
    },
    currentYearGlobalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    maxDepth: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      activeCollapse: false,
    };
  },
  computed: {
    children() {
      if (this.competence.children) {
        return this.competence.children.filter((c) => c.isVisible);
      }
      return [];
    },
  },
};
</script>
