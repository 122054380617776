<template>
  <div v-if="competence && competenceScore" class="align-items-center">
    <div
      v-b-toggle="`collapse-${competence._id}${index}`"
      class="row"
      :class="`px-${maxDepth - competence.depth}`"
    >
      <div class="col-4">
        <div class="d-flex">
          <span :style="{ opacity: competence.children.length ? 1 : 0 }">
            <i class="fas fa-caret-down mt-1" />
            <i class="fas fa-caret-right mt-1" />
          </span>
          <div class="ml-2">
            <b>{{ competence.names[0].label }}</b>
            <div class="d-flex">
              <div>
                <i class="fas fa-star text-primary" />
                <span class="text-muted">
                  {{ (currentYearCompetenceScore[scenarioKey].averageScore / 25).toFixed(2) }}
                </span>
                <span class="mx-1">></span>
                <span>{{ (competenceScore[scenarioKey].averageScore / 25).toFixed(2) }}</span>
              </div>
              <div class="ml-3">
                <i class="fas fa-user text-primary" />
                <span class="text-muted">
                  {{ currentYearCompetenceScore[scenarioKey].employeeCount }}
                </span>
                <span class="mx-1">></span>
                <span>{{ competenceScore[scenarioKey].employeeCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 text-center">
        <span
          v-if="competenceScore && competenceScore[scenarioKey].evolution === -1"
          class="text-danger"
        >
          <i class="fas fa-arrow-square-down fa-2x" />
        </span>
        <span
          v-if="competenceScore && competenceScore[scenarioKey].evolution === 0"
          class="text-warning"
        >
          <i class="fas fa-arrow-square-right fa-2x" />
        </span>
        <span
          v-if="competenceScore && competenceScore[scenarioKey].evolution === 1"
          class="text-success"
        >
          <i class="fas fa-arrow-square-up fa-2x" />
        </span>
      </div>
      <div class="col-3">
        <ProgressTooltip
          :id="`${competence._id}-current`"
          :scores="competenceScore.current.scoreRepartition"
        />
      </div>
      <div class="col-3">
        <ProgressTooltip
          :id="`${competence._id}-${scenarioKey}`"
          :scores="competenceScore[scenarioKey].scoreRepartition"
        />
      </div>
    </div>
    <b-collapse :id="`collapse-${competence._id}${index}`" v-model="activeCollapse" class="w-100">
      <div v-for="(itemChild1, idx1) in children" :key="idx1">
        <div v-if="globalValues[itemChild1._id]" class="card p-0 my-2 bg-light">
          <div v-if="globalValues[itemChild1._id]" class="">
            <div class="mb-2">
              <competence-tree-head
                :competence="itemChild1"
                :competence-score="globalValues[itemChild1._id]"
                :current-year-competence-score="currentYearGlobalValues[itemChild1._id]"
                :global-values="globalValues"
                :index="idx1"
                :scenario-key="scenarioKey"
                :max-depth="maxDepth"
              />
              <competence-tree-body
                v-if="activeCollapse"
                :competence="itemChild1"
                :competence-score="globalValues[itemChild1._id]"
                :current-year-competence-score="currentYearGlobalValues[itemChild1._id]"
                :current-year-global-values="currentYearGlobalValues"
                :global-values="globalValues"
                :index="idx1"
                :scenario-key="scenarioKey"
                :max-depth="maxDepth"
              />
            </div>
          </div>
        </div>
      </div>
    </b-collapse>
  </div>
</template>
<script>
import ProgressTooltip from '@/components/all/ProgressTooltip.vue';
import CompetenceTreeHead from '@/components/analysis-tabs/competences/CompetenceTreeHead.vue';
import CompetenceTreeBody from '@/components/analysis-tabs/competences/CompetenceTreeBody.vue';

export default {
  name: 'CompetenceTree',
  components: {
    ProgressTooltip,
    CompetenceTreeHead,
    CompetenceTreeBody,
  },
  props: {
    competence: {
      type: Object,
      default() {
        return {};
      },
    },
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    currentYearGlobalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    maxDepth: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
  data() {
    return {
      activeCollapse: false,
    };
  },
  computed: {
    children() {
      if (this.competence.children) {
        return this.competence.children.filter((c) => c.isVisible);
      }
      return [];
    },
    competenceScore() {
      // eslint-disable-next-line
      return this.globalValues[this.competence._id];
    },
    currentYearCompetenceScore() {
      // eslint-disable-next-line
      return this.currentYearGlobalValues[this.competence._id];
    },
  },
};
</script>

<style scoped>
.not-collapsed .fa-caret-right {
  display: none;
}
.collapsed .fa-caret-down {
  display: none;
}
</style>