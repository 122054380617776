<template>
  <div
    v-if="item"
    class="card-body px-0 py-2 rounded-lg"
    :class="{ 'bg-global-row': item.entity === 'Global' }"
  >
    <div class="text-center mb-0">
      <table-tree-head
        v-if="item"
        :item="item"
        :index="index"
        :active-departure-details="activeDepartureDetails"
        :active-entry-details="activeEntryDetails"
        :active-mobility-details="activeMobilityDetails"
        :active-external-details="activeExternalDetails"
        :show-external-cols="showExternalCols"
        :depth="depth"
        :scenario-key="scenarioKey"
        :editable="editable"
        :previous-year-edits="previousYearEdits"
        :type-scenario-target="typeScenarioTarget"
        @update:item="updateItem"
        @new:edit="addEdit"
      />
    </div>
    <b-collapse
      v-if="item.children"
      :id="`collapse-${item.entity}${index}`"
      v-model="activeCollapse"
      class="list-group m-1"
    >
      <div
        v-for="(itemChild1, idx1) in item.children.filter((c) => c.isEnabled)"
        :key="idx1"
        class="list-group-item px-0 bg-gray-100"
      >
        <table-tree-head
          :item="itemChild1"
          :index="idx1"
          :active-departure-details="activeDepartureDetails"
          :active-entry-details="activeEntryDetails"
          :active-mobility-details="activeMobilityDetails"
          :active-external-details="activeExternalDetails"
          :show-external-cols="showExternalCols"
          :depth="depth"
          :scenario-key="scenarioKey"
          :editable="editable"
          :previous-year-edits="previousYearEdits"
          :type-scenario-target="typeScenarioTarget"
          @update:item="updateItem"
          @new:edit="addEdit"
        />
        <table-tree-body
          v-if="activeCollapse"
          :item="itemChild1"
          :index="idx1"
          :active-departure-details="activeDepartureDetails"
          :active-entry-details="activeEntryDetails"
          :active-mobility-details="activeMobilityDetails"
          :active-external-details="activeExternalDetails"
          :show-external-cols="showExternalCols"
          :depth="depth"
          :scenario-key="scenarioKey"
          :editable="editable"
          :previous-year-edits="previousYearEdits"
          :type-scenario-target="typeScenarioTarget"
          @update:item="updateItem"
          @new:edit="addEdit"
        />
      </div>
    </b-collapse>
  </div>
</template>

<script>
import { computeAllHypothesisNode } from '@/providers/helpers/hypothesis';
import TableTreeHead from '@/components/analysis-tabs/hypothesis/TableTreeHead.vue';
import TableTreeBody from '@/components/analysis-tabs/hypothesis/TableTreeBody.vue';
import HypothesisColSize from './colSize';

export default {
  name: 'TableTreeRow',
  components: {
    TableTreeHead,
    TableTreeBody,
  },
  mixins: [HypothesisColSize],
  props: {
    typeScenarioTarget: {
      type: String,
      required: false,
      default: 'effective',
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    activeDepartureDetails: {
      type: Boolean,
      default: false,
    },
    activeEntryDetails: {
      type: Boolean,
      default: false,
    },
    activeMobilityDetails: {
      type: Boolean,
      default: false,
    },
    activeExternalDetails: { //new active for External column
      type: Boolean,
      default: false,
    },
    showExternalCols: { //new active for External column
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    previousYearEdits: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      activeCollapse: false,
      computeAllHypothesisNode,
      rerenderKey: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.activeCollapse = this.item.activeCollapse || this.activeCollapse;
      },
      deep: true,
    },
  },
  methods: {
    updateItem() {
      this.$emit('update:item', this.item);
      // eslint-disable-next-line no-plusplus
      // this.rerenderKey++;
      this.$forceUpdate();
    },
    addEdit($event) {
      this.$emit('new:edit', $event);
      this.$forceUpdate();
    },
  },
};
</script>
