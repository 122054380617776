<template>
  <div>
    <template v-if="objectValue">
      <TreeSelect
        v-if="options.length"
        v-model="optionSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :options="options"
        :disabled="disabled"
        :placeholder="placeholder"
        :disable-fuzzy-matching="true"
        value-format="object"
      />
    </template>
    <template v-else>
      <TreeSelect
        v-if="options.length"
        v-model="optionSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :options="options"
        :disabled="disabled"
        :placeholder="placeholder"
        :disable-fuzzy-matching="true"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: 'TypeContractSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    globalSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    objectValue: {
      type: Boolean,
      default: true,
    },
    keyOption: {
      type: String,
      required: true,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Sélectionner un type de contrat',
    },
  },
  data() {
    return {
      normalizer(node) {
        return {
          // eslint-disable-next-line
          id: node._id,
          // eslint-disable-next-line
          label: node.name || node.names && node.names[0].label,
        };
      },
      optionSelected: [],
    };
  },
  computed: {
    // stateOptions was implemented in this way because mapState returns
    // its value at compile time and props are assigned at runtime
    options() {
      return this.$store.state.auth.basic[this.keyOption];
    },
  },
  watch: {
    optionSelected(curr) {
      if (curr) {
        this.$emit('selected', curr);
      }
    },
  },
  mounted() {
    if (this.globalSelectedDisabled) {
      const global = {
        _id: 'Global',
        names: [
          {
            label: 'Global',
          },
        ],
        isDisabled: this.globalSelectedDisabled,
      };
      this.optionSelected = [this.objectValue ? global : 'Global'];
    }
    if (this.selection) {
      const options = [...this.optionSelected, ...this.selection];
      this.optionSelected = Array
        // eslint-disable-next-line no-underscore-dangle
        .from(new Set(options.map((a) => a._id ?? a)))
        // eslint-disable-next-line no-underscore-dangle
        .map((_id) => options.find((a) => a._id === _id || a === _id));
    }
  },
};
</script>
