<template>
  <div v-if="hasData" :key="JSON.stringify(currentSelection)">
    <div class="row justify-content-between align-items-center mb-4">
      <div class="col-lg-5 mb-2">
        <main-filter-switcher
          :g-filters="gFilters"
          :selected-fn="selectMainFilterItem"
          :multi="true"
          :selection="selection"
          :allowed="allowed"
          :selection-disabled="false"
          :global-option="true"
          :global-selected-disabled="true"
          :object-value="true"
        />
      </div>
      <div class="col-auto">
        <ScenariosSelector
          :hidden="scenarioMetadata ? [] : ['target']"
          @changed="updateManagerSeries"
        />
      </div>
    </div>
    <hr>
    <div class="form-row">
      <div class="col">
        <highcharts :options="chartManager" />
      </div>
      <div class="col-auto">
        <div class="mb-2">
          Manager
        </div>
        <div class="small">
          <div v-for="(i, idx) in categoriesManager" :key="i" class="form-row">
            <div class="col">
              <b-form-checkbox v-model="ctLegendRight" :value="i" name="fRigth">
                <span>{{ i }}</span>
              </b-form-checkbox>
            </div>
            <div class="col-auto">
              <i
                class="fas fa-circle ml-1 text-primary"
                :style="{ opacity: getOpacity(categoriesManager, idx) }"
              />
            </div>
          </div>
        </div>
        <DataLabelsSwitcher />
      </div>
    </div>
  </div>
  <div v-else>
    No data
  </div>
</template>
<script>
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';
import MainAnalysisFilterMixin from '@/mixins/MainAnalysisFilterMixin';
import SetChartSelectsMixin from '@/mixins/SetChartSelectsMixin';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';

const chartTypeOne = (categories, showDataLabels) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: { enabled: false },
  xAxis: {
    categories,
  },
  yAxis: {
    title: null,
    labels: {
      formatter(item) {
        return `${item.value} %`;
      },
    },
  },
  tooltip: {
    pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
    series: {
      dataLabels: {
        enabled: showDataLabels,
      },
    },
  },
  series: [],
});

export default {
  name: 'ChartTypeManager',
  components: {
    MainFilterSwitcher,
    ScenariosSelector,
    DataLabelsSwitcher,
  },
  mixins: [MainAnalysisFilterMixin, SetChartSelectsMixin],
  props: {
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      ctLegendRight: [],
      refreshSeriesCallback: 'updateManagerSeries',
      managerSeries: [],
    };
  },
  computed: {
    hasData() {
      return !!this.dataEffectifs[this.keyGetter];
    },
    categoriesManager() {
      if (!this.dataEffectifs?.[this.keyGetter]) {
        return []
      }
      const { categories } = this.dataEffectifs?.[this.keyGetter]
      return categories
    },
    chartManager() {
      const chart = chartTypeOne(this.labels, this.showDataLabels);
      chart.series = this.managerSeries;
      return chart;
    },
  },
  watch: {
    ctLegendRight: {
      handler() {
        this.updateManagerSeries(this.currentChecks);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.ctLegendRight = this.categoriesManager;
  },
  methods: {
    getOpacity(items, idx) {
      const val = (1 / items.length) * (idx + 1);
      return val.toFixed(2);
    },
    updateManagerSeries(scenarios) {
      if (!this.dataEffectifs?.[this.keyGetter]) {
        return
      }
      this.managerSeries = []
      const { data } = this.dataEffectifs[this.keyGetter];
      this.ctLegendRight.forEach((i) => {
        scenarios.forEach((entity) => {
          if (data[entity][i.toLowerCase()]) {
            this.managerSeries.push(data[entity][i.toLowerCase()])
          }
        })
      })
    },
  },
};
</script>
