<template>
  <div class="mb-5">
    <b-row>
      <b-col>
        <div class="h5 text-uppercase font-weight-bold" style="text-decoration: underline">
          Ajustez les compétences cibles
        </div>
      </b-col>
      <b-col class="text-right">
        <b-radio-group v-model="localScenario" disabled>
          <b-radio value="structural">
            Structurelle
          </b-radio>
          <b-radio value="conjunctural">
            Conjoncturelle
          </b-radio>
          <b-radio value="noRecruiting">
            Sans recrutement
          </b-radio>
        </b-radio-group>
      </b-col>
    </b-row>
    <hr>
    <div>
      <div>
        Pour ajuster les compétences cibles associées à un métier,
        sélectionnez le métier dans le moteur de recherche ci-dessous
      </div>
      <JobSelector
        :key="key"
        :allowed="filters.jobs || []"
        :global-option="false"
        :multi="false"
        :only-children="true"
        style="max-width: 500px"
        @selected="onUpdateJobs"
      />
      <div v-if="scenarioJobs && scenarioJobs.length" class="mt-3">
        <div>
          Métier(s) modifié(s) pour ce scénario:
        </div>
        <div v-for="(job, i) in scenarioJobs" :key="i">
          <b-badge
            :variant="currentJob && currentJob._id === job._id ? 'secondary' : 'gray-300'"
            @click="select(job)"
          >
            {{ job.name }}
          </b-badge>
        </div>
      </div>
      <div class="my-3">
        <SkillsTableEditor
          :job="currentJob"
          :target="true"
          :years="years"
          :payload="{ scenarioJobs, editedSkills }"
          :hypothesis="hypothesis"
          @update="onUpdateSkill(currentJob, $event)"
        />
      </div>
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('previous')"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          v-if="!readonly"
          variant="link"
          class="text-uppercase"
          @click="$emit('saveDraft')"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button
          class="btn btn-secondary text-uppercase"
          type="button"
          @click="showValidationModal"
        >
          <span>Valider</span>
        </button>
      </div>
    </div>
    <b-modal
      v-model="open"
      hide-header
      hide-footer
      body-class="text-center"
    >
      <div class="py-4">
        <span class="fa fa-check-circle fa-3x text-primary" />
      </div>
      <div class="py-4">
        Votre hypothèse a été enregistrée avec succès
      </div>
      <div class="py-4 d-flex justify-content-between">
        <b-button
          variant="outline-secondary"
          class="text-uppercase w-50 mr-1"
          @click="addNewSkillTable"
        >
          Emettre une nouvelle hypothèse
        </b-button>
        <b-button
          variant="secondary"
          class="d-block text-uppercase w-50 ml-1"
          @click="next"
        >
          Valider mon scénario
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import JobSelector from '@/components/selectors/JobSelector'
import SkillsTableEditor from '@/components/scenario/skills/SkillsTableEditor'
import { getJobNameById } from '@/services/jobs'
import { buildPayloadFromScenarioCustomSkills } from '@/services/skills'

export default {
  components: { SkillsTableEditor, JobSelector },
  props: {
    scenario: {
      type: String,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {}
    },
    years: {
      type: Array,
      required: true
    },
    editedSkills: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      key: 0,
      localScenario: '',
      currentJob: null,
      payload: {
        yearJobSkillChanges: {}
      },
      open: false,
      currentHypothesis: null,
      hypothesis: {},
      scenarioJobs: []
    }
  },
  watch: {
    editedSkills: {
      handler() {
        if (this.editedSkills && Object.keys(this.editedSkills).length) {
          this.scenarioJobs = this.extractEditedJobs(this.editedSkills)
          this.currentHypothesis = {
            id: this.currentJob?._id,
            items: {
              formatted: this.editedSkills
            }
          }
        }
      },
      immediate: true
    }
  },
  mounted() {
    this.localScenario = this.scenario

  },
  methods: {
    extractEditedJobs (editedSkills) {
      if (Object.keys(editedSkills).length) {
        const keys = Object.keys(editedSkills)
        const jobs = Object.keys(editedSkills[keys[0]])
        return jobs.map(j => ({
          name: getJobNameById(j, this.jobTree),
          id: j,
          ...this.findOptionTree(this.jobTree, j)
        }))
      } else {
        return []
      }
    },
    showValidationModal () {
      if (this.currentHypothesis?.items?.formatted) {
        Object.keys(this.currentHypothesis.items.formatted).forEach(key => {
          this.payload.yearJobSkillChanges[key] = {
            ...buildPayloadFromScenarioCustomSkills(this.payload.yearJobSkillChanges?.[key]),
            ...this.currentHypothesis.items.formatted[key]
          }
        })
      }
      this.open = true
    },
    onUpdateJobs (job) {
      if (job) {
        this.currentJob = { ...job }
      } else {
        this.currentJob = null
      }
    },
    onUpdateSkill (job, items) {
      this.currentHypothesis = {
        id: this.currentJob._id,
        items
      }
      this.hypothesis[this.currentHypothesis?.id] = this.currentHypothesis
    },
    addNewSkillTable () {
      this.currentJob = null
      this.key++
      this.currentHypothesis = null
      this.open = false
    },
    next () {
      this.$emit('save', this.payload)
    },
    select (jobObject) {
      if (!jobObject) {
        return
      }
      this.currentJob = { ...jobObject }
    },
  },
};
</script>
