<template>
  <div>
    <b-card v-if="scenarioMetadata" no-body>
      <NoData v-if="!workforceCount" />
      <b-tabs
        v-else-if="scenarioMetadata"
        card
        pills
        justified
        nav-class="p-0 border-right"
        nav-wrapper-class="tabs-header-h"
        lazy
      >
        <RatesTab
          :rates="rates"
          :metadata="scenarioMetadata"
          :years="years"
          :scenario="currentScenario"
        >
          <!-- :hidden="
                  scenarioMetadata
                    ? comparing
                      ? ['predicted']
                      : ['predicted', 'optimal', 'target']
                    : ['target']
                " -->
          <!-- :default-active="scenarioMetadata ? 'target'
                                : currentScenario || defaultScenario" -->
          <div class="d-flex justify-content-end">
            <ScenariosSelector
              :multi="false"
              :hidden="scenarioMetadata ? [] : ['target']"
              :displayed-scenarios="['predicted', 'optimal', 'target']"
              :default-active="currentScenario || defaultScenario"
              @changed="updateCurrentScenario"
            />
          </div>
        </RatesTab>
        <StaffTab
          :filters="gFilters"
          :scenario="currentScenario"
          :perimeter="perimeter"
          :metadata="scenarioMetadata"
        >
          <div class="d-flex justify-content-between align-items-center">
            <DownloadFileModal
              :show-modal="showDownloadModal"
              :display-download-loader.sync="displayDownloadLoader"
              @set-modal-visibility="setDownloadFileModalVisibility"
              @set-random-string="setRandomString"
              @download-data="downloadData"
            >
              <template v-slot:open>
                <div class="col mb-3 px-0">
                  <div class="col px-0 py-2">
                    <b-button variant="outline-secondary" @click="openDownloadModal">
                      <i class="fa fa-download mr-2" />
                      <span class="ml-2">TELECHARGER L'EXCEL</span>
                    </b-button>
                  </div>
                </div>
              </template>
              <template v-slot:description>
                <p>
                  Vous pouvez télécharger en format excel les données de « prévision des effectifs »
                  pour le périmètre que vous avez sélectionné.
                </p>
                <p>
                  Il s'agit de données stratégiques et personnelles de collaborateurs. Aussi,
                  <span class="text-underline"
                    >l'usage de ces données correspond à un important niveau de confidentialité.</span
                  >
                </p>
                <p>
                  Le fichier excel que vous téléchargez est protégé par un mot de passe qui vous est
                  <span class="text-underline text-bold">affiché UNE SEULE FOIS</span> lorsque vous
                  déclenchez le téléchargement.
                </p>
                <p class="text-underline text-bold">Pensez à bien noter le mot de passe.</p>
              </template>
            </DownloadFileModal>
            <ScenariosSelector
              :multi="false"
              :hidden="scenarioMetadata ? [] : ['target']"
              :displayed-scenarios="['predicted', 'optimal', 'target']"
              :default-active="currentScenario || defaultScenario"
              @changed="updateCurrentScenario"
            />
          </div>
        </StaffTab>
        <SkillsTab
          :years="Object.keys(allYearsPredictions)"
          :jobs="perimeter.jobs || []"
          :edited-skills="scenarioMetadata.yearJobSkillChanges || {}"
          :scenario="currentScenario"
        >
        <!-- :hidden="
                scenarioMetadata
                  ? comparing
                    ? ['predicted']
                    : ['predicted', 'optimal', 'target']
                  : ['target']
              " -->
          <!-- :default-active="scenarioMetadata ? 'target'
                                : currentScenario || defaultScenario" -->
          <div class="d-flex justify-content-end">
            <ScenariosSelector
              :multi="false"
              :hidden="scenarioMetadata ? [] : ['target']"
              :displayed-scenarios="['predicted', 'optimal', 'target']"
              :default-active="currentScenario || defaultScenario"
              @changed="updateCurrentScenario"
            />
          </div>
        </SkillsTab>
        <CommentTab :metadata="scenarioMetadata">
          <!-- :hidden="
                scenarioMetadata
                  ? comparing
                    ? ['predicted']
                    : ['predicted', 'optimal', 'target']
                  : ['target']
              " -->
          <div class="d-flex justify-content-end">
            <ScenariosSelector
              :multi="false"
              :hidden="scenarioMetadata ? [] : ['target']"
              :displayed-scenarios="['predicted', 'optimal', 'target']"
              :default-active="currentScenario || defaultScenario"
              @changed="updateCurrentScenario"
            />
          </div>
        </CommentTab>
      </b-tabs>
    </b-card>
    <div v-if="!scenarioMetadata && workforceCount">
      <StaffTab
        :tab="false"
        :metadata="scenarioMetadata"
        :perimeter="perimeter"
        :scenario="currentScenario"
        :filters="gFilters"
      >
        <div class="d-flex justify-content-between align-items-center">
          <DownloadFileModal
            :show-modal="showDownloadModal"
            :display-download-loader.sync="displayDownloadLoader"
            @set-modal-visibility="setDownloadFileModalVisibility"
            @set-random-string="setRandomString"
            @download-data="downloadData"
          >
            <template v-slot:open>
              <div class="col mb-3 px-0">
                <div class="col px-0 py-2">
                  <b-button variant="outline-secondary" @click="openDownloadModal">
                    <i class="fa fa-download mr-2" />
                    <span class="ml-2">TELECHARGER L'EXCEL</span>
                  </b-button>
                </div>
              </div>
            </template>
            <template v-slot:description>
              <p>
                Vous pouvez télécharger en format excel les données de « prévision des effectifs »
                pour le périmètre que vous avez sélectionné.
              </p>
              <p>
                Il s'agit de données stratégiques et personnelles de collaborateurs. Aussi,
                <span class="text-underline"
                  >l'usage de ces données correspond à un important niveau de confidentialité.</span
                >
              </p>
              <p>
                Le fichier excel que vous téléchargez est protégé par un mot de passe qui vous est
                <span class="text-underline text-bold">affiché UNE SEULE FOIS</span> lorsque vous
                déclenchez le téléchargement.
              </p>
              <p class="text-underline text-bold">Pensez à bien noter le mot de passe.</p>
            </template>
          </DownloadFileModal>
          <ScenariosSelector
            :multi="false"
            :hidden="scenarioMetadata ? [] : ['target']"
            :displayed-scenarios="['predicted', 'optimal', 'target']"
            :default-active="currentScenario || defaultScenario"
            @changed="updateCurrentScenario"
          />
        </div>
      </StaffTab>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from "vuex";
import ScenariosSelector from "@/components/shared/ScenariosSelector.vue";
import HypothesisMixin from "@/mixins/HypothesisMixin";
import NoData from "@/components/all/NoData.vue";
import SkillsTab from "@/components/analysis-tabs/hypothesis/tabs/SkillsTab";
import CommentTab from "@/components/analysis-tabs/hypothesis/tabs/CommentTab";
import StaffTab from "@/components/analysis-tabs/hypothesis/tabs/StaffTab";
import RatesTab from "@/components/analysis-tabs/hypothesis/tabs/RatesTab";
import DownloadFileModal from "@/components/analysis-tabs/modals/DownloadFileModal";

export default {
  components: {
    RatesTab,
    SkillsTab,
    ScenariosSelector,
    NoData,
    CommentTab,
    StaffTab,
    DownloadFileModal,
  },
  mixins: [HypothesisMixin],
  props: {
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {},
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => "predicted",
    },
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    perimeter: {
      type: Object,
      required: false,
      default: () => {},
    },
    rates: {
      type: Object,
      required: false,
      default: () => {},
    },
    years: {
      type: Array,
      required: true,
    },
    comparing: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    globalFilter: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      paths: [],
      currentScenario: "predicted",
      showDownloadModal: false,
      randomString: null,
      displayDownloadLoader: false
    };
  },
  created() {
    if (this.$route.params.id && this.$route.query.default === '0') {
      this.updateCurrentScenario(['target']);
    }
  },
  computed: {
    ...mapState("analysis", {
      gFilters: (state) => state.filters,
    }),
    ...mapGetters("analysis", {
      allYearsPredictions: "allYearsData",
      predictions: "predictions",
    }),
  },
  methods: {
    updateCurrentScenario(scenario) {
      [this.currentScenario] = scenario;
    },
    openDownloadModal() {
      this.showDownloadModal = true;
    },
    setDownloadFileModalVisibility(value) {
      this.showDownloadModal = value;
    },
    setRandomString(value) {
      this.randomString = value;
    },
    async downloadData() {
      const filter = { ...this.globalFilter }
      for (const key of Object.keys(filter)) {
        filter[key] = filter[key] || []
      }
      const body = {
        id: this.scenarioMetadata?._key,
        filters: {
          password: this.randomString,
          filterAttribute: this.gFilters.type,
          ...filter
        }
      }
      if (this.$route?.query?.id2) body['id2'] = this.$route.query.id2;
      const data = await this.downloadStaffingTableZip(body);
      this.displayDownloadLoader = false;
      saveAs(new Blob([data]), "StaffingTable.zip")
    },
    ...mapActions({
      downloadStaffingTableZip: "analysis/downloadStaffingTableZip",
    }),
  },
};
</script>

<style lang="scss">
.col {
  .col {
    padding: 0;
  }
}
.text-underline {
  text-decoration-line: underline;
}
.text-bold {
  font-weight: bold;
}
</style>
