export function generateRandomString() { // mainly for excel download
  const chars = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
  let randomString = "";
  for (let i = 0; i < 5; i++) {
    let randomChar = chars.charAt(Math.floor(Math.random() * chars.length));
    randomString += randomChar;
  }
  let specialChars = "!@#$%^&*()_+";
  for (let i = 0; i < 2; i++) {
    let randomSpecialChar = specialChars.charAt(Math.floor(Math.random() * specialChars.length));
    let randomPosition = Math.floor(Math.random() * randomString.length);
    randomString =
      randomString.slice(0, randomPosition) +
      randomSpecialChar +
      randomString.slice(randomPosition);
  }
  return randomString;
}

export const formatCostType = (value, type) => {
  if (value === 0) {
    return '0';
  } else if (value < 0) {
    const formattedValue = formatCostType(Math.abs(value), type);
    return `-${formattedValue}`;
  } else if (value < 1000) {
    if (type === 'effective' || type === 'fte') {
      const formatted = value.toFixed(1);
      return formatted.endsWith('.0') ? `${parseInt(formatted)}` : `${formatted}`;
    } else if (type === 'budget') {
      const formatted = value.toFixed(1);
      return formatted.endsWith('.0') ? `${parseInt(formatted)}€` : `${formatted}€`;
    }
  } else if (value < 1000000) {
    const formattedValue = (value / 1000).toFixed(1);
    if (type === 'effective' || type === 'fte') {
      return `${formattedValue}K`;
    } else if (type === 'budget') {
      return `${formattedValue}K€`;
    }
  } else {
    const formattedValue = (value / 1000000).toFixed(1);
    if (type === 'effective' || type === 'fte') {
      return `${formattedValue}M`;
    } else if (type === 'budget') {
      return `${formattedValue}M€`;
    }
  }
};

export const findUltimateParentWithChild = (tree, targetKey, key) => {
  const findParent = (node, targetKey, parentPath = []) => {
    if (node[key] === targetKey) {
      return { ultimateParent: parentPath[0], child: node };
    }
    if (node.children) {
      for (const childNode of node.children) {
        const newPath = [...parentPath, node];
        const result = findParent(childNode, targetKey, newPath);
        if (result) {
          return result;
        }
      }
    }

    return null;
  }
  for (const rootNode of tree) {
    const superParentPath = findParent(rootNode, targetKey);
    if (superParentPath) {
      return superParentPath;
    }
  }
  return null;
}
