<template>
  <b-card body-class="py-3">
    <h6 class="text-uppercase mb-4">
      <b>Raisons d’attrition actuelles</b>
    </h6>
    <div
      v-for="(item, i) in attritionReasons"
      :key="i"
      class="form-row align-items-center mb-2"
    >
      <div class="col-5 small" style="line-height: 1">
        {{ reasons[item.label]['Libellé'] }}
      </div>
      <div class="col">
        <b-progress :max="100" height="1.25rem">
          <b-progress-bar :value="item.value" class="text-right pr-2">
            <strong>{{ Math.round(item.value) }}</strong>
          </b-progress-bar>
        </b-progress>
        <small>{{ item.percent }}%</small>
      </div>
    </div>
  </b-card>
</template>

<script>
import { reasons } from "@/services/staff";

export default {
  props: {
    attritionReasons: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      reasons
    }
  }
}
</script>
