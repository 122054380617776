<template>
  <div class="mb-4">
    <b-row>
      <b-col class="d-flex mb-4">
          <div class="h5 m-0 text-uppercase font-weight-bold" style="text-decoration: underline">
            Projection au {{ date }}
          </div>
          <b-badge pill variant="gray-200" class="mx-3 py-2 px-3 text-primary">
            {{ scenarioLabel }}
          </b-badge>
        </b-col>
    </b-row>
    <b-row class="sgb__background">
      <b-col class="col-2 py-2 px-0">
        <div :class="[showExternalCols ? 'col-1' : 'col-2']">
          <b-popover
            :target="`popover-target-${customJob ? 'hyp-table-cjob': 'hyp-table'}`"
            triggers="hover"
            placement="top"
            variant="info"
          >
            Afficher/Cacher les externes
          </b-popover>
          <div :id="`popover-target-${customJob ? 'hyp-table-cjob': 'hyp-table'}`">
            <b-form-checkbox
              v-model="showExternalCols"
              name="check-button"
              switch
              class="hypothesis-table-external__checkbox boxfont-weight-bolder"
            >
              Externes
            </b-form-checkbox>
          </div>
        </div>
      </b-col>
      <b-col class="col-1 border-left" />
      <b-col class="col-3">
        <ScenarioGroupButtons
          :buttons="defaultScenarioButtons"
          :default-value="scenarioTarget"
          @send-type="getScenarioType"
        />
      </b-col>
      <b-col class="col-6 btn__modals">
        <EditBudgetModal
          v-if="scenarioTarget === 'budget'"
          :jobs="jobsCostTree"
          :period-type="periodType"
        />
        <AddNewJobModal @add="addNewJob" class="ml-3"/>
      </b-col>
    </b-row>
    <hr>
    <HypothesisTableEditor
      v-if="items && items.length > 1"
      ref="tableEditor"
      :rows="items"
      :edits="edits"
      :current-scenario="currentScenario"
      :selection="selection"
      :filters="{ type: 'Job' }"
      :can-filter="false"
      :editable="true"
      :type-scenario-target="scenarioTarget"
      :hidden-external-switcher="hiddenExternalSwitcher"
      @new:edit="addEdit"
      @update:item="updateItem"
    />
    <div v-else>
      <b-card class="p-4 m-4">
        <h5>Les filtres ne retournent aucun résultat.</h5>
      </b-card>
    </div>
    <div v-if="newJobsItems.length" class="my-5">
      <b-row>
        <b-col class="d-flex justify-content-start align-items-center">
          <div class="h5 m-0 text-uppercase font-weight-bold" style="text-decoration: underline">
            NOUVEAUX METIERS CREES POUR CE SCENARIO
          </div>
        </b-col>
      </b-row>
      <hr>
      <CustomJobHypothesisTableEditor
        v-if="newJobsItems && newJobsItems.length >= 1"
        ref="customTableEditor"
        :rows="newJobsItems"
        :current-scenario="currentScenario"
        :selection="selection"
        :filters="{ type: 'Job' }"
        :can-filter="false"
        :editable="true"
        :type-scenario-target="scenarioTarget"
        :show-external-cols="showExternalCols"
        :hidden-external-switcher="hiddenExternalSwitcher"
        @new:edit="addNewJobEdit"
        @update:item="updateNewJob"
        @update:job="updateCustomJob"
        @updateCustom:job="onUpdateCustomJob"
      />
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('previous')"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          variant="link"
          class="text-uppercase"
          @click="$emit('saveDraft', { customJobs: customJobs })"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button class="btn btn-secondary text-uppercase" type="button" @click="next">
          <span>Valider {{ dateYear }}</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HypothesisMixin from '@/mixins/HypothesisMixin';
import HypothesisTableEditor from '@/components/analysis-tabs/hypothesis/HypothesisTableEditor.vue';
import ScenarioGroupButtons from '@/components/shared/ScenarioGroupButtons.vue';
import EditBudgetModal from '@/components/steps/EditBudgetModal'
import AddNewJobModal from '@/components/steps/AddNewJobModal'
import { transformNewJobsToHypothesisTableEditorItems } from '@/services/jobs'
import {
  formatSkillToSkillsTableEditorItem,
  getSkillsObjects
} from '@/services/skills'
import CustomJobHypothesisTableEditor
  from '@/components/analysis-tabs/hypothesis/customjob/CustomJobHypothesisTableEditor'
import { mapActions } from 'vuex'

export default {
  components: {
    CustomJobHypothesisTableEditor,
    ScenarioGroupButtons,
    EditBudgetModal,
    AddNewJobModal,
    HypothesisTableEditor
  },
  mixins: [HypothesisMixin],
  props: {
    customJob: {
      type: Boolean,
      required: false,
      default: () => false
    },
    scenario: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    rows: {
      type: Object,
      required: true,
    },
    customJobItems: {
      type: Array,
      required: false,
      default: () => [],
    },
    filters: {
      type: Object,
      required: true,
    },
    currentEdits: {
      type: Array,
      required: false,
      default: () => [],
    },
    scenarioTarget: {
      type: String,
      required: false,
      default: 'effective',
    },
    periodType: {
      type: String,
      required: false,
      default: 'all',
    },
  },
  data() {
    return {
      showExternalCols: false,
      hiddenExternalSwitcher: true,
      defaultScenarioButtons:[
        {
          name: 'EFF',
          key: 'effective',
          popover: 'Nombre de collaborateurs présents à la date de projection.',
          active: true
        },
        {
          name: 'ETP',
          key: 'fte',
          popover: `Equivalent Temps Plein, Nombre de collaborateurs rapporté
          second au temps de travail effectif à la date de projection.`,
          active: false
        },
        {
          name: 'Coût',
          key: 'budget',
          active: false
        },
      ],
      localScenario: '',
      currentScenario: 'target',
      edits: [],
      customEdits: [],
      items: [],
      filtersKeys: {
        Job: 'jobs',
        Unit: 'units',
      },
      type: 'Job',
      newJobs: [],
      jobsCostTree: []
    };
  },
  computed: {
    newJobsItems () {
      return transformNewJobsToHypothesisTableEditorItems(this.newJobs, this.year)
    },
    scenarioLabel () {
      let label = ''
      switch (this.localScenario) {
        case 'structural':
          label = 'Structurelle'
          break
        case 'noRecruiting':
          label = 'Sans recrutement'
          break
        case 'conjunctural':
          label = 'Conjoncturelle'
          break
      }
      return label
    },
    selection () {
      return this.filters?.[this.filtersKeys?.[this.type]] || [];
    },
    date () {
      return `31/12/${this.year}`;
    },
    dateYear () {
      return this.year;
    },
    customJobs () {
      return this.newJobs.map(job => job._id)
    }
  },
  watch: {
    showExternalCols (val) {
      this.$refs.tableEditor.toggleExternalCol(val);
      this.$refs.customTableEditor.toggleExternalCol(val);
    }
  },
  async mounted() {
    if (this.customJobItems.length) {
      this.newJobs = this.customJobItems.map(cj => {
        return {
          ...cj,
          formData: this.customJobToFormData(cj)
        }
      })
    }
    if (this.scenarioTarget === 'budget') this.getJobsCostTree(this.periodType);
    this.loadData();
    this.localScenario = this.scenario
  },
  methods: {
    ...mapActions({
      getAssumptionsByCost: 'scenarios/getAssumptionsByCost'
    }),
    async getJobsCostTree(periodType) {
      const jobs = await this.getAssumptionsByCost();
      this.jobsCostTree = this.parseRowsToJobCostTree(jobs, periodType)
    },
    getScenarioType(type) {
      this.$emit('change-scenario', type);
    },
    customJobToFormData(cj) {
      cj.sk
      const skills = this.addSkillsToFormSkills(cj.skills)
      const form = {
        name: cj.names[0].label,
        family: cj.parents[0]['#'],
        description: cj.names[0].desc,
        date: cj.targetCreationDate,
        experience: cj.seniorityType,
        manager: cj.isManagerJob,
        units: cj.filter.units,
        sites: cj.filter.sites,
        costCentres: cj.filter.costCentres,
        years: [
          { label: 2023, value: cj.yearTargetEmployeeCount['2023'] },
          { label: 2024, value:  cj.yearTargetEmployeeCount['2024'] },
          { label: 2025, value:  cj.yearTargetEmployeeCount['2025'] }
        ],
        skills: skills
      }
      return form
    },
    addSkillsToFormSkills (skills) {
      const skillsObjects = getSkillsObjects([...skills], '_id', this.skillTree)
      let skillsResponse = []
      skillsObjects.forEach(skill => {
        // check if skill exists in new job skills
        if (!skillsResponse.filter(jsk => jsk.uid === skill.uid).length) {
          // if not, we can add skill in new job skills
          skillsResponse.push(formatSkillToSkillsTableEditorItem(skill))
        }
      })
      return skillsResponse
    },
    loadData() {
      if (this.currentEdits) {
        this.edits = [...this.currentEdits, ...this.edits];
      }
      if (this.type && ['Job', 'Unit'].includes(this.type)) {
        // eslint-disable-next-line max-len
        this.items = this.parseItemsTreeToNestedRowsArray(
          this.rows,
          this.currentScenario,
          this.type
        );
      } else {
        this.items = [];
      }
    },
    addEdit(edit) {
      this.edits.push(edit);
      this.edits = [...this.edits.filter(item => item)];
      this.$emit('updateTable', this.edits);
      this.$forceUpdate();
    },
    updateItem() {
      this.loadData();
    },
    addNewJob ({ job, data, formData }) {
      console.log('Add a New Job', job, data, formData)
      this.newJobs.push({ ...job, ...data, formData: { ...formData } })
    },
    updateNewJob (e) {
      console.log('Update New Job', e)
    },
    addNewJobEdit (e) {
      console.log('Add New Job Edit', e)
      this.edits.push(e);
      this.edits = [...this.edits.filter(item => item)];
      this.$emit('updateTable', this.edits);
      this.$forceUpdate();
    },
    onUpdateCustomJob(e) {
      console.log('onUpdateCustomJob Edit', e)

      /* this.edits.push(e); */
      this.edits = [...this.edits.filter(item => item), ...e.filter(item => item)];
      this.$emit('updateTable', this.edits);
      this.$forceUpdate();
    },
    updateCustomJob (e) {
      console.log('Update New CUSTOM JOB', e)
    },
    next () {
      this.$emit('next', { customJobs: this.customJobs })
    }
  },
};
</script>

<style scoped>
.sgb__background{
  background-color: #fff;
  width: 100%;
  padding: 20px;
  margin: auto;
  border-radius: 10px;
}

.btn__modals{
  display: flex;
  flex-direction: row;
  justify-content: right;
}

</style>
