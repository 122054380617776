<template>
  <div>
    <b-card no-body>
      <NoData v-if="!workforceCount" class="mx-3" />
      <b-tabs
        v-else
        card
        pills
        justified
        nav-class="p-0 border-right"
        nav-wrapper-class="tabs-header-h"
        lazy
      >
        <b-tab title="Répartition des compétences">
          <RepartitionCompetence
            :graph-data="graphData"
            :global-values="globalValues"
            :attritions-values="attritionsValues"
            :augmentations-values="augmentationsValues"
            :totals-values="totalsValues"
            :default-scenario="defaultScenario"
            :scenario-metadata="scenarioMetadata"
          />
        </b-tab>
        <b-tab title="Barrettes de compétences">
          <BarCompetence
            :global-values="globalValues"
            :current-year-global-values="currentYearGlobalValues"
            :default-scenario="defaultScenario"
            :scenario-metadata="scenarioMetadata"
          />
        </b-tab>
        <b-tab title="Représentations graphiques">
          <GraphCompetence
            :global-values="globalValues"
            :default-scenario="defaultScenario"
            :scenario-metadata="scenarioMetadata"
          />
        </b-tab>
        <b-tab title="Tableau comparatif">
          <TableCompetence
            :global-values="globalValues"
            :g-filters="gFilters"
            :default-scenario="defaultScenario"
            :scenario-metadata="scenarioMetadata"
          />
        </b-tab>
      </b-tabs>
    </b-card>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// eslint-disable-next-line max-len,vue/max-len
import RepartitionCompetence from '@/components/analysis-tabs/competences/tabs/RepartitionCompetence.vue';
import BarCompetence from '@/components/analysis-tabs/competences/tabs/BarCompetence.vue';
import GraphCompetence from '@/components/analysis-tabs/competences/tabs/GraphCompetence.vue';
import TableCompetence from '@/components/analysis-tabs/competences/tabs/TableCompetence.vue';
import NoData from '@/components/all/NoData.vue';

export default {
  components: {
    NoData,
    RepartitionCompetence,
    BarCompetence,
    GraphCompetence,
    TableCompetence,
  },
  props: {
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => 'predicted',
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  computed: {
    ...mapState({
      gFilters: (state) => state.analysis.filters,
    }),
    ...mapGetters('analysis/competences', {
      graphData: 'graphDataCompetence',
      attritionsValues: 'attritionsCompetence',
      augmentationsValues: 'augmentationsCompetence',
      totalsValues: 'totalsCompetence',
      globalValues: 'globalValuesCompetence',
      currentYearGlobalValues: 'currentYearGlobalValuesCompetence',
    }),
  },
};
</script>
