<template>
  <div
    class="position-sticky fixed-top bg-white list-group p-2 pb-4 container-fluid"
    style="z-index: 900"
  >
    <b-row>
      <b-col cols="2">
        <span class="sr-only">SELECTOR</span>
      </b-col>
      <b-col cols="1" />
      <b-col cols="4">
        <span class="badge badge-secondary badge-pill py-2 d-block text-white"> DEPARTS </span>
      </b-col>
      <b-col cols="2">
        <span class="badge badge-dark badge-pill py-2 d-block"> MOBILITES </span>
      </b-col>
      <b-col cols="2">
        <span class="badge badge-info badge-pill py-2 d-block"> ARRIVEES </span>
      </b-col>
      <b-col cols="1" />
    </b-row>
    <b-row class="text-center mt-3 font-12 font-weight-bold">
      <b-col cols="2">
        <span class="sr-only">SELECTOR</span>
      </b-col>
      <b-col cols="1">
        <div class="text-dark" />
      </b-col>
      <b-col cols="4">
        <div class="d-flex text-secondary">
          <div class="detail__item same-width">
            Taux de départ (hors retraite)
            <span id="departure_rate">
              <i class="fal fa-question-circle bubble-info" />
            </span>
            <b-popover target="departure_rate" triggers="hover">
              Calculé selon la formule suivante appliquée au périmètre
              sélectionné à l’étape 1 : [(Nombre de départs (hors retraite)
              sur l’Année N + Nombre d’arrivées sur l’Année N) / 2] / [moyenne
              (Effectif au 31/12 de l’Année N-1 ; Effectif au 31/12 de l’Année N)] x 100
            </b-popover>
          </div>
          <div class="detail__item same-width">
            Taux de départ à la retraite
            <span id="retirement_rate">
              <i class="fal fa-question-circle bubble-info" />
            </span>
            <b-popover target="retirement_rate" triggers="hover">
              Calculé selon la formule suivante appliquée au périmètre
              sélectionné à l’étape 1 : [(Nombre de départs à la retraite
              sur l’Année N + Nombre d’arrivées sur l’Année N) / 2] / [moyenne
              (Effectif au 31/12 de l’Année N-1 ; Effectif au 31/12 de l’Année N)] x 100
            </b-popover>
          </div>
          <div class="detail__item same-width">
            Age minimum départ retraite
            <span id="minimum_age">
              <i class="fal fa-question-circle bubble-info" />
            </span>
            <b-popover target="minimum_age" triggers="hover">
              Age minimum requis pour partir à la retraite.
              Les personnes qui partent avant cet âge sont des départs hors retraite
            </b-popover>
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="d-flex text-dark">
          <div class="detail__item">
            Taux de mobilité interne
            <span id="internal_mobility_rate">
              <i class="fal fa-question-circle bubble-info" />
            </span>
            <b-popover target="internal_mobility_rate" triggers="hover">
              Calculé selon la formule suivante appliquée au périmètre
              sélectionné à l’étape 1 : [(Nombre de départs en mobilité
              sur l’Année N + Nombre d’arrivées en mobilité sur l’Année N) / 2] / [moyenne
              (Effectif au 31/12 de l’Année N-1 ; Effectif au 31/12 de l’Année N)] x 100
            </b-popover>
          </div>
        </div>
      </b-col>
      <b-col cols="2">
        <div class="d-flex text-info">
          <div class="detail__item same-width">
            Taux de recrutement
            <span id="recruitement_rate">
              <i class="fal fa-question-circle bubble-info" />
            </span>
            <b-popover target="recruitement_rate" triggers="hover">
              Le nombre de personnes recrutées du périmètre d’analyse
              sur la période cumulée de projection par rapport à l’effectif actuel
            </b-popover>
          </div>
          <div v-if="true" class="detail__item same-width">
            Taux d'externes
            <span id="externals_rate">
              <i class="fal fa-question-circle bubble-info" />
            </span>
            <b-popover target="externals_rate" triggers="hover">
              Le nombre de personnes externes du périmètre d’analyse sur la période cumulée
              de projection par rapport à l’effectif interne + externe actuel
            </b-popover>
          </div>
        </div>
      </b-col>
      <b-col cols="1" />
    </b-row>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
};
</script>

<style scoped>

.bubble-info{
  color:#664a64;
  font-size: large;
  position: relative;
}
</style>
