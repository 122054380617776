import {filterEntityMap, getOpacity, scenariosColors} from "@/services/helpers";
import {getJobNameById} from "@/services/jobs";
import {getUnitNameById} from "@/services/units";
import {getSkillNameById} from "@/services/skills";
import {getCostCenterNameById} from "@/services/costcenters";
import global from '@/providers/helpers/global'

export const reasons = {
  "Unknown": {
    "Code": "00",
    "Libellé": "Non communiqué",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:52 par Larotte Marie",
    "__1": ""
  },
  "OrganizationChange": {
    "Code": "01",
    "Libellé": "Mobilité Groupe",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:52 par Larotte Marie",
    "__1": ""
  },
  "Resignation": {
    "Code": "02",
    "Libellé": "Démission",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:52 par Larotte Marie",
    "__1": ""
  },
  "ContractEnd": {
    "Code": "03",
    "Libellé": "Fin de contrat",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:53 par Larotte Marie",
    "__1": ""
  },
  "InternshipEnd": {
    "Code": "04",
    "Libellé": "Fin de stage",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:53 par Larotte Marie",
    "__1": ""
  },
  "Retirement": {
    "Code": "05",
    "Libellé": "Départ retraite",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:54 par Larotte Marie",
    "__1": ""
  },
  "ForcedRetirement": {
    "Code": "06",
    "Libellé": "Mise à la retraite",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:55 par Larotte Marie",
    "__1": ""
  },
  "MutualAgreement": {
    "Code": "07",
    "Libellé": "Rupture conventionnelle",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:55 par Larotte Marie",
    "__1": ""
  },
  "Firing": {
    "Code": "08",
    "Libellé": "Licenciement",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:55 par Larotte Marie",
    "__1": ""
  },
  "EmployeeEndOfTrialPeriod": {
    "Code": "09",
    "Libellé": "Rupture période d'essai (salarié)",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:57 par Larotte Marie",
    "__1": ""
  },
  "CompanyEndOfTrialPeriod": {
    "Code": 10,
    "Libellé": "Rupture période d'essai (employeur)",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:58 par Larotte Marie",
    "__1": ""
  },
  "Transfer": {
    "Code": 11,
    "Libellé": "Transfert",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:58 par Larotte Marie",
    "__1": ""
  },
  "EmployeeFixedContractEnd": {
    "Code": 12,
    "Libellé": "Rupture CDD initiative employeur",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:59 par Larotte Marie",
    "__1": ""
  },
  "CompanyFixedContractEnd": {
    "Code": 13,
    "Libellé": "Rupture CDD initiative salarié",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:59 par Larotte Marie",
    "__1": ""
  },
  "MutualFixedContractEnd": {
    "Code": 14,
    "Libellé": "Rupture CDD commun accord",
    "Suivi": "Dernière mise à jour le 20-06-2017 11:59 par Larotte Marie",
    "__1": ""
  },
  "Death": {
    "Code": 15,
    "Libellé": "Décès",
    "Suivi": "Dernière mise à jour le 20-06-2017 12:00 par Larotte Marie",
    "__1": ""
  },
  "TemporaryPositionSuppression": {
    "Code": 16,
    "Libellé": "Suppression poste temporaire",
    "Suivi": "Dernière mise à jour le 19-06-2018 12:54 par Initialisation",
    "__1": ""
  },
  "Desistement": {
    "Code": 17,
    "Libellé": "Désistement",
    "Suivi": "Dernière mise à jour le 09-08-2018 16:47 par LE NIVET MAGALI",
    "__1": ""
  },
  "LegalContractInvalidation": {
    "Code": 18,
    "Libellé": "Résiliation judiciaire",
    "Suivi": "Dernière mise à jour le 29-05-2019 16:01 par BOLMIER DIDIER",
    "__1": ""
  }
}

// this method will parse attrition data and format in order to be used in UI components
export const formatStaffData = (attritionData) => {
  return {
    ...attritionData,
    formatted: {
      reasons: formatAttritionReasons(attritionData),
      // charts: formatChartsStaffData(attritionData)
    }
  }
}

const formatAttritionReasons = (data) => {
  const total = data?.attritionReasons?.total || 1
  return data?.attritionReasons?.values?.map(obj => ({
    ...obj,
    percent: (obj.value * 100 / total).toFixed(2)
  }))
}

const formatChartsStaffData = (data) => {
  Object.keys(data).forEach((chart) => {
    data[chart] = {
      ...data[chart],
      data: data[chart]?.entityValueMap,
      categories: data[chart]?.entityValueMap
    }
  })
  return data
}

export const formatAttritionPerAttribute = (data, type, year, scenario, tree, getNameFn) => {

  return data?.
    attritionTopPerAttribute?.
    [type]?.
    [year]?.
    [scenario]?.
  map(obj => ({
    value: (obj.value).toFixed(0),
    label: getNameFn ? getNameFn(obj.label, tree) : '-'
  }))
}

export const formatTurnoverChartData = (data, scenario, years) => {
  if (!scenario) {
    return
  }
  const graph = turnoverChart(years)
  const serie = { name: 'DATA', data: [] }
  data?.attritionGraph?.forEach(obj => {
    const turnover = obj?.[scenario]?.turnOver || 0
    if (turnover) serie.data.push([obj.year, turnover])
  })
  graph.series = [serie]
  return graph
}

const turnoverChart = (categories) => ({
  credits: {
    enabled: false,
  },
  legend: {
    enabled: false,
  },
  chart: {
    type: 'line',
    height: 240,
  },
  title: null,
  xAxis: {
    categories
  },
  yAxis: {
    labels: {
      formatter (data) {
        return `${(data.value).toFixed(1)}%`
      }
    },
    title: {
      enabled: false,
    },
  },
  tooltip: {
    enabled: false,
  },
  plotOptions: {
    line: {
      dataLabels: {
        enabled: true,
        format: '{point.y:.1f}%',
      },
    },
  },
  series: [],
})

export const formatInPopulationData = (data, year, type) => {
  return data?.inPopulationStats?.effective?.[year]?.[type]?.contents
}

export const formatOutPopulationData = (data, year, type) => {
  return data?.outPopulationStats?.effective?.[year]?.[type]?.contents
}

export const getStaffOut = (data, year, scenario) => {
  const attritionGraphData =  data?.attritionGraph || []
  const yearData = attritionGraphData.find(obj => parseInt(obj.year) === parseInt(year))
  return yearData?.[scenario]?.attritionCount || ''
}

const nestedCategories = (rows) => {
  const categories = []
  rows.forEach(row => {
    if (row.values) {
      return row?.values?.forEach((category, i) => {
        if (!categories.includes(category.label)) {
          categories.push(category.label)
        }
      })
    }
  })
  return categories
}

export const extractCategoriesFromStaffData = (data, type,scenario) => {
  const categories = data?.[type]?.entityValueMap?.Global?.values?.[0]?.values
  return categories?.map((category, i) => ({
    label: category.label,
    color: scenariosColors?.[scenario]?.color.replace('1)', `${1/(i+1)})`)
  })) || []
}

export const extractStackedCategoriesFromStaffData = (data, type,scenario) => {
  const categories = data?.[type]?.entityValueMap?.Global?.values
  return categories?.map((category, i) => ({
    label: category.category,
    color: scenariosColors?.[scenario]?.color.replace('1)', `${1/(i+1)})`)
  })) || []
}

export const extractEntitiesFromStaffData = (data, type) => {
  const entities = data?.[type]?.entityValueMap
  return Object.keys(entities)
}

export const extractSeriesFromStaffData = (data, type, scenario, selectedCategories) => {
  let categories = data?.[type]?.entityValueMap?.Global?.values?.[0]?.values

  if (selectedCategories) {
    categories = categories.filter(category => {
      return selectedCategories.includes(category.label)
    })
  }

  return categories?.map((category, i) => ({
    name: category?.label,
    data: [category?.[scenario]],
    color: scenariosColors?.[scenario]?.color.replace('1)', `${1/(i+1)})`)
  })) || []
}

export const extractNestedSeriesFromStaffData = (data, type, scenario, categories) => {
  let cat = data?.[type]?.entityValueMap?.Global?.values
  let countValues = data?.[type]?.entityValueMap?.Global?.values?.[0]?.values?.length
  // eslint-disable-next-line max-len,vue/max-len
  let mainCategories = nestedCategories(cat) || data?.[type]?.entityValueMap?.Global?.values?.[0]?.values
  let series = []

  for (let i = 0; i < mainCategories.length; i++) {
    series.push({
      color: scenariosColors?.[scenario]?.color.replace('1)', `${1/(i+1)})`),
      name: mainCategories?.[i],
      // eslint-disable-next-line max-len,vue/max-len
      data: cat.map(dataCat => {
        const value = dataCat?.values?.find(item => item.label === mainCategories?.[i])
        return (value?.[scenario] || 0) * (i % 2 ? -1 : 1)
      }),
      negative: i % 2
    })
  }

  return series
}
