<template>
  <div class="container mb-5">
    <div class="row mb-4">
      <div class="col">
        <h5 class="text-uppercase text-primary mb-2">
          Scenarie
          <a href="#" class="small ml-2 text-muted">
            <i class="fal fa-pencil" />
          </a>
        </h5>
        <b-form-checkbox name="check-button" switch>
          Scénario privé
        </b-form-checkbox>
      </div>
      <div class="col-4">
        <h5 class="text-uppercase text-primary mb-3">
          Chronologie
        </h5>
        <b-progress show-value>
          <b-progress-bar :value="35" variant="primary">
            1 ans
          </b-progress-bar>
          <b-progress-bar :value="35" variant="dark">
            2 ans
          </b-progress-bar>
          <b-progress-bar :value="30" variant="info">
            3 ans
          </b-progress-bar>
        </b-progress>
      </div>
    </div>
    <div class="row mb-3">
      <div class="col">
        <b>TABLEAU DÉTAILLÉ DES EFFECTIFS</b>
      </div>
      <div class="col-auto">
        <button class="btn btn-outline-primary btn-sm text-uppercase" type="button">
          <span>TÉLÉCHARGER TABLEAU EXCEL</span>
          <i class="fas fa-download ml-2" />
        </button>
        <button
          class="btn btn-primary text-uppercase btn-sm ml-2"
          type="button"
          @click="$router.push('/pag1')"
        >
          <span>VALIDER LE SCÉNARIO</span>
        </button>
      </div>
    </div>
    <div class="mb-4">
      <div class="text-muted mb-3">
        Total
      </div>
      <div class="row">
        <div v-for="i in 6" :key="i" class="col border-right">
          <div>
            <small style="line-height: 1.2" class="d-inline-block">
              Effectif actuel (en unités)
            </small>
            <div class="lead font-weight-bold">
              3340
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="table-responsive">
      <table class="table table-sm table-middle small">
        <thead>
          <tr class="text-uppercase">
            <th class="border-0" colspan="2" />
            <th class="border-0" colspan="4">
              <span class="badge badge-primary badge-pill py-2 d-block"> RESSOURCES </span>
            </th>
            <th class="border-0" colspan="2">
              <span class="badge badge-danger badge-pill py-2 d-block"> BESOINS </span>
            </th>
            <th class="border-0" colspan="2">
              <span class="badge badge-dark badge-pill py-2 d-block"> ANALYSE </span>
            </th>
          </tr>
          <tr class="text-center small">
            <!-- 2 -->
            <th>Edit</th>
            <th>
              <b-form-select
                id="input-f-m"
                size="sm"
                :options="['Metier', 'Compétence']"
                required
              />
            </th>
            <!-- 4 -->
            <th class="text-success">
              Effectif actuel(en unités)
            </th>
            <th class="text-success">
              Effectif budgété(en unités)
            </th>
            <th class="text-success">
              Effectif budgété(en pourcentage)
            </th>
            <th class="text-success">
              Tendanced’évolution
            </th>
            <!-- 2 -->
            <th class="text-danger">
              Prévisions de départ
            </th>
            <th class="text-danger">
              Prévisions d’arrivées
            </th>
            <!-- 2 -->
            <th class="text-dark">
              Delta Ressources-Besoin
            </th>
            <th class="text-dark">
              Besoins en ressources externes
            </th>
          </tr>
        </thead>
        <tbody>
          <tr class="text-center font-weight-bold table-success">
            <td>
              <button type="button" class="btn btn-link text-muted">
                <i class="fal fa-pencil" />
              </button>
            </td>
            <td>
              <span class="badge badge-primary badge-pill py-2 d-block">
                <span>CC GP</span>
                <i class="fal fa-search ml-3" />
              </span>
            </td>
            <td class="text-success">
              1212
            </td>
            <td class="text-success">
              <div class="mx-auto" style="width: 80px">
                <InputNumber v-model="val1" />
              </div>
            </td>
            <td class="text-success">
              <div class="mx-auto" style="width: 80px">
                <InputNumber v-model="val2" sufix="%" />
              </div>
            </td>
            <td class="text-warning">
              <i class="fas fa-2x fa-arrow-square-right" />
            </td>
            <td class="text-danger">
              <div class="mx-auto" style="width: 80px">
                <InputNumber v-model="val1" />
              </div>
            </td>
            <td class="text-danger">
              <div class="mx-auto" style="width: 80px">
                <InputNumber v-model="val2" />
              </div>
            </td>
            <td class="text-dark">
              184
            </td>
            <td class="text-dark">
              <span class="badge badge-danger badge-pill py-2 d-block"> En hause </span>
            </td>
          </tr>
          <tr v-for="i in 8" :key="i" class="text-center font-weight-bold">
            <td>
              <button type="button" class="btn btn-link text-muted">
                <i class="fal fa-pencil" />
              </button>
            </td>
            <td>
              <span class="badge badge-primary badge-pill py-2 d-block">
                <span>CC GP</span>
                <i class="fal fa-search ml-3" />
              </span>
            </td>
            <td class="text-success">
              1212
            </td>
            <td class="text-success">
              840
            </td>
            <td class="text-success">
              113%
            </td>
            <td class="text-warning">
              <i class="fas fa-2x fa-arrow-square-right" />
            </td>
            <td class="text-danger">
              50
            </td>
            <td class="text-danger">
              37
            </td>
            <td class="text-dark">
              184
            </td>
            <td class="text-dark">
              <span class="badge badge-danger badge-pill py-2 d-block"> En hause </span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>
import InputNumber from '@/components/all/InputNumber.vue';

export default {
  name: 'PageDetail',
  components: {
    InputNumber,
  },
  data() {
    return {
      val1: 840,
      val2: 114,
    };
  },
};
</script>
