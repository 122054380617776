// import allDataJSON from '@/providers/settings/constants/all-data.json';
import DataService from '@/providers/services/data';
import effectifs from './effectifs';
import competences from './competences';
import hypoteshis from './hypothesis';
import mobility from './mobility';

const state = {
  // allData: allDataJSON,
  allData: {
    entityTypes: [],
    yearPredictedStatsMap: null,
  },
  allDataByScenarioTarget: {
    effective: null,
    fte: null,
    budget: null
  },
  allDataComparativeByScenarioTarget: {

  },
  allDataTableByScenarioTarget: {
    effective: null,
    fte: null,
    budget: null
  },
  comparativesSelects: ['Global'],
  skillData: null,
  loadingSkillData: false,
  skillDataEnded: true,
  skillRequestHandler: null,
  gaTypes: {
    current: { name: 'Existant', color: 'rgba(157,79,234,1)' },
    predicted: { name: 'Evolution conjoncturelle', color: 'rgba(88,19,116,1)' },
    target: { name: 'Scénario cible', color: 'rgba(253,103,91,1)' },
    optimal: { name: 'Sans recrutement', color: 'rgba(179,74,140,1)' },
  },
  filters: {
    // type: allDataJSON.entityTypes[0],
    type: null,
    year: '',
  },
  detailedFilters: {},
};

const getters = {
  yearStatsAll({ filters, allData }) {
    if (!filters.year) return null;
    return allData.yearPredictedStatsMap[filters.year];
  },
  yearTableStatsAllByType({ filters, allDataTableByScenarioTarget }, rootGetters) {
    const type = rootGetters['effectifs/getEffectifsScenarioTarget'];
    if (!filters.year) return null;
    return allDataTableByScenarioTarget[type].yearPredictedStatsMap[filters.year];
  },
  currentYearStatsAll({ allData }) {
    const key = Object.keys(allData.yearPredictedStatsMap)[0]
    return allData.yearPredictedStatsMap[key];
  },
  skillStatsAll({ filters, skillData, loadingSkillData, skillDataEnded }) {
    if (!filters.year) return null;
    return {
      data: skillData,
      loading: loadingSkillData,
      ended: skillDataEnded,
    };
  },
  globalEffectifs({ allData, gaTypes }) {
    const { employeeCountEvolution: countEvo } = allData;
    const getData = (arr, name) => arr.map((i) => i[name]);

    const categories = getData(countEvo, 'year');
    const current = getData(countEvo, 'current');
    const predicted = getData(countEvo, 'predicted');
    const target = getData(countEvo, 'target');
    const optimal = getData(countEvo, 'optimal');

    const dataType = (data, name, color) => ({ data, name, color });
    const data = {
      current: dataType(current, gaTypes.current.name, gaTypes.current.color),
      predicted: dataType(predicted, gaTypes.predicted.name, gaTypes.predicted.color),
      target: dataType(target, gaTypes.target.name, gaTypes.target.color),
      optimal: dataType(optimal, gaTypes.optimal.name, gaTypes.optimal.color),
    };
    return { categories, data };
  },
  globalEffectifsByScenarioTarget({ allDataByScenarioTarget, gaTypes }, rootGetters) {
    const type = rootGetters['effectifs/getEffectifsScenarioTarget'];
    const allData = allDataByScenarioTarget[type]
    const { employeeCountEvolution: countEvo } = allData;
    const getData = (arr, name) => arr.map((i) => i[name]);

    const categories = getData(countEvo, 'year');
    const current = getData(countEvo, 'current');
    const predicted = getData(countEvo, 'predicted');
    const target = getData(countEvo, 'target');
    const optimal = getData(countEvo, 'optimal');
    const dataType = (data, name, color) => ({ data, name, color });
    const data = {
      current: dataType(current, gaTypes.current.name, gaTypes.current.color),
      predicted: dataType(predicted, gaTypes.predicted.name, gaTypes.predicted.color),
      target: dataType(target, gaTypes.target.name, gaTypes.target.color),
      optimal: dataType(optimal, gaTypes.optimal.name, gaTypes.optimal.color),
    };
    return { categories, data };
  },
  // eslint-disable-next-line
  globalComparativeByScenarioTarget({ comparativesSelects, allDataComparativeByScenarioTarget, gaTypes, filters }, rootGetters) {
    const type = rootGetters['effectifs/getEffectifsScenarioTarget'];
    let current = []
    let predicted = []
    let target = []
    let optimal = []

    comparativesSelects.forEach((i) => {
      const allData = allDataComparativeByScenarioTarget[i][type]['employeeCountEvolution']
      const countEvo = allData.find((evolution) => evolution.year.toString() === filters.year)
      const getData = (obj, name) => (obj[name] ? obj[name]: null)
      current = [...current, getData(countEvo, 'current')];
      predicted = [...predicted, getData(countEvo, 'predicted')];
      target = [...target, getData(countEvo, 'target')];
      optimal = [...optimal, getData(countEvo, 'optimal')];
    });
    const dataType = (data, name, color) => ({ data, name, color });
    const data = {
      current: dataType(current, gaTypes.current.name, gaTypes.current.color),
      predicted: dataType(predicted, gaTypes.predicted.name, gaTypes.predicted.color),
      target: dataType(target, gaTypes.target.name, gaTypes.target.color),
      optimal: dataType(optimal, gaTypes.optimal.name, gaTypes.optimal.color),
    };

    return { data }
  },
  globalTableByScenarioTarget({ allDataTableByScenarioTarget, gaTypes }, rootGetters) {
    const table = rootGetters['hypoteshis/tableDataDetailByTarget'];
    const tableData = table['Global']
    const getData = (obj, name) => {
      return [
        obj[name] ? obj[name]['currentEmployeeCount']: null,
        obj[name] ? obj[name]['departureDetails']['retirement'] : null,
        obj[name] ? obj[name]['departureDetails']['others']: null,
        obj[name] ? obj[name]['mobilityDetails']['out']: null,
        obj[name] ? obj[name]['mobilityDetails']['in'] : null,
        obj[name] ? obj[name]['entryDetails']['recruitement'] : null,
        obj[name] ? obj[name]['entryDetails']['others'] : null,
        {
          isSum: true,
        }
      ]
    };
    // eslint-disable-next-line
    const categories = ['Existant', 'Départs-Retraite', 'Départs-Autres', 'Départs-Mobilités', 'Arrivées-Mobilités', 'Arrivées-Recrutement', 'Arrivées-Autres', 'Projection']
    const current = getData(tableData, 'current');
    const predicted = getData(tableData, 'predicted');
    const target = getData(tableData, 'target');
    const optimal = getData(tableData, 'optimal');

    const dataType = (data, name, color) => ({ data, name, color });
    const data = {
      current: dataType(current, gaTypes.current.name, gaTypes.current.color),
      predicted: dataType(predicted, gaTypes.predicted.name, gaTypes.predicted.color),
      target: dataType(target, gaTypes.target.name, gaTypes.target.color),
      optimal: dataType(optimal, gaTypes.optimal.name, gaTypes.optimal.color),
    };
    return { categories, data };
  },
  predictions({ allData }) {
    return allData.predictions;
  },
  allYearsData({ allData }) {
    return allData.yearPredictedStatsMap;
  },
};

const mutations = {
  // eslint-disable-next-line
  setAllData(state, val) {
    state.allData = val;
  },
  setAllDataByScenarioTarget(state, val) {
    state.allDataByScenarioTarget = val;
  },
  setAllDataComparativeByScenarioTarget(state, val) {
    state.allDataComparativeByScenarioTarget = {
      ...state.allDataComparativeByScenarioTarget,
      ...val
    };
  },
  setAllDataTableByScenarioTarget(state, val) {
    state.allDataTableByScenarioTarget = val;
  },
  // eslint-disable-next-line
  setSkillData(state, data) {
    state.skillData = data;
  },
  // eslint-disable-next-line
  setFilter(state, { key, val }) {
    state.filters[key] = val;
  },
  // eslint-disable-next-line
  setLoadingSkillData(state, val) {
    state.loadingSkillData = val;
  },
  // eslint-disable-next-line
  setSkillRequestHandler(state, val) {
    state.skillRequestHandler = val;
  },
  // eslint-disable-next-line
  clearSkillRequestHandler(state) {
    clearInterval(state.skillRequestHandler);
    state.skillRequestHandler = null;
  },
  // eslint-disable-next-line
  setSkillDataEnded(state, val) {
    state.skillDataEnded = val;
  },
  // eslint-disable-next-line
  setDetailedFilters(state, filters) {
    state.detailedFilters = filters;
  },
  setComparativesSelects(state, val) {
    state.comparativesSelects = val;
  },
};

const actions = {
  // eslint-disable-next-line
  async loadDataByFilter({ commit, state }, filters = null) {
    // set analysis data
    let data = await DataService.getAnalysisData(filters);
    commit('setDetailedFilters', filters);
    commit('setAllData', data);
    // main filter (blue)
    if (!filters) {
      commit('setFilter', { key: 'type', val: data.entityTypes[0] });
    } else {
      commit('setFilter', { key: 'type', val: state.filters.type });
      commit('setFilter', { key: 'year', val: state.filters.year });
    }
    data = null;
  },
  async updateScenarioData({ commit }, { id, filter }) {
    // set analysis data
    let data = await DataService.getScenarioData(id, filter);
    commit('setDetailedFilters', filter);
    commit('setAllData', data);
    // main filter (blue)
    if (!filter) {
      commit('setFilter', { key: 'type', val: data.entityTypes[0] });
    } else {
      commit('setFilter', { key: 'type', val: state.filters.type });
      commit('setFilter', { key: 'year', val: state.filters.year });
    }
    data = null;
  },
  async loadScenarioData(context, { id, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioData(id, filters);
  },
  setFilter({ commit }, filter) {
    commit('setFilter', filter);
  },
  async loadScenarioGraphData(context, { id, id2, filters, type }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioGraphResults(id, id2, filters, type)
  },
  async loadScenarioBasicData(context, { id, id2, filters, type }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioBasicResults(id, id2, filters, type)
  },
  async loadScenarioTableData(context, { id, id2, filters, type}) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioTableResults(id, id2, filters, type)
  },
  async loadScenarioCompetencyData(context, { id, id2, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioCompetencyResults(id, id2, filters)
  },
  async loadScenarioMobilityData(context, { id, id2, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioMobilityResults(id, id2, filters)
  },
  async loadScenarioAttritionData (context, { id, id2, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getScenarioAttritionResults(id, id2, filters)
  },
  // eslint-disable-next-line
  async loadSkillDataByFilter({ commit, state }, { id, id2, filter }, callback = null) {
    if (!state?.filters?.type || !state?.filters?.year) {
      return false
    }

    if (!filter) {
      filter = {}
    }

    const data = await DataService.getAnalysisSkillStatData(id, id2, {
      // eslint-disable-next-line radix
      year: parseInt(state.filters.year),
      filterAttribute: state.filters.type,
      ...filter,
      ...state.detailedFilters,
    })

    commit('setSkillData', data)
    return data

  },
  // eslint-disable-next-line
  async downloadRetirementZip(context, { id, id2, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getRetirementZip(id, id2, filters)
  },
  // eslint-disable-next-line
  async downloadStaffingTableZip(context, { id, id2, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getStaffingTableZip(id, id2, filters)
  },
  // eslint-disable-next-line
  async downloadMobilityZip(context, { id, id2, filters }) {
    // eslint-disable-next-line no-return-await
    return await DataService.getMobilityZip(id, id2, filters)
  },
  setComparativesSelects({ commit }, val) {
    commit('setComparativesSelects', val);
  },
};

const modules = {
  effectifs,
  competences,
  hypoteshis,
  mobility,
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
  modules,
};
