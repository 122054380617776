import basic from './basic'
import AuthService from '@/providers/services/auth'

const state = {
  token: '',
  configuration: null
};

const mutations = {
  // eslint-disable-next-line
  setAuthToken(state, val) {
    state.token = val;
    if (window.localStorage.getItem('__user')) {
      const user = JSON.parse(window.localStorage.getItem('__user'));
      user.token = val;
      window.localStorage.setItem('__user', JSON.stringify(user));
    }
  },
  setConfiguration(state, configuration) {
    state.configuration = configuration;
  }
};

const actions = {
  // eslint-disable-next-line
  setAuthToken({ commit }, val) {
    commit('setAuthToken', val);
  },
  setUser(context, user) {
    window.localStorage.setItem('__user', JSON.stringify(user));
  },
  removeUser() {
    window.localStorage.removeItem('__user');
  },
  async logout(context) {
    return await AuthService.logout()
  },
  async getConfiguration({ commit }) {
    const config =  await AuthService.getConfigurations();
    /* const config = {
      availableDataTypes: {
        budget: true,
        effective: true,
        fte: false
      }
    } */
    commit('setConfiguration', config);
  },
};

const getters = {
  currentUser() {
    return JSON.parse(window.localStorage.getItem('__user') || '{}');
  },
};

const modules = {
  basic,
};

export default {
  namespaced: true,
  state,
  mutations,
  actions,
  modules,
  getters,
};
