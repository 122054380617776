<template>
  <div class="text-center">
    <h3>Cette page n'existe pas.</h3>
    <div class="py-3">
      <button type="button" class="btn btn-primary" @click="$router.push('/collab')">
        Revenir à l'accueil
      </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
};
</script>
