<template>
  <div>
    <div v-if="scenariosToDisplay && scenariosToDisplay.length && multi">
      <b-form-checkbox
        v-for="i in scenariosToDisplay"
        :key="i.id"
        v-model="currentScenarios"
        :value="i.id"
        name="scenarios"
        class="custom-control-inline"
      >
        <span class="text-truncate d-inline-block" style="max-width: 200px">{{ i.name }}</span>
        <i class="fas fa-circle ml-1" :class="i.color" style="vertical-align: super" />
      </b-form-checkbox>
    </div>
    <div v-if="scenariosToDisplay && scenariosToDisplay.length && !multi">
      <b-form-radio-group v-model="currentScenarios" size="sm" name="scenarios">
        <b-form-radio v-for="i in scenariosToDisplay" :key="i.id" :value="i.id">
          <span class="text-truncate d-inline-block" style="max-width: 200px">{{ i.name }}</span>
        </b-form-radio>
      </b-form-radio-group>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
  props: {
    multi: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    hidden: {
      type: Array,
      required: false,
      default: () => [],
    },
    displayedScenarios: {
      type: Array,
      required: false,
      default: () => [],
    },
    defaultActive: {
      type: String,
      required: false,
      default: () => '',
    },
  },
  data() {
    return {
      currentScenarios: []
    };
  },
  computed: {
    scenariosToDisplay() {
      if (this.displayedScenarios && this.displayedScenarios.length) {
        // eslint-disable-next-line max-len
        return this.scenarios.filter(
          (s) => this.displayedScenarios.includes(s.id) && !this.hidden.includes(s.id)
        );
      }
      return this.scenarios.filter((s) => !this.hidden.includes(s.id));
    },
    ...mapGetters('auth/basic', {
      scenarios: 'getScenarios',
      activeScenarios: 'getActiveScenarios',
    }),
    ...mapGetters('analysis', {
      scenariosToEnable: 'predictions',
    }),
    ids() {
      return this.activeScenarios.map((s) => s.id);
    },
  },
  watch: {
    currentScenarios(newVal, oldVal) {
      if (JSON.stringify(oldVal) !== JSON.stringify(newVal)) {
        this.setActiveScenarios(newVal);
        this.$emit('changed', this.ids);
        this.$forceUpdate();
      }
    },
    /*
    hidden: {
      handler(val) {
        this.setDisabledScenarios(val);
      },
      immediate: true,
    },
    */
    ids() {
      this.setCurrentScenarios();
    },
    defaultActive() {
      this.currentScenarios = this.defaultActive;
      this.addActiveScenarios([this.defaultActive]);
    },
  },
  mounted() {
    if (!this.multi) {
      if (!this.defaultActive) {
        // init default and current scenarios
        this.setDefaultScenario();
      } else {
        this.setActiveScenarios([this.defaultActive]);
      }
      if (this.ids.length === 0) {
        // set scenarios from predictions in result data
        this.setEnabledScenarios(this.scenariosToEnable);
        this.setActiveScenarios(this.scenariosToEnable);
      }
    }
    this.setCurrentScenarios();
  },
  methods: {
    setCurrentScenarios() {
      if (this.multi) {
        this.currentScenarios = this.activeScenarios.map((s) => s.id);
      } else {
        // eslint-disable-next-line max-len
        this.currentScenarios =
          this.activeScenarios[0] && this.activeScenarios[0].id ? this.activeScenarios[0].id : [];
      }
    },
    setDefaultScenario() {
      const { id } = this.scenarios[0];
      this.setActiveScenarios([id]);
    },
    ...mapMutations('auth/basic', {
      setActiveScenarios: 'setActiveScenarios',
      setDisabledScenarios: 'setDisabledScenarios',
      setEnabledScenarios: 'setEnabledScenarios',
      addActiveScenarios: 'addActiveScenarios',
    }),
  },
};
</script>
