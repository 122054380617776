// import basicData from '@/providers/settings/constants/basic-data.json';
import DataService from '@/providers/services/data';
import { lightSkillStruct, RecursiveSkillGraph } from '@/providers/helpers/skill';
import { lightJobStruct, RecursiveJob } from '@/providers/helpers/job';
import { lightUnitStruct, UnitRecursive } from '@/providers/helpers/unit';
import { lightOrganizationStruct, OrganizationRecursive } from '@/providers/helpers/organization';
import { lightSiteStruct, SiteRecursive } from '@/providers/helpers/site';

const state = {
  data: null,
  skillTree: {
    client: [],
    wiserskill: [],
  },
  jobTree: [],
  jobTreeParents: [],
  unitTree: [],
  costCentres: [],
  contractType: [],
  organizationsTree: [],
  siteTree: [],
  sites: [],
  scenarioTarget: 'effective',
  scenarios: [
    {
      name: 'Evolution conjoncturelle',
      fallbackName: 'Evolution conjoncturelle',
      id: 'predicted',
      color: 'text-primary',
      disabled: false,
      active: true,
      locked: false,
    },
    {
      name: 'Scénario cible',
      fallbackName: 'Scénario cible',
      id: 'target',
      color: 'text-danger',
      disabled: false,
      active: true,
      locked: false,
    },
    {
      name: 'Sans recrutement',
      fallbackName: 'Sans recrutement',
      id: 'optimal',
      color: 'text-secondary',
      disabled: false,
      active: true,
      locked: false,
    },
    {
      name: 'Existant',
      fallbackName: 'Existant',
      id: 'current',
      color: 'text-info',
      disabled: false,
      active: true,
      locked: false,
    },
  ],
};

const mutations = {
  // eslint-disable-next-line
  setData(state, val) {
    state.data = val;
  },
  // eslint-disable-next-line
  setSkillTree(state, val) {
    state.skillTree = val;
  },
  // eslint-disable-next-line
  setJobTree(state, val) {
    state.jobTree = val;
  },
  // eslint-disable-next-line
  setJobTreeParents(state, val) {
    state.jobTreeParents = val;
  },
  // eslint-disable-next-line
  setUnitTree(state, val) {
    state.unitTree = val;
  },
  // eslint-disable-next-line
  setCostCentres(state, val) {
    state.costCentres = val;
  },
  // eslint-disable-next-line
  setContractType(state, val) {
    state.contractType = val;
  },
  // eslint-disable-next-line
  setOrganizationsTree(state, val) {
    state.organizationsTree = val;
  },
  // eslint-disable-next-line
  setSiteTree(state, val) {
    state.siteTree = val;
  },
  // eslint-disable-next-line
  setSites(state, val) {
    state.sites = val;
  },
  // eslint-disable-next-line
  setScenarioTarget(state, payload) {
    state.scenarioTarget = payload;
  },
  // eslint-disable-next-line
  setActiveScenarios(state, val) {
    // state.scenarios = state.scenarios.filter((s) => !s.locked).map((s) => {
    state.scenarios = state.scenarios.map((s) => {
      if (val.includes(s.id)) {
        s.active = true;
      } else {
        s.active = false;
      }
      return s;
    });
  },
  // eslint-disable-next-line
  addActiveScenarios(state, val) {
    state.scenarios = state.scenarios.map((s) => {
      if (val.includes(s.id)) {
        s.active = true;
        s.locked = false;
        s.disabled = false;
      }
      return s;
    });
  },
  // eslint-disable-next-line
  setDisabledScenarios(state, val) {
    // state.scenarios = state.scenarios.filter((s) => !s.locked).map((s) => {
    state.scenarios = state.scenarios.map((s) => {
      if (val.includes(s.id)) {
        s.disabled = true;
      }
      return s;
    });
  },
  // eslint-disable-next-line
  setEnabledScenarios(state, val) {
    // state.scenarios = state.scenarios.filter((s) => !s.locked).map((s) => {
    state.scenarios = state.scenarios.map((s) => {
      if (!val.includes(s.id)) {
        s.disabled = true;
      } else {
        s.disabled = false;
      }
      return s;
    });
  },

  switchScenariosToComparisonMode(state, scenariosNames) {
    state.scenarios = state.scenarios.map(scenario => {
      if (scenario.id === 'target') {
        scenario.name = scenariosNames[0]
      }
      if (scenario.id === 'optimal') {
        scenario.name = scenariosNames[1]
      }
      return scenario
    })
  },

  switchScenariosToNormalMode(state) {
    state.scenarios = state.scenarios.map(scenario => {
      if (scenario.id === 'target' || scenario.id === 'optimal') {
        scenario.name = scenario.fallbackName
      }
      return scenario
    })
  }
};

const actions = {
  // eslint-disable-next-line
  async loadBasicData({ dispatch, commit, state }, ids = null) {
    try {
      const data = await DataService.getBasicData(ids);
      commit('setData', data);
      await dispatch('getSkillTree');
      await dispatch('getJobTree');
      await dispatch('getUnitTree');
      await dispatch('getJobTreeParents');
      dispatch('getCostCentres');
      await dispatch('getOrganizationsTree');
      await dispatch('getSiteTree');
      await dispatch('getSites');
      await dispatch('getContractType');
    } catch (err) {
      console.log(err)
    }
  },
  // eslint-disable-next-line
  async getSkillTree({ commit, state }) {
    // get skill parents categories
    const { skillCategories } = state.data;
    const skillCategoriesParent = skillCategories.filter((sc) => !sc.parents);
    // eslint-disable-next-line
    const skillCategoriesMap = new Map(skillCategories.map((item) => [item._id, item]));
    // get skill object
    const { skills } = state.data;
    const skillsObject = {};
    // eslint-disable-next-line
    skills.forEach((item) => skillsObject[item._id] = item);
    // skill references
    const skillReferences = {
      client: [],
      wiserskill: [],
    };
    // eslint-disable-next-line
    for (const item of skillCategoriesParent) {
      // eslint-disable-next-line
      const response = await RecursiveSkillGraph(item, skillCategoriesMap, skillsObject);
      if (response.children) {
        item.children = response.children;
      }
      item.categoryDepth = response.categoryDepth + 1;
      lightSkillStruct(item);
      // Set Client or Wiserskill
      // eslint-disable-next-line
      if (item.org.includes('WSK')) skillReferences.wiserskill.push(item);
      else skillReferences.client.push(item);
    }
    commit('setSkillTree', skillReferences);
  },
  // eslint-disable-next-line
  async getJobTree({ commit, state }) {
    // const data = cloneDeep(state.data)
    const data = { ...state.data }
    // get job and parent categories
    const { jobCategories } = data
    // eslint-disable-next-line
    const jobCategoriesMap = new Map(jobCategories.map((item) => [item._id, item]));
    const parentJobCategories = jobCategories.filter((item) => !item.parents);
    // get jobs
    const  { jobs } = data
    // eslint-disable-next-line
    const jobsMap = new Map(jobs.map((item) => [item._id, item]));

    // generate tree
    const jobTree = [];
    // eslint-disable-next-line
    for (const category of parentJobCategories) {
      // eslint-disable-next-line
      const response = await RecursiveJob(category, jobCategoriesMap, jobsMap);
      if (response) {
        category.children = response;
      }
      jobTree.push(lightJobStruct(category));
    }
    commit('setJobTree', jobTree);
  },
  async getJobTreeParents({ commit, state }) {
    const data = { ...state.data }
    // get job and parent categories
    const { jobCategories } = data;
    // eslint-disable-next-line
    const jobCategoriesMap = new Map(jobCategories.map((item) => [item._id, item]));
    const parentJobCategories = jobCategories.filter((item) => !item.parents);
    // get jobs
    const { jobs } = data;
    // eslint-disable-next-line
    const jobsMap = new Map(jobs.map((item) => [item._id, item]));

    // generate tree
    const jobTree = [];
    // eslint-disable-next-line
    for (const cat of parentJobCategories) {
      const category = { ...cat }
      // eslint-disable-next-line
      const response = await RecursiveJob(category, jobCategoriesMap, jobsMap, true);
      if (response) {
        category.children = response;
      }
      jobTree.push(lightJobStruct(category));
    }
    commit('setJobTreeParents', jobTree);
  },
  // eslint-disable-next-line
  async getUnitTree({ commit, state }) {
    // get unit and parents
    const { units } = state.data;
    const parentUnits = units.filter((unit) => !unit.parent);
    // eslint-disable-next-line
    for (const unit of parentUnits) {
      // eslint-disable-next-line
      const response = await UnitRecursive(unit, units);
      if (response.length) unit.children = response;
      lightUnitStruct(unit);
    }

    commit('setUnitTree', parentUnits);
  },
  // eslint-disable-next-line
  async getCostCentres({ commit, state }) {
    const { costCentres } = state.data;
    commit('setCostCentres', costCentres);
  },
  async getContractType({ commit, state }) {
    const { contractTypes } = state.data;
    commit('setContractType', contractTypes);
  },
  // eslint-disable-next-line
  async getOrganizationsTree({ commit, state }) {
    // get organizations
    const { organizations } = state.data;
    // get parents
    const parentsOrganizations = organizations.filter((organization) => !organization.parent);
    // eslint-disable-next-line
    for (const organization of parentsOrganizations) {
      // eslint-disable-next-line
      const response = await OrganizationRecursive(organization, organizations);
      if (response.length) organization.children = response;
      lightOrganizationStruct(organization);
    }
    commit('setOrganizationsTree', parentsOrganizations);
  },
  // eslint-disable-next-line
  async getSites({ commit, state }) {
    const { sites } = state.data;
    commit('setSites', sites);
  },
  // eslint-disable-next-line
  async sentScenarioTarget({ commit }, type) {
    commit('setScenarioTarget', type);
  },
  // eslint-disable-next-line
  async getSiteTree({ commit, state }) {
    // get sites
    const { sites } = state.data;
    // get parents
    const parentsSites = sites.filter((site) => !site.parent);
    // eslint-disable-next-line
    for (const site of parentsSites) {
      // eslint-disable-next-line
      const response = await SiteRecursive(site, sites);
      if (response.length) site.children = response;
      lightSiteStruct(site);
    }
    commit('setSiteTree', parentsSites);
  },
  // eslint-disable-next-line no-shadow
  getEnabledScenariosArray({ state }) {
    return state.scenarios;
  },
};

const getters = {
  // eslint-disable-next-line
  getScenarioTarget(state) {
    return state.scenarioTarget;
  },
  // eslint-disable-next-line
  getScenariosMap(state) {
    const map = {};
    state.scenarios
      .filter((s) => !s.disabled)
      .forEach((s) => {
        map[s.id] = s;
      });
    return map;
  },
  // eslint-disable-next-line
  getScenarios(state) {
    // return state.scenarios.filter((s) => !s.disabled);
    return state.scenarios; // .filter((s) => !s.disabled);
  },
  // eslint-disable-next-line
  getScenariosWithoutCurrent(state) {
    return state.scenarios
      .filter((s) => !s.disabled && s.id !== 'current')
      .map((s) => ({
        text: s.name,
        value: s.id,
      }));
  },
  // eslint-disable-next-line
  getActiveScenarios(state) {
    return state.scenarios.filter((s) => s.active && !s.disabled);
  },
  // eslint-disable-next-line
  getActiveScenariosIds(state) {
    return state.scenarios.filter((s) => s.active && !s.disabled).map((s) => s.id);
  },
  getDataSkills (state) {
    return state?.data?.skills || []
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
