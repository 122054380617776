<template>
  <div>
    <TreeSelect
      v-if="skillTree.length"
      v-model="skillSelected"
      :multiple="multi"
      :normalizer="normalizer"
      :options="options"
      :disable-branch-nodes="onlyChildren"
      :disable-fuzzy-matching="exact"
      :disabled="disabled"
      :placeholder="placeholder"
    >
      <label
        slot="option-label"
        slot-scope="{ node, labelClassName }"
        v-b-tooltip
        :class="labelClassName"
        :title="node.label"
      >
        {{ node.label }}
      </label>
    </TreeSelect>
  </div>
</template>
<script>
export default {
  name: 'SkillSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    exact: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    onlyChildren: {
      type: Boolean,
      required: false,
      default: () => false
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Sélectionner une compétence'
      // default: () => 'Sélectionner une compétence (laisser vide pour tout sélectionner)'
    }
  },
  data() {
    return {
      normalizer(node) {
        return {
          // eslint-disable-next-line
          id: node._id,
          label: node.names && node.names[0].label,
          comment: node.description,
        };
      },
      skillSelected: [],
    };
  },
  computed: {
    options() {
      const options = this.resetTreeOptions(this.skillTree);
      if (this.selection && this.selectionDisabled) {
        this.selection.forEach((s) => {
          const node = this.findOptionTree(options, s);
          if (node) {
            node.isDisabled = true;
          }
        });
      }
      return options;
    },
  },
  watch: {
    skillSelected(curr) {
      this.$emit('selected', curr);
    },
  },
  beforeMount() {
    if (!this.multiple) {
      this.skillSelected = null
    }
  },
  mounted() {
    if (this.multi && this.selection && this.selection.length) {
      const options = [...this.skillSelected, ...this.selection];
      this.skillSelected = Array
        // eslint-disable-next-line no-underscore-dangle
        .from(new Set(options.map((a) => a._id ?? a)))
        // eslint-disable-next-line no-underscore-dangle
        .map((_id) => options.find((a) => a._id === _id || a === _id));
    }
  },
};
</script>
