<template>
  <b-card class="bg-primary text-white mb-3" body-class="py-3">
    <div class="row">
      <div class="col">
        <h5 class="mb-1">
          {{ value }}
        </h5>
        <span style="opacity: 0.75">{{ label }}</span>
      </div>
    </div>
  </b-card>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    label: {
      type: String,
      required: true
    }
  }
}
</script>
