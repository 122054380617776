export const mergePredictionData = (source, sourceKey, target, targetKey, base = {}) => {
  const data = Object.assign(base, source, target)
  Object.keys(data.yearPredictedStatsMap).forEach(year => {
    data.yearPredictedStatsMap[year][sourceKey] = source.yearPredictedStatsMap[year][sourceKey]
    data.yearPredictedStatsMap[year][targetKey] = target.yearPredictedStatsMap[year][targetKey]
  })
  return data
}

export const appendPredictionData = (base, source, sourceKey) => {
  Object.keys(base.yearPredictedStatsMap).forEach(year => {
    base.yearPredictedStatsMap[year][sourceKey] = source.yearPredictedStatsMap[year][sourceKey]
  })
  return base
}

export const treeSelectLimitText = count => {
  let start = 'et '
  let end = count > 1 ? 'autres' : 'autre'
  return `${start} ${count} ${end}`
}

export const unitSelectorTreeSelectLimitText = () => {
  let treePoints = ' " . . . " '
  return `${treePoints}`
}

export const getCustomFilterFrom = (userFilter, metadataFilter, defaultFilter, appInstance) => {
  const obj = {}
  let forceNoValue = false
  Object.keys(defaultFilter).forEach((prop) => {
    // Si l'utilisateur a un périmètre défini et le scénario également, on ne conserve que
    // l'intersection de ces 2 périmètres.
    let filter = []
    if (userFilter?.[prop]?.length) {
      if (metadataFilter?.[prop]?.length) {
        // handle the case when metadata filter contains parent ID instead of each children IDs
        filter = metadataFilter[prop].filter((f) => userFilter[prop].includes(f))
        if (!filter.length) {
          forceNoValue = false
        }
        filter = [...(metadataFilter?.[prop] || [])]
      } else {
        filter = [...userFilter[prop]]
      }
    } else {
      filter = [...(metadataFilter?.[prop] || [])]
    }
    obj[prop] = filter
  })
  return {
    filter: obj,
    forceNoValue
  }
}

export const buildFilter = (filter, appInstance) => {
  const allowed = [
    filterAttributesMap.job,
    filterAttributesMap.skill,
    filterAttributesMap.unit
  ]
  // get children for each values in each property
  Object.keys(filter).forEach(key => {
    if (allowed.includes(key)) {
      const tree = `${filterEntityMap[key].single}Tree`
      if (appInstance?.[tree]) {
        const options = appInstance[tree]
        filter[key].forEach(id => {
          const node = appInstance.findOptionTree(options, id)
          const children = appInstance.getNodeChildren(node)
          const ids = children.map(child => child._id)
          filter[key] = [...filter[key], ...ids]
        })
      }
    }
  })
  return filter
}

export const filterAttributesMap = {
  job: 'jobs',
  organization: 'organizations',
  unit: 'units',
  costCentre: 'costCentres',
  grade: 'grades',
  skill: 'skills',
  contractType: 'contractType',
}

export const filterEntityMap = {
  jobs: { single: 'job', word: 'Job' },
  organizations: { single: 'organization', word: 'Organization' },
  units: { single: 'unit', word: 'Unit' },
  costCentres: { single: 'costCentre', word: 'CostCentre' },
  skills: { single: 'skill', word: 'Skill' },
  grades: { single: 'grade', word: 'Grade' },
  contractType: { single: 'contractType', word: 'ContractType' },
}

export const getOpacity = (items, idx) => {
  const val = (1 / items.length) * (idx + 1)
  return val.toFixed(2)
}

export const scenariosColors = {
  current: { name: 'Existant', color: 'rgba(157,79,234,1)' },
  predicted: { name: 'Evolution conjoncturelle', color: 'rgba(88,19,116,1)' },
  target: { name: 'Scénario cible', color: 'rgba(253,103,91,1)' },
  optimal: { name: 'Sans recrutement', color: 'rgba(179,74,140,1)' },
}

export const SCENARIOS_MODES = {
  MERGE: 'merge',
  SELECT: 'select'
}

export const getMode = (mode) => {
  return Object.keys(SCENARIOS_MODES).filter(key => SCENARIOS_MODES[key] === mode)
}

export const toggleItemInArray = (collection, item) => {
  const idx = collection.indexOf(item);
  if (idx !== -1) {
    collection.splice(idx, 1);
  } else {
    collection.push(item);
  }
}

export const formatDate = (str) => {
  if (!str) return ''
  const d = Date.parse(str)
  return new Date(d).toLocaleDateString()
}

export const getArraysIntersection = (arr1, arr2) => {
  return arr1.filter(element => {
    return arr2.includes(element)
  })
}
