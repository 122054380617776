<template>
  <div
    v-if="active"
    class="d-flex justify-content-center align-items-center p-3"
    :class="fixed ? 'position-fixed' : ''"
    style="position: absolute; top: 0; left: 0; right: 0; bottom: 0; z-index: 999"
    :style="{ background: 'rgba(0,0,0,0.5)' }"
  >
    <div class="card">
      <div class="card-body p-5 text-center">
        <slot />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ModalInline',
  props: {
    active: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    fixed: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
};
</script>