/* eslint-disable linebreak-style */
import axios from 'axios';
import Cookies from 'js-cookie';
import router from '@/router/auto'

export default function setup() {
  axios.interceptors.request.use(
    (config) => {
      if (config.headers.common.Authorization) {
        const token = config.headers.common.Authorization;
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = token.includes('Bearer') ? token : `Bearer ${token}`;
        return config;
      }
      const token = Cookies.get('gpec') || null;
      if (token) {
        // eslint-disable-next-line no-param-reassign
        config.headers.common.Authorization = `Token ${token}`;
      }
      // config.onDownloadProgress = (progressEvent) => {
      //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   console.log('progress:', percentCompleted);
      // };
      // config.onUploadProgress = (progressEvent) => {
      //   const percentCompleted = Math.round((progressEvent.loaded * 100) / progressEvent.total);
      //   console.log('progress:', percentCompleted);
      // };
      return config;
    },
    (err) => Promise.reject(err)
  );
  axios.interceptors.response.use(
    (response) => response,
    async (error) => {
      if (error.response.status === 401) {
        const token = Cookies.remove('gpec')
        await router.push('/login')
      }
      return Promise.reject(error)
    }
  );
}
