import global from '@/providers/helpers/global'
import mobility from '@/store/modules/mobility'

export const getJobNameById = (id, jobTree) => {
  const node = global.methods.findOptionTree(jobTree, id)
  return node?.names?.[0]?.label || id
}

export const buildNewCustomJobPayload = (customJob, years, edit = false) => {
  const start = customJob.date.split('-')[0]
  let index = years.indexOf(parseInt(start), years)
  index = index >= 0 ? index : 0
  const skills = []
  customJob.skills.forEach(skill => {
    /*
     * r = 0 (en dur comme tu dis, je sais pas trop à quoi ça sert)
     * sc = ça sera l’utilisateur qui va le définir, tu prends celui de la première année dispo
     * sk = skill._key
     * uid = un identifiant aléatoirement généré, de 9 caractères
     *  (mélange de chiffre, lettre min, lettre maj)
    */
    skills.push({
      r: 0,
      sc: skill[`year_${index}`],
      sk: skill.uid,
      uid: skill.uid
    })
  })
  const payload = {
    id: customJob?._key || null,
    names: [
      {
        label: customJob.name,
        desc: customJob.description,
      }
    ],
    targetCreationDate: customJob.date,
    parents: [
      { '#': customJob.family }
    ],
    isManagerJob: customJob.manager,
    seniorityType: customJob.experience,
    filter: {
      costCentres: customJob.costCentres,
      units: customJob.units,
      sites: customJob.sites
    },
    yearTargetEmployeeCount: {},
    isFinalized: true,
    skills
  }
  if (edit) {
    payload._id =  `CustomJob/${customJob?._key}` || nul;
    payload._key = customJob?._key || null;
  }
  customJob.years.forEach(year => {
    payload.yearTargetEmployeeCount[year.label] = parseInt(year.value)
  })
  console.log('buildNewCustomJobPayload', customJob)
  return payload
}

export const transformNewJobsToHypothesisTableEditorItems = (jobs, year) => {
  return jobs.map(job => {
    console.log('job to transform for hypothesis table', job)
    // retour de l'API GET du custom job
    const mobilityDetails = { in: 0, out: 0 }
    // effectif projeté dans l'étape de création du custom job
    const employeeCount = job.yearTargetEmployeeCount[year]
    return {
      formData: { ...job.formData, _id: job._id, _key: job._key },
      isEnabled: true,
      names: [
        {label: job.names[0].label}
      ],
      entity: job._id,
      children: [],
      // TODO: mandatory in hypothesis table editor
      target: {
        budgedtedExternalCount: 0,
        budgetedExternalCount: 0,
        currentExternalCount: 0,
        externalDelta: 0,
        budgetedAllCount: 0,
        mobilityDetails,
        entryDetails: {
          recruitement: employeeCount - mobilityDetails.in,
          others: 0
        },
        departureDetails: {
          retirement: 0,
          others: 0
        },
        currentEmployeeCount: 0, // reste 0
      }
    }
  })
}

export const extractCustomJobsRowsFromMainRows = (customJobs, rows, year) => {
  const customs = []
  Object.keys(rows).forEach(id => {
    const ids = customJobs.map(j => j._id)
    if (ids.includes(id)) {
      // const jobMetadata = global.methods.findOptionTree(jobTree, id)
      const jobMetadata = customJobs.find(j => j._id === id)
      console.log('jobMetadata', id, jobMetadata)
      customs.push({ ...rows[id], ...jobMetadata })
    }
  })
  return transformNewJobsToHypothesisTableEditorItems(customs, year)
}
