<template>
  <div class="mb-5">
    <div class="p-lg-5 position-relative mb-4">
      <form @submit.prevent="save(false)">
        <div class="mb-4">
          <b-form-group label="Nom" label-for="form2-1">
            <b-form-input
              id="form2-1"
              v-model="label"
              :readonly="readonly"
              placeholder="Nom du scenario"
            />
          </b-form-group>
        </div>
        <div class="mb-4">
          <b-form-group label="Commentaire" label-for="form2-2">
            <b-form-textarea
              id="form2-2"
              v-model="comment"
              :readonly="readonly"
              placeholder="Vous pouvez ajouter un commentaire sur ce scénario..."
              rows="10"
            />
          </b-form-group>
        </div>
      </form>
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('previous')"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          v-if="!readonly"
          variant="link"
          class="text-uppercase"
          @click="save(true)"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button class="btn btn-secondary text-uppercase" type="button" @click="save(false)">
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    scenario: {
      type: Object,
      required: false,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      label: '',
      comment: '',
    };
  },
  mounted() {
    if (this.scenario) {
      this.label = this.scenario.label;
      this.comment = this.scenario.comment;
    }
  },
  methods: {
    save(draft) {
      const evtName = draft ? 'saveDraft' : 'save';
      this.$emit(evtName, {
        label: this.label,
        comment: this.comment,
      });
    },
  },
};
</script>
