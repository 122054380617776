import JobService from '@/providers/services/job'

const actions = {
  async fetchCustomJob(context, { id, year }) {
    const data = await JobService.getCustomJob(id, year)
    return data
  },
  async createCustomJob(context, job) {
    const data = await JobService.createNewCustomJob(job)
    return data;
  },
  async updateCustomJob(context, job) {
    const data = await JobService.updateNewCustomJob(job)
    return data;
  },
  async fetchCustomJobMetadata(context, id) {
    const data = await JobService.getCustomJobMetadata(id)
    return data;
  }
}

export default {
  namespaced: true,
  actions
}
