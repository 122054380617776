import { mapState, mapGetters } from 'vuex';
import {filterEntityMap, formatDate} from "@/services/helpers";
import {getCostCenterNameById} from "@/services/costcenters";
import {getJobNameById} from "@/services/jobs";
import {getSkillNameById} from "@/services/skills";
import {getUnitNameById} from "@/services/units";

export default {
  computed: {
    ...mapState('auth', {
      clientSkillTree: (state) => state.basic.skillTree.client,
      costCentresList: (state) => state.basic.costCentres,
      configuration: (state) => state.configuration,
      jobTree: (state) => state.basic.jobTree,
      jobTreeParents: (state) => state.basic.jobTreeParents,
      lists: (state) => state.basic.data,
      siteTree: (state) => state.basic.siteTree,
      skillTree: (state) => [...state.basic.skillTree.client, ...state.basic.skillTree.wiserskill],
      unitTree: (state) => state.basic.unitTree,
      contractTypeList: (state) => state.basic.contractType,
    }),
    ...mapGetters({
      showDataLabels: 'app/showDataLabels',
    }),
  },
  methods: {
    getCurrentTypeOptions (gType) {
      let type = ''
      let getNameFn = null
      let tree = null
      switch (gType) {
        case filterEntityMap.jobs.word:
          type = filterEntityMap.jobs.word
          getNameFn = getJobNameById
          tree = this.jobTree
          break
        case filterEntityMap.units.word:
          type = filterEntityMap.units.word
          getNameFn = getUnitNameById
          tree = this.unitTree
          break
        case filterEntityMap.skills.word:
          type = filterEntityMap.skills.word
          getNameFn = getSkillNameById
          tree = this.skillTree
          break
        case filterEntityMap.costCentres.word:
          type = filterEntityMap.costCentres.word
          getNameFn = getCostCenterNameById
          tree = this.costCentresList
          break
      }
      return {
        tree,
        getNameFn
      }
    },
    resetTreeOptions(options) {
      options.forEach((s) => {
        if (s) {
          s.isDisabled = !s?.active || false;
          if (s.children && s.children.length) {
            s.children = this.resetTreeOptions(s.children);
          } else if (s.children && !s.children.length) {
            delete s.children
          }
        }
      });
      return options.filter(Boolean).filter((o) => o.active);
    },
    findOptionTree(data, value, key = '_id', sub = 'children', tempObj = {}) {
      if (value && data) {
        data.find((node) => {
          if (node[key] === value) {
            tempObj.found = node;
            return node;
          }
          return this.findOptionTree(node[sub], value, key, sub, tempObj);
        });
        if (tempObj.found) {
          return tempObj.found;
        }
      }
      return false;
    },
    findOptionTreePath(data, value, key = '_id', sub = 'children', tempObj = {}, path = []) {
      if (value && data) {
        data.find((node) => {
          path.push(node[key]);
          if (node[key] === value) {
            tempObj.found = node;
            return node;
          }
          const child = this.findOptionTree(node[sub], value, key, sub, tempObj, path);
          if (!child) {
            path.pop();
          }
          return false;
        });
        if (tempObj.found) {
          return path;
        }
      }
      return false;
    },
    getPath(obj, value, key = 'entity') {
      const path = [];
      let found = false;
      function search(children) {
        children.every((child) => {
          // eslint-disable-next-line no-underscore-dangle
          path.push(child[key]);
          // eslint-disable-next-line no-underscore-dangle
          if (child[key] === value) {
            found = true;
            return false;
          }
          if (child.children && child.children.length) {
            search(child.children);
            if (found) return false;
          }
          path.pop();
          return true;
        });
      }
      if (obj.children && obj.children.length) {
        // eslint-disable-next-line no-underscore-dangle
        path.push(obj[key]);
        search(obj.children);
      }
      if (!found) {
        path.pop();
      }
      return path;
    },
    fixNumber(number, cantDecimal = 2) {
      if (Number.isInteger(number)) return number;
      return Number(number.toFixed(cantDecimal));
    },
    parseYearDataTypeValue(value) {
      if (typeof value === 'object') {
        return value;
      }
      if (!value) {
        return { years: 0, months: 0 }
      }
      // split value:
      // integer is years
      // decimal is months
      const val = Number(value);
      let years = Math.floor(val);
      let months = val - Math.floor(val);
      months = Math.round(months * 12);
      if (months === 12) {
        months = 0;
        years += 1;
      }
      return { years, months };
    },
    parseAgeObjectToFloatValue({ years, months }) {
      // cast months in a float value
      const decimal = parseInt(months) / 12;
      return parseFloat(Number(parseFloat(years) + decimal).toFixed(2));
    },
    sortByKey(array, key, isDate = false, descending = false) {
      const newArray = array.sort((a, b) => {
        const x = a[key];
        const y = b[key];
        if (isDate) return new Date(x).getTime() - new Date(y).getTime();
        // eslint-disable-next-line no-nested-ternary
        return x < y ? -1 : x > y ? 1 : 0;
      });
      if (descending) newArray.reverse();
      return newArray;
    },
    getFilteredList(type) {
      let list = [];
      switch (type) {
        default:
        case 'Job':
          list = [...this.lists.jobs, ...this.lists.jobCategories];
          break;
        case 'Unit':
          list = this.lists.units;
          break;
        case 'CostCentre':
          list = this.lists.costCentres;
          break;
        case 'Organisation':
          list = this.lists.organizations;
          break;
        case 'Site':
          list = this.lists.sites;
          break;
        case 'ContractType':
          list = this.lists.contractType;
          break;
      }
      return list;
    },
    loopInTree(tree, callback) {
      if (tree?.children) {
        tree.children.forEach((child) => {
          callback(child);
          this.loopInTree(child, callback);
        });
      }
    },
    getNodeChildren(node) {
      const children = [];
      this.loopInTree(node, (n) => {
        children.push(n);
      });
      return children;
    },
    disableLeafInTree(tree, leafId) {
      // eslint-disable-next-line no-restricted-syntax,no-prototype-builtins
      if (tree.hasOwnProperty('entity') && tree.entity === leafId) {
        tree.isDisabled = true;
      } else {
        tree.isDisabled = false;
        if (tree.children !== null && tree.children.length) {
          this.disableLeafInTree(tree, leafId);
        }
      }
    },
    enableLeafInTree(tree, leafId, key = '_id') {
      // eslint-disable-next-line no-restricted-syntax,no-prototype-builtins
      if (tree.hasOwnProperty(key) && tree[key] === leafId) {
        tree.isDisabled = false;
      } else if (tree.children && tree.children.length) {
        tree.children.forEach((t) => {
          this.enableLeafInTree(t, leafId);
        });
      }
    },
    getNodesWithParents(pathArray, options) {
      let parents = [];
      pathArray.forEach((n) => {
        // eslint-disable-next-line no-underscore-dangle
        parents = [...parents, ...this.getPath({ children: options }, n, '_id')];
        parents = parents.filter(Boolean);
      });
      return parents;
    },
    formatDate (str) {
      return formatDate(str)
    }
  },
};
