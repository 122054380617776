<template>
  <div class="container mb-5">
    <div class="row justify-content-center">
      <div class="col-lg-11">
        <div class="row justify-content-between mb-4">
          <div class="col-lg-8">
            <h5 class="text-primary text-uppercase">
              DASHBOARD
            </h5>
            <p class="text-muted">
              Choisir le scénario qui sera comparé à l’existant durant la Gap Analysis. Pour
              consulter uniquement l’existant, cliquer Gap Analysis sans sélectionner de scénario.
            </p>
          </div>
          <div class="col-auto mt-1">
            <a
              href="#"
              class="btn btn-primary text-uppercase"
              @click.prevent="$router.push('/collab')"
            >
              Gap analysis
            </a>
          </div>
        </div>
        <div>
          <div class="card shadow-sm border-0 mb-4">
            <div class="card-body py-2">
              <i class="fas fa-check-circle" :class="{ 'text-primary': true }" />
              <span class="ml-5 mr-3">Existant</span>
              <div class="badge badge-primary badge-pill p-2">
                Tendance naturelle
              </div>
            </div>
          </div>
        </div>
        <div>
          <div class="px-3 text-muted small mb-3">
            <div class="form-row align-items-center">
              <div class="col-3">
                <b class="text-uppercase"> Mes scénarios </b>
              </div>
              <div class="col-2">
                Date de création
              </div>
              <div class="col-2">
                Date de modification
              </div>
              <div class="col-2">
                Statut
              </div>
              <div class="col-2">
                Propriétaires
              </div>
              <div class="col-1">
                Éditer
              </div>
            </div>
          </div>
          <div v-if="!displayData" class="my-3">
            <button
              type="button"
              class="btn btn-primary btn-lg btn-block"
              @click="displayData = true"
            >
              <i class="fal fa-plus-circle mr-2" />
              Créer votre premier scénario
            </button>
          </div>
          <div v-else>
            <div class="mb-4">
              <div v-for="i in 10" :key="i" class="card shadow-sm border-0 mb-3">
                <div class="card-body py-2 px-3">
                  <div class="form-row align-items-center">
                    <div class="col-3 mb-2 mb-md-0">
                      <i class="fal fa-circle" :class="{ 'text-primary': true }" />
                      <span class="ml-3 text-muted">
                        <i class="fas fa-star" :class="{ 'text-primary': i % 2 === 0 }" />
                      </span>
                      <span class="ml-2"> Scenario 1 </span>
                    </div>
                    <div class="col-2 mb-2 mb-md-0">
                      01-01-2020
                    </div>
                    <div class="col-2 mb-2 v">
                      01-01-2020
                    </div>
                    <div class="col-2 mb-2 mb-md-0">
                      <div class="badge badge-primary badge-pill p-2">
                        brouillon
                      </div>
                    </div>
                    <div class="col-2 mb-2 mb-md-0">
                      <UsersInline :users="users" />
                    </div>
                    <div class="col-1">
                      <button class="btn btn-link btn-sm text-muted mr-2" type="button">
                        <i class="fal fa-pencil fa-lg" />
                      </button>
                      <b-dropdown
                        no-caret
                        right
                        variant="link btn-sm"
                        toggle-class="text-decoration-none"
                      >
                        <template #button-content>
                          <span class="text-muted">
                            <i class="fas fa-ellipsis-v fa-lg" />
                          </span>
                        </template>
                        <b-dropdown-item>
                          <i class="fal fa-archive" />
                          <span class="ml-2">Archiver</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <i class="fal fa-clone" />
                          <span class="ml-2">Dupliquer</span>
                        </b-dropdown-item>
                        <b-dropdown-item>
                          <i class="fal fa-trash-alt" />
                          <span class="ml-2">Suprimer</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="text-right">
              <a href="#" class="btn btn-outline-secondary text-uppercase" @click.prevent="">
                <i class="fal fa-archive mr-2" />
                Archives
              </a>
              <a href="#" class="btn btn-secondary text-uppercase ml-3" @click.prevent="">
                <i class="fal fa-plus-circle mr-2" />
                Poursuirve
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import UsersInline from '@/components/all/UsersInline.vue';

const users = [
  { name: 'kenyk', color: '#4783FC', image: null },
  { name: 'kenyk', color: null, image: 'https://picsum.photos/90' },
  { name: 'Morgan', color: '#FFC738', image: null },
];

export default {
  name: 'GapAnalysis',
  components: {
    UsersInline,
  },
  data() {
    return {
      users,
      displayData: false,
    };
  },
};
</script>
