<template>
  <div class="input-number">
    <input v-model="val" type="text" class="form-control form-control-sm border-primary">
    <div class="input-number__actions text-primary">
      <i class="fal fa-lg fa-plus-square" @click="plus()" />
      <i class="fal fa-lg fa-minus-square" @click="minus()" />
    </div>
  </div>
</template>

<script>
export default {
  name: 'InputNumber',
  props: {
    value: {
      required: true,
      type: Number,
    },
    sufix: {
      type: String,
      default: '',
    },
  },
  computed: {
    val: {
      get() {
        return `${this.value}${this.sufix}`;
      },
      set(newVal) {
        const cleanValue = newVal.replace(this.sufix, '');
        this.$emit('input', parseInt(cleanValue, 10));
      },
    },
  },
  methods: {
    plus() {
      this.$emit('input', this.value + 1);
    },
    minus() {
      this.$emit('input', this.value - 1);
    },
  },
};
</script>

<style lang="scss">
.input-number {
  display: flex;
  input {
    text-align: center;
    color: inherit;
  }
  &__actions {
    margin-left: 0.5rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
}
</style>
