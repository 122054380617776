<template>
  <b-tab title="Taux">
    <slot />
    <hr class="my-4">
    <RatesTable :key="key" :rows="localRates" readonly />
  </b-tab>
</template>

<script>
import RatesTable from '@/components/scenario/rates/RatesTable'
import {
  buildCustomRatesFromMetadataYearRateMap,
  castCustomRateToYearRateMap,
  castObjectToItemRow,
  computeAverageRatesFromManyRates,
  computeAverageRatesFromYearMap,
  findJobRatesFromRates
} from '@/services/rates'
import { getJobNameById } from '@/services/jobs'

export default {
  components: {
    RatesTable
  },
  props: {
    rates: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      required: true
    },
    years: {
      type: Array,
      required: true
    },
    scenario: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      key: 0,
      localRates: {},
      yearGlobalRate: {},
      yearRateMap: {},
      mapScenarios: {
        optimal: 'noRecruiting',
        predicted: 'conjunctural',
        target: null
      }
    }
  },
  watch: {
    scenario () {
      this.switchTargetValues(this.scenario)
    }
  },
  /**
   * On mounted event, we want to build the right rates data relatively to the current scenario
   * AND the provided metadata.
   * If there is no metadata, we build the rates data from the provided the rates:
   * each year will use the globalRates for current scenario
   * The current scenario data is located in target attribute of rates data: `localRates.target`
   */
  mounted () {
    this.localRates = { ...this.rates }
    this.localRates.yearGlobalRate = this.metadata?.yearGlobalRate || {}
    if (this.localRates?.target?.yearGlobalRate && this.metadata?.yearGlobalRate) {
      this.localRates.target.yearGlobalRate = this.metadata?.yearGlobalRate
    }
    this.loadData()
  },
  methods: {
    switchTargetValues (scenario) {
      const attr = this.mapScenarios[scenario] || this.metadata.scenario
      this.localRates.current.globalRates = this.rates.all[attr].globalRates
      this.key++
    },
    loadData () {

      // build yearGlobalRate
      this.years.forEach((y) => {
        this.localRates.yearGlobalRate[y] = {
          // eslint-disable-next-line max-len,vue/max-len
          globalRates: this.metadata?.yearGlobalRate?.[y]?.globalRates || this.metadata?.yearGlobalRate?.[y] || this.localRates.target
        }
      })

      // build customs
      // eslint-disable-next-line max-len,vue/max-len
      const jobsYearRateMap = buildCustomRatesFromMetadataYearRateMap(this.metadata.yearGlobalRate, this.metadata.yearRateMap)
      this.localRates.customs = []

      if (jobsYearRateMap?.rates) {
        Object.keys(jobsYearRateMap.rates).forEach(job => {
          this.localRates.customs.push({
            title: getJobNameById(job, this.jobTree),
            shortTitle: getJobNameById(job, this.jobTree),
            item: {
              globalRates: computeAverageRatesFromYearMap(jobsYearRateMap.rates[job])
            },
            children: jobsYearRateMap.rates[job]
          })
        })
      }
    }
  }
}
</script>
