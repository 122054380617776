<template>
  <div>
    <div class="p-4">
      <b-alert show variant="warning">
        <span>Nous avons détecté des conflits entre plusieurs scénarios</span>
      </b-alert>
      <VerticalStep
        title="Parmi les scénarios ci-dessous, sélectionnez
          celui que nous devons appliquer sur les périmètres communs"
        :step="1"
      >
        <b-table class="bg-light small" :fields="fields" :items="conflicts[0]">
          <template #cell(action)="{ item }">
            <b-check v-model="scenarioToKeep" :value="item.ID" />
          </template>
          <template #cell(comment)="{ item }">
            <CommentTooltip :id="`merging-${item.ID}`" :comment="item.comment" />
          </template>
        </b-table>
      </VerticalStep>
    </div>
    <hr>
    <div class="d-flex align-items-center justify-content-end m-4">
      <b-button variant="link" class="text-uppercase" @click="$emit('cancel')">
        Abandonner
      </b-button>
      <b-button
        variant="secondary"
        class="text-uppercase"
        @click="$emit('success', { conflicts, scenario: scenarioToKeep })"
      >
        Continuer
      </b-button>
    </div>
  </div>
</template>

<script>
import VerticalStep from '@/components/ui/VerticalStep'
import CommentTooltip from '@/components/ui/CommentTooltip'
import { formatDate } from '@/services/helpers'

export default {
  components: {
    VerticalStep,
    CommentTooltip
  },
  props: {
    scenarios: {
      type: Array,
      required: true
    },
    conflicts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      scenarioToKeep: null,
      fields: [
        { label: '', key: 'action' },
        { label: 'Nom', key: 'label', tdClass: 'text-secondary' },
        { label: 'Date de création', key: 'createdAt', formatter: (str) => formatDate(str) },
        { label: 'Date de modification', key: 'updatedAt', formatter: (str) => formatDate(str) },
        { label: '', key: 'comment' }
      ]
    }
  },
  watch: {
    conflicts () {
      this.scenarioToKeep = null
    }
  }
}
</script>