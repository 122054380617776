<template>
  <div class="d-inline-block rounded text-center" :class="variationClass" style="width: 55px">
    <span v-if="!editing">
      {{ isBudget ? formatCostType(Number(editableValue), typeScenarioTarget) : editableValue }}
    </span>
    <b-form-input
      v-else
      v-model="editableValue"
      v-sign-input="type"
      type="number"
      style="font-size: 13px; padding-left: 0.3rem"
      :min="min"
      :max="max"
      :formatter="formatter"
      @change="onChanged"
    />
    <pre v-if="$debug">
      {{ `e: ${editableValue}` }}
      {{ `v: ${value}` }}
      {{ `c: ${customValue}` }}
      {{ `l: ${limit}` }}
      {{ `-: ${min}` }}
      {{ `+: ${max}` }}
    </pre>
  </div>
</template>
<script>
import { formatCostType } from '@/providers/helpers/functions';
import SignInput from '@/directives/sign-input';

export default {
  name: 'EditableCell',
  directives: {
    'sign-input': SignInput
  },
  props: {
    isBudget:{
      type: Boolean,
      required: false,
      default: () => false,
    },
    typeScenarioTarget: {
      type: String,
      required: false,
      default: 'effective',
    },
    value: {
      type: [Number, String],
      required: true,
    },
    customValue: {
      type: [Boolean, Number, String],
      required: false,
      default: () => false,
    },
    editing: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    type: {
      type: String,
      required: false,
      default: () => 'all',
    },
    variation: {
      type: Number,
      required: false,
      default: () => 0,
    },
    showVariation: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    limit: {
      type: [Boolean, Number],
      required: false,
      default: () => false,
    },
    name: {
      type: String,
      required: false,
      default: () => ''
    },
  },
  data() {
    return {
      editableValue: null,
      min: null,
      max: null,
      sign: null,
      formatCostType,
    };
  },
  computed: {
    variationClass() {
      if (this.showVariation && this.variation > 0) {
        return 'bg-success text-white';
      }
      if (this.showVariation && this.variation < 0) {
        return 'bg-danger text-white';
      }
      return '';
    },
  },
  watch: {
    value() {
      const val = Number.isInteger(this.customValue) ? this.customValue : this.value;
      this.editableValue = parseInt(`${val}`, 10);
    },
  },
  mounted() {
    const val = Number.isInteger(this.customValue) ? this.customValue : this.value;
    this.editableValue = parseInt(`${val}`, 10);
    switch (this.type) {
      case 'positive':
        this.max = Number.isInteger(this.limit) ? this.limit : null;
        this.min = 0;
        // this.sign = 1;
        // this.editableValue = (this.editableValue < this.min) ? this.min : this.editableValue;
        break;
      case 'negative':
        this.max = 0;
        this.min = Number.isInteger(this.limit) ? this.limit : null;
        // this.sign = -1;
        // this.editableValue = (this.editableValue > this.max) ? this.max : this.editableValue;
        break;
      default:
      case 'all':
        this.min = null;
        this.max = null;
        // this.sign = null;
        break;
    }
  },
  methods: {
    onChanged() {
      if (this.limit === this.editableValue) return;
      this.$emit('changed', parseInt(this.editableValue, 10));
    },
    formatter(value) {
      // if (Math.sign(value) !== this.sign) {
      //   return value * -1;
      // }
      return value;
    },
  },
};
</script>
