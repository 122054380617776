<template>
  <div>
    <div @click="handleClick">
      <slot />
    </div>
    <b-modal
      :key="users.length"
      v-model="showModal"
      content-class="rounded-xl p-4"
      size="lg"
      hide-footer
      header-class="border-0"
    >
      <div class="text-center">
        <b-avatar variant="primary">
          <i class="fa fa-share" />
        </b-avatar>
      </div>
      <div class="my-4 text-center">
        <p class="text-muted">
          Choisissez les personnes avec lesquelles vous souhaitez partager ce scénario
        </p>
      </div>
      <div class="my-4">
        <table class="table table-middle small">
          <thead class="text-muted">
            <tr>
              <th />
              <th colspan="2">
                Nom
              </th>
              <th>Métier actuel</th>
              <th>Entité</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="user in users" :key="user.id">
              <td>
                <b-check :checked="isSelected(user)" size="lg" @change="toggleSelectItem(user)" />
              </td>
              <td>
                <div class="d-flex">
                  <b-avatar />
                  <div class="ml-2">
                    <div class="font-weight-bold text-capitalize">
                      {{ `${user.firstName.trim()} ${user.lastName.trim()}` }}
                    </div>
                    <div class="text-muted">
                      {{ user.matricule }}
                    </div>
                  </div>
                </div>
              </td>
              <td colspan="2">
                <div class="text-wrap">
                  {{ getUserJob(user.job) }}
                </div>
              </td>
              <td>
                <div class="text-uppercase">
                  {{ getUserUnit(user.unit) }}
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="d-flex justify-content-center">
        <b-button variant="primary" class="text-uppercase" @click="share">
          Partager
        </b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
export default {
  props: {
    users: {
      type: Array,
      required: true,
    },
    sharedWith: {
      type: Array,
      required: true,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      loading: false,
      error: false,
      showModal: false,
      localUsers: [],
      localSharedWith: this.sharedWith,
    };
  },
  methods: {
    isSelected(user) {
      return this.localSharedWith.includes(user.id);
    },
    getUserJob(job) {
      const jobFound = this.findOptionTree(this.jobTree, job, '_id', 'children');
      return jobFound?.names?.[0]?.label || '-';
    },
    getUserUnit(unit) {
      const unitFound = this.findOptionTree(this.unitTree, unit, '_id', 'children');
      return unitFound?.names?.[0]?.label || '-';
    },
    toggleSelectItem(user) {
      if (this.localSharedWith.includes(user.id)) {
        this.localSharedWith = this.localSharedWith.filter((u) => u !== user.id);
      } else {
        this.localSharedWith.push(user.id);
      }
    },
    handleClick() {
      if (!this.disabled) {
        this.showModal = true;
      }
    },
    share() {
      this.$emit('share', this.localSharedWith);
      this.showModal = false;
    },
  },
};
</script>
