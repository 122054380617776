<template>
  <div>
    <b-row v-if="canFilter">
      <b-col cols="6" offset="6">
        <div class="my-2">
          <MainFilterSwitcher
            tooltip=""
            :g-filters="filters"
            :multi="true"
            :object-value="false"
            :selected-fn="filterItems"
            :allowed="allowed"
            :placeholder="placeholder"
          />
        </div>
      </b-col>
    </b-row>
    <HypothesisTableHeader
      :custom-job="true"
      ref="customTableHeader"
      @showDetailDeparture="activeDepartureDetails = true"
      @hideDetailDeparture="activeDepartureDetails = false"
      @showDetailEntry="activeEntryDetails = true"
      @hideDetailEntry="activeEntryDetails = false"
      @showDetailMobility="activeMobilityDetails = true"
      @hideDetailMobility="activeMobilityDetails = false"
      @showDetailExternal="activeExternalDetails = true"
      @hideDetailExternal="activeExternalDetails = false"
      @showExternalCols="showExternalCols = $event"
      :hidden-external-switcher="hiddenExternalSwitcher"
    />
    <div v-for="(item, idx) in items" :key="idx">
      <div v-if="item.isEnabled" class="my-3 bg-white card" :class="{ 'detail-row': idx > 0 }">
        <CustomJobTableTreeRow
          v-if="item.isEnabled"
          :key="rerenderKey"
          :item="item"
          :index="idx"
          :depth="maxDepth"
          :editable="editable"
          :active-departure-details="activeDepartureDetails"
          :active-entry-details="activeEntryDetails"
          :active-mobility-details="activeMobilityDetails"
          :active-external-details="activeExternalDetails"
          :show-external-cols="showExternalCols"
          :scenario-key="currentScenario"
          :previous-year-edits="previousYearEdits"
          :type-scenario-target="typeScenarioTarget"
          @new:edit="addEdit"
          @update:item="updateItem"
          @update:job="$emit('update:job', $event)"
        />
      </div>
    </div>
  </div>
</template>

<script>
import CustomJobTableTreeRow
  from '@/components/analysis-tabs/hypothesis/customjob/CustomJobTableTreeRow.vue';
import HypothesisTableHeader from '@/components/shared/HypothesisTableHeader.vue';
import HypothesisMixin from '@/mixins/HypothesisMixin';
import { computeAllHypothesisNode } from '@/providers/helpers/hypothesis';
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';
import { debounce } from 'lodash'

export default {
  components: {
    CustomJobTableTreeRow,
    HypothesisTableHeader,
    MainFilterSwitcher,
  },
  mixins: [HypothesisMixin],
  props: {
    typeScenarioTarget: {
      type: String,
      required: false,
      default: 'effective',
    },
    hiddenExternalSwitcher:{
      type: Boolean,
      required: false,
      default: () => false,
    },
    rows: {
      type: Array,
      required: true,
    },
    currentScenario: {
      type: String,
      required: true,
    },
    edits: {
      type: Array,
      required: false,
      default: () => []
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    canFilter: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
    filters: {
      type: Object,
      required: true,
    },
    previousYearEdits: {
      type: Array,
      required: false,
      default: () => [],
    }
  },
  data() {
    return {
      activeDepartureDetails: true,
      activeEntryDetails: true,
      activeMobilityDetails: true,
      activeExternalDetails: true,
      showExternalCols: false,
      computeAllHypothesisNode,
      rerenderKey: 0,
      items: [],
      entityFilters: [],
      debounceChangeItems : debounce((val) => {
        this.$nextTick(() => {
          this.$emit('updateCustom:job', val.map(item => item.target.editing));
        });
      }, 1000)
    };
  },
  computed: {
    placeholder() {
      let placeholder = '';
      switch (this.filters.type) {
        case 'Job':
          placeholder = 'Saisir ou sélectionner le(s) métier(s)';
          break;
        case 'Unit':
          placeholder = "Saisir ou sélectionner l'(les) entité(s)";
          break;
        default:
          placeholder = 'Saisir ou sélectionner le(s) filtre(s)';
      }
      return placeholder;
    },
    allowedItems() {
      const items = [];
      let children = [];
      this.loopInTree({ children: this.items }, (n) => {
        if (this.selection.includes(n.entity)) {
          items.push(n.entity);
          children = [...children, ...this.getNodeChildren(n).map((c) => c.entity)];
        }
      });
      const data = [...items, ...children];
      return { units: data, jobs: data };
    },
  },
  watch: {
    rows: {
      handler(val) {
        console.log('Watch ROWS', val);
        this.fetchItems();
      },
      deep: true
    },
    edits: {
      handler(val) {
        console.log('Watch EDITS', val);
        // this.fetchItems(this.entityFilters);
        this.fetchItems();
      },
      deep: true,
      immediate: true,
    },
    items: {
      handler(val) {
        if (val.length) {
          console.log('Watch ITEMS', val)
          this.debounceChangeItems(val)
          /* this.$nextTick(() => {
            this.$emit('updateCustom:job', val.map(item => item.target.editing));
          }); */
        }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.fetchItems();
  },
  methods: {
    toggleExternalCol(toggle){
      if(this.hiddenExternalSwitcher) {
        this.showExternalCols = toggle
        this.$refs.customTableHeader.toggleExternalCol(toggle)
      }
    },
    fetchItems(filters) {
      this.items = this.highlightEdits(this.rows, this.edits, this.currentScenario);
      this.items = this.applyEdits(this.items, this.currentScenario);
      this.items = this.applyFilters(this.items, filters);
      this.items = this.computeRows(this.items, this.currentScenario);
      this.items = this.injectLastVersion(this.items, this.edits, this.currentScenario);
      this.$forceUpdate();
    },
    addEdit($event) {
      this.$emit('new:edit', $event);
      this.$forceUpdate();
    },
    updateItem(item) {
      // this.fetchItems(this.entityFilters);
      this.$emit('update:item', item);
      this.$forceUpdate();
    },
    filterItems(type, filters) {
      const obj = {};
      obj[type] = filters;
      // this.entityFilters = filters;
      // this.rows = this.filterItemsBySelection(this.rows, filters);
      // // eslint-disable-next-line no-plusplus
      // this.rerenderKey++;
      // this.$forceUpdate();
      this.items = this.applyFilters(this.items, obj);
      this.fetchItems(filters);
    },
  },
};
</script>
