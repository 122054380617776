<template>
  <div v-if="canShowForm">
    <div v-if="step && years" class="mb-4 container" style="overflow: auto">
      <Stepper
        :step="step"
        :years="years"
        :period="payload.period"
        :steps="steps"
      />
    </div>
    <StepsLoader :loading="loading" :text="loadingText" />
    <div v-if="step === steps.FILTER" class="container">
      <StepOne
        :filters="customFilters"
        @updateStep="updateStep($event)"
        @updateFilters="updateFilters"
        @saveDraft="saveAsDraft"
      />
    </div>
    <div v-if="step === steps.BASE_SCENARIO" class="container">
      <StepTwo
        :type="payload.scenario"
        @updateStep="updateStep($event)"
        @updateType="updateType"
        @saveDraft="saveAsDraft"
      />
    </div>
    <div v-if="step === steps.STRATEGY" class="container">
      <StepStrategy
        :type="payload.scenario"
        :filters="payload.filter"
        :years-map="years"
        :payload-rates="rates"
        @previous="previous"
        @updateRates="updateRates"
        @saveDraft="saveAsDraft"
      />
    </div>
    <div v-if="step === steps.PERIOD" class="container">
      <StepSeven
        :type="payload.period"
        @previous="previous"
        @updatePeriod="updatePeriod"
        @saveDraft="saveAsDraft"
      />
    </div>
    <div v-if="step === steps.PEOPLE && !loading" class="container-fluid">
      <div v-if="substep === substeps.HYPOTHESIS_1 && Object.keys(items).length">
        <NewStepFour
          :key="rerenderKey"
          :scenario="payload.scenario"
          :year="projectionYear"
          :rows="items"
          :custom-job-items="customJobItems"
          :current-edits="edits[projectionYear]"
          :filters="payload.filter"
          :scenario-target="scenarioTarget"
          :period-type="payload.period"
          @previous="previous"
          @updateTable="saveYearEdits"
          @updateTableCustom="saveYearCustomEdits"
          @saveDraft="saveAsDraft(
            { ...$event, activeStep: 'PEOPLE.HYPOTHESIS_1' })"
          @next="onNext($event)"
          @change-scenario="onChangeScenario($event)"
        />
      </div>
      <div v-if="substep === substeps.HYPOTHESIS_2 && Object.keys(items).length">
        <NewStepFour
          :key="rerenderKey"
          :scenario="payload.scenario"
          :year="projectionYear"
          :rows="items"
          :custom-job-items="customJobItems"
          :current-edits="edits[projectionYear]"
          :filters="payload.filter"
          :scenario-target="scenarioTarget"
          :period-type="payload.period"
          @previous="substep--"
          @updateTable="saveYearEdits"
          @updateTableCustom="saveYearCustomEdits"
          @saveDraft="saveAsDraft(
            { ...$event, activeStep: 'PEOPLE.HYPOTHESIS_2'})"
          @next="onNext($event)"
          @change-scenario="onChangeScenario($event)"
        />
      </div>
      <div v-if="substep === substeps.HYPOTHESIS_3 && Object.keys(items).length">
        <NewStepFour
          :key="rerenderKey"
          :scenario="payload.scenario"
          :year="projectionYear"
          :rows="items"
          :custom-job-items="customJobItems"
          :current-edits="edits[projectionYear]"
          :filters="payload.filter"
          :scenario-target="scenarioTarget"
          :period-type="payload.period"
          @previous="payload.period === 'last' ? updateStep(steps.PERIOD) : substep--"
          @updateTable="saveYearEdits"
          @updateTableCustom="saveYearCustomEdits"
          @saveDraft="saveAsDraft(
            { ...$event, activeStep: 'PEOPLE.HYPOTHESIS_3' })"
          @next="next"
          @change-scenario="onChangeScenario($event)"
        />
      </div>
    </div>
    <div v-if="step === steps.SKILLS" class="container">
      <StepSkills
        :scenario="payload.scenario"
        :filters="payload.filter"
        :edited-skills="payload.yearJobSkillChanges"
        :years="years"
        @previous="previous"
        @save="updateSkills"
        @saveDraft="saveAsDraft({ ...$event, activeStep: 'SKILLS' })"
      />
    </div>
    <div v-if="step === steps.VALIDATION" class="container">
      <StepSix
        :scenario="payload"
        @previous="previous"
        @save="save"
        @saveDraft="saveAsDraft({ ...$event, activeStep: 'VALIDATION' })"
      />
    </div>
    <ModalInline :active="modal" :fixed="true">
      <div>
        <div class="text-primary">
          <i class="fas fa-check-circle fa-2x" />
        </div>
        <div class="py-4">
          Votre scénario a été sauvegardé avec succès et vos
          <br>
          données sont en train d’être traitées.
        </div>
        <div>
          <button
            class="btn btn-primary text-uppercase"
            type="button"
            @click="$router.push('/collab')"
          >
            <span>FERMER ET RETOURNER À L’ACCUEIL</span>
          </button>
        </div>
      </div>
    </ModalInline>
    <ModalInline :active="modalDraft" :fixed="true">
      <div>
        <div class="text-primary">
          <i class="fas fa-check-circle fa-2x" />
        </div>
        <div class="py-4">
          Votre scénario a été sauvegardé avec succès.
        </div>
        <div>
          <button
            class="btn btn-primary text-uppercase"
            type="button"
            @click="$router.push('/collab')"
          >
            <span>FERMER ET RETOURNER À L’ACCUEIL</span>
          </button>
        </div>
      </div>
    </ModalInline>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { buildScenarioYearChangesMapPayload, deduplicateNodes } from '@/providers/helpers/scenario';
import Stepper from '@/components/all/ScenarioCreationStepperNavigation.vue';
import StepOne from '@/components/steps/StepOne.vue';
import StepTwo from '@/components/steps/StepTwo.vue';
import StepSix from '@/components/steps/StepSix.vue';
import StepSeven from '@/components/steps/StepSeven.vue';
import StepSkills from '@/components/steps/StepSkills'
import ModalInline from '@/components/all/ModalInline.vue';
import NewStepFour from '@/components/steps/NewStepFour.vue';
import StepsLoader from '@/components/shared/StepsLoader.vue';
import StepStrategy from '@/components/steps/StepStrategy.vue';
import { isEmpty } from 'lodash';

export default {
  name: 'StepsValidate',
  components: {
    StepsLoader,
    Stepper,
    StepOne,
    StepTwo,
    StepSix,
    StepSeven,
    NewStepFour,
    ModalInline,
    StepStrategy,
    StepSkills
  },
  data() {
    return {
      rerenderKey: 0,
      loading: false,
      steps: {
        FILTER: 1,
        BASE_SCENARIO: 2,
        STRATEGY: 3,
        PERIOD: 4,
        PEOPLE: 5,
        SKILLS: 6,
        VALIDATION: 7,
      },
      substeps: {
        HYPOTHESIS_1: 1,
        HYPOTHESIS_2: 2,
        HYPOTHESIS_3: 3
      },
      substep: 1,
      step: 1,
      canShowForm: false,
      modal: false,
      modalDraft: false,
      payload: {
        filter: {},
        scenario: '',
        filterAttribute: 'Job',
        isReady: false,
        isFinalized: false,
        label: '',
        comment: '',
        yearChangesMap: {},
        period: null,
        yearGlobalRate: {},
        yearRateMap: {}
      },
      rates: {},
      scenarioTableData: {},
      backup: null,
      editsBackup: null,
      editsCustomBackup: null,
      years: [],
      projectionYear: null,
      edits: {},
      customEdits: {},
      comingBack: false,
      items: {},
      customJobItems: [],
      scenarioTarget: 'effective'
    };
  },
  computed: {
    loadingText() {
      let t = '';
      switch (this.step) {
        case this.steps.BASE_SCENARIO:
          t = 'Nous préparons la vue sur le périmètre sélectionné';
          break;
          case this.steps.PEOPLE:
            switch (this.substep) {
              case this.substeps.HYPOTHESIS_1:
              case this.substeps.HYPOTHESIS_2:
                t = 'Nous intégrons vos hypothèses pour la suite des années';
                break;
              case this.substeps.HYPOTHESIS_3:
                t = 'Nous intégrons vos hypothèses pour la dernière année';
                break;
            }
            break;
        default:
          t = 'Chargement des données en cours';
      }
      return t;
    },
    customFilters() {
      const metadataFilter = this.payload?.filter || {};
      const userFilter = this.currentUser?.filter || {};

      const obj = {};
      Object.keys(metadataFilter).forEach((f) => {
        if (metadataFilter?.[f]?.length) {
          obj[f] = metadataFilter[f];
        }
      });
      Object.keys(userFilter).forEach((f) => {
        if (userFilter?.[f]?.length) {
          obj[f] = userFilter[f];
        }
      });
      return obj;
    },
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  watch: {
    async step(newStep, oldStep) {
      this.comingBack = oldStep > newStep;
      if (this.comingBack) {
        this.projectionYear = null
      }
      if (this.step === this.steps.PEOPLE) {
        await this.fetchScenarioTableBySubStep()
      }
    },
    async substep() {
      await this.fetchScenarioTableBySubStep()
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.years = await this.getPredictionYears();
      if (this.$route.params.id) {
        this.payload = await this.fetchScenarioById({ id: this.$route.params.id });
        if (this.payload.yearChangesMap) {
          this.edits = { ...this.edits, ...this.payload.yearChangesMap };
        }
        if (this.payload.yearRateMap) {
          this.rates = {
            rates: {},
            yearGlobalRate: this.payload.yearGlobalRate,
            yearRateMap: this.payload.yearRateMap
          }
        }
        if (this.payload && this.payload.yearChangesMap && this.payload.customJobs) {
          const promises = []
          this.payload.customJobs.forEach(id => {
            const keyParts = id.split('/')
            promises.push(this.fetchCustomJob(keyParts[1]))
          })
          this.customJobItems = await Promise.all(promises)
        }
        if (this.payload && this.payload.activeStep) {
          if (this.payload.activeStep.includes('.')) {
            const [step, subStep] =  this.payload.activeStep.split('.');
            this.updateStep(this.steps[step]);
            this.updateSubStep(this.substeps[subStep]);
          } else {
            this.updateStep(this.steps[this.payload.activeStep]);
          }
        }
      }
      this.canShowForm = true;
    } catch (err) {
      console.log('error on loading', err);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    async onChangeScenario(type) {
      this.scenarioTarget = type
      if (isEmpty(this.edits)) {
        this.fetchScenarioTable(this.projectionYear);
      } else {
        try {
          const payload = { ...this.payload }
          this.editsBackup = JSON.parse(JSON.stringify(this.edits));
          this.editsCustomBackup = JSON.parse(JSON.stringify(this.customEdits));
          // this.setLoaderStatus(true);
          // eslint-disable-next-line max-len
          const yearChangesMap = buildScenarioYearChangesMapPayload(
            this.scenarioTableData,
            this.years,
            false,
            { ...this.edits },
            this.editsBackup,
          );
          // don't send yearChangesMap attribute in payload if it's null
          if (Object.keys(yearChangesMap).length) {
            payload.yearChangesMap = yearChangesMap;
          }
          // eslint-disable-next-line no-unreachable
          await this.convertValuesScenarioTable({
            changeType: this.scenarioTarget,
            requestedChanges: payload.yearChangesMap[this.projectionYear]
          });
        } catch (err) {
          console.log('ERROR onChangeScenario', err);
          this.$bvToast.toast('Une erreur est survenue.', { variant: 'danger', solid: true });
        } finally {
          //this.setLoaderStatus(false);
        }
      }
    },
    async fetchScenarioTableBySubStep () {
      let year = null
      // eslint-disable-next-line default-case
      switch (this.substep) {
        case this.substeps.HYPOTHESIS_1:
          year = this.years[0]
          break;
        case this.substeps.HYPOTHESIS_2:
          year = this.years[1]
          break;
        case this.substeps.HYPOTHESIS_3:
          year = this.years[2]
          break;
      }
      console.log(this.projectionYear, year)
      if (this.projectionYear !== year) {
        await this.fetchScenarioTable(year);
      }
    },
    getPreviousYearItem(id, scenario = 'target') {
      if (this.years.includes(this.projectionYear - 1)) {
        // eslint-disable-next-line max-len
        return this.scenarioTableData
          ?.[this.projectionYear - 1]
          ?.[this.payload.filterAttribute]
          ?.rowMap
          ?.[id]
          ?.[scenario];
      }
      return false;
    },
    getYearItem (id, year, scenario = 'target') {
      if (this.years.includes(year)) {
        // eslint-disable-next-line max-len
        return this.scenarioTableData
          ?.[year]
          ?.[this.payload.filterAttribute]
          ?.rowMap
          ?.[id]
          ?.[scenario];
      }
      return false;
    },
    sumPreviousYearsItemValue (entity, key, subkey, scenario) {
      let sum = 0
      this.years.forEach(year => {
        if (year < this.projectionYear) {
          const item = this.getYearItem(entity, year, scenario)
          sum += item?.[key]?.[subkey] || 0
        }
      })
      return sum
    },
    getPreviousYearBudgetedEmployeeCount(id, scenario) {
      // if a previous year exists
      if (this.payload?.period !== 'last' && this.years.includes(this.projectionYear - 1)) {
        // get first year item for this line/id
        const previousYear = this.projectionYear - 1;
        // eslint-disable-next-line max-len
        const item = this.scenarioTableData
          ?.[previousYear]
          ?.[this.payload.filterAttribute]
          ?.rowMap
          ?.[id]
          ?.[scenario];
        // get the real budgeted employee count
        const sum = item?.budgedtedEmployeeCount;
        return sum || 0;
      }
      // if there is no previous year
      // eslint-disable-next-line max-len
      const item = this.scenarioTableData
        ?.[this.projectionYear]
        ?.[this.payload.filterAttribute]
        ?.rowMap
        ?.[id]
        ?.[scenario];
      return item?.currentEmployeeCount || 0;
    },
    getPreviousYearEditsSum(key) {
      const lastYearEdits = this.edits[this.projectionYear - 1] || [];
      const edit = lastYearEdits.filter((e) => e.entity === key);
      if (edit?.length) {
        // eslint-disable-next-line no-unused-vars
        let sum = 0;
        const e = edit[0];
        // augmentation de l'effectif
        sum += e.inMobility.customValue - e.inMobility.actualValue;
        sum += e.recruitment.customValue - e.recruitment.actualValue;
        // diminution de l'effectif
        sum += e.outMobility.customValue - e.outMobility.actualValue;
        // eslint-disable-next-line max-len
        sum += e.otherDeparture.customValue - e.otherDeparture.actualValue;
        sum += e.retirement.customValue - e.retirement.actualValue;
        sum += e.external.customValue - e.external.actualValue;
        return sum;
      }
      return 0;
    },
    computeBudgetedEmployeeCount(row) {
      return (
        row.currentEmployeeCount +
        row.departureDetails.retirement +
        row.departureDetails.others +
        row.mobilityDetails.in +
        row.mobilityDetails.out +
        row.entryDetails.recruitement +
        row.entryDetails.others
      );
    },
    /**
     * This methods returns:
     * - the previous year custom value if there is an edit for the provided id
     * - or the previous year value if no edit exists for this id
     * @param id
     * @param key
     * @param originalKey
     * @param scenario
     * @returns {*|number}
     */
    getPreviousYearCustomValue(id, key, originalKey = '', scenario = 'target') {
      const ok = originalKey?.split('.')
      const lastYearEdits = this.edits[this.projectionYear - 1] || [];
      const edit = lastYearEdits.filter((e) => e.entity === id);
      const previousYearItem = this.getPreviousYearItem(id)
      // eslint-disable-next-line max-len,vue/max-len
      return edit?.[0]?.[key]?.customValue || this.sumPreviousYearsItemValue(id, ok[0], ok[1], scenario) || 0;
    },
    prepareTableDataForYear(data, attribute, year, scenario = 'target') {
      const rows = data?.[year]?.[attribute]?.rowMap || {};

      // keep a backup of data to retrieve original value later
      this.backup = JSON.parse(JSON.stringify(rows));

      // for each row
      Object.keys(rows).forEach((key) => {
        const row = rows[key][scenario];

        // 1. replace currentEmployeeCount with last year budgedtedEmployeeCount
        row.currentEmployeeCount = this.getPreviousYearBudgetedEmployeeCount(row.entity, scenario);

        // 2. apply last year edits in currentEmployeeCount
        // if we are NOT coming back from future (ex. 2024 to 2023)
        // if (!this.comingBack) {
        // row.currentEmployeeCount += this.getPreviousYearEditsSum(row.entity);
        // 3. update each field removing last year edits
        // OR last year value
        // eslint-disable-next-line max-len,vue/max-len
        row.departureDetails.retirement -= this.getPreviousYearCustomValue(key, 'retirement', 'departureDetails.retirement', scenario);
        // eslint-disable-next-line max-len,vue/max-len
        row.departureDetails.others -= this.getPreviousYearCustomValue(key, 'otherDeparture', 'departureDetails.others', scenario);
        row.totalDeparture = row.departureDetails.retirement + row.departureDetails.others;
        // eslint-disable-next-line max-len,vue/max-len
        row.entryDetails.recruitement -= this.getPreviousYearCustomValue(key, 'recruitment', 'entryDetails.recruitement', scenario);
        row.totalEntry = row.entryDetails.recruitement + row.entryDetails.others;
        // eslint-disable-next-line max-len,vue/max-len
        row.mobilityDetails.in -= this.getPreviousYearCustomValue(key, 'inMobility', 'mobilityDetails.in', scenario);
        // eslint-disable-next-line max-len,vue/max-len
        row.mobilityDetails.out -= this.getPreviousYearCustomValue(key, 'outMobility', 'mobilityDetails.out', scenario);
        row.netMobility = row.mobilityDetails.in + row.mobilityDetails.out;
        row.budgedtedEmployeeCount = this.computeBudgetedEmployeeCount(row);
        // }
      });
      return rows;
    },
    saveYearEdits(edits) {
      this.edits[this.projectionYear] = deduplicateNodes(edits, 'entityValue');
      this.edits[this.projectionYear].forEach((edit) => {
        edit.original = this.getOriginalActualValue(edit.entity);
      });
    },
    saveYearCustomEdits(edits) {
      this.customEdits[this.projectionYear] = deduplicateNodes(edits, 'entityValue');
      this.customEdits[this.projectionYear].forEach((edit) => {
        edit.original = this.getOriginalActualValue(edit.entity);
      });
    },
    getOriginalActualValue(key, scenario = 'target') {
      return this.backup?.[key]?.[scenario];
    },
    updateStep(step) {
      this.step = step;
    },
    updateSubStep(substep) {
      this.substep = substep;
    },
    updateSkills (skills) {
      this.payload = { ...this.payload, ...skills }
      this.next()
    },
    updateRates({ rates, yearGlobalRate, yearRateMap }) {
      this.rates = { rates, yearGlobalRate, yearRateMap }
      this.payload.yearGlobalRate = yearGlobalRate;
      this.payload.yearRateMap = yearRateMap;
      this.next();
    },
    previous() {
      this.step -= 1;
    },
    next(data) {
      console.log('DATA NEXT', data)
      if (data) {
        this.payload = { ...this.payload, ...data}
      }
      this.step += 1;
    },
    async onNext(data) {
      this.substep++
      if (data.customJobs && data.customJobs.length) {
        const promises = []
        data.customJobs.forEach(id => {
          const keyParts = id.split('/')
          promises.push(this.fetchCustomJob(keyParts[1]))
        })
        this.customJobItems = await Promise.all(promises)
      }
    },
    updateFilters(filters) {
      this.payload.filter = filters;
    },
    updateType(type) {
      this.payload.scenario = type.id;
      this.step += 1;
    },
    updatePeriod(period) {
      this.payload.period = period.id;
      if (this.payload.period === 'last') {
        this.substep = this.substeps.HYPOTHESIS_3;
      } else {
        this.substep = this.substeps.HYPOTHESIS_1
      }
      this.step += 1;
    },
    async save(details) {
      // ensure payload has yearChangesMap value in order to save scenario with isFinalized = true
      this.payload = { ...this.payload, ...details, isFinalized: true };
      await this.sendPayload(this.payload, false);
    },
    async saveAsDraft(details) {
      this.payload = { ...this.payload, ...details, isFinalized: false };
      await this.sendPayload(this.payload, true);
    },
    async sendPayload(payload, draft) {
      try {
        this.editsBackup = JSON.parse(JSON.stringify(this.edits));
        this.editsCustomBackup = JSON.parse(JSON.stringify(this.customEdits))
        this.setLoaderStatus(true);
        // eslint-disable-next-line max-len
        const yearChangesMap = buildScenarioYearChangesMapPayload(
          this.scenarioTableData,
          this.years,
          false,
          { ...this.edits },
          this.editsBackup,
        );
        // don't send yearChangesMap attribute in payload if it's null
        if (Object.keys(yearChangesMap).length) {
          payload.yearChangesMap = yearChangesMap;
        }
        // eslint-disable-next-line no-unreachable
        await this.saveScenario(payload);
        if (draft) {
          this.modalDraft = true;
        } else {
          this.modal = true;
        }
      } catch (err) {
        console.log('ERROR saveScenario', err);
        this.$bvToast.toast('Une erreur est survenue.', { variant: 'danger', solid: true });
      } finally {
        this.setLoaderStatus(false);
      }
    },
    async fetchScenarioTable(year) {
      try {
        this.editsBackup = JSON.parse(JSON.stringify(this.edits));
        this.editsCustomBackup = JSON.parse(JSON.stringify(this.customEdits));
        this.projectionYear = year;
        this.loading = true;
        // build the yearChangesMap object respecting 2 rules:
        // - don't send future year data than projectionYear
        // - add previous year lines for all edits*
        // *(ex. if Job/ComJob23 is edited in 2023, complete with 2022 and 2021 informations)
        // *(ex. if Job/ComJob23 is edited in 2022, complete with 2023 and 2021 informations)
        // eslint-disable-next-line max-len
        const yearChangesMap = buildScenarioYearChangesMapPayload(
          this.scenarioTableData,
          this.years,
          year,
          {...this.edits},
          this.editsBackup
        );
        // build the 'fetch scenario table by year' payload
        const payload = {
          year,
          scenario: this.payload.scenario,
          filterAttribute: this.payload.filterAttribute,
          yearGlobalRate: this.payload.yearGlobalRate || {},
          yearRateMap: this.payload.yearRateMap || {},
          ...this.payload.filter,
        };
        // don't send yearChangesMap attribute in payload if it's null
        if (Object.keys(yearChangesMap).length) {
          payload.yearChangesMap = yearChangesMap;
        }
        const data = await this.getScenarioTable({ payload, type: this.scenarioTarget });
        // eslint-disable-next-line max-len
        this.scenarioTableData = { ...this.scenarioTableData, ...data };

        // set items
        this.items = this.prepareTableDataForYear(
          this.scenarioTableData,
          this.payload.filterAttribute,
          this.projectionYear
        );
        // eslint-disable-next-line no-plusplus
        this.rerenderKey++;
      } catch (err) {
        console.log('error', err);
      } finally {
        this.loading = false;
      }
    },
    ...mapActions({
      fetchScenarioById: 'scenarios/fetchScenarioById',
      saveScenario: 'scenarios/saveScenario',
      getScenarioTable: 'scenarios/getScenarioTable',
      setLoaderStatus: 'app/setLoaderStatus',
      getPredictionYears: 'scenarios/getPredictionYears',
      fetchCustomJob: 'jobs/fetchCustomJobMetadata',
      convertValuesScenarioTable: 'scenarios/convertValuesScenarioTable',
    }),
  },
};
</script>
