<template>
  <div class="mb-5">
    <h6 class="font-weight-normal mb-3">
      Définissez les paramètres sur lesquels vous voulez travailler.
    </h6>
    <div class="p-lg-5">
      <PerimeterFilters
        :readonly="readonly"
        :selection="filters"
        :allowed="allowedUnits"
        @update:filters="$emit('updateFilters', $event)"
      >
        <b-row class="align-content-between">
          <b-col>
            <button class="btn btn-outline-dark text-uppercase" type="button" @click="goBack">
              <i class="fal fa-angle-left mr-2" />
              <span>ANNULER</span>
            </button>
          </b-col>
          <b-col class="text-right">
            <b-button
              v-if="!readonly"
              variant="link"
              class="text-uppercase"
              @click="$emit('saveDraft', { activeStep: 'FILTER' })"
            >
              Enregistrer en tant que brouillon
            </b-button>
            <button
              class="btn btn-secondary text-uppercase"
              type="button"
              @click="$emit('updateStep', 2)"
            >
              <span>Valider</span>
            </button>
          </b-col>
        </b-row>
      </PerimeterFilters>
    </div>
  </div>
</template>
<script>
import PerimeterFilters from '@/components/steps/PerimeterFilters.vue';
import { mapGetters } from 'vuex';

export default {
  components: {
    PerimeterFilters,
  },
  props: {
    filters: {
      type: Object,
      required: false,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
    allowedUnits() {
      return this.currentUser.filter?.units?.length ? this.filters : {}
    }
  },
  methods: {
    goBack() {
      this.$router.push('/collab');
    },
  },
};
</script>
