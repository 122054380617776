<template>
  <div>
    <i
      v-if="comment"
      :id="`comment-${id}`"
      class="fal fa-comment-alt-dots text-primary"
      style="font-size: 1.5em"
    />
    <b-popover v-if="comment" :target="`comment-${id}`" triggers="hover">
      {{ comment }}
    </b-popover>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    comment: {
      type: String,
      required: false,
      default: () => ''
    }
  }
}
</script>
