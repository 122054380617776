import {
  mergeEditions,
  computeAllHypothesisNode,
  mergeNodesValues
} from '@/providers/helpers/hypothesis';

import {
  findUltimateParentWithChild,
} from '@/providers/helpers/functions';

const getDepth = (node, depth = 0) => {
  if (node.children && node.children.length > 0) {
    const childDepths = [];
    depth += 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < node.children.length; i++) {
      childDepths.push(getDepth(node.children[i]));
    }
    return depth + Math.max(...childDepths);
  }
  return depth;
};

export default {
  computed: {
    maxDepth() {
      const max = [];
      this.items.forEach((item) => {
        max.push(getDepth(item, 1));
      });
      return Math.max(...max);
    },
  },
  methods: {
    injectLastVersion(items, edits, scenarioKey) {
      if (edits) {
        // 1. for each edit, fill attributes to merge children values in parents'
        edits.forEach((edit) => {
          // manage edit
          const item = this.findOptionTree(items, edit.entityValue, 'entity');
          if (item && item[scenarioKey]) {
            if (!item[scenarioKey].history) {
              item[scenarioKey].history = [];
            }
            item[scenarioKey].history.push(edit);
            item[scenarioKey].lastVersion = mergeEditions(item[scenarioKey].history);
            item[scenarioKey].lastVersion.entity = edit.entityValue;
          }
          // manage parents in path
          const paths = this.getPath({ children: items }, edit.entityValue).filter(Boolean);
          paths.reverse().forEach((path) => {
            // eslint-disable-next-line no-unused-vars
            let node = this.findOptionTree(items, path, 'entity');
            computeAllHypothesisNode(node, scenarioKey);
          });
        });
      }
      return items;
    },
    computeGlobalRow(items, scenarioKey) {
      if (items && items.length > 1 && items[0] && items[0][scenarioKey]) {
        const rows = items.slice(1, items.length);
        items[0][scenarioKey] = this.resetRow();
        rows.forEach((c) => {
          items[0][scenarioKey].currentEmployeeCount += c[scenarioKey].currentEmployeeCount;
          items[0][scenarioKey].budgetedEmployeeCount += c[scenarioKey].budgetedEmployeeCount;
          items[0][scenarioKey].budgedtedEmployeeCount += c[scenarioKey].budgedtedEmployeeCount;
          // eslint-disable-next-line max-len,vue/max-len
          items[0][scenarioKey].departureDetails.retirement += c[scenarioKey].departureDetails?.retirement;
          items[0][scenarioKey].departureDetails.others += c[scenarioKey].departureDetails?.others;
          items[0][scenarioKey].totalDeparture += c[scenarioKey].totalDeparture;
          items[0][scenarioKey].evolution += c[scenarioKey].evolution;
          // eslint-disable-next-line max-len,vue/max-len
          items[0][scenarioKey].entryDetails.recruitement += c[scenarioKey].entryDetails?.recruitement;
          items[0][scenarioKey].entryDetails.others += c[scenarioKey].entryDetails?.others;
          items[0][scenarioKey].totalEntry += c[scenarioKey].totalEntry;
          items[0][scenarioKey].mobilityDetails.in += c[scenarioKey].mobilityDetails?.in;
          items[0][scenarioKey].mobilityDetails.out += c[scenarioKey].mobilityDetails?.out;
          items[0][scenarioKey].netMobility += c[scenarioKey].netMobility;
          items[0][scenarioKey].delta += c[scenarioKey].delta;
          items[0][scenarioKey].budgetedExternalCount += c[scenarioKey].budgetedExternalCount;
          items[0][scenarioKey].currentExternalCount += c[scenarioKey].currentExternalCount;
          items[0][scenarioKey].externalDelta += c[scenarioKey].externalDelta;
          items[0][scenarioKey].budgetedAllCount += c[scenarioKey].budgetedAllCount;
        });
      }
      return items;
    },
    applyEdits(items, scenarioKey) {
      this.loopInTree({ children: items }, (item) => {
        if (!item[scenarioKey]?.lastVersion) {
          return item;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.inMobility?.customValue) && item[scenarioKey].lastVersion?.inMobility?.customValue !== item[scenarioKey].lastVersion?.inMobility?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].mobilityDetails.in = item[scenarioKey].lastVersion?.inMobility?.customValue || item[scenarioKey].mobilityDetails.in || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.outMobility?.customValue) && item[scenarioKey].lastVersion?.outMobility?.customValue !== item[scenarioKey].lastVersion?.outMobility?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].mobilityDetails.out = item[scenarioKey].lastVersion?.outMobility?.customValue || item[scenarioKey].mobilityDetails.out || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.recruitment?.customValue) && item[scenarioKey].lastVersion?.recruitment?.customValue !== item[scenarioKey].lastVersion?.recruitment?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].entryDetails.recruitement = item[scenarioKey].lastVersion?.recruitment?.customValue || item[scenarioKey].entryDetails.recruitement || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.retirement?.customValue) && item[scenarioKey].lastVersion?.retirement?.customValue !== item[scenarioKey].lastVersion?.retirement?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].departureDetails.retirement = item[scenarioKey].lastVersion?.retirement?.customValue || item[scenarioKey].departureDetails.retirement || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.otherDeparture?.customValue) && item[scenarioKey].lastVersion?.otherDeparture?.customValue !== item[scenarioKey].lastVersion?.otherDeparture?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].departureDetails.others = item[scenarioKey].lastVersion?.otherDeparture?.customValue || item[scenarioKey].departureDetails.others || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.budgetedExternalCount?.customValue) && item[scenarioKey].lastVersion?.budgetedExternalCount?.customValue !== item[scenarioKey].lastVersion?.budgetedExternalCount?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].budgetedExternalCount = item[scenarioKey].lastVersion?.budgetedExternalCount?.customValue || item[scenarioKey].budgetedExternalCount || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.currentExternalCount?.customValue) && item[scenarioKey].lastVersion?.currentExternalCount?.customValue !== item[scenarioKey].lastVersion?.currentExternalCount?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].currentExternalCount = item[scenarioKey].lastVersion?.currentExternalCount?.customValue || item[scenarioKey].currentExternalCount || 0;
        }
        // eslint-disable-next-line max-len,vue/max-len
        if (Number.isInteger(item[scenarioKey]?.lastVersion?.external?.customValue) && item[scenarioKey].lastVersion?.external?.customValue !== item[scenarioKey].lastVersion?.external?.actualValue) {
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].budgetedExternalCount = item[scenarioKey].lastVersion?.external?.customValue || item[scenarioKey].budgetedExternalCount || 0;
        }

        // eslint-disable-next-line max-len,vue/max-len
        // if (Number.isInteger(item[scenarioKey]?.lastVersion?.budgetedAllCount?.customValue) && item[scenarioKey].lastVersion?.budgetedAllCount?.customValue !== item[scenarioKey].lastVersion?.budgetedAllCount?.actualValue) {
        // eslint-disable-next-line max-len,vue/max-len
        //   item[scenarioKey].budgetedAllCount = item[scenarioKey].lastVersion?.budgetedAllCount?.customValue || item[scenarioKey].budgetedAllCount || 0;
        // }

        // eslint-disable-next-line max-len
        item[scenarioKey].budgedtedEmployeeCount = item[scenarioKey].currentEmployeeCount
          + item[scenarioKey].mobilityDetails.in
          + item[scenarioKey].mobilityDetails.out
          + item[scenarioKey].entryDetails.recruitement
          + item[scenarioKey].entryDetails.others
          + item[scenarioKey].departureDetails.retirement
          + item[scenarioKey].departureDetails.others;
        return item;
      });
      return items;
    },
    resetRow() {
      return {
        currentEmployeeCount: 0,
        budgedtedEmployeeCount: 0,
        budgetedEmployeeCount: 0,
        totalDeparture: 0,
        budgetedExternalCount: 0,
        currentExternalCount: 0,
        externalDelta: 0,
        budgetedAllCount: 0,
        departureDetails: {
          retirement: 0,
          others: 0,
        },
        evolution: 0,
        totalEntry: 0,
        entryDetails: {
          recruitement: 0,
          others: 0,
        },
        netMobility: 0,
        mobilityDetails: {
          in: 0,
          out: 0,
        },
        delta: 0,
      };
    },
    computeRows(items, scenarioKey) {
      const nodes = [];
      // get all rows with children
      this.loopInTree({ children: items }, (item) => {
        if (item?.children?.length) {
          nodes.push(item);
        }
      });
      // reverse rows to stat with children
      nodes.reverse().forEach((item) => {
        item.children.forEach((c, i) => {
          if (i === 0) {
            item[scenarioKey] = this.resetRow();
          }
          item[scenarioKey].currentEmployeeCount += c[scenarioKey].currentEmployeeCount;
          item[scenarioKey].budgedtedEmployeeCount += c[scenarioKey].budgedtedEmployeeCount;
          item[scenarioKey].budgetedEmployeeCount += c[scenarioKey].budgetedEmployeeCount;
          // eslint-disable-next-line max-len,vue/max-len
          item[scenarioKey].departureDetails.retirement += c[scenarioKey].departureDetails.retirement;
          item[scenarioKey].departureDetails.others += c[scenarioKey].departureDetails.others;
          item[scenarioKey].totalDeparture += c[scenarioKey].totalDeparture;
          item[scenarioKey].evolution += c[scenarioKey].evolution;
          item[scenarioKey].entryDetails.recruitement += c[scenarioKey].entryDetails.recruitement;
          item[scenarioKey].entryDetails.others += c[scenarioKey].entryDetails.others;
          item[scenarioKey].totalEntry += c[scenarioKey].totalEntry;
          item[scenarioKey].mobilityDetails.in += c[scenarioKey].mobilityDetails.in;
          item[scenarioKey].mobilityDetails.out += c[scenarioKey].mobilityDetails.out;
          item[scenarioKey].netMobility += c[scenarioKey].netMobility;
          item[scenarioKey].delta += c[scenarioKey].delta;
          item[scenarioKey].budgetedExternalCount += c[scenarioKey].budgetedExternalCount;
          item[scenarioKey].currentExternalCount += c[scenarioKey].currentExternalCount;
          item[scenarioKey].externalDelta += c[scenarioKey].externalDelta;
          item[scenarioKey].budgetedAllCount += c[scenarioKey].budgetedAllCount;
        });
      });
      return items;
    },
    injectVariation (items, edits, scenarioKey) {
      edits.forEach((edit) => {
        // inject edit in current item
        const item = this.findOptionTree(items, edit.entityValue, 'entity')
        if (!item[scenarioKey]) {
          return
        }
        item[scenarioKey].userEdit = edit
        // manage parents in path providing variation for each column
        const paths = this.getPath({ children: items }, edit.entityValue).filter(Boolean)
        paths.reverse().forEach((path) => {
          const childrenEdits = []
          // eslint-disable-next-line no-unused-vars
          let node = this.findOptionTree(items, path, 'entity')
          if (node?.children?.length) {
            node.children.forEach((child) => {
              if (child[scenarioKey]?.userEdit) {
                childrenEdits.push(child[scenarioKey]?.userEdit)
              }
            })
            function mergeProp (prop, value) {
              if (typeof prop !== 'string' && typeof value !== 'string'
                && typeof prop !== 'object' && typeof value !== 'object') {
                prop += parseInt(value)
              }
              return prop
            }
            function mergeProps (arrayValues) {
              const sumObject = {}
              arrayValues.forEach((value) => {
                Object.keys(value).forEach((key) => {
                  if (typeof value[key] === 'object') {
                    sumObject[key] = {}
                    Object.keys(value[key]).forEach((subkey) => {
                      if (!sumObject[key][subkey]) {
                        sumObject[key][subkey] = 0
                      }
                      if (typeof value[key][subkey] !== 'object') {
                        // eslint-disable-next-line max-len,vue/max-len
                        sumObject[key][subkey] = mergeProp(sumObject[key][subkey], value[key][subkey])
                      }
                    })
                  } else {
                    sumObject[key] = mergeProp(sumObject[key], value[key])
                  }
                })
              })
              return sumObject
            }
            node[scenarioKey].userEdit = mergeProps(childrenEdits)
          }
        })
      })

      return items
    },
    highlightEdits(items, edits, scenarioKey = 'target') {
      edits.forEach((edit) => {
        // manage edit
        const item = this.findOptionTree(items, edit.entityValue, 'entity');
        if (item && item[scenarioKey]) {
          if (!item[scenarioKey].history) {
            item[scenarioKey].history = [];
          }
          item[scenarioKey].history.push(edit);
          item[scenarioKey].lastVersion = mergeEditions(item[scenarioKey].history);
          item[scenarioKey].lastVersion.entity = edit.entityValue;
        }
        // manage parents in path
        const paths = this.getPath({ children: items }, edit.entityValue).filter(Boolean);
        paths.reverse().forEach((path) => {
          // eslint-disable-next-line no-unused-vars
          let node = this.findOptionTree(items, path, 'entity');
          computeAllHypothesisNode(node, scenarioKey);
        });
      });
      return items;
    },
    applyFilters(items, filters) {
      const filtered = this.filterItemsByJobs(items, filters?.jobs);
      return this.filterItemsBySelection(filtered, filters);
    },
    depthDecorator(array, depth = 1) {
      return array.map((child) =>
        Object.assign(child, {
          depth,
          children: this.depthDecorator(child.children || [], depth + 1),
        })
      );
    },
    setHypothesisObjects(items, scenario, parent, reducedTree, tree) {
      // eslint-disable-next-line
      for (const treeCategory of reducedTree) {
        // eslint-disable-next-line
        if (treeCategory.children && parent[scenario]?.entity === treeCategory._id) {
          const children = [];
          treeCategory.children.forEach((child) => {
            // eslint-disable-next-line
            const element = tree[child._id];
            if (element) {
              const newChild = { ...element, names: child.names };
              children.push(newChild);
            }
          });
          if (children.length) {
            parent.children = children;
            // eslint-disable-next-line
            for (const child of parent.children) {
              this.setHypothesisObjects(items, scenario, child, treeCategory.children, tree);
            }
          }
        }
      }
    },
    sumEdit(edit) {
      // eslint-disable-next-line no-unused-vars
      let sum = 0;
      // augmentation de l'effectif
      sum += edit.inMobility.customValue - edit.inMobility.actualValue;
      sum += edit.recruitment.customValue - edit.recruitment.actualValue;
      // diminution de l'effectif
      sum += edit.outMobility.customValue - edit.outMobility.actualValue;
      // eslint-disable-next-line max-len
      sum += edit.otherDeparture.customValue - edit.otherDeparture.actualValue;
      sum += edit.retirement.customValue - edit.retirement.actualValue;
      sum += edit.budgetedExternalCount.customValue - edit.budgetedExternalCount.actualValue;
      sum += edit.currentExternalCount.customValue - edit.currentExternalCount.actualValue;
      sum += edit.externalDelta.customValue - edit.externalDelta.actualValue;
      // sum += edit.budgetedAllCount.customValue - edit.budgetedAllCount.actualValue;
      return sum;
    },
    parseRowsToItemsWithEdits(items, edits, scenarioKey, jobs) {
      if (edits) {
        // 1. for each edit, fill attributes to merge children values in parents'
        edits.forEach((edit) => {
          // manage edit
          const item = this.findOptionTree(items, edit.entityValue, 'entity');
          if (item && item[scenarioKey]) {
            if (!item[scenarioKey].history) {
              item[scenarioKey].history = [];
            }
            item[scenarioKey].history.push(edit);
            item[scenarioKey].lastVersion = mergeEditions(item[scenarioKey].history);
            item[scenarioKey].lastVersion.entity = edit.entityValue;
          }
          // manage parents in path
          const paths = this.getPath({ children: items }, edit.entityValue).filter(Boolean);
          paths.reverse().forEach((path) => {
            // eslint-disable-next-line no-unused-vars
            let node = this.findOptionTree(items, path, 'entity');
            computeAllHypothesisNode(node, scenarioKey);
          });
        });
        // 2. update global row
        if (items && items.length && items[0] && items[0][scenarioKey]) {
          const rows = items.slice(1, items.length);
          const final = {
            mobilityDetails: { in: 0, out: 0 },
            departureDetails: { retirement: 0, others: 0 },
            entryDetails: { recruitement: 0, others: 0 },
          };
          rows.forEach((i) => {
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.inMobility?.customValue) && i[scenarioKey].lastVersion?.inMobility?.customValue !== i[scenarioKey].lastVersion?.inMobility?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.mobilityDetails.in += (i[scenarioKey].lastVersion?.inMobility?.customValue || 0) - (i[scenarioKey].lastVersion?.inMobility.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.outMobility?.customValue) && i[scenarioKey].lastVersion?.outMobility?.customValue !== i[scenarioKey].lastVersion?.outMobility?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.mobilityDetails.out += (i[scenarioKey].lastVersion?.outMobility?.customValue || 0) - (i[scenarioKey].lastVersion?.outMobility.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.recruitment?.customValue) && i[scenarioKey].lastVersion?.recruitment?.customValue !== i[scenarioKey].lastVersion?.recruitment?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.entryDetails.recruitement += (i[scenarioKey].lastVersion?.recruitment?.customValue || 0) - (i[scenarioKey].lastVersion?.recruitment.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.retirement?.customValue) && i[scenarioKey].lastVersion?.retirement?.customValue !== i[scenarioKey].lastVersion?.retirement?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.departureDetails.retirement += (i[scenarioKey].lastVersion?.retirement?.customValue || 0) - (i[scenarioKey].lastVersion?.retirement.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.otherDeparture?.customValue) && i[scenarioKey].lastVersion?.otherDeparture?.customValue !== i[scenarioKey].lastVersion?.otherDeparture?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.departureDetails.others += (i[scenarioKey].lastVersion?.otherDeparture?.customValue || 0) - (i[scenarioKey].lastVersion?.otherDeparture.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.budgetedExternalCount?.customValue) && i[scenarioKey].lastVersion?.budgetedExternalCount?.customValue !== i[scenarioKey].lastVersion?.budgetedExternalCount?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.budgetedExternalCount += (i[scenarioKey].lastVersion?.budgetedExternalCount?.customValue || 0) - (i[scenarioKey].lastVersion?.budgetedExternalCount.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.currentExternalCount?.customValue) && i[scenarioKey].lastVersion?.currentExternalCount?.customValue !== i[scenarioKey].lastVersion?.currentExternalCount?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.currentExternalCount += (i[scenarioKey].lastVersion?.currentExternalCount?.customValue || 0) - (i[scenarioKey].lastVersion?.currentExternalCount.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            if (Number.isInteger(i[scenarioKey].lastVersion?.external?.customValue) && i[scenarioKey].lastVersion?.external?.customValue !== i[scenarioKey].lastVersion?.external?.actualValue) {
              // eslint-disable-next-line max-len,vue/max-len
              final.budgetedExternalCount += (i[scenarioKey].lastVersion?.external?.customValue || 0) - (i[scenarioKey].lastVersion?.external.actualValue || 0);
            }
            // eslint-disable-next-line max-len,vue/max-len
            // if (Number.isInteger(i[scenarioKey].lastVersion?.budgetedAllCount?.customValue) && i[scenarioKey].lastVersion?.budgetedAllCount?.customValue !== i[scenarioKey].lastVersion?.budgetedAllCount?.actualValue) {
            // eslint-disable-next-line max-len,vue/max-len
            //   final.budgetedAllCount += (i[scenarioKey].lastVersion?.budgetedAllCount?.customValue || 0) - (i[scenarioKey].lastVersion?.budgetedAllCount.actualValue || 0);
            // }
          });
          delete items[0][scenarioKey].lastVersion;
          items[0][scenarioKey] = { ...items[0][scenarioKey], ...final };
        }
      }
      return this.filterItemsByJobs(items, jobs);
    },
    filterItemsByJobs(items, jobs = []) {
      if (jobs && jobs.length) {
        jobs.forEach((job) => {
          const nodesNames = this.getPath({ children: items }, job);
          const jobNode = this.findOptionTree(items, job, 'entity');
          const childrenNames = this.getNodeChildren(jobNode).map((c) => c.entity);
          jobs = [...jobs, ...nodesNames.filter(Boolean), ...childrenNames];
        });
        this.loopInTree({ children: items }, (n) => {
          if (n.entity === 'Global') {
            n.isEnabled = true;
          } else {
            n.isEnabled = !!(jobs.length && jobs.includes(n.entity));
            n.activeCollapse = jobs.length > 0;
          }
        });
      } else {
        this.loopInTree({ children: items }, (n) => {
          n.isEnabled = true;
          n.activeCollapse = false;
        });
      }
      return items;
    },
    filterItemsBySelection(items, selection) {
      if (selection && selection.length) {
        selection.forEach((selectionItem) => {
          // eslint-disable-next-line max-len
          const nodesNames = this.getPath({ children: items }, selectionItem);
          const node = this.findOptionTree(items, selectionItem, 'entity');
          const childrenNames = this.getNodeChildren(node).map((c) => c.entity);
          selection = [...selection, ...nodesNames.filter(Boolean), ...childrenNames];
        });
        this.loopInTree({ children: items }, (n) => {
          if (n.entity === 'Global') {
            n.isEnabled = true;
          } else {
            n.isEnabled = !!(selection.length && selection.includes(n.entity));
            n.activeCollapse = selection.length > 0;
          }
        });
      } else {
        this.loopInTree({ children: items }, (n) => {
          n.isEnabled = true;
          n.activeCollapse = false;
        });
      }
      return items;
    },
    parseRowsToJobCostTree(rows, periodType) {
      const jobCategoryCost = {}
      for (const key in rows) {
          const parentChildPair = findUltimateParentWithChild(this.jobTree, key, '_id');
          const childJobCost = {
            id: parentChildPair.child._id,
            name: parentChildPair.child?.names[0]?.label || '',
            value3: rows[key],
          }
          if (periodType === 'all') {
            childJobCost['value1'] = rows[key]
            childJobCost['value2'] = rows[key]
          }
          if (jobCategoryCost[parentChildPair.ultimateParent._id]?.children) {
            // eslint-disable-next-line
            jobCategoryCost[parentChildPair.ultimateParent._id].children = [...jobCategoryCost[parentChildPair.ultimateParent._id].children, childJobCost]
          } else {
            jobCategoryCost[parentChildPair.ultimateParent._id] = {
              id: parentChildPair.ultimateParent._id,
              children: [childJobCost],
              name: parentChildPair.ultimateParent?.names[0]?.label || ''
            }
          }
      }
      return Object.values(jobCategoryCost)
    },
    parseItemsTreeToNestedRowsArray(tree, scenarioKey, type) {
      // cast tree to array
      const rows = Object.values(tree);
      // find parent categories
      const { parents, reducedTree } = this.findParentCategories(rows, scenarioKey, type);
      // add children in each parent
      // eslint-disable-next-line
      for (const parent of parents) {
        this.setHypothesisObjects(rows, scenarioKey, parent, reducedTree, tree);
      }
      // add global row
      this.addGlobalRow(rows, parents);
      // decorate data with depth for UI purpose
      return this.depthDecorator(parents);
    },
    findParentCategories(items, scenario, type) {
      // define categories from entity name
      let categories = [];
      let tree;
      let entities = [];
      switch (type) {
        case 'Job':
          categories = items.filter((item) => item[scenario]?.entity.includes('JobCategory'));
          tree = this.jobTree;
          break;
        case 'Unit':
          tree = this.unitTree;
          // eslint-disable-next-line no-underscore-dangle
          entities = tree.map((t) => t._id);
          categories = items.filter((item) => entities.includes(item[scenario]?.entity));
          break;
        case 'Site':
          tree = this.siteTree;
          // eslint-disable-next-line no-underscore-dangle
          entities = tree.map((t) => t._id);
          categories = items.filter((item) => entities.includes(item[scenario]?.entity));
          break;
        case 'CostCentre':
          tree = this.costCentresList;
          // eslint-disable-next-line no-underscore-dangle
          entities = tree.map((t) => t._id);
          categories = items.filter((item) => entities.includes(item[scenario]?.entity));
          break;
        default:
          categories = items;
          tree = this.unitTree;
      }

      // set super parents
      const parents = [];
      const reducedTree = []; // jobTree with super parents found in tableDataDetail

      // eslint-disable-next-line
      for (const leaf of tree) {
        // eslint-disable-next-line
        const matchParent = categories.find((category) => category.entity === leaf._id);
        // eslint-disable-next-line no-underscore-dangle
        if (matchParent) {
          reducedTree.push(leaf);
          parents.push({ ...matchParent, names: leaf.names });
        }
      }

      return { parents, reducedTree };
    },
    addGlobalRow(items, parents) {
      // add global
      const global = items.find((item) => item.entity === 'Global');
      if (global) parents.unshift({ ...global, names: [{ label: 'Global' }] });
    },
    getEditDiff(edit) {
      if (edit.inMobility.customValue !== edit.inMobility.actualValue) {
        return { key: 'inMobility', object: edit.inMobility };
      }
      if (edit.recruitment.customValue !== edit.recruitment.actualValue) {
        return { key: 'recruitment', object: edit.recruitment };
      }
      if (edit.outMobility.customValue !== edit.outMobility.actualValue) {
        return { key: 'outMobility', object: edit.outMobility };
      }
      if (edit.otherDeparture.customValue !== edit.otherDeparture.actualValue) {
        return { key: 'otherDeparture', object: edit.otherDeparture };
      }
      if (edit.retirement.customValue !== edit.retirement.actualValue) {
        return { key: 'retirement', object: edit.retirement };
      }
      if (edit.budgetedExternalCount.customValue !== edit.budgetedExternalCount.actualValue) {
        return { key: 'budgetedExternalCount', object: edit.budgetedExternalCount };
      }
      if (edit.currentExternalCount.customValue !== edit.currentExternalCount.actualValue) {
        return { key: 'currentExternalCount', object: edit.currentExternalCount };
      }
      if (edit.external.customValue !== edit.external.actualValue) {
        return { key: 'budgetedExternalCount', object: edit.budgetedExternalCount };
      }
      // if (edit.budgetedAllCount.customValue !== edit.budgetedAllCount.actualValue) {
      //   return { key: 'budgetedAllCount', object: edit.budgetedAllCount };
      // }
      return null;
    },
  },
};
