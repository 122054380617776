import Vue from 'vue';
import Router from 'vue-router';
import Cookies from 'js-cookie';
import { isEmpty } from 'lodash';

import setup from '../providers/settings/constants/setupPags';
import LayoutLoader from '../layout/LayoutLoader.vue';
import NotFound from '../components/NotFound.vue';
import Login from '../views/LoginPage.vue';
import store from '../store';

Vue.use(Router);

// Get env from URL or defautl collab
/* const baseEnv =
  window.location.href.split('/')[3] === ''
    ? `${Object.keys(setup)[0]}`
    : window.location.href.split('/')[3];
store.commit('setEnv', baseEnv); */

// Create Routes from all projects
const dinamycRoutes = [];
Object.keys(setup).forEach((env) => {
  // Set Default env
  setup[env].body.forEach((_, routeIndex) => {
    const item = setup[env].body[routeIndex];
    dinamycRoutes.push({
      path: `/${env}/${item.path}`,
      name: `${env}/${item.name}`,
      meta: {
        auth: true,
        routeIndex,
      },
      component: LayoutLoader,
    });

    const { children = [] } = item;
    const index = dinamycRoutes.length;
    if (!isEmpty(children)) {
      dinamycRoutes[index - 1].children = [];
      children.forEach((elem, inx) => {
        dinamycRoutes[index - 1].children.push({
          path: `${elem.path}`,
          name: `${env}${elem.name}`,
          meta: {
            auth: true,
            routeIndex: `${routeIndex}-${inx}`,
          },
          component: LayoutLoader,
        });
      });
    }
  });
});
export const createRoutes = () => [
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: async (to, from, next) => {
      const token = Cookies.get('gpec');
      if (token) {
        next('/collab/');
      }
      next();
    },
  },
  ...dinamycRoutes,
  {
    path: '*',
    name: 'not-found',
    component: NotFound,
    beforeEnter: async (to, from, next) => {
      const token = Cookies.get('gpec');
      if (token && to.path === '/') {
        next('/collab');
      } else if (token && to.path !== '/') {
        next();
      } else {
        next('/login');
      }
    },
  },
];

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    }
    return { x: 0, y: 0 };
  },
  routes: createRoutes(),
});

// Check route require auth
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.auth)) {
    const token = Cookies.get('gpec');
    if (token) {
      next();
    } else {
      next({
        name: 'Login',
        query: {
          redirect: to.fullPath,
        },
      });
    }
  } else {
    next();
  }
});

export default router;
