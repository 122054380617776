<template>
  <div>
    <b-modal
      v-model="open"
      hide-footer
      hide-header
      no-close-on-backdrop
      size="lg"
      body-class="p-5"
      centered
    >
      <div class="text-center">
        <b-avatar size="4em" variant="primary">
          <span class="fa fa-hourglass fa-2x" />
        </b-avatar>
      </div>
      <div class="text-center p-4 text-muted">
        <span>Veuillez patienter</span>
        <br>
        <span>{{ text }}</span>
      </div>
      <div class="p-4">
        <b-progress
          :value="value"
          :max="100"
          :animated="!value"
          height="2em"
        >
          <b-progress-bar :value="value" :label="`${value}%`" />
        </b-progress>
      </div>
    </b-modal>
  </div>
</template>

<script>
function getRandomIntInclusive(min, max) {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export default {
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
    text: {
      type: String,
      required: false,
      default: () => 'Chargement des données en cours',
    },
  },
  data() {
    return {
      open: false,
      value: 0,
      interval: null,
    };
  },
  watch: {
    loading: {
      immediate: true,
      handler(val) {
        if (val) {
          this.start();
        } else {
          this.stop();
        }
      }
    }
  },
  methods: {
    show() {
      this.open = true;
    },
    close() {
      this.open = false;
    },
    start() {
      this.show();
      this.value = 0;
      this.interval = setInterval(() => {
        const increment = getRandomIntInclusive(3, 7);
        if (this.value + increment >= 99) {
          this.value = 99;
        } else {
          this.value += increment;
        }
      }, 1000);
    },
    stop() {
      if (this.interval) {
        clearInterval(this.interval);
      }
      this.value = 100;
      setTimeout(() => {
        this.close();
      }, 1000);
    },
  },
};
</script>
