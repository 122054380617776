<template>
  <div>
    <b-card-header header-tag="header" class="p-1" role="tab">
      <b-button
        v-b-toggle="`collapse-${index}`"
        class="text-left text-dark"
        block
        variant="gray-100"
      >
        <b-row>
          <b-col cols="7">
            <i class="fas fa-chevron-down" />
            {{ currentJob.name }}
          </b-col>
          <b-col cols="5">
            <b-row>
              <b-col v-if="periodType === 'all'" class="text-center">
                {{ formatCostType(totalValueJob.value1, 'budget') }}
              </b-col>
              <b-col v-if="periodType === 'all'" class="text-center">
                {{ formatCostType(totalValueJob.value2,'budget') }}
              </b-col>
              <b-col class="text-center">
                {{ formatCostType(totalValueJob.value3, 'budget') }}
              </b-col>
            </b-row>
          </b-col>
        </b-row>
        <!-- <i class="fas fa-chevron-down" />
        {{ currentJob.name }} -->
      </b-button>
    </b-card-header>
    <b-collapse :id="`collapse-${index}`" accordion="accordion-job" role="tabpanel">
      <b-card-body>
        <b-row v-for="child in currentJob.children" :key="child.id" class="align-items-center mb-2">
          <b-col cols="7">
            <span>{{ child.name }}</span>
          </b-col>
          <b-col>
            <b-form class="text-center">
              <b-form-row>
                <b-col v-if="periodType === 'all'">
                  <b-input
                    v-model="child.value1"
                    class="text-center"
                    type="number"
                  />
                </b-col>
                <b-col v-if="periodType === 'all'">
                  <b-input
                    v-model="child.value2"
                    class="text-center"
                    type="number"
                  />
                </b-col>
                <b-col>
                  <b-input
                    v-model="child.value3"
                    class="text-center"
                    type="number"
                  />
                </b-col>
              </b-form-row>
            </b-form>
          </b-col>
        </b-row>
      </b-card-body>
    </b-collapse>
  </div>
</template>
<script>
import { formatCostType } from '@/providers/helpers/functions';
export default {
  props: {
    index: {
      type: [Number, String],
      required: false,
      default: 0
    },
    job: {
      type: Object,
      required: false,
      default: () => {}
    },
    periodType: {
      type: String,
      required: false,
      default: 'all',
    },
  },
  data () {
    return {
      currentJob: {},
      formatCostType
    }
  },
  computed: {
    totalValueJob() {
      let value1 = 0
      let value2 = 0
      let value3 = 0
      for (const job of this.currentJob.children) {
        if (this.periodType === 'all') {
          value1 += Number(job.value1)
          value2 += Number(job.value2)
        }
        value3 += Number(job.value3)
      }
      return this.periodType === 'all' ? {
        value1,
        value2,
        value3
      } : {
        value3
      }
    }
  },
  created() {
    this.currentJob = { ...this.job }
  },
  mounted () {
  },
  methods: {
  },
  filters: {
    formatNumber(value) {
      return Number(value).toLocaleString();
    },
  },
}
</script>
<style lang="scss" scoped>

</style>
