import { getJobNameById } from '@/services/jobs'

export const findJobRatesFromRates = (id, rates) => {
  const rate = rates?.target?.ratesForAttribute?.[id]

  if (!rate) {
    return
  }

  const jobRate = {
    target: rate,
    yearGlobalRate: {}
  }
  Object.keys(rates.yearGlobalRate).forEach(k => {
    jobRate.yearGlobalRate[k] = JSON.parse(JSON.stringify(rate))
  })

  return jobRate
}

export const buildDefaultRateItem = (key, label, defaultValue) => {
  if (defaultValue) {
    return {
      key,
      label,
      ...defaultValue
    }
  } else {
    return {
      key,
      label,
      departureRate: 0,
      retirementRate: 0,
      minimumRetirementAge: 0,
      mobilityRate: 0,
      recruitingRate: 0,
      externalConsultantRate: 0
    }
  }
}

export const round = (nb) => {
  const percent = (nb * 100).toFixed(1);
  return `${percent} %`;
}

export const withYearSuffix = (nb) => {
  const { years, months } = parseYearDataTypeValue(nb);
  const suffix = years > 1 ? 'ans' : 'an';
  const m = months > 0 ? ` et ${months} mois` : '';
  return `${years} ${suffix} ${m}`.trim();
}

export const ageObjectToFloat = (ageObj) => {
  return parseAgeObjectToFloatValue(ageObj);
}

export const percentToFloat = (value) => {
  return value / 100;
}

export const parseYearDataTypeValue = (value) => {
  if (typeof value === 'object') {
    return value;
  }
  if (!value) {
    return { years: 0, months: 0 }
  }
  // split value:
  // integer is years
  // decimal is months
  const val = value.toFixed(2);
  let years = Math.floor(parseFloat(val));
  let months = val - Math.floor(val);
  months = Math.round(months * 12);
  if (months === 12) {
    months = 0;
    years += 1;
  }
  return { years, months };
}

export const parseAgeObjectToFloatValue = ({ years, months }) => {
  // cast months in a float value
  const decimal = parseInt(months) / 12;
  return Number(parseFloat(years) + decimal).toFixed(2);
}

export const castCustomRateToYearRateMap = (customRates) => {
  /**
   * "yearRateMap": {
   *         "2022": {
   *             "Job/ComBPCE_9658": {
   *                 "turnOverRate": 0.05,
   *                 "recruitingRate": 0.05,
   *                 "minimumRetirementAge": 62,
   *                 "retirementRate": 0.05,
   *                 "jobChangeRate": 0.05,
   *                 "unitChangeRate": 0.05,
   *                 "costCentreChangeRate": 0.05,
   *                 "siteChangeRate": 0.05
   *             }
   *         }
   *     }
   *
   *     customRates
   *     {
   *     "jobs": [
   *         "JobCategory/ComBPCE_5000",
   *         "JobCategory/ComBPCE_1500"
   *     ],
   *     "rates": {
   *         "target": {
   *             "turnOverRate": 0,
   *             "recruitingRate": 0,
   *             "departureRate": 0.001,
   *             "minimumRetirementAge": 62.7,
   *             "retirementRate": 0.008130081300813009,
   *             "mobilityRate": 0.17073170731707318
   *         },
   *         "yearGlobalRate": {
   *             "2022": {
   *                 "turnOverRate": 0,
   *                 "recruitingRate": 0,
   *                 "departureRate": 0.001,
   *                 "minimumRetirementAge": 62.7,
   *                 "retirementRate": 0.008130081300813009,
   *                 "mobilityRate": 0.17073170731707318
   *             },
   *             "2023": {
   *                 "turnOverRate": 0,
   *                 "recruitingRate": 0,
   *                 "departureRate": 0.001,
   *                 "minimumRetirementAge": 62.7,
   *                 "retirementRate": 0.008130081300813009,
   *                 "mobilityRate": 0.17073170731707318
   *             },
   *             "2024": {
   *                 "turnOverRate": 0,
   *                 "recruitingRate": 0,
   *                 "departureRate": 0.001,
   *                 "minimumRetirementAge": 62.7,
   *                 "retirementRate": 0.008130081300813009,
   *                 "mobilityRate": 0.17073170731707318
   *             }
   *         }
   *     }
   * }
   *
   */

  const keys = Object.keys(customRates.rates.yearGlobalRate)
  let yearRateMap = {}
  keys.forEach(key => {
    yearRateMap[key] = {}
    customRates.jobs.forEach(job => {
      yearRateMap[key][job] = customRates.rates.yearGlobalRate[key]
    })
  })
  return yearRateMap
}

export const buildRatesCustomsFromYearRateMap = (yearGlobalRate, yearRateMap, jobs) => {
  // build customs
  // eslint-disable-next-line max-len,vue/max-len
  const jobsYearRateMap = buildCustomRatesFromMetadataYearRateMap(yearGlobalRate, yearRateMap, jobs)
  let customs = []

  Object.keys(jobsYearRateMap.rates).forEach(job => {
    customs.push({
      title: getJobNameById(job, jobs),
      shortTitle: getJobNameById(job, jobs),
      item: {
        globalRates: computeAverageRatesFromYearMap(jobsYearRateMap.rates[job])
      },
      children: jobsYearRateMap.rates[job]
    })
  })

  return customs
}

export const castObjectToItemRow = (row, jobs) => {
  /**
   * input:
   * {
   *     "jobs": [
   *         "JobCategory/ComBPCE_5000"
   *     ],
   *     "rates": {
   *         "target": {
   *             "turnOverRate": 0,
   *             "recruitingRate": 0,
   *             "departureRate": 0,
   *             "minimumRetirementAge": 62.7,
   *             "retirementRate": 0.008130081300813009,
   *             "mobilityRate": 0.17300000000000001
   *         },
   *         "yearGlobalRate": {
   *             "2022": {
   *                 "turnOverRate": 0,
   *                 "recruitingRate": 0,
   *                 "departureRate": 0,
   *                 "minimumRetirementAge": 62.7,
   *                 "retirementRate": 0.008130081300813009,
   *                 "mobilityRate": 0.17300000000000001
   *             },
   *             "2023": {
   *                 "turnOverRate": 0,
   *                 "recruitingRate": 0,
   *                 "departureRate": 0,
   *                 "minimumRetirementAge": 62.7,
   *                 "retirementRate": 0.008130081300813009,
   *                 "mobilityRate": 0.17300000000000001
   *             },
   *             "2024": {
   *                 "turnOverRate": 0,
   *                 "recruitingRate": 0,
   *                 "departureRate": 0,
   *                 "minimumRetirementAge": 62.7,
   *                 "retirementRate": 0.008130081300813009,
   *                 "mobilityRate": 0.17300000000000001
   *             }
   *         }
   *     }
   * }
   *
   * must return:
   * {
   * globalRates: {},
   * ratesForAttribute: {
   *   Job1: {}, (...)
   * }
   * }
   */
  if (!row.rates.yearGlobalRate) {
    throw Error('no row.rates.yearGlobalRate')
  }

  const children = {}
  Object.keys(row.rates.yearGlobalRate).forEach(year => {
    children[year] = {
      globalRates: row.rates.yearGlobalRate[year]
    }
  })
  return {
    jobs: row.jobs,
    title: row.jobs.map(j => getJobNameById(j, jobs)).join(', '),
    shortTitle: row.jobs?.length > 1 ? 'Sélection multiple' : getJobNameById(row.jobs[0], jobs),
    item: {
      globalRates: row.rates.target,
      ratesForAttribute: row.rates.yearGlobalRate
    },
    children
  }
}

export const resetCustomRates = (customRates, allRates) => {
  const rates = []
  customRates.forEach(rate => {
    // get values for all jobs
    const jobsRates = []
    rate.jobs.forEach(job => {
      jobsRates.push(findJobRatesFromRates(job._id, allRates))
    })

    // get average
    const averageRates = computeAverageRatesFromManyRates(jobsRates)

    // build rates object
    rates.push({
      jobs: rate.jobs,
      rates: averageRates
    })
  })
  return rates
}

export const computeAverageRatesFromYearMap = (map) => {
  const average = {}
  Object.keys(map).forEach(year => {
    Object.keys(map[year].globalRates).forEach(field => {
      if (!average[field]) {
        average[field] = 0
      }
      average[field] += map[year].globalRates[field]
    })
  })

  Object.keys(average).forEach(key => {
    average[key] = average[key] / Object.keys(map).length
  })

  return average
}

export const computeAverageRatesFromManyRates = (jobsRates) => {
  const average = {
    target: {},
    yearGlobalRate: {}
  }

  // sum all entries
  jobsRates.forEach(jobRate => {

    Object.keys(jobRate.target).forEach(key => {
      if (!average.target[key]) {
        average.target[key] = 0
      }
      average.target[key] += jobRate.target[key]
    })

    Object.keys(jobRate.yearGlobalRate).forEach(year => {
      if (!average.yearGlobalRate[year]) {
        average.yearGlobalRate[year] = {}
      }
      Object.keys(jobRate.yearGlobalRate[year]).forEach(key => {
        if (!average.yearGlobalRate[year][key]) {
          average.yearGlobalRate[year][key] = 0
        }
        average.yearGlobalRate[year][key] += jobRate.yearGlobalRate[year][key]
      })
    })

  })

  // make the average by dividing
  Object.keys(average.target).forEach(key => {
    average.target[key] = average.target[key] / jobsRates.length
  })
  Object.keys(average.yearGlobalRate).forEach(year => {
    Object.keys(average.yearGlobalRate[year]).forEach(key => {
      average.yearGlobalRate[year][key] = average.yearGlobalRate[year][key] / jobsRates.length
    })
  })

  return average
}

export const removeJobFromYearRateMap = (map, jobs) => {
  let isEmpty = true
  Object.keys(map).forEach(year => {
    jobs.forEach(job => {
      if (map?.[year]?.[job]) {
        delete map[year][job]
      }
    })
    if (Object.keys(map[year]).length > 0) {
      isEmpty = false
    }
  })
  if (isEmpty) {
    map = {}
  }
  return map
}

export const buildCustomRatesFromMetadataYearRateMap = (yearGlobalRate, yearRateMap) => {

  let rates = {}
  let all = []
  let map = {}

  if (!yearRateMap) {
    return false
  }

  Object
    .keys(yearRateMap)
    .forEach(year => {
      Object.keys(yearRateMap[year]).forEach(job => {
        rates[job] = rates[job] || {}
        rates[job][year] = { globalRates: yearRateMap[year][job] }
      })
    })
    // .map(key => buildDefaultRateItem(key, key, customRates))

  // keys.unshift(buildDefaultRateItem('target', 'Sur 3 ans', yearGlobalRate))

  // get average
  // const averageRates = computeAverageRatesFromManyRates(customJobs)

  return {
    jobs: Object.keys(rates),
    rates
  }
}
