<template>
  <div
    v-if="competence && competenceScore"
    class="align-items-center my-2"
    :class="`px-${maxDepth + 1 - competence.depth}`"
  >
    <div
      v-b-toggle="`collapse-${competence._id}${index}`"
      class="row"
    >
      <div class="col-4">
        <div class="d-flex">
          <span
            v-if="competence.children.length"
            :style="{ opacity: competence.children.length ? 1 : 0 }"
          >
            <i class="fas fa-caret-right mt-1" />
            <i class="fas fa-caret-down mt-1" />
          </span>

          <div class="ml-2">
            <b>{{ competence.names[0].label }}</b>
            <div class="d-flex">
              <div>
                <i class="fas fa-star text-primary" />
                <span class="text-muted">
                  {{ (competenceScore.current.averageScore / 25).toFixed(2) }}
                </span>
                <span class="mx-1">></span>
                <span>{{ (competenceScore[scenarioKey].averageScore / 25).toFixed(2) }}</span>
              </div>
              <div class="ml-3">
                <i class="fas fa-user text-primary" />
                <span class="text-muted">
                  {{ competenceScore.current.employeeCount }}
                </span>
                <span class="mx-1">></span>
                <span>{{ competenceScore[scenarioKey].employeeCount }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-2 text-center">
        <span
          v-if="competenceScore && competenceScore[scenarioKey].evolution === -1"
          class="text-danger"
        >
          <i class="fas fa-arrow-square-down fa-2x" />
        </span>
        <span
          v-if="competenceScore && competenceScore[scenarioKey].evolution === 0"
          class="text-warning"
        >
          <i class="fas fa-arrow-square-right fa-2x" />
        </span>
        <span
          v-if="competenceScore && competenceScore[scenarioKey].evolution === 1"
          class="text-success"
        >
          <i class="fas fa-arrow-square-up fa-2x" />
        </span>
      </div>
      <div class="col-3">
        <ProgressTooltip
          :id="`${competence._id}-current`"
          :scores="competenceScore.current.scoreRepartition"
          :last="!competence.children || !competence.children.length"
        />
      </div>
      <div class="col-3">
        <ProgressTooltip
          :id="`${competence._id}-${scenarioKey}`"
          :scores="competenceScore[scenarioKey].scoreRepartition"
          :last="!competence.children || !competence.children.length"
        />
      </div>
    </div>
  </div>
</template>
<script>
import ProgressTooltip from '@/components/all/ProgressTooltip.vue';

export default {
  name: 'CompetenceTreeHead',
  components: {
    ProgressTooltip,
  },
  props: {
    competence: {
      type: Object,
      default() {
        return null;
      },
    },
    competenceScore: {
      type: Object,
      default() {
        return null;
      },
    },
    currentYearCompetenceScore: {
      type: Object,
      default() {
        return null;
      },
    },
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    maxDepth: {
      type: Number,
      required: false,
      default: () => 0,
    },
  },
};
</script>

<style scoped>
.not-collapsed .fa-caret-right {
  display: none;
}
.collapsed .fa-caret-down {
  display: none;
}
</style>
