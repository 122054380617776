<template>
  <b-row
    v-if="localItem && localItem.globalRates"
    :key="key"
    class="text-center font-12 font-weight-bold align-items-center justify-content-center"
    :class="`py-1 px-${depth}`"
  >
    <b-col cols="3" class="d-flex justify-content-around align-items-center">
      <div class="w-100">
        <b-badge
          variant="primary-light"
          pill
          block
          class="mx-2 font-13 py-2 d-block text-wrap border"
          @click="$emit('collapse')"
        >
          <span>{{ title }}</span>
          <i v-if="isTarget || removable" class="fal fa-search ml-2" />
        </b-badge>
      </div>
      <b-button
        v-if="!isCurrent && editable"
        variant="link"
        class="text-muted"
        @click="toggleEdit"
      >
        <i class="fa fa-edit" />
      </b-button>
      <div v-else style="width: 44px">
        &nbsp;
      </div>
    </b-col>
    <b-col cols="4">
      <div class="d-flex text-secondary">
        <div class="detail__item same-width">
          <RatesCell
            id="departureRate"
            :value="round(localItem.globalRates.departureRate)"
            :editing="editing"
            :setter="percentToFloat"
            @update="onUpdate"
          />
        </div>
        <div class="detail__item same-width">
          <RatesCell
            id="retirementRate"
            :value="round(localItem.globalRates.retirementRate)"
            :editing="editing"
            :setter="percentToFloat"
            @update="onUpdate"
          />
        </div>
        <div class="detail__item same-width">
          <RatesCell
            id="minimumRetirementAge"
            :value="parseYearDataTypeValue(localItem.globalRates.minimumRetirementAge)"
            :editing="editing"
            :formatter="withYearSuffix"
            :setter="ageObjectToFloat"
            :age="true"
            @update="onUpdate"
          />
        </div>
      </div>
    </b-col>
    <b-col cols="2">
      <div class="d-flex text-dark">
        <div class="detail__item">
          <RatesCell
            id="mobilityRate"
            :value="round(localItem.globalRates.mobilityRate)"
            :editing="editing"
            :setter="percentToFloat"
            @update="onUpdate"
          />
        </div>
      </div>
    </b-col>
    <!-- Added col="2" to fit the space bewteen columns -->
    <b-col cols="2">
      <div class="d-flex text-info">
        <div class="detail__item">
          <RatesCell
            id="recruitingRate"
            :value="round(localItem.globalRates.recruitingRate)"
            :editing="editing"
            :setter="percentToFloat"
            @update="onUpdate"
          />
        </div>
        <!-- Added new column into Arrivees called "Taux d'externes"  -->
        <div class="detail__item">
          <RatesCell
            id="externalConsultantRate"
            :value="round(localItem.globalRates.externalConsultantRate)"
            :editing="editing"
            :setter="percentToFloat"
            @update="onUpdate"
          />
        </div>
      </div>
    </b-col>
    <b-col cols="1">
      <b-button
        v-if="restorable && editable"
        variant="link"
        class="text-muted"
        @click="restore"
      >
        <i class="fa fa-redo" />
      </b-button>
      <b-button
        v-if="removable && editable"
        variant="link"
        class="text-muted"
        @click.stop="remove"
      >
        <i class="fa fa-trash" />
      </b-button>
    </b-col>
  </b-row>
</template>

<script>
import RatesCell from '@/components/scenario/rates/RatesCell.vue';

export default {
  components: {
    RatesCell,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    item: {
      type: Object,
      required: true,
    },
    depth: {
      type: Number,
      required: false,
      default: () => 0,
    },
    children: {
      type: Object,
      required: false,
      default: () => {},
    },
    removable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    restorable: {
      type: Boolean,
      required: false,
      default: () => false
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => true
    }
  },
  data() {
    return {
      editing: false,
      localItem: null,
      key: 0
    };
  },
  computed: {
    isCurrent() {
      return this.title.toLowerCase() === 'existant';
    },
    isTarget() {
      return this.title.toLowerCase() === 'scénario cible';
    },
  },
  watch: {
    item: {
      handler(curr) {
        if (curr) {
          this.localItem = JSON.parse(JSON.stringify(curr))
        }
      },
      immediate: true,
      deep: true
    },
  },
  methods: {
    remove () {
      this.$emit('remove')
    },
    restore() {
      this.$emit('restore')
    },
    onUpdate({ key, value }) {
      this.localItem.globalRates[key] = value;
      this.$emit('update', this.localItem);
    },
    round(nb) {
      const percent = (nb * 100).toFixed(1);
      return `${percent} %`;
    },
    withYearSuffix(nb) {
      const { years, months } = this.parseYearDataTypeValue(nb);
      const suffix = years > 1 ? 'ans' : 'an';
      const m = months > 0 ? ` et ${months} mois` : '';
      return `${years} ${suffix} ${m}`.trim();
    },
    ageObjectToFloat(ageObj) {
      return this.parseAgeObjectToFloatValue(ageObj);
    },
    percentToFloat(value) {
      return value / 100;
    },
    toggleEdit() {
      this.editing = !this.editing;
      if (this.editing) {
        this.$emit('close');
        this.key++
      }
    },
  },
};
</script>
