<template>
  <b-button-group
    v-model="selectedCheck"
    class="row sgb p-1"
    name="filtersAnalysis"
  >
    <span
      v-for="button in buttonsFiltered"
      :key="button.key"
      class="d-inline-block"
    >
      <b-button
        v-b-popover.hover.top="`${button.key == 'budget' ?
          '' : button.popover }`"
        name="radio-sub-component"
        variant="outline-primary"
        class="px-4 sgb__button"
        :value="button.key"
        :class="{ 'active': selectedCheck === button.key }"
        :disabled="buttonsFiltered.length === 1"
        @click="changeType(button.key)"
      >
        <b>{{ button.name }}</b>
      </b-button>
    </span>
  </b-button-group>
</template>

<script>
export default {
  name: 'ScenarioGroupButtons',
  props: {
    buttons:{
      type: Array,
      required: true,
      default: ()=> []
    },
    defaultValue:{
      type: String,
      default: ()=> ''
    },
  },
  data(){
    return{
      selectedCheck: '',
    }
  },
  computed: {
    buttonsFiltered() {
      return this.buttons.filter(button => this.configuration.availableDataTypes[button.key])
    }
  },
  created(){
    this.selectedCheck = this.defaultValue !== '' ? this.defaultValue : this.buttonsFiltered[0].key;
  },
  methods:{
    async changeType(type){
      this.selectedCheck = type;
      this.$emit('send-type', this.selectedCheck)
    },
  },
}
</script>

<style lang="scss" scoped>

  .sgb {
    background-color: #f9f9f9;
    border-radius: 10px;
    &__button {
      border-radius: 15px;
      border: none;
      &:focus{
        outline: none;
        box-shadow: none !important;
        border-color: transparent !important
      }
    }
  }

</style>
