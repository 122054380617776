<template>
  <div
    class="position-sticky fixed-top bg-white list-group p-2 pb-4 detail-row"
    style="z-index: 900"
  >
    <div class="row">
      <div :class="[showExternalCols ? 'col-1' : 'col-2']">
        <span class="sr-only">SELECTOR</span>
      </div>
      <div class="col-1" />
      <div :class="departuresCol">
        <span
          v-if="!activeDepartureDetails"
          class="plus-detail__button"
          @click="showDetailDeparture"
        >
          +
        </span>
        <span v-else class="plus-detail__button" @click="hideDetailDeparture">-</span>
        <span class="badge badge-secondary badge-pill py-2 d-block text-white"> DEPARTS </span>
      </div>
      <div :class="mobilitiesCol">
        <span
          v-if="!activeMobilityDetails"
          class="plus-detail__button"
          @click="showDetailMobility"
        >
          +
        </span>
        <span v-else class="plus-detail__button" @click="hideDetailMobility">-</span>
        <span class="badge badge-dark badge-pill py-2 d-block"> MOBILITES </span>
      </div>
      <div :class="entriesCol">
        <span v-if="!activeEntryDetails" class="plus-detail__button" @click="showDetailEntry">
          +
        </span>
        <span v-else class="plus-detail__button" @click="hideDetailEntry">-</span>
        <span class="badge badge-info badge-pill py-2 d-block"> ARRIVEES </span>
      </div>
      <div :class="[showExternalCols ? 'col-1' : 'col-2']"/>
      <!-- Added the new Externes column  -->
      <div v-show="showExternalCols" :class="externalCol">
        <span v-if="!activeExternalDetails" class="plus-detail__button" @click="showDetailExternal">
          +
        </span>
        <span v-else class="plus-detail__button" @click="hideDetailExternal">-</span>
        <span class="badge badge-gray badge-pill py-2 d-block"> EXTERNES </span>
      </div>
      <div class="col-2" />
    </div>

    <div class="row text-center mt-3 font-12 font-weight-bold">
      <div :class="[showExternalCols ? 'col-1' : 'col-2']">
        <template v-if="!hiddenExternalSwitcher">
            <b-popover
            :target="`popover-target-${customJob ? 'hyp-table-cjob': 'hyp-table'}`"
            triggers="hover"
            placement="top"
            variant="info"
          >
            Afficher/Cacher les externes
          </b-popover>
          <div :id="`popover-target-${customJob ? 'hyp-table-cjob': 'hyp-table'}`">
            <b-form-checkbox
              v-model="showExternalCols"
              name="check-button"
              switch
              class="hypothesis-table-external__checkbox boxfont-weight-bolder"
            >
              Externes
            </b-form-checkbox>
          </div>
        </template>
      </div>
      <div class="col-1">
        <div class="text-dark">
          <!-- Text changed from Effectif actuel to Effectif interne actuel -->
          Effectif interne actuel
        </div>
      </div>
      <div :class="departuresCol">
        <div class="d-flex text-secondary">
          <div class="detail__item plus-detail">
            <span>Départs</span>
            <!-- <span
              v-if="!activeDepartureDetails"
              class="plus-detail__button"
              @click="showDetailDeparture"
            >
              +
            </span>
            <span v-else class="plus-detail__button" @click="hideDetailDeparture">-</span> -->
          </div>
          <template v-if="activeDepartureDetails">
            <div class="detail__item">
              Retraites
            </div>
            <div class="detail__item">
              Autres
            </div>
          </template>
        </div>
      </div>
      <div :class="mobilitiesCol">
        <div class="d-flex text-dark">
          <div class="detail__item plus-detail">
            <span>Net de mobilités</span>
            <!-- <span
              v-if="!activeMobilityDetails"
              class="plus-detail__button"
              @click="showDetailMobility"
            >
              +
            </span>
            <span v-else class="plus-detail__button" @click="hideDetailMobility">-</span> -->
          </div>
          <template v-if="activeMobilityDetails">
            <div class="detail__item">
              Départs mobilité
            </div>
            <div class="detail__item">
              Arrivées mobilité
            </div>
          </template>
        </div>
      </div>
      <div :class="entriesCol">
        <div class="d-flex text-dark">
          <div class="detail__item plus-detail">
            <span>Arrivées</span>
            <!-- <span v-if="!activeEntryDetails"
              class="plus-detail__button" @click="showDetailEntry">
              +
            </span>
            <span v-else class="plus-detail__button" @click="hideDetailEntry">-</span> -->
          </div>
          <template v-if="activeEntryDetails">
            <div class="detail__item">
              Recrutements
            </div>
            <div class="detail__item">
              Autres
            </div>
          </template>
        </div>
      </div>

      <div :class="[showExternalCols ? 'col-1' : 'col-2']">
        <div class="row">
          <div class="col-6 text-success p-0">
            <!-- Text changed from Effectif projeté to Effectif interne projeté -->
            Effectif interne projeté
          </div>
          <div class="col-6 text-dark p-0">
            Variation
          </div>
        </div>
      </div>

      <!-- External new column  -->
      <div v-show="showExternalCols" :class="externalCol">
        <div class="d-flex text-dark">
          <div class="detail__item plus-detail">
            <span>Effectif externe cible</span>
          <!-- <span v-if="!activeExternalDetails"
            class="plus-detail__button" @click="showDetailExternal">
              +
            </span>
            <span v-else class="plus-detail__button" @click="hideDetailExternal">-</span> -->
          </div>
          <template v-if="activeExternalDetails">
            <div class="detail__item">
              Effectif externe actuel
            </div>
            <div class="detail__item">
              Variation
            </div>
          </template>
        </div>
      </div>

      <div class="col-1">
        <div class="row">
          <!-- New column text called "Effectif projeté" -->
          <div v-show="showExternalCols" class="col-5 text-success p-0">
            Effectif projeté
          </div>
          <div class="col-7 text-dark p-0">
            Tendance
          </div>
          <div v-if="customJob" class="col-1 text-dark" />
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import HypothesisColSize from '@/components/analysis-tabs/hypothesis/colSize';

export default {
  name: 'HypothesisTableHeader',
  mixins: [HypothesisColSize],
  props: {
    customJob: {
      type: Boolean,
      required: false,
      default: () => false
    },
    hiddenExternalSwitcher:{
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      activeDepartureDetails: true,
      activeEntryDetails: true,
      activeMobilityDetails: true,
      // Added "activeExternalDetail" for the "Externes column"
      activeExternalDetails: true,
      showExternalCols: false
    };
  },
  watch: {
    showExternalCols (val) {
      this.$emit('showExternalCols', val);
    }
  },
  methods: {
    toggleExternalCol(toggle){
      if(this.hiddenExternalSwitcher){
        this.showExternalCols = toggle
      }
    },
    showDetailDeparture() {
      this.activeDepartureDetails = true;
      this.$emit('showDetailDeparture');
    },
    hideDetailDeparture() {
      this.activeDepartureDetails = false;
      this.$emit('hideDetailDeparture');
    },
    showDetailEntry() {
      this.activeEntryDetails = true;
      this.$emit('showDetailEntry');
    },
    hideDetailEntry() {
      this.activeEntryDetails = false;
      this.$emit('hideDetailEntry');
    },
    showDetailMobility() {
      this.activeMobilityDetails = true;
      this.$emit('showDetailMobility');
    },
    hideDetailMobility() {
      this.activeMobilityDetails = false;
      this.$emit('hideDetailMobility');
    },

    // Added new method called "External" for the "Externes column"
    showDetailExternal() {
      this.activeExternalDetails = true;
      this.$emit('showDetailExternal');
    },
    hideDetailExternal() {
      this.activeExternalDetails = false;
      this.$emit('hideDetailExternal');
    },
  },
};
</script>
<style lang="scss" scoped>
.hypothesis-table-external__checkbox  {
  ::v-deep label {
    line-height: 1.9 !important;
  }
}
</style>
