<template>
  <b-container v-if="scenarioMetadata && scenarioResults">
    <div v-if="step && years" class="mb-4" style="overflow: auto">
      <Stepper
        :step="step"
        :steps="steps"
        :years="years"
        :period="scenarioMetadata.period"
      />
    </div>
    <div v-if="step === steps.FILTER">
      <StepOne readonly :filters="scenarioMetadata.filter" @updateStep="updateStep($event)" />
    </div>
    <div v-if="step === steps.BASE_SCENARIO">
      <StepTwo
        readonly
        :type="scenarioMetadata.scenario"
        @updateStep="updateStep($event)"
        @updateType="updateType"
      />
    </div>
    <div v-if="step === steps.STRATEGY">
      <StepStrategy
        :filters="payload.filter"
        :type="payload.scenario"
        :years-map="years"
        @previous="previous"
      />
    </div>
    <div v-if="step === steps.PERIOD">
      <StepSeven
        readonly
        :type="scenarioMetadata.period"
        @previous="previous"
        @updatePeriod="updatePeriod"
      />
    </div>
    <div v-if="step === steps.HYPOTHESIS_1">
      <StepFour
        readonly
        :scenario="scenarioMetadata.scenario"
        :year="projectionYear"
        :rows="scenarioTableForProjectionYear"
        :filters="scenarioMetadata.filter"
        @previous="previous"
        @next="next"
      />
    </div>
    <div v-if="step === steps.HYPOTHESIS_2">
      <StepFour
        readonly
        :scenario="scenarioMetadata.scenario"
        :year="projectionYear"
        :rows="scenarioTableForProjectionYear"
        :filters="scenarioMetadata.filter"
        @previous="previous"
        @next="next"
      />
    </div>
    <div v-if="step === steps.HYPOTHESIS_3">
      <StepFour
        readonly
        :scenario="scenarioMetadata.scenario"
        :year="projectionYear"
        :rows="scenarioTableForProjectionYear"
        :filters="scenarioMetadata.filter"
        @previous="previous"
        @next="next"
      />
    </div>
    <div v-if="step === steps.VALIDATION">
      <StepSix
        readonly
        :scenario="scenarioMetadata"
        @previous="previous"
        @save="save"
      />
    </div>
  </b-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import Stepper from '@/components/all/ScenarioCreationStepperNavigation.vue';
import StepOne from '@/components/steps/StepOne.vue';
import StepTwo from '@/components/steps/StepTwo.vue';
import StepFour from '@/components/steps/StepFour.vue';
import StepSix from '@/components/steps/StepSix.vue';
import StepSeven from '@/components/steps/StepSeven.vue';
import StepStrategy from '@/components/steps/StepStrategy'

export default {
  components: {
    Stepper,
    StepOne,
    StepTwo,
    StepFour,
    StepSix,
    StepSeven,
    StepStrategy
  },
  data() {
    return {
      loading: false,
      step: 1,
      years: [],
      payload: {
        filter: {},
      },
      projectionYear: null,
      edits: [],
      scenarioMetadata: null,
      scenarioResults: null,
      steps: {
        FILTER: 1,
        BASE_SCENARIO: 2,
        STRATEGY: 3,
        PERIOD: 4,
        HYPOTHESIS_1: 5,
        HYPOTHESIS_2: 6,
        HYPOTHESIS_3: 7,
        VALIDATION: 8,
      },
    };
  },
  computed: {
    scenarioTableForProjectionYear() {
      // eslint-disable-next-line max-len
      // eslint-disable-next-line max-len
      return (
        this.scenarioResults?.yearPredictedStatsMap?.[this.projectionYear]?.tableData?.[
          this.scenarioMetadata.filterAttribute
        ]?.rowMap || {}
      );
    },
    ...mapState('analysis', {
      yearPredictedStatsMap: (state) => state.allData.yearPredictedStatsMap,
    }),
  },
  watch: {
    async step() {
      switch (this.step) {
        case 4:
          // eslint-disable-next-line prefer-destructuring
          this.projectionYear = this.years[0];
          break;
        case 5:
          // eslint-disable-next-line prefer-destructuring
          this.projectionYear = this.years[1];
          break;
        case 6:
          // eslint-disable-next-line prefer-destructuring
          this.projectionYear = this.years[2];
          break;
        default:
      }
    },
  },
  async mounted() {
    await this.loadData();
  },
  methods: {
    async loadData() {
      if (!this.$route.params.id) {
        alert('no id');
        return;
      }
      await this.fetchScenario(this.$route.params.id);
      this.years = await this.getPredictionYears();
    },
    updateStep(i) {
      this.step = i;
    },
    updateType() {
      this.next();
    },
    previous() {
      if (this.scenarioMetadata.period === 'last' && this.step === 6) {
        this.step = 3;
      } else {
        this.step -= 1;
      }
    },
    updatePeriod() {
      this.next();
    },
    next() {
      if (this.scenarioMetadata.period === 'last' && this.step === 3) {
        this.step = 6;
      } else {
        this.step += 1;
      }
    },
    save() {
      this.$router.push('/collab');
    },
    async fetchScenario(id) {
      try {
        this.setLoaderStatus(true);
        // we fetch current scenario filters and metadata
        this.scenarioMetadata = await this.fetchScenarioById({ id });
        // we fetch current scenario results
        this.scenarioResults = await this.loadScenarioData({ id });
      } catch (err) {
        console.log('err', err);
      } finally {
        this.setLoaderStatus(false);
      }
    },
    ...mapActions({
      fetchScenarioById: 'scenarios/fetchScenarioById',
      loadScenarioData: 'analysis/loadScenarioData',
      getPredictionYears: 'scenarios/getPredictionYears',
      setLoaderStatus: 'app/setLoaderStatus',
    }),
  },
};
</script>
