export const catGenderDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: null,
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});

export const catContractDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: null,
      values: [
        {
          label: 'CDI',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'CDD',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'DEFAULT',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: null,
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});

export const catAgeDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: '+ 50',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
    {
      category: '40-49',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
    {
      category: '30-39',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
    {
      category: '- 30',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});

export const catManagerDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: null,
      values: [
        {
          label: 'Manager',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Non Manager',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});

export const catSeniorityDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: null,
      values: [
        {
          label: '1 an et moins',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: '1 à 2 ans',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: '2 à 3 ans',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: '3 ans et plus',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: null,
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});

export const catSeniorityGenderDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: '1 an et moins',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
    {
      category: '1 à 2 ans',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
    {
      category: '2 à 3 ans',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
    {
      category: '3 ans et plus',
      values: [
        {
          label: 'Homme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
        {
          label: 'Femme',
          current: 0,
          predicted: 0,
          target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});

//New catInternalExternalDefault
export const catInternalExternalDefault = (entityId) => ({
  entity: entityId,
  values: [
    {
      category: null,
      values: [
        {
          label: 'Externes',
          current: 0,
          predicted: 0,
          // target: 0,
          optimal: 0,
        },
        {
          label: 'Internes',
          current: 0,
          predicted: 0,
          // target: 0,
          optimal: 0,
        },
      ],
      total: {
        label: 'Total',
        current: 0,
        predicted: 0,
        target: 0,
        optimal: 0,
      },
    },
  ],
  isLeaf: true,
});
