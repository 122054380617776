import ConfigService from './config';

class UserService extends ConfigService {
  async updatePassword(payload) {
    // eslint-disable-next-line no-return-await
    return await super.put(`${this.apiUrl}/user/changePassword`, payload);
  }

  async fetchUsers() {
    // eslint-disable-next-line no-return-await
    return await super.get(`${this.apiUrl}/user/list`);
  }
}

export default new UserService();
