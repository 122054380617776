// eslint-disable-next-line
export const lightSkillStruct = (item) => {
  delete item.members;
  delete item.parents;
  delete item.v;
  delete item.createdAt;
  delete item.relatedSkills;
  delete item.status;
  delete item.tags;
  delete item.updatedAt;
  delete item.internalId;
  delete item.code;
  // delete item.org;
  delete item.test;
  return item;
};

// eslint-disable-next-line
export const RecursiveSkillGraph = async (skillCategory, skillCategoriesMap, skillsObject) => {
  const children = [];

  if (!skillCategory.members) {
    // eslint-disable-next-line
    for (const [key, category] of skillCategoriesMap) {
      if (category.parents) {
        // eslint-disable-next-line
        const matchChild = category.parents.find((item) => item['#'] === `${skillCategory._id}`);
        if (matchChild) {
          // eslint-disable-next-line
          const response = await RecursiveSkillGraph(category, skillCategoriesMap, skillsObject);
          if (response.children) {
            category.children = response.children;
          }
          category.categoryDepth = response.categoryDepth + 1;
          children.push(lightSkillStruct(category));
        }
      }
    }
  } else {
    // eslint-disable-next-line
    for (let skill of skillCategory.members) {
      skill = skillsObject[skill['#']];
      if (skill) {
        skill.categoryDepth = 1;
        children.push(lightSkillStruct(skill));
      }
    }
  }

  return {
    children,
    categoryDepth: children[0].categoryDepth,
  };
};
