<template>
  <div>
    <div class="p-4">
      <VerticalStep
        title="Donnez un nom au scénario créé"
        :step="1"
      >
        <b-form-input
          v-model="name"
          placeholder="Nom du scénario"
        />
      </VerticalStep>
      <VerticalStep
        title="Cliquez sur 'Créer' pour créer un scénario compilant les scénarios ci-dessous"
        :step="2"
      >
        <b-table class="bg-light small" :fields="fields" :items="scenarios">
          <template #cell(comment)="{ item }">
            <CommentTooltip :id="`merging-${item.ID}`" :comment="item.comment" />
          </template>
        </b-table>
      </VerticalStep>
    </div>
    <hr>
    <div class="d-flex align-items-center justify-content-end m-4">
      <b-button variant="link" class="text-uppercase" @click="$emit('cancel')">
        Abandonner
      </b-button>
      <b-button
        :disabled="scenarios.length <= 1"
        variant="secondary"
        class="text-uppercase"
        @click="$emit('success', name)"
      >
        Créer
      </b-button>
    </div>
  </div>
</template>

<script>
import VerticalStep from '@/components/ui/VerticalStep'
import CommentTooltip from '@/components/ui/CommentTooltip'
import { formatDate } from '@/services/helpers'

export default {
  components: {
    VerticalStep,
    CommentTooltip
  },
  props: {
    scenarios: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      name: '',
      fields: [
        { label: '', key: 'action' },
        { label: 'Nom', key: 'label', tdClass: 'text-secondary' },
        { label: 'Date de création', key: 'createdAt', formatter: (str) => formatDate(str) },
        { label: 'Date de modification', key: 'updatedAt', formatter: (str) => formatDate(str) },
        { label: '', key: 'comment' }
      ]
    }
  }
}
</script>