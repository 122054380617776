<template>
  <div class="mb-5">
    <div class="mb-5">
      <h6 class="font-weight-normal mb-3">
        Choisissez le type d’évolution
      </h6>
      <div class="row align-items-stretch">
        <div v-for="t in types" :key="t.id" class="col">
          <CardValidate :data="t" :selected="selectedType" @selectType="select" />
        </div>
      </div>
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('updateStep', 1)"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          v-if="!readonly"
          variant="link"
          class="text-uppercase"
          :disabled="!Object.keys(selectedType).length"
          @click="$emit('saveDraft', { scenario: selectedType.id, activeStep: 'BASE_SCENARIO' })"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button
          class="btn btn-secondary text-uppercase"
          type="button"
          :disabled="!Object.keys(selectedType).length"
          @click="next"
        >
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import CardValidate from '@/components/all/CardValidate.vue';

export default {
  components: {
    CardValidate,
  },
  props: {
    type: {
      type: String,
      required: false,
      default: () => '',
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      selectedType: {},
      types: [
        {
          title: 'Évolution Structurelle',
          desciption:
            `Évolution des effectifs actuels identiques à
             celle constatée sur les 3 dernières années`,
          color: '#4783FC',
          id: 'structural',
        },
        {
          title: 'Évolution Conjoncturelle',
          desciption:
            'Évolution des effectifs actuels identique à celle constatée sur les 12 derniers mois',
          color: '#34D1D1',
          id: 'conjunctural',
        },
        {
          title: 'Évolution Conjoncturelle avec gel des recrutements',
          desciption: 'Évolution des effectifs actuels avec gel des recrutements',
          color: '#FFC738',
          id: 'noRecruiting',
        },
      ],
    };
  },
  mounted() {
    if (this.type) {
      this.selectedType = this.types.find((t) => t.id === this.type);
    }
  },
  methods: {
    select(type) {
      if (!this.readonly) {
        this.selectedType = type;
      }
    },
    next() {
      this.$emit('updateType', this.selectedType);
    },
  },
};
</script>
