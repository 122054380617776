<template>
  <div>
    <div class="position-relative">
      Ce tableau vous permet d'<b>analyser l’évolution des compétences</b>.
      <span id="table" class="ml-1" style="position: relative; top: -5px">
        <i class="fal fa-question-circle" />
      </span>
      <b-popover target="table" triggers="hover" custom-class="w-400">
        <ul>
          <li>
            Sélectionner les compétences à comparer grâce aux moteurs de recherche situés en haut
            des colonnes.
          </li>
          <li>
            Sélectionner les métiers ou entités à comparer grâce au moteur de recherche situé sur la
            première ligne. La date de projection est celle sélectionnée plus haut.
          </li>
          <li>Le chiffre à côté de l’étoile indique le niveau moyen sur la compétence.</li>
          <li>Le chiffre à côté du bonhomme indique le nombre de personnes ayant la compétence.</li>
        </ul>
      </b-popover>
    </div>
    <hr>
    <div class="form-row justify-content-between mb-4">
      <div class="col-auto">
        <div class="position-relative">
          <span class="font-weight-bold"> Niveau d’adéquation </span>
          <span id="niveau" class="ml-1" style="position: relative; top: -5px">
            <i class="fal fa-question-circle" />
          </span>
          <b-popover target="niveau" triggers="hover">
            La couleur de la case indique le niveau moyen d'adéquation de la population par rapport
            au niveau de compétence attendu sur le métier.
          </b-popover>
        </div>
        <span class="mr-2">
          <i class="fas fa-circle text-success" />
          <small class="ml-1">Bonne</small>
        </span>
        <span class="mr-2">
          <i class="fas fa-circle text-warning" />
          <small class="ml-1">Moyenne</small>
        </span>
        <span class="mr-2">
          <i class="fas fa-circle text-danger" />
          <small class="ml-1">Faible</small>
        </span>
      </div>
      <div class="col-auto">
        <ScenariosSelector
          :multi="false"
          :hidden="scenarioMetadata ? [] : ['target']"
          :displayed-scenarios="['predicted', 'optimal', 'target']"
          :default-active="defaultScenario"
          @changed="updateCurrentScenario"
        />
      </div>
    </div>
    <hr>
    <div v-if="gFilters && !['Job', 'Unit'].includes(gFilters.type)">
      <b-alert show variant="warning" class="my-4">
        Veuillez sélectionner la variable d'analyse "Métiers" ou "Entités" pour afficher cette page.
      </b-alert>
    </div>
    <div v-else>
      <b-overlay :show="skillStatsAll && (!skillStatsAll.ended || skillStatsAll.loading)">
        <div class="form-row mb-2">
          <div class="col-3 d-flex align-items-start">
            <div class="d-flex align-items-center">
              <MainFilterSwitcher
                tooltip=""
                :g-filters="gFilters"
                :multi="true"
                :global-option="true"
                :global-selected-disabled="true"
                :object-value="true"
                :allowed="detailedFilters"
                :selected-fn="onSelect"
                :disabled="loading"
                :limit="2"
                class="main-filter-switcher"
              />
              <b-button size="sm" class="ml-1" @click="loadData">
                Valider
              </b-button>
            </div>
          </div>
          <div class="col-9 d-flex align-items-end">
            <div class="form-row w-100">
              <div class="col">
                <SkillSelector
                  :exact="true"
                  :multi="false"
                  @selected="setColumnsToShow($event, 0)"
                />
                <div class="form-row small text-center mt-2 mb-2">
                  <div class="col">
                    <span>Existant</span>
                  </div>
                  <div class="col">
                    <span>{{ scenarioName }}</span>
                  </div>
                </div>
              </div>
              <div class="col">
                <SkillSelector
                  :multi="false"
                  :exact="true"
                  @selected="setColumnsToShow($event, 1)"
                />
                <div class="form-row small text-center mt-2 mb-2">
                  <div class="col">
                    <span>Existant</span>
                  </div>
                  <div class="col">
                    <span>{{ scenarioName }}</span>
                  </div>
                </div>
              </div>
              <div v-if="false" class="col">
                <SkillSelector
                  :multi="false"
                  :exact="true"
                  @selected="setColumnsToShow($event, 2)"
                />
                <div class="form-row small text-center mt-2 mb-2">
                  <div class="col">
                    <span>Existant</span>
                  </div>
                  <div class="col">
                    <span>{{ scenarioName }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <template v-if="entities.length">
          <TableItemCompetence
            v-for="(item, idx) in entities"
            :key="idx"
            :item="item"
            :columns-to-show="columnsToShow"
            :skills="skills"
            :index="idx"
            :global-values="item._id === 'Global' ? yearStatsAll : skillStatsAll"
            :scenario-key="selectedCheck"
            :loading="item._id === 'Global' ? false : loading"
          />
        </template>
      </b-overlay>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
// eslint-disable-next-line max-len,vue/max-len
import TableItemCompetence from '@/components/analysis-tabs/competences/tabs/TableItemCompetence.vue';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';

export default {
  name: 'TableCompetence',
  components: {
    MainFilterSwitcher,
    TableItemCompetence,
    ScenariosSelector,
  },
  props: {
    gFilters: {
      type: Object,
      default() {
        return {};
      },
    },
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => 'predicted',
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      loading: false,
      selectedCheck: 'predicted',
      scenarioName: '',
      jobs: [],
      units: [],
      entities: [],
      skills: [null, null, null],
      columnsToShow: [false, false, false],
      requestHandler: null
    };
  },
  computed: {
    scenario() {
      return this.activeScenarios.filter((s) => s.id === this.selectedCheck)[0];
    },
    ...mapState({
      detailedFilters: state => state.analysis.detailedFilters
    }),
    ...mapGetters('auth/basic', {
      activeScenarios: 'getActiveScenarios',
      scenariosMap: 'getScenariosMap',
    }),
    ...mapGetters('analysis', {
      yearStatsAll: 'yearStatsAll',
      skillStatsAll: 'skillStatsAll',
    }),
  },
  watch: {
    gFilters: {
      async handler (newFilters, oldFilters) {
        if (newFilters?.type !== oldFilters?.type) {
          this.entities = []
        }
        await this.loadData()
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    retryLoadData (ids) {
      this.requestHandler = setInterval(async () => {
        this.loading = true
        const data = await this.loadSkillDataByFilter({
          id: this.scenarioMetadata?._key,
          filter: { entities: ids }
        })
        if (!data.error) {
          clearInterval(this.requestHandler)
          this.requestHandler = null
          this.loading = false
        }
      }, 30 * 1000)
    },
    async loadData () {
      let data = null
      let ids = []
      try {
        clearInterval(this.requestHandler)
        this.loading = true
        const nodes = this.entities.filter(e => e._id !== 'Global')
        let all = [...nodes]
        nodes.forEach(n => all = [...all, ...this.getNodeChildren(n)])
        ids = all.map(a => a._id)
        if (ids.length) {
          data = await this.loadSkillDataByFilter({
            id: this.scenarioMetadata?._key,
            filter: { entities: ids }
          })
        }
      } catch (err) {
        console.log(err)
      } finally {
        if (data?.error) {
          this.retryLoadData(ids)
        } else {
          this.loading = false
        }
      }
    },
    updateCurrentScenario(scenario) {
      [this.selectedCheck] = scenario;
      this.scenarioName = this.scenariosMap[this.selectedCheck]?.name;
    },
    // onSelectJob(jobs) {
    //   this.units = [];
    //   this.jobs = [];
    //   // eslint-disable-next-line no-restricted-syntax
    //   for (const j of jobs) {
    //     const jobFound = this.findOptionTree(this.jobTree, j, '_id', 'children');
    //     if (jobFound) this.jobs.push(jobFound);
    //   }
    // },
    // onSelectUnit(units) {
    //   this.jobs = [];
    //   this.units = [];
    //   // eslint-disable-next-line no-restricted-syntax
    //   for (const u of units) {
    //     const unitFound = this.findOptionTree(this.unitTree, u, '_id', 'children');
    //     if (unitFound) this.units.push(unitFound);
    //   }
    // },
    onSelect(arg1, arg2) {
      this.entities = arg2;
    },
    setColumnsToShow(skill, index) {
      this.$set(this.columnsToShow, index, !!skill);
      this.$set(this.skills, index, skill);
    },
    ...mapActions({
      loadSkillDataByFilter: 'analysis/loadSkillDataByFilter',
    }),
  },
};
</script>
<style>
.w-400 {
  min-width: 400px;
}
.main-filter-switcher .vue-treeselect__list {
  width: 400px;
}
.main-filter-switcher .vue-treeselect__menu {
  border: solid 1px #CCC;
  border-bottom-right-radius: 5px;
}
</style>
