<template>
  <div class="d-flex">
    <JobSelector
      v-if="gFilters && gFilters.type === 'Job'"
      :global-option="globalOption"
      :global-selected-disabled="globalSelectedDisabled"
      :multi="multi"
      :object-value="objectValue"
      :selection="selection"
      :selection-disabled="selectionDisabled"
      :placeholder="placeholder"
      :allowed="allowed && allowed.jobs"
      :disabled="disabled"
      :limit="limit"
      :limit-text="treeSelectLimitText"
      @selected="selectedFn('job', $event)"
    />
    <UnitSelector
      v-if="gFilters && gFilters.type === 'Unit'"
      :global-option="globalOption"
      :global-selected-disabled="globalSelectedDisabled"
      :multi="multi"
      :object-value="objectValue"
      :selection="selection"
      :selection-disabled="selectionDisabled"
      :placeholder="placeholder"
      :allowed="allowed && allowed.units"
      :disabled="disabled"
      :limit="limit"
      :limit-text="treeSelectLimitText"
      @selected="selectedFn('unit', $event)"
    />
    <FilterSelector
      v-if="gFilters && gFilters.type === 'CostCentre'"
      key-option="costCentres"
      :global-option="globalOption"
      :global-selected-disabled="globalSelectedDisabled"
      :multi="multi"
      :object-value="objectValue"
      :selection="selection"
      :selection-disabled="selectionDisabled"
      :disabled="disabled"
      @selected="selectedFn('costCenter', $event)"
    />
    <FilterTreeSelector
      v-if="gFilters && gFilters.type === 'Site'"
      :options="siteTree"
      :global-option="globalOption"
      :global-selected-disabled="globalSelectedDisabled"
      :multi="multi"
      :selection="selection"
      :selection-disabled="selectionDisabled"
      :object-value="objectValue"
      :disabled="disabled"
      @selected="selectedFn('site', $event)"
    />
    <span
      v-if="tooltip && tooltip.length"
      id="p-repartition-effectifs-age"
      class="ml-1"
      style="position: relative; top: -5px"
    >
      <i class="fal fa-question-circle" />
    </span>
    <b-popover
      v-if="tooltip && tooltip.length"
      target="p-repartition-effectifs-age"
      triggers="hover"
    >
      {{ tooltip }}
    </b-popover>
  </div>
</template>
<script>
import FilterSelector from '@/components/selectors/FilterSelector.vue';
import FilterTreeSelector from '@/components/selectors/FilterTreeSelector.vue';
import { treeSelectLimitText } from '@/services/helpers'

export default {
  components: { FilterTreeSelector, FilterSelector },
  props: {
    tooltip: {
      type: String,
      required: false,
      default: () => `
      Visualiser ces graphs par métiers ou par entités en modifiant la variable d’analyse
      principale dans la rubrique "Variable d’analyse principale".
      `,
    },
    gFilters: {
      type: Object,
      required: true,
    },
    selectedFn: {
      type: Function,
      required: true,
    },
    multi: {
      type: Boolean,
      required: false,
      default: () => true,
    },
    objectValue: {
      type: Boolean,
      default: true,
    },
    globalOption: {
      type: Boolean,
      default: false,
    },
    globalSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Select',
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
    disabled: {
      type: Boolean,
      required: false,
      default: () => false
    },
    limit: {
      type: Number,
      required: false,
      default: () => 3
    }
  },
  data () {
    return {
      treeSelectLimitText
    }
  }
};
</script>
