// eslint-disable-next-line
export const lightJobStruct = (item) => {
  delete item?.v;
  delete item?.createdAt;
  delete item?.updatedAt;
  delete item?.parents;
  delete item?.members;
  delete item?.skillGraph;
  delete item?.relatedJobs;
  delete item?.motivations;
  // delete item.skills;
  delete item?.tags;
  delete item?.isHR;
  delete item?.orderType;
  delete item?.progressType;
  delete item?.test;
  delete item?.radarType;
  return item;
};

// eslint-disable-next-line
export const RecursiveJob = async(category, jobCategoriesMap, jobsMap, onlyParents = false) => {
  const children = [];
  if (!category.members) {
    // eslint-disable-next-line
    for (const [key, cate] of jobCategoriesMap) {
      const cat = { ...cate }
      if (cat.parents) {
        // eslint-disable-next-line
        const matchChild = cat.parents.find((item) => item['#'] === category._id);
        if (matchChild) {
          // eslint-disable-next-line
          const response = await RecursiveJob(cat, jobCategoriesMap, jobsMap, onlyParents);
          if (response) {
            cat.children = response;
          }
          children.push(lightJobStruct(cat));
        }
      }
    }
  } else {
    if (!onlyParents) {
      // eslint-disable-next-line
      for (const job of category.members) {
        const item = jobsMap.get(job['#']);
        children.push(lightJobStruct(item));
      }
    }
  }

  return children;
};
