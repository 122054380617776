// eslint-disable-next-line
export const lightSiteStruct = (item) => {
  /*
  delete item.v;
  delete item.createdAt;
  delete item.updatedAt;
  delete item.org;
  delete item.parentCode;
  delete item.costCentres;
  delete item.stats;
  delete item.sites;
  delete item.closedAt;
  delete item.establishedAt;
  delete item.createdFrom;
  */
  return item;
};

// eslint-disable-next-line
export const SiteRecursive = async (parentSite, sites) => {
  const children = [];
  // eslint-disable-next-line
  for (const site of sites) {
    const siteParentRef = site.parent;
    // eslint-disable-next-line
    if (siteParentRef && siteParentRef['#'] === parentSite._id) {
      // eslint-disable-next-line
      const response = await SiteRecursive(site, sites);
      if (response.length) {
        site.children = response;
      }
      children.push(lightSiteStruct(site));
    }
  }
  return children;
};
