<template>
  <div v-if="item.isEnabled" :key="rerenderKey" class="align-items-center">
    <b-collapse
      :id="`collapse-${item.entity}${index}`"
      v-model="activeCollapse"
      class="list-group m-1"
    >
      <div
        v-for="(itemChild1, idx1) in item.children.filter((c) => c.isEnabled)"
        :key="idx1"
        class="list-group-item px-0 bg-gray-200"
      >
        <custom-job-table-tree-head
          :item="itemChild1"
          :index="idx1"
          :active-departure-details="activeDepartureDetails"
          :active-entry-details="activeEntryDetails"
          :active-mobility-details="activeMobilityDetails"
          :show-external-cols="showExternalCols"
          :depth="depth"
          :scenario-key="scenarioKey"
          :editable="editable"
          :previous-year-edits="previousYearEdits"
          :type-scenario-target="typeScenarioTarget"
          @update:item="updateItem"
          @new:edit="addEdit"
        />
        <custom-job-table-tree-body
          v-if="activeCollapse"
          :item="itemChild1"
          :index="idx1"
          :active-departure-details="activeDepartureDetails"
          :active-entry-details="activeEntryDetails"
          :active-mobility-details="activeMobilityDetails"
          :show-external-cols="showExternalCols"
          :depth="depth"
          :scenario-key="scenarioKey"
          :editable="editable"
          :previous-year-edits="previousYearEdits"
          :type-scenario-target="typeScenarioTarget"
          @update:item="updateItem"
          @new:edit="addEdit"
        />
      </div>
    </b-collapse>
  </div>
</template>
<script>
import CustomJobTableTreeHead
  from '@/components/analysis-tabs/hypothesis/customjob/CustomJobTableTreeHead.vue';
// eslint-disable-next-line
import CustomJobTableTreeBody
  from '@/components/analysis-tabs/hypothesis/customjob/CustomJobTableTreeBody.vue';
import { computeAllHypothesisNode } from '@/providers/helpers/hypothesis';

export default {
  name: 'CustomJobTableTreeBody',
  components: {
    CustomJobTableTreeHead,
    CustomJobTableTreeBody,
  },
  props: {
    typeScenarioTarget: {
      type: String,
      required: false,
      default: 'effective'
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    activeDepartureDetails: {
      type: Boolean,
      default: false,
    },
    activeEntryDetails: {
      type: Boolean,
      default: false,
    },
    activeMobilityDetails: {
      type: Boolean,
      default: false,
    },
    showExternalCols: { //new active for External column
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    previousYearEdits: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      activeCollapse: false,
      computeAllHypothesisNode,
      rerenderKey: 0,
    };
  },
  watch: {
    item: {
      handler() {
        this.activeCollapse = this.item.activeCollapse || this.activeCollapse;
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateItem(item) {
      // eslint-disable-next-line no-plusplus
      // this.rerenderKey++;
      this.$emit('update:item', item);
      this.$forceUpdate();
    },
    addEdit($event) {
      this.$emit('new:edit', $event);
      this.$forceUpdate();
    },
  },
};
</script>
