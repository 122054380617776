<template>
  <div :key="key" class="form-row">
    <div class="col">
      <highcharts :options="chart" />
      <div class="px-5 d-flex justify-content-between">
        <b>{{ seriesNames[1] }}
          <i class="fa fa-circle" :style="{ color: getSerieColor(seriesNames[1]) }" />
        </b>
        <b>
          <i class="fa fa-circle" :style="{ color: getSerieColor(seriesNames[0]) }" />
          {{ seriesNames[0] }}
        </b>
      </div>
      <DataLabelsSwitcher />
    </div>
  </div>
</template>

<script>
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher'
import { getOpacity } from '@/services/helpers'
import {
  extractCategoriesFromStaffData,
  extractEntitiesFromStaffData, extractNestedSeriesFromStaffData,
  extractSeriesFromStaffData, extractStackedCategoriesFromStaffData
} from '@/services/staff'

const chartTypeTwo = (entities, showDataLabels) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'bar',
  },
  title: null,
  legend: { enabled: false },
  accessibility: {
    point: {
      valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.',
    },
  },
  xAxis: [
    {
      categories: entities,
      reversed: false,
      labels: {
        step: 1,
      },
      accessibility: {
        description: 'Age (male)',
      },
    },
    {
      categories: entities,
      opposite: true,
      reversed: false,
      linkedTo: 0,
      labels: {
        step: 1,
      },
      accessibility: {
        description: 'Age (female)',
      },
    },
  ],
  yAxis: {
    title: null,
    gridLineWidth: 0,
    labels: {
      formatter() {
        return `${Math.abs(this.value)}`;
      },
    },
  },
  tooltip: {
    formatter() {
      return `
        <b>${this.series.name}, ancienneté ${this.point.category}</b><br/>
        Population: ${Math.abs(this.point.y)}
      `;
    },
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      dataLabels: {
        enabled: showDataLabels,
        formatter() {
          return `${Math.abs(this.y)}`;
        },
      },
    },
  },
  series: [],
})
const chartTypeOne = (entities, showDataLabels) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: { enabled: false },
  xAxis: {
    categories: entities,
  },
  yAxis: {
    title: null,
    gridLineWidth: 0,
    labels: {
      formatter(item) {
        return `${item.value} %`
      },
    },
  },
  tooltip: {
    pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
    series: {
      dataLabels: {
        enabled: showDataLabels,
      },
    },
  },
  series: [],
})

export default {
  components: {
    DataLabelsSwitcher
  },
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      key: 0,
      getOpacity,
      series: [],
      categories: [],
      selectedCategories: [],
      labels: []
    }
  },
  computed: {
    seriesNames () {
      return [
        this.series?.[0]?.name,
        this.series?.[1]?.name
      ]
    },
    chart () {
      const chart = chartTypeTwo(this.categories, this.showDataLabels)
      chart.series = this.series || []
      return chart
    }
  },
  watch: {
    dataset: {
      handler () {
        this.refreshChart()
        this.selectedCategories = this.categories.map(cat => cat.label)
      },
      immediate: true
    },
    selectedCategories: {
      handler () {
        this.refreshChart()
      },
      immediate: true
    },
    $attrs: {
      handler () {
        this.refreshChart()
      },
      deep: true
    }
  },
  methods: {
    getSerieColor (label) {
      const item = this.series.find(serie => serie?.name === label)
      return item?.color
    },
    refreshChart () {
      this.entities = extractEntitiesFromStaffData(this.dataset, 'seniorityGender')
      const categories = extractStackedCategoriesFromStaffData(
        this.dataset,
        'seniorityGender',
        this.$attrs.scenario
      )
      this.series = extractNestedSeriesFromStaffData(
        this.dataset,
        'seniorityGender',
        this.$attrs.scenario,
        categories
      )
      this.categories = categories.map(cat => cat.label)
      this.key++
    }
  }
}
</script>
