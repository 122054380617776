<template>
  <header>
    <nav class="navbar navbar-expand-md navbar-dark bg-primary">
      <a class="navbar-brand" @click="$router.push('/collab')">
        <strong>SWP</strong>
      </a>
      <b-navbar-nav>
        <b-nav-item to="/collab">
          LISTE DES SCENARIOS
        </b-nav-item>
      </b-navbar-nav>
      <button class="navbar-toggler" type="button">
        <span class="navbar-toggler-icon" />
      </button>
      <div id="navbarCollapse" class="collapse navbar-collapse">
        <ul class="navbar-nav ml-auto align-items-center">
          <li v-if="false" class="nav-item active">
            <a class="nav-link mr-3" href="#">
              <i class="fal fa-search" />
            </a>
          </li>
          <li v-if="false" class="nav-item">
            <b-dropdown
              no-caret
              right
              variant="link"
              toggle-class="text-decoration-none"
              menu-class="shadow"
            >
              <template #button-content>
                <span class="text-white position-relative">
                  <i class="fal fa-bell" />
                  <i
                    class="fas fa-circle text-warning position-absolute"
                    style="top: -5px; right: -5px"
                  />
                </span>
              </template>
              <div class="px-3 py-1" style="width: 220px">
                <h6 class="text-primary text-uppercase font-weight-normal">
                  Notificaciones
                </h6>
                <ul class="list-unstyled">
                  <li v-for="i in 2" :key="i" class="mb-2">
                    <div class="d-flex">
                      <span class="text-primary mr-2">
                        <i class="fas fa-check-circle" />
                      </span>
                      <small> Votre scénario est prêt à être consulté </small>
                    </div>
                  </li>
                </ul>
              </div>
            </b-dropdown>
          </li>
          <li class="nav-item">
            <b-dropdown
              no-caret
              right
              variant="link"
              toggle-class="text-decoration-none"
              menu-class="shadow"
            >
              <template #button-content>
                <b-avatar v-if="false" :text="firstLetters" size="32px" />
                <img
                  class="rounded-circle"
                  width="32"
                  src="https://picsum.photos/200"
                  alt="User"
                  style="border: 2px solid white"
                >
              </template>
              <b-dropdown-item to="/collab/user/profile">
                <i class="fa fa-user mr-2" />
                <span>Mon compte</span>
              </b-dropdown-item>
              <b-dropdown-item variant="danger" @click.prevent="logout">
                <i class="fa fa-power-off mr-2" />
                <span>Se déconnecter</span>
              </b-dropdown-item>
            </b-dropdown>
          </li>
        </ul>
      </div>
    </nav>
  </header>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';
import Cookies from 'js-cookie';

export default {
  data() {
    return {};
  },
  computed: {
    firstLetters() {
      return `${this.user.firstname[0]}${this.user.lastname[0]}`.toUpperCase();
    },
    ...mapGetters({
      user: 'auth/currentUser',
    }),
  },
  methods: {
    ...mapActions({
      setToken: 'auth/setAuthToken',
      removeUser: 'auth/removeUser',
      deleteSession: 'auth/logout'
    }),
    async logout() {
      await this.deleteSession()
      this.setToken('');
      this.removeUser();
      Cookies.remove('gpec');
      this.$router.go();
    },
  },
};
</script>
