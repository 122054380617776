export const sumEdit = (edit) => {
  // eslint-disable-next-line no-unused-vars
  let sum = 0;
  // augmentation de l'effectif
  sum += edit.inMobility.customValue - edit.inMobility.actualValue;
  sum += edit.recruitment.customValue - edit.recruitment.actualValue;
  // diminution de l'effectif
  sum += edit.outMobility.customValue - edit.outMobility.actualValue;
  sum += edit.otherDeparture.customValue - edit.otherDeparture.actualValue;
  sum += edit.retirement.customValue - edit.retirement.actualValue;
  sum += edit.external.customValue - edit.external.actualValue;
  return sum;
};

export const mergeEditions = (editions) => {
  let finalObject = {};
  const last = editions[editions.length - 1];
  // eslint-disable-next-line prefer-destructuring
  finalObject = editions[0];
  finalObject.inMobility.customValue = last.inMobility.customValue;
  finalObject.outMobility.customValue = last.outMobility.customValue;
  finalObject.otherDeparture.customValue = last.otherDeparture.customValue;
  finalObject.recruitment.customValue = last.recruitment.customValue;
  finalObject.retirement.customValue = last.retirement.customValue;
  return finalObject;
};

export const hasChanged = (obj) =>
  obj.inMobility.customValue !== obj.inMobility.actualValue ||
  obj.outMobility.customValue !== obj.outMobility.actualValue ||
  obj.otherDeparture.customValue !== obj.otherDeparture.actualValue ||
  obj.retirement.customValue !== obj.retirement.actualValue ||
  obj.recruitment.customValue !== obj.recruitment.actualValue ||
  obj.external.customValue !== obj.external.actualValue;

export const mergeNodesValues = (nodes, scenarioKey) => {
  const exceptionsKeys = ['history', 'editing'];
  const finalObject = {};
  nodes.forEach((obj) => {
    const current = obj[scenarioKey];
    if (current) {
      Object.keys(current)
        .filter((k) => !exceptionsKeys.includes(k))
        .forEach((field) => {
          if (field === 'children' && !current.isEnabled) {
            return;
          }
          if (typeof current[field] === 'number') {
            if (!finalObject[field]) {
              finalObject[field] = 0;
            }
            finalObject[field] += current[field];
          }
          if (typeof current[field] === 'object') {
            if (!finalObject[field]) {
              finalObject[field] = {};
            }
            Object.keys(current[field]).forEach((subfield) => {
              if (typeof current[field][subfield] === 'number') {
                if (!finalObject[field][subfield]) {
                  finalObject[field][subfield] = 0;
                }
                if (Number.isInteger(current[field][subfield])) {
                  finalObject[field][subfield] += current[field][subfield];
                }
              } else if (typeof current[field][subfield] === 'object') {
                if (!finalObject[field][subfield]) {
                  finalObject[field][subfield] = {};
                }
                Object.keys(current[field][subfield]).forEach((subsubfield) => {
                  if (typeof current[field][subfield][subsubfield] === 'number') {
                    if (!finalObject[field][subfield][subsubfield]) {
                      finalObject[field][subfield][subsubfield] = 0;
                    }
                    if (Number.isInteger(current[field][subfield][subsubfield])) {
                      // eslint-disable-next-line max-len
                      finalObject[field][subfield][subsubfield] +=
                        current[field][subfield][subsubfield];
                    }
                  } else if (typeof current[field][subfield][subsubfield] === 'object') {
                    if (!finalObject[field][subfield][subsubfield]) {
                      finalObject[field][subfield][subsubfield] = {};
                    }
                    Object.keys(current[field][subfield][subsubfield]).forEach((subsubsubfield) => {
                      if (
                        typeof current[field][subfield][subsubfield][subsubsubfield] === 'number'
                      ) {
                        if (!finalObject[field][subfield][subsubfield][subsubsubfield]) {
                          finalObject[field][subfield][subsubfield][subsubsubfield] = 0;
                        }
                        // eslint-disable-next-line max-len
                        if (
                          Number.isInteger(current[field][subfield][subsubfield][subsubsubfield])
                        ) {
                          // eslint-disable-next-line max-len
                          finalObject[field][subfield][subsubfield][subsubsubfield] +=
                            current[field][subfield][subsubfield][subsubsubfield];
                        }
                      } else {
                        // eslint-disable-next-line max-len
                        finalObject[field][subfield][subsubfield][subsubsubfield] =
                          current[field][subfield][subsubfield][subsubsubfield];
                      }
                    });
                  } else {
                    // eslint-disable-next-line max-len
                    finalObject[field][subfield][subsubfield] =
                      current[field][subfield][subsubfield];
                  }
                });
              }
            });
          }
        });
    }
  });
  return finalObject;
};

export const computeHypothesisNode = (currentNode, updatedChildNode, scenarioKey) => {
  if (!currentNode?.children?.length) {
    return;
  }
  // find updated child in current node children
  const index = currentNode.children.findIndex((child) => child.entity === updatedChildNode.entity);
  if (index >= 0) {
    // replace updated child in current node children
    currentNode.children[index] = updatedChildNode;
    // loop through all children to compute node values for each fields
    // set new values in current node scenario
    currentNode[scenarioKey] = mergeNodesValues(currentNode.children, scenarioKey);
  }
  // eslint-disable-next-line consistent-return
  return currentNode;
};

export const computeAllHypothesisNode = (currentNode, scenarioKey) => {
  if (!currentNode.children || !currentNode.children.length) {
    return currentNode;
  }
  // loop through all children to compute node values for each field
  // set new values in current node scenario
  currentNode[scenarioKey] = mergeNodesValues(currentNode.children, scenarioKey);
  // eslint-disable-next-line consistent-return
  return currentNode;
};
