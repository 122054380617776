import UserService from '@/providers/services/user';

const state = {};
const getters = {};
const mutations = {};
const actions = {
  async updatePassword(context, payload) {
    // eslint-disable-next-line no-return-await
    return await UserService.updatePassword(payload);
  },
  async fetchUsers() {
    // eslint-disable-next-line no-return-await
    return await UserService.fetchUsers();
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
