<template>
  <div class="mb-5">
    <b-row>
      <b-col>
        <div class="h5 text-uppercase font-weight-bold" style="text-decoration: underline">
          Projection au {{ date }}
        </div>
      </b-col>
      <b-col class="text-right">
        <b-radio-group v-model="localScenario" disabled>
          <b-radio value="structural">
            Structurelle
          </b-radio>
          <b-radio value="conjunctural">
            Conjoncturelle
          </b-radio>
          <b-radio value="noRecruiting">
            Sans recrutement
          </b-radio>
        </b-radio-group>
      </b-col>
    </b-row>
    <hr>
    <HypothesisTableEditor
      v-if="items && items.length > 1"
      :key="rerenderKey"
      :editable="!readonly"
      :rows="items"
      :edits="edits"
      :previous-year-edits="previousYearEdits"
      :current-scenario="currentScenario"
      :selection="selection"
      :filters="{ type: 'Job' }"
      :can-filter="false"
      @new:edit="addEdit"
      @update:item="updateItem"
    />
    <div v-else>
      <b-card class="p-4 m-4">
        <h5>Les filtres ne retournent aucun résultat.</h5>
      </b-card>
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('previous')"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          v-if="!readonly"
          variant="link"
          class="text-uppercase"
          @click="$emit('saveDraft')"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button class="btn btn-secondary text-uppercase" type="button" @click="$emit('next')">
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import HypothesisMixin from '@/mixins/HypothesisMixin';
import HypothesisTableEditor from '@/components/analysis-tabs/hypothesis/HypothesisTableEditor.vue';

export default {
  components: { HypothesisTableEditor },
  mixins: [HypothesisMixin],
  props: {
    scenario: {
      type: String,
      required: true,
    },
    year: {
      type: Number,
      required: true,
    },
    rows: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    currentEdits: {
      type: Array,
      required: false,
      default: () => [],
    },
    previousYearEdits: {
      type: Array,
      required: false,
      default: () => [],
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      localScenario: '',
      currentScenario: 'target',
      edits: [],
      items: [],
      filtersKeys: {
        Job: 'jobs',
        Unit: 'units',
      },
      type: 'Job',
      rerenderKey: 0,
    };
  },
  computed: {
    selection() {
      return this.filters?.[this.filtersKeys?.[this.type]] || [];
    },
    date() {
      return `31/12/${this.year}`;
    },
  },
  mounted() {
    this.localScenario = this.scenario
    this.loadData();
  },
  methods: {
    loadData() {
      if (this.currentEdits) {
        this.edits = [...this.currentEdits, ...this.edits];
      }
      if (this.type && ['Job', 'Unit'].includes(this.type)) {
        // eslint-disable-next-line max-len
        this.items = this.parseItemsTreeToNestedRowsArray(
          this.rows,
          this.currentScenario,
          this.type
        );
      } else {
        this.items = [];
      }
      // eslint-disable-next-line no-plusplus
      this.rerenderKey++;
    },
    addEdit(edit) {
      this.edits.push(edit);
      this.edits = [...this.edits];
      this.$emit('updateTable', this.edits);
      this.$forceUpdate();
    },
    updateItem() {
      // this.loadData();
      // this.$forceUpdate();
    },
  },
};
</script>
