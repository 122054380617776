import ConfigService from './config';

class JobService extends ConfigService {
  getCustomJob(id, year) {
    let url = `${this.apiUrl}/customjob/candidates?id=${id}`
    if (year) {
      url = `${url}&year=${year}`
    }
    return super.get(url);
  }

  getCustomJobMetadata(id) {
    return super.get(`${this.apiUrl}/customjob?id=${id}`);
  }

  createNewCustomJob(job) {
    return super.post(`${this.apiUrl}/customjob`, job);
  }

  updateNewCustomJob(job) {
    return super.post(`${this.apiUrl}/customjob`, job);
  }

}

export default new JobService();
