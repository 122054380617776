const MainAnalysisFilterMixin = {
  props: {
    id: {
      type: String,
      required: true,
    },
    currentChecks: {
      type: Array,
      required: true,
    },
    dataEffectifs: {
      type: Object,
      required: false,
    },
    gFilters: {
      type: Object,
      required: true,
    },
    keyGetter: {
      type: String,
      required: false,
    },
    keySelects: {
      type: String,
      required: true,
    },
    keySetSelects: {
      type: String,
      required: true,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    labels: {
      type: Array,
      required: false,
      default: () => [],
    },
    scenarioTarget: {
      type: String,
      required: false,
      default: 'effective'
    },
  },
  data() {
    return {
      type: '',
      currentSelection: [],
    };
  },
  watch: {
    dataEffectifs: {
      handler(prev, curr) {
        if (this.gFilters.type && JSON.stringify(prev) !== JSON.stringify(curr)) {
          if (
            this.selection &&
            Array.isArray(this.selection) &&
            !['senioritygender', 'age'].includes(this.id)
          ) {
            this.currentSelection = this.selection;
          }
          this.selectMainFilterItem(this.gFilters.type, this.currentSelection);
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    selectMainFilterItem(item, values) {
      if (!values || !values.length) {
        return;
      }
      const data = Array.isArray(values) ? values : [values];
      if (typeof data[0] === 'object') {
        this.buildSelect(item, data, true);
      } else {
        this.buildSelect(item, data, false);
      }
    },
    buildSelect(item, values, parse = false) {
      this.type = item;
      this.currentSelection = values;
      this.$emit('selection', this.currentSelection);
      if (parse) {
        // eslint-disable-next-line no-underscore-dangle
        const ids = this.currentSelection.map((e) => e._id);
        this.setSelect(this.keySetSelects, ids);
      } else {
        this.setSelect(this.keySetSelects, this.currentSelection);
      }
      if (this.refreshSeriesCallback && this[this.refreshSeriesCallback]) {
        setTimeout(() => {
          this[this.refreshSeriesCallback].apply(this, [this.currentChecks]);
          this.$forceUpdate();
        }, 99);
      }
    },
  },
};
export default MainAnalysisFilterMixin;
