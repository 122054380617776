<template>
  <b-card body-class="py-3">
    <h6 class="text-uppercase mb-3">
      <b>Top des départs hors retraite</b>
    </h6>
    <table class="table table-striped table-sm table-middle mb-0">
      <tbody>
        <tr v-for="item in attritionTopPerAttribute" :key="item.label">
          <td width="60" class="pl-3 border-0">
            <div
              class="bg-secondary text-white d-flex
                        justify-content-center align-items-center"
              style="width: 30px; height: 30px; border-radius: 50%"
            >
              <i class="fa fa-user" />
            </div>
          </td>
          <td class="border-0">
            <span>{{ item.label }}</span>
            <br>
            <b>{{ item.value }}</b>
          </td>
        </tr>
      </tbody>
    </table>
  </b-card>
</template>

<script>
export default {
  props: {
    attritionTopPerAttribute: {
      type: Array,
      required: true
    }
  }
}
</script>
