<template>
  <ul class="list-unstyled user-inline">
    <li v-for="(item, idx) in users" :key="idx">
      <img
        v-if="item.image"
        class="rounded-circle"
        width="35"
        :src="item.image"
        alt="User"
      >
      <span v-else class="d-inline-block">
        <span class="user-letters" :style="{ background: item.color }">
          {{ item.name.substring(0, 2) }}
        </span>
      </span>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'UsersInline',
  props: {
    users: {
      type: Array,
      required: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.user-inline {
  padding: 0;
  margin: 0;
  display: flex;
  li {
    img {
      border: 2px solid #bbb;
    }
    &:not(:first-child) {
      margin-left: -7px;
    }
  }
}
.user-letters {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  color: #fff;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
