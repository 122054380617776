<template>
  <div class="container mb-5">
    <div class="row mb-4">
      <div class="col">
        <h5>MON COMPTE</h5>
      </div>
    </div>
    <div>
      <h5>Sécurité</h5>
      <div class="text-muted">
        Modifier mon mot de passe
      </div>
      <b-card class="mt-3">
        <b-alert v-if="error" show variant="danger">
          {{ error }}
        </b-alert>
        <b-form @submit.prevent="updatePassword">
          <b-form-group label="Mot de passe actuel">
            <b-form-input
              v-model="currentPassword"
              type="password"
              :state="validateState('currentPassword')"
            />
            <b-form-invalid-feedback v-if="!$v.currentPassword.required">
              Ce champs est obligatoire.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.currentPassword.minLength">
              Le mot de passe doit contenir au moins 8 caractères.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Nouveau mot de passe">
            <b-form-input
              v-model="newPassword"
              type="password"
              :state="validateState('newPassword')"
            />
            <b-form-invalid-feedback v-if="!$v.newPassword.required">
              Ce champs est obligatoire.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.newPassword.minLength">
              Le mot de passe doit contenir au moins 8 caractères.
            </b-form-invalid-feedback>
          </b-form-group>
          <b-form-group label="Confirmez votre nouveau mot de passe">
            <b-form-input
              v-model="newPasswordConfirmation"
              type="password"
              :state="validateState('newPasswordConfirmation')"
            />
            <b-form-invalid-feedback v-if="!$v.newPasswordConfirmation.required">
              Ce champs est obligatoire.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.newPasswordConfirmation.sameAsPassword">
              Le mot de passe saisi est différent.
            </b-form-invalid-feedback>
            <b-form-invalid-feedback v-if="!$v.newPasswordConfirmation.minLength">
              Le mot de passe doit contenir au moins 8 caractères.
            </b-form-invalid-feedback>
          </b-form-group>
          <div>
            <b-button type="submit" vairant="primary" :disabled="loading">
              <b-spinner v-if="loading" />
              <span v-else>Enregistrer</span>
            </b-button>
          </div>
        </b-form>
      </b-card>
    </div>
    <div v-if="true" class="mt-3">
      <h5>Mes informations</h5>
      <div class="text-muted">
        Voir mon profil
      </div>
      <b-card class="mt-3">
        <div class="d-flex justify-content-between">
          <div>Prénom</div>
          <div>{{ currentUser.firstname }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Nom</div>
          <div>{{ currentUser.lastname }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Matricule</div>
          <div>{{ currentUser.matricule }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Entité</div>
          <div>{{ getUserUnit(currentUser.unit) }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Poste</div>
          <div>{{ currentUser.job }}</div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Périmètre</div>
          <div>
            <div v-for="(item, i) in currentUser.filter.units" :key="i">
              {{ getUserUnit(item) }}
            </div>
          </div>
        </div>
        <div class="d-flex justify-content-between">
          <div>Rôle</div>
          <div>{{ currentUser.scenariocreator ? 'Création' : 'Consultation' }}</div>
        </div>
        <div v-if="false">
          <b-button @click="toggleUserRole">
            Changer le rôle
          </b-button>
        </div>
      </b-card>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { required, minLength, sameAs } from 'vuelidate/lib/validators';
import Cookies from 'js-cookie';

export default {
  data() {
    return {
      error: false,
      loading: false,
      newPassword: '',
      newPasswordConfirmation: '',
      currentPassword: '',
    };
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },
  validations: {
    newPassword: { required, minLength: minLength(8) },
    newPasswordConfirmation: {
      required,
      minLength: minLength(8),
      sameAsPassword: sameAs('newPassword'),
    },
    currentPassword: { required, minLength: minLength(8) },
  },
  methods: {
    getUserUnit(unit) {
      const unitFound = this.findOptionTree(this.unitTree, unit, '_id', 'children');
      return unitFound?.names?.[0]?.label || '-';
    },
    async updatePassword() {
      this.$v.$touch();
      if (this.$v.$anyError) {
        return;
      }
      try {
        this.error = false;
        this.loading = true;
        const payload = {
          old_password: this.currentPassword,
          new_password1: this.newPassword,
          new_password2: this.newPasswordConfirmation,
        };
        const data = await this.changePassword(payload);
        Cookies.set('gpec', data.token);
        this.setAuthToken(data.token);
        this.$bvToast.toast('Le mot de passe a été modifié avec succès.', {
          title: 'Modification du mot de passe',
          variant: 'success',
          solid: true,
        });
        this.resetForm();
      } catch (err) {
        // console.log('err', err);
        this.error = "Le mot de passe actuel n'est pas correct. Veuillez le saisir à nouveau.";
      } finally {
        this.loading = false;
      }
    },
    toggleUserRole () {
      const user = { ...this.currentUser }
      user.scenariocreator = !user.scenariocreator
      this.setUser(user)
    },
    resetForm() {
      this.currentPassword = '';
      this.newPasswordConfirmation = '';
      this.newPassword = '';
      this.$v.$reset();
    },
    validateState(name) {
      const { $dirty, $error } = this.$v[name];
      return $dirty ? !$error : null;
    },
    ...mapActions({
      changePassword: 'user/updatePassword',
      setAuthToken: 'auth/setAuthToken',
      setUser: 'auth/setUser'
    }),
  },
};
</script>
