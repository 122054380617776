import Vue from 'vue';

const isDebug = process.env.VUE_APP_DEBUG === true || process.env.VUE_APP_DEBUG === 'true';

Vue.prototype.$debug = isDebug;

// eslint-disable-next-line func-names
Vue.prototype.$log = function (title, data, isTable, columns) {
  if (!isDebug) {
    return;
  }

  // eslint-disable-next-line max-len, vue/max-len
  const LOG_PREFIX = `${new Date().getDate()}.${new Date().getMonth()}.${new Date().getFullYear()} / ${new Date().getHours()}:${new Date().getMinutes()}:${new Date().getSeconds()}`;
  const { log, table } = console;

  // 1. Convert args to a normal array
  // eslint-disable-next-line no-undef,prefer-rest-params
  const args = Array.from(arguments);
  // OR you can use: Array.prototype.slice.call( arguments );

  if (isTable) {
    log.apply(console, [title]);
    table.apply(console, [data, columns]);
  } else {
    // 2. Prepend log prefix log string
    args.unshift(`${LOG_PREFIX}: `);
    args.unshift(`${title}: `);

    // 3. Pass along arguments to console.log
    log.apply(console, args);
  }
};
