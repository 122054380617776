<template>
  <div>
    <div>
      <b-button :disabled="count < 2" @click="open">
        <i class="fal fa-puzzle-piece" />
        <span class="ml-2 text-uppercase">Assembler</span>
        <b-badge class="ml-2 rounded-xl text-secondary small" variant="light">
          {{ count }}
        </b-badge>
      </b-button>
    </div>
    <b-modal
      v-model="show"
      hide-footer
      content-class="rounded-xl border-0"
      body-class="p-0"
      header-class="border-0 d-flex justify-content-center align-items-center text-dark p-4"
      size="lg"
    >
      <template #modal-header>
        <i class="fa fa-puzzle-piece" />
        <span class="h5 my-0 ml-3">Assembler plusieurs scénarios</span>
        <b-btn-close @click="close" />
      </template>
      <div>
        <MergeScenarioConflictFilterManager
          v-if="filterConflicts.length"
          :scenarios="localScenarios"
          :conflicts="filterConflicts"
          @success="resolve"
          @cancel="cancel"
        />
        <MergeScenarioConflictTypeManager
          v-else-if="typeConflicts.length"
          :scenarios="localScenarios"
          :conflicts="typeConflicts"
          @success="setType"
          @cancel="cancel"
        />
        <MergeScenarioConfiguration
          v-else
          :scenarios="localScenarios"
          @success="setLabel"
          @cancel="cancel"
        />
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  buildMergedScenarioPayload,
  checkOverlapScenariosFilter,
  checkOverlapScenariosType
} from '@/services/scenarios'
import {mapActions} from 'vuex'
import MergeScenarioConfiguration from '@/components/scenario/merge/MergeScenarioConfiguration'
import MergeScenarioConflictFilterManager
  from '@/components/scenario/merge/MergeScenarioConflictFilterManager'
import MergeScenarioConflictTypeManager
  from '@/components/scenario/merge/MergeScenarioConflictTypeManager'

export default {
  components: {
    MergeScenarioConflictTypeManager,
    MergeScenarioConfiguration,
    MergeScenarioConflictFilterManager
  },
  props: {
    count: {
      type: Number,
      required: false,
      default: () => 0
    },
    scenarios: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      show: false,
      type: '',
      label: '',
      filterConflicts: [],
      typeConflicts: [],
      scenarioToKeep: null,
      localScenarios: [],
      scenariosMetadata: []
    }
  },
  watch: {
    scenarios: {
      handler () {
        this.localScenarios = [...this.scenarios]
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    setType (type) {
      this.type = type
      this.create()
    },
    cancel () {
      this.$emit('cancel')
      this.close()
    },
    checkFilterConflicts () {
      this.filterConflicts = checkOverlapScenariosFilter(this.localScenarios)
    },
    async checkTypeConflicts () {
      this.typeConflicts = checkOverlapScenariosType(this.localScenarios)
    },
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
    setLabel (label) {
      this.label = label
      this.check()
    },
    async check () {
      this.checkFilterConflicts()
      if (!this.filterConflicts.length) {
        await this.loadScenariosMetadata(this.localScenarios)
        if (!this.typeConflicts.length && this.localScenarios.length >= 2) {
          this.create()
        }
      }
    },
    resolve ({ conflicts, scenario: keep }) {
      conflicts[0].forEach(scenario => {
        if (scenario.ID !== keep) {
          this.$emit('remove', scenario.ID)
          this.localScenarios = this.localScenarios.filter(s => s.ID !== scenario.ID)
        }
      })
      this.check()
    },
    create () {
      // eslint-disable-next-line max-len,vue/max-len
      const payload = buildMergedScenarioPayload(this.scenariosMetadata, { label: this.label, scenario: this.type, isFinalized: true })
      this.$emit('success', payload)
      this.$emit('cancel')
    },
    async loadScenariosMetadata () {
      try {
        this.loading = true
        const promises = []
        this.localScenarios.forEach((scenario) => {
          promises.push(this.fetchScenario({id : scenario.ID}))
        })
        this.scenariosMetadata = await Promise.all(promises)
        this.typeConflicts = checkOverlapScenariosType(this.scenariosMetadata)
        if (!this.typeConflicts.length && this.scenariosMetadata.length) {
          this.type = this.scenariosMetadata[0]?.scenario || 'conjunctural'
        }
      } catch (err) {
        console.log('err', err)
      } finally {
        this.loading = false
      }
    },
    next (e) {
      e.preventDefault()
      this.check()
      if (!this.filterConflicts.length) {
        this.$emit('success', { label: this.name, scenarios: this.localScenarios })
      }
    },
    ...mapActions({
      fetchScenario: 'scenarios/fetchScenarioById'
    })
  }
}
</script>
