<template>
  <div class="w-50">
    <template v-if="dataset && typeof adequacy === 'number' && employeeCount">
      <div class="rounded ml-1 text-white d-flex" style="font-size: 14px" :class="variant">
        <div class="text-center p-1 w-50">
          <i class="fas fa-star d-block" />
          <small class="mx-1">{{ (averageScore / 25).toFixed(2) }}</small>
        </div>
        <div class="text-center p-1 w-50">
          <i class="fas fa-user d-block" />
          <small class="mx-1">{{ employeeCount }}</small>
        </div>
      </div>
    </template>
    <template v-else>
      <div v-b-tooltip title="La compétence n'existe pas" class="text-center">
        <i class="fas fa-ban" />
      </div>
    </template>
  </div>
</template>
<script>
export default {
  props: {
    dataset: {
      type: [Object, undefined],
      required: false,
      default: () => {}
    },
    columnIndex: {
      type: Number,
      required: true,
    },
    skills: {
      type: Array,
      required: true,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
  },
  computed: {
    adequacy() {
      return this.dataset?.[this.skill]?.[this.scenarioKey].adequacy;
    },
    variant() {
      let className = 'bg-secondary';
      switch (this.adequacy) {
        case -1:
          className = 'bg-danger';
          break;
        case 0:
          className = 'bg-warning';
          break;
        case 1:
          className = 'bg-success';
          break;
        default:
          className = 'bg-secondary';
      }
      return className;
    },
    skill() {
      return this.skills[this.columnIndex];
    },
    averageScore() {
      return this.dataset?.[this.skill]?.[this.scenarioKey].averageScore;
    },
    employeeCount() {
      return this.dataset?.[this.skill]?.[this.scenarioKey].employeeCount;
    },
  },
};
</script>
