<template>
  <div>
    <template v-if="objectValue">
      <TreeSelect
        v-if="jobOptions.length"
        v-model="jobSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :disable-branch-nodes="onlyChildren"
        :options="jobOptions"
        :disabled="disabled"
        :placeholder="placeholder"
        value-format="object"
        :limit="limit"
        :limit-text="limitText"
        :disable-fuzzy-matching="true"
        no-children-text=""
      >
        <label
          slot="option-label"
          slot-scope="{ node, labelClassName }"
          :class="labelClassName"
        >
          <span class="align-middle">{{ node.label }}</span>
          <b-badge
            v-if="node.id.includes('Custom')"
            variant="primary"
            class="text-uppercase ml-1"
          >
            Nouveau
          </b-badge>
        </label>
      </TreeSelect>
    </template>
    <template v-else>
      <TreeSelect
        v-if="jobOptions.length"
        v-model="jobSelected"
        :multiple="multi"
        :disable-branch-nodes="onlyChildren"
        :normalizer="normalizer"
        :options="jobOptions"
        :disabled="disabled"
        :placeholder="placeholder"
        :limit="limit"
        :limit-text="limitText"
        :disable-fuzzy-matching="true"
        no-children-text=""
      >
        <label
          slot="option-label"
          slot-scope="{ node, labelClassName }"
          :class="labelClassName"
        >
          <span class="align-middle">{{ node.label }}</span>
          <b-badge
            v-if="node.id.includes('Custom')"
            variant="primary"
            class="text-uppercase ml-1"
          >
            Nouveau
          </b-badge>
        </label>
      </TreeSelect>
    </template>
  </div>
</template>
<script>
export default {
  name: 'JobSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    globalOption: {
      type: Boolean,
      default: false,
    },
    globalSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    objectValue: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Sélectionner un métier',
      // default: () => 'Sélectionner un métier (laisser vide pour tout sélectionner)',
    },
    allowed: {
      type: Array,
      required: false,
      default: () => [],
    },
    onlyChildren: {
      type: Boolean,
      required: false,
      default: () => false
    },
    limit: {
      type: Number,
      required: false,
      default: () => 3
    },
    limitText: {
      type: Function,
      required: false,
      default: () => () => ''
    },
    onlyParents: {
      type: Boolean,
      required: false,
      default: () => false
    },
  },
  data() {
    return {
      normalizer(node) {
        return {
          // eslint-disable-next-line
          id: node._id,
          label: node.names && node.names[0].label,
          comment: node.description,
          custom: node._id ? !!node._id.includes('Custom') : false
        };
      },
      jobSelected: []
    };
  },
  computed: {
    jobOptions() {
      // eslint-disable-next-line
      const jobTree = JSON.parse(JSON.stringify(this.jobTree))
      const jobTreeParents = JSON.parse(JSON.stringify(this.jobTreeParents))
      const options = this.resetTreeOptions(!this.onlyParents ? jobTree : jobTreeParents);
      // eslint-disable-next-line
      if (this.globalOption && options.length && options[0]._id !== 'Global') {
        options.unshift({
          _id: 'Global',
          names: [
            {
              label: 'Global',
            },
          ],
          isDisabled: this.globalSelectedDisabled,
        });
      }
      // Only allow allowed leafs with parents and children, and disable others.
      if (this.allowed.length) {
        // disabled all options and children
        this.loopInTree({ children: options }, (o) => {
          o.isDisabled = true;
        });
        // enable only allowed leaf and children
        const parents = this.getNodesWithParents(this.allowed, options);
        this.loopInTree({ children: options }, (n) => {
          // eslint-disable-next-line no-underscore-dangle
          if (!parents.includes(n._id)) {
            n.isDisabled = true;
          } else {
            n.isDisabled = false;
          }
        });
        this.allowed.forEach((a) => {
          const node = this.findOptionTree(options, a);
          const children = this.getNodeChildren(node);
          children.forEach((c) => {
            c.isDisabled = false;
          });
        });
      }

      if (this.selection) {
        this.selection.forEach((s) => {
          const node = this.findOptionTree(options, s);
          if (node) {
            node.isDisabled = this.selectionDisabled;
          }
        });
      }
      /*
      if (this.allowed.length) {
        this.loopInTree({ children: options }, (n) => {
          // eslint-disable-next-line no-underscore-dangle
          if (!this.allowed.includes(n._id)) {
            n.isDisabled = true;
          }
        });
      }
      */
      return options;
    },
  },
  watch: {
    jobSelected(curr, old) {
      if (curr && curr !== old) {
        setTimeout(() => {
          this.$emit('selected', curr);
        }, 0);
      }
      if (!curr) {
        this.setIfJobSelectedIsNull();
      }
    }
  },
  beforeMount() {
    if (!this.multi) {
      this.jobSelected = null
    }
  },
  mounted() {
    /* if (this.globalSelectedDisabled) {
      const global = {
        _id: 'Global',
        names: [
          {
            label: 'Global',
          },
        ],
        isDisabled: this.globalSelectedDisabled,
      };
      this.jobSelected = [this.objectValue ? global : 'Global'];
    } */
    this.setIfJobSelectedIsNull()
    if (this.selection && this.selection.length) {
      if (this.multi) {
        this.setMultiSelection()
      } else {
        this.setSelection()
      }
    }
  },
  methods: {
    setIfJobSelectedIsNull() {
      if (this.globalSelectedDisabled) {
        const global = {
          _id: 'Global',
          names: [
            {
              label: 'Global',
            },
          ],
          isDisabled: this.globalSelectedDisabled,
        };
        this.jobSelected = [this.objectValue ? global : 'Global'];
      }
    },
    setMultiSelection () {
      const options = [...this.jobSelected, ...this.selection];
      this.jobSelected = Array
        // eslint-disable-next-line no-underscore-dangle
        .from(new Set(options.map((a) => a._id ?? a)))
        // eslint-disable-next-line no-underscore-dangle
        .map((_id) => options.find((a) => a._id === _id || a === _id));
    },
    setSelection () {
      const jobTree = JSON.parse(JSON.stringify(this.jobTree))
      const jobTreeParents = JSON.parse(JSON.stringify(this.jobTreeParents))
      // eslint-disable-next-line
      const jobFound = this.findOptionTree(!this.onlyParents ? jobTree : jobTreeParents, this.selection[0], '_id', 'children');
      this.jobSelected =jobFound
    }
  }
};
</script>
