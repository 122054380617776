<template>
  <div v-if="hasData">
    <div class="row justify-content-between align-items-center mb-4">
      <div class="col-lg-5 mb-2">
        <main-filter-switcher
          :g-filters="gFilters"
          :selected-fn="selectMainFilterItem"
          :multi="false"
          :global-option="true"
          :global-selected-disabled="true"
          :object-value="false"
        />
      </div>
      <div class="col-auto">
        <ScenariosSelector
          :hidden="scenarioMetadata ? [] : ['target']"
          @changed="updateSeniorityGenderSeries"
        />
      </div>
    </div>
    <hr>
    <div>
      <highcharts :options="chartSeniorityGender" />
      <div class="px-5 d-flex justify-content-between">
        <b>Femme</b>
        <b>Homme</b>
      </div>
      <DataLabelsSwitcher />
    </div>
  </div>
  <div v-else>
    No data
  </div>
</template>
<script>
import { formatCostType } from '@/providers/helpers/functions';
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';
import MainAnalysisFilterMixin from '@/mixins/MainAnalysisFilterMixin';
import SetChartSelectsMixin from '@/mixins/SetChartSelectsMixin';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';

const chartTypeTwo = (categories, showDataLabels, scenarioTarget) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'bar',
  },
  title: null,
  legend: { enabled: false },
  accessibility: {
    point: {
      valueDescriptionFormat: '{index}. Age {xDescription}, {value}%.',
    },
  },
  xAxis: [
    {
      categories,
      reversed: false,
      labels: {
        step: 1,
      },
      accessibility: {
        description: 'Age (male)',
      },
    },
    {
      categories,
      opposite: true,
      reversed: false,
      linkedTo: 0,
      labels: {
        step: 1,
      },
      accessibility: {
        description: 'Age (female)',
      },
    },
  ],
  yAxis: {
    title: null,
    gridLineWidth: 0,
    labels: {
      formatter() {
        return `${Math.abs(this.value)}`;
      },
    },
  },
  tooltip: {
    formatter() {
      const formattedValue = formatCostType(this.y, scenarioTarget);
      return `
        <b>${this.series.name}, ancienneté ${this.point.category}</b><br/>
        Population: ${formattedValue}
      `;
    },
  },
  plotOptions: {
    series: {
      stacking: 'normal',
      dataLabels: {
        enabled: showDataLabels,
        formatter() {
          const value = this.y;
          const formattedValue = formatCostType(value, scenarioTarget);
          return formattedValue
        },
      },
    },
  },
  series: [],
});

export default {
  name: 'ChartTypeSeniorityGender',
  components: {
    MainFilterSwitcher,
    ScenariosSelector,
    DataLabelsSwitcher,
  },
  mixins: [MainAnalysisFilterMixin, SetChartSelectsMixin],
  props: {
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      refreshSeriesCallback: 'updateSeniorityGenderSeries',
      seniorityGenderSeries: [],
    };
  },
  computed: {
    hasData() {
      return !!this.dataEffectifs[this.keyGetter];
    },
    chartSeniorityGender() {
      const { categories } = this.dataEffectifs[this.keyGetter];
      const chart = chartTypeTwo(categories, this.showDataLabels, this.scenarioTarget);
      chart.series = this.seniorityGenderSeries;
      return chart;
    },
  },
  methods: {
    updateSeniorityGenderSeries(scenarios) {
      const { data } = this.dataEffectifs[this.keyGetter];
      this.seniorityGenderSeries = [];
      scenarios.forEach((i) => {
        if (data[i].femme) this.seniorityGenderSeries.push(data[i].femme);
        if (data[i].homme) this.seniorityGenderSeries.push(data[i].homme);
      });
    },
  },
};
</script>
