const setup = {
  collab: {
    body: [
      {
        name: 'home',
        path: '',
        classes: '',
        components: [
          {
            name: 'TableScenario',
            classes: '',
          },
        ],
        children: [],
      },
      {
        name: 'new-scenario',
        path: 'scenario/new',
        classes: 'col-12',
        components: [
          {
            name: 'StepsValidate',
            classes: 'col-12',
          },
        ],
        children: [],
      },
      {
        name: 'view-scenario',
        path: 'scenario/view/:id',
        classes: 'col-12',
        components: [
          {
            name: 'ArchivedScenarioStepper',
            classes: 'col-12',
          },
        ],
        children: [],
      },
      {
        name: 'edit-scenario',
        path: 'scenario/edit/:id',
        classes: 'col-12',
        components: [
          {
            name: 'StepsValidate',
            classes: 'col-12',
          },
        ],
        children: [],
      },
      {
        name: 'analysis',
        path: 'analysis',
        classes: 'col-12',
        components: [
          {
            name: 'GapAnalysis',
            classes: 'col-12',
          },
        ],
        children: [],
      },
      {
        name: 'analysisDetail',
        path: 'analysis/:id?',
        classes: 'col-12',
        components: [
          {
            name: 'AnalysisDetail',
            classes: 'col-12',
          },
        ],
        children: [],
      },
      {
        name: 'profile',
        path: 'user/profile',
        classes: 'col-12',
        components: [
          {
            name: 'UserProfile',
            classes: 'col-12',
          },
        ],
      },
      /*
      {
        name: 'pag2',
        path: 'pag2',
        classes: 'col-12',
        components: [
          {
            name: 'PageDetail',
            classes: 'col-12',
          },
        ],
        children: [],
      },
      // demo all
      {
        name: 'Demo all',
        path: 'employeeDetail/:id',
        classes: 'col-lg-12',
        components: [
          {
            name: 'menuSublink',
            classes: 'col-lg-12',
          },
        ],
        children: [
          {
            name: 'employeeIdList',
            path: 'list',
            classes: 'col-lg-12',
            components: [
              {
                name: 'tableCustom',
                classes: 'col-lg-9',
              },
              {
                name: 'competencyChart',
                classes: 'col-lg-3',
              },
            ],
            children: [],
          },
          {
            name: 'employeeIdGallery',
            path: 'gallery',
            classes: 'col-lg-12',
            components: [
              {
                name: 'slider',
                classes: 'col-lg-6',
              },
            ],
            children: [],
          },
        ],
      },
      */
    ],
  },
};

export default setup;
