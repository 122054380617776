<template>
  <div v-bind="$attrs">
    <b-progress :max="10" height="20px" class="rounded-50">
      <b-progress-bar
        v-for="(item, idx) in formatScoresOrdered"
        :id="`tooltip-${$attrs.id}-${idx}`"
        :key="idx"
        style="font-size: 11px; border: solid 0.1px white"
        :value="item.percent"
        :style="{ background: `${getColor(item)}` }"
      >
        <div>{{ getPercent(item) }}</div>
      </b-progress-bar>
    </b-progress>
    <div>
      <b-tooltip
        v-for="(item, idx) in formatScoresOrdered"
        :key="idx"
        :target="`tooltip-${$attrs.id}-${idx}`"
      >
        <span>{{ countStarsSelected(item.score).label }}</span>
        <div class="my-1" v-html="renderStars(item.score)" />
        <div class="mb-1">
          <span>{{ item.percent }}%</span>
          <span v-if="last" class="mx-2">|</span>
          <span v-if="last">{{ item.count }}</span>
          <i v-if="last" class="fas fa-user ml-1" />
        </div>
      </b-tooltip>
    </div>
  </div>
</template>

<script>
const pValues = [{ value: 1 }, { value: 3 }, { value: 4 }, { value: 2 }];

export default {
  name: 'ProgressTooltip',
  props: {
    scores: {
      type: Array,
      default() {
        return [];
      },
    },
    last: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      pValues,
    };
  },
  computed: {
    formatScoresOrdered() {
      const scoresOrdered = this.formatScores;
      scoresOrdered.sort((a, b) => a.score - b.score);
      return scoresOrdered;
    },
    formatScores() {
      let totalScore = 0;
      // eslint-disable-next-line
      for (const score of this.scores) {
        totalScore += score.count;
      }
      return this.sortByKey(
        this.scores.map((s) => {
          const percent = (s.count * 100) / totalScore;
          return {
            ...s,
            score: Number(s.score),
            percent: percent.toFixed(2),
          };
        }),
        'score'
      );
    },
  },
  methods: {
    getPercent(item) {
      if (item.percent > 20) {
        return `${Math.round(item.percent)}%`;
      }
      return '';
    },
    getColor(item) {
      let opacity = '';
      switch (Math.round(item.score)) {
        case 25:
          opacity = '0.25';
          break;
        case 50:
          opacity = '0.50';
          break;
        case 75:
          opacity = '0.75';
          break;
        case 100:
          opacity = '1';
          break;
        default:
          opacity = '0.15';
      }
      return `rgba(88,18,116,${opacity})`;
    },
    countStarsSelected(score) {
      const total = 100;
      const countStars = 4;
      const starsSelectd = (score / total) * countStars;
      let label = 'Niveau Novice';
      if (starsSelectd === 2) label = 'Niveau Intermédiaire';
      if (starsSelectd === 3) label = 'Niveau Avancé';
      if (starsSelectd === 4) label = 'Niveau Expert';

      return {
        countStars,
        starsSelectd,
        label,
      };
    },
    renderStars(score) {
      const { starsSelectd, countStars } = this.countStarsSelected(score);
      const selected = '<i class="fas fa-star ml-1 text-primary"></i>';
      const unSelected = '<i class="fas fa-star ml-1"></i>';
      let stars = '';
      if (starsSelectd === 1) {
        stars = selected;
        // eslint-disable-next-line
        for (let i = 0; i < countStars - starsSelectd; i++) {
          // eslint-disable-next-line
          stars = stars + unSelected;
        }
      } else if (starsSelectd === 0) {
        // eslint-disable-next-line
        for (let i = 0; i < countStars; i++) {
          // eslint-disable-next-line
          stars = stars + unSelected;
        }
      } else {
        // eslint-disable-next-line
        for (let i = 0; i < starsSelectd; i++) {
          // eslint-disable-next-line
          stars = stars + selected;
        }
        // eslint-disable-next-line
        for (let i = 0; i < countStars - starsSelectd; i++) {
          // eslint-disable-next-line
          stars = stars + unSelected;
        }
      }
      return stars;
    },
  },
};
</script>
