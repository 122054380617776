// eslint-disable-next-line
export const lightUnitStruct = (item) => {
  delete item.v;
  delete item.createdAt;
  delete item.updatedAt;
  delete item.org;
  // delete item.code;
  delete item.parentCode;
  delete item.costCentres;
  delete item.stats;
  delete item.sites;
  delete item.closedAt;
  delete item.establishedAt;
  delete item.createdFrom;
  // delete item.active;
  // delete item.name;
  // delete item.parent;
  return item;
};

// eslint-disable-next-line
export const UnitRecursive = (parentUnit, units) => {
  const children = [];
  // eslint-disable-next-line
  for (const u of units) {
    const uParentRef = u.parent;
    // eslint-disable-next-line
    if (uParentRef && uParentRef['#'] === parentUnit._id) {
      // eslint-disable-next-line
      const response =  UnitRecursive(u, units);
      if (response.length) {
        u.children = response;
      }
      children.push(lightUnitStruct(u));
    }
  }
  return children;
};
