<template>
  <div>
    <AppLoader v-if="loading" />
    <router-view v-else />
    <FullLoader />
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import Cookies from 'js-cookie';

export default {
  name: 'App',
  data() {
    return {
      loading: false,
    };
  },
  async created() {
    try {
      this.loading = true;
      const token = Cookies.get('gpec');
      if (token) {
        this.setToken(token);
        await Promise.all([
          this.loadBasicData(),
          this.getConfiguration()
        ]);
      }
      this.loading = false;
    } catch (e) {
      this.loading = false;
      console.error(e);
    }
  },
  methods: {
    ...mapActions('auth', {
      setToken: 'setAuthToken',
      loadBasicData: 'basic/loadBasicData',
      getConfiguration: 'getConfiguration',
    })
  },
};
</script>

<style>
.vue-treeselect__menu {
  color: black;
}
</style>
