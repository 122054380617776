import {
  catAgeDefault,
  catContractDefault,
  catGenderDefault,
  catInternalExternalDefault,
  catManagerDefault,
  catSeniorityDefault,
  catSeniorityGenderDefault,
} from '@/providers/settings/constants/effectifs';

const state = {
  ageSelect: 'Global',
  contratSelects: ['Global'],
  effectifsScenarioTarget: 'effective',
  genderSelects: ['Global'],
  internalExternalSelects: ['Global'], //new state
  managerSelects: ['Global'],
  seniorityGenderSelects: ['Global'],
  senioritySelects: ['Global']
};

const getters = {
  // eslint-disable-next-line
  graphDataGender({ genderSelects }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    const allGender = rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.gender;
    if (!allGender) return null;
    const entities = Object.keys(allGender.entityValueMap);

    const dataCatType = ({ values }, name, categories) => {
      const obj = {};
      categories.forEach((cat) => {
        const fd = values.find((i) => i.label === cat);
        obj[cat.toLowerCase()] = fd && fd[name] ? fd[name] : 0;
      });
      return obj;
    };
    const dataCat = (stack) => ({
      femme: { name: 'Femme', stack, data: [] },
      homme: { name: 'Homme', stack, data: [] },
    });
    const dataPushCat = (obj, data, color) => {
      obj.femme.data.push({ y: data.femme, color: color.replace('1)', '0.5)') });
      obj.homme.data.push({ y: data.homme, color });
      return obj;
    };

    const { gaTypes } = analysis;
    const categories = allGender.entityValueMap['Global'].values[0].values
      .filter((i) => i.label)
      .map((i) => i.label);

    let current = dataCat('current');
    let predicted = dataCat('predicted');
    let target = dataCat('target');
    let optimal = dataCat('optimal');
    genderSelects.forEach((i) => {
      const cat = allGender.entityValueMap[i] || catGenderDefault(i);
      const catVal = cat.values[0];
      const catCurrent = dataCatType(catVal, 'current', categories);
      const catPredicted = dataCatType(catVal, 'predicted', categories);
      const catTarget = dataCatType(catVal, 'target', categories);
      const catOptimal = dataCatType(catVal, 'optimal', categories);

      current = dataPushCat(current, catCurrent, gaTypes.current.color);
      predicted = dataPushCat(predicted, catPredicted, gaTypes.predicted.color);
      target = dataPushCat(target, catTarget, gaTypes.target.color);
      optimal = dataPushCat(optimal, catOptimal, gaTypes.optimal.color);
    });
    const data = {
      current,
      predicted,
      target,
      optimal,
    };
    return { entities, categories, data };
  },
  // eslint-disable-next-line
  graphDataAge({ ageSelect }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    const allAge = rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.age;
    if (!allAge) return null;
    const entities = Object.keys(allAge.entityValueMap);

    const dataCat = (stack, name, color, { femme, homme }) => ({
      femme: {
        name,
        stack,
        color,
        data: femme,
      },
      homme: {
        name,
        stack,
        color,
        data: homme,
      },
    });
    const dataCatType = ({ values }, name, genderCategories) => {
      const obj = {};
      genderCategories.forEach((cat, idx) => {
        const fd = values.map((val) => {
          const f = val.values.find((i) => i.label === cat);
          return f && f[name] ? (idx === 0 ? 1 : -1) * f[name] : 0;
        });
        obj[cat.toLowerCase()] = fd;
      });
      return obj;
    };

    const { gaTypes } = analysis;

    const elt = Array.isArray(ageSelect) ? ageSelect[0] : ageSelect;
    const cat = allAge.entityValueMap[elt] || catAgeDefault(elt);
    const categories = cat.values.map((l) => l.category);
    const genderCategories = ['Homme', 'Femme'];

    const dataCurrent = dataCatType(cat, 'current', genderCategories);
    const dataPredicted = dataCatType(cat, 'predicted', genderCategories);
    const dataTarget = dataCatType(cat, 'target', genderCategories);
    const dataOptimal = dataCatType(cat, 'optimal', genderCategories);

    const current = dataCat('current', gaTypes.current.name, gaTypes.current.color, dataCurrent);
    const predicted = dataCat(
      'predicted',
      gaTypes.predicted.name,
      gaTypes.predicted.color,
      dataPredicted
    );
    const target = dataCat('target', gaTypes.target.name, gaTypes.target.color, dataTarget);
    const optimal = dataCat('optimal', gaTypes.optimal.name, gaTypes.optimal.color, dataOptimal);

    const data = {
      current,
      predicted,
      target,
      optimal,
    };
    return { entities, categories, data };
  },
  // eslint-disable-next-line
  graphDataContrat({ contratSelects }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    const allContrat = rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.contract;
    if (!allContrat) return null;
    const entities = Object.keys(allContrat.entityValueMap);

    const dataCat = (stack, categories) => {
      const obj = {};
      categories.forEach((cat) => {
        if (cat) {
          obj[cat] = { name: cat, stack, data: [] };
        }
      });
      return obj;
    };

    const dataCatType = (name, values, categories, color) => {
      const obj = {};
      const m = 1 / categories.length;
      categories.forEach((cat, idx) => {
        const fd = values.find((i) => i.label === cat);
        const mi = m * (idx + 1);
        obj[cat] = {
          val: fd && fd[name] ? fd[name] : 0,
          color: color.replace('1)', `${mi.toFixed(2)})`),
        };
      });
      return obj;
    };

    const dataPushCat = (obj, data) => {
      Object.keys(obj).forEach((cat) => {
        obj[cat].data.push({
          y: data[cat] && data[cat].val ? data[cat].val : 0,
          color: data[cat].color,
        });
      });
      return obj;
    };

    const { gaTypes } = analysis;

    const categories = allContrat.entityValueMap['Global'].values[0].values
      .filter((i) => i.label)
      .map((i) => i.label);

    let current = dataCat('current', categories);
    let predicted = dataCat('predicted', categories);
    let target = dataCat('target', categories);
    let optimal = dataCat('optimal', categories);

    contratSelects.forEach((i) => {
      const cat = allContrat.entityValueMap[i] || catContractDefault(i);
      const mapObj = cat.values[0].values;

      const dataCurrent = dataCatType('current', mapObj, categories, gaTypes.current.color);
      const dataPredicted = dataCatType('predicted', mapObj, categories, gaTypes.predicted.color);
      const dataTarget = dataCatType('target', mapObj, categories, gaTypes.target.color);
      const dataOptimal = dataCatType('optimal', mapObj, categories, gaTypes.optimal.color);

      current = dataPushCat(current, dataCurrent);
      predicted = dataPushCat(predicted, dataPredicted);
      target = dataPushCat(target, dataTarget);
      optimal = dataPushCat(optimal, dataOptimal);
    });

    const data = {
      current,
      predicted,
      target,
      optimal,
    };
    return { entities, categories, data };
  },
  // eslint-disable-next-line
  graphDataManager({ managerSelects }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    const allManager = rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.manager;
    if (!allManager) return null;
    const entities = Object.keys(allManager.entityValueMap);

    const dataCatType = ({ values }, name, categories) => {
      const obj = {};
      categories.forEach((cat) => {
        const fd = values.find((i) => i.label === cat);
        obj[cat.toLowerCase()] = fd && fd[name] ? fd[name] : 0;
      });
      return obj;
    };
    const dataCat = (stack) => ({
      'manager': { name: 'Manager', stack, data: [] },
      'non manager': { name: 'Non Manager', stack, data: [] },
    });
    const dataPushCat = (obj, data, color) => {
      obj['manager'].data.push({ y: data['manager'], color: color.replace('1)', '0.5)') });
      obj['non manager'].data.push({ y: data['non manager'], color });
      return obj;
    };

    const { gaTypes } = analysis;

    const categories = allManager.entityValueMap['Global'].values[0].values
      .filter((i) => i.label)
      .map((i) => i.label);


    let current = dataCat('current');
    let predicted = dataCat('predicted');
    let target = dataCat('target');
    let optimal = dataCat('optimal');

    managerSelects.forEach((i) => {
      const cat = allManager.entityValueMap[i] || catManagerDefault(i);
      const catVal = cat.values[0];
      const catCurrent = dataCatType(catVal, 'current', categories);
      const catPredicted = dataCatType(catVal, 'predicted', categories);
      const catTarget = dataCatType(catVal, 'target', categories);
      const catOptimal = dataCatType(catVal, 'optimal', categories);

      current = dataPushCat(current, catCurrent, gaTypes.current.color);
      predicted = dataPushCat(predicted, catPredicted, gaTypes.predicted.color);
      target = dataPushCat(target, catTarget, gaTypes.target.color);
      optimal = dataPushCat(optimal, catOptimal, gaTypes.optimal.color);
    });
    const data = {
      current,
      predicted,
      target,
      optimal,
    };
    return { entities, categories, data };
  },
  // eslint-disable-next-line
  graphDataSeniority({ senioritySelects }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    // eslint-disable-next-line max-len,vue/max-len
    const allSeniority = rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.seniority;
    if (!allSeniority) return null;
    const entities = Object.keys(allSeniority.entityValueMap);

    const dataCat = (stack, categories) => {
      const obj = {};
      categories.forEach((cat) => {
        if (cat) {
          obj[cat] = { name: cat, stack, data: [] };
        }
      });
      return obj;
    };

    const dataCatType = (name, values, categories, color) => {
      const obj = {};
      const m = 1 / categories.length;
      categories.forEach((cat, idx) => {
        const fd = values.find((i) => i.label === cat);
        const mi = m * (idx + 1);
        obj[cat] = {
          val: fd && fd[name] ? fd[name] : 0,
          color: color.replace('1)', `${mi.toFixed(2)})`),
        };
      });
      return obj;
    };

    const dataPushCat = (obj, data) => {
      Object.keys(obj).forEach((cat) => {
        obj[cat].data.push({
          y: data[cat] && data[cat].val ? data[cat].val : 0,
          color: data[cat].color,
        });
      });
      return obj;
    };

    const { gaTypes } = analysis;

    const categories = allSeniority.entityValueMap['Global'].values[0].values
      .filter((i) => i.label)
      .map((i) => i.label);

    let current = dataCat('current', categories);
    let predicted = dataCat('predicted', categories);
    let target = dataCat('target', categories);
    let optimal = dataCat('optimal', categories);

    senioritySelects.forEach((i) => {
      const cat = allSeniority.entityValueMap[i] || catSeniorityDefault(i);
      const mapObj = cat.values[0].values;

      const dataCurrent = dataCatType('current', mapObj, categories, gaTypes.current.color);
      const dataPredicted = dataCatType('predicted', mapObj, categories, gaTypes.predicted.color);
      const dataTarget = dataCatType('target', mapObj, categories, gaTypes.target.color);
      const dataOptimal = dataCatType('optimal', mapObj, categories, gaTypes.optimal.color);

      current = dataPushCat(current, dataCurrent);
      predicted = dataPushCat(predicted, dataPredicted);
      target = dataPushCat(target, dataTarget);
      optimal = dataPushCat(optimal, dataOptimal);
    });

    const data = {
      current,
      predicted,
      target,
      optimal,
    };
    return { entities, categories, data };
  },
  // eslint-disable-next-line
  graphDataSeniorityGender({ seniorityGenderSelects }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    const allSeniorityGender =
      rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.seniorityGender;
    if (!allSeniorityGender) return null;
    const entities = Object.keys(allSeniorityGender.entityValueMap);

    const dataCat = (stack, name, color, { femme, homme }) => ({
      femme: {
        name,
        stack,
        color,
        data: femme,
      },
      homme: {
        name,
        stack,
        color,
        data: homme,
      },
    });
    const dataCatType = ({ values }, name, genderCategories) => {
      const obj = {};
      genderCategories.forEach((cat, idx) => {
        const fd = values.map((val) => {
          const f = val.values.find((i) => i.label === cat);
          return f && f[name] ? (idx === 0 ? 1 : -1) * f[name] : 0;
        });
        obj[cat.toLowerCase()] = fd;
      });
      return obj;
    };

    const { gaTypes } = analysis;

    const cat = allSeniorityGender.entityValueMap[seniorityGenderSelects];
    catSeniorityGenderDefault(seniorityGenderSelects);
    const categories = cat.values.map((l) => l.category);
    const genderCategories = ['Homme', 'Femme'];

    const dataCurrent = dataCatType(cat, 'current', genderCategories);
    const dataPredicted = dataCatType(cat, 'predicted', genderCategories);
    const dataTarget = dataCatType(cat, 'target', genderCategories);
    const dataOptimal = dataCatType(cat, 'optimal', genderCategories);

    const current = dataCat('current', gaTypes.current.name, gaTypes.current.color, dataCurrent);
    const predicted = dataCat(
      'predicted',
      gaTypes.predicted.name,
      gaTypes.predicted.color,
      dataPredicted
    );
    const target = dataCat('target', gaTypes.target.name, gaTypes.target.color, dataTarget);
    const optimal = dataCat('optimal', gaTypes.optimal.name, gaTypes.optimal.color, dataOptimal);

    const data = {
      current,
      predicted,
      target,
      optimal,
    };
    return { entities, categories, data };
  },
  // eslint-disable-next-line
  graphDataInternalExternal({ internalExternalSelects }, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    const allExternal = rootGetters['analysis/yearStatsAll']?.graphData?.[type]?.contents?.external;
    if (!allExternal) return null;
    const entities = Object.keys(allExternal.entityValueMap);

    const dataCatType = ({ values }, name, categories) => {
      const obj = {};
      categories.forEach((cat) => {
        const fd = values.find((i) => i.label === cat);
        obj[cat.toLowerCase()] = fd && fd[name] ? fd[name] : 0;
      });
      return obj;
    };
    const dataCat = (stack) => ({
      interne: { name: 'Internes', stack, data: [] },
      externe: { name: 'Externes', stack, data: [] },
    });
    const dataPushCat = (obj, data, color) => {
      obj.interne.data.push({ y: data.interne, color: color.replace('1)', '0.5)') });
      obj.externe.data.push({ y: data.externe, color });
      return obj;
    };

    const { gaTypes } = analysis;
    const categories = allExternal.entityValueMap['Global'].values[0].values
      .filter((i) => i.label)
      .map((i) => i.label);

    let current = dataCat('current');
    let predicted = dataCat('predicted');
    let target = dataCat('target');
    let optimal = dataCat('optimal');
    internalExternalSelects.forEach((i) => {
      const cat = allExternal.entityValueMap[i] || catInternalExternalDefault(i);
      const catVal = cat.values[0];
      const catCurrent = dataCatType(catVal, 'current', categories);
      const catPredicted = dataCatType(catVal, 'predicted', categories);
      const catTarget = dataCatType(catVal, 'target', categories);
      const catOptimal = dataCatType(catVal, 'optimal', categories);

      current = dataPushCat(current, catCurrent, gaTypes.current.color);
      predicted = dataPushCat(predicted, catPredicted, gaTypes.predicted.color);
      optimal = dataPushCat(optimal, catOptimal, gaTypes.optimal.color);
      target = dataPushCat(target, catTarget, gaTypes.target.color);
    });
    const data = {
      current,
      predicted,
      optimal,
      target
    };
    return { entities, categories, data };
  },
  getEffectifsScenarioTarget({ effectifsScenarioTarget }) {
    return effectifsScenarioTarget
  }
};

const mutations = {
  // eslint-disable-next-line
  setGenderSelects(state, val) {
    state.genderSelects = val;
  },
  // eslint-disable-next-line
  setAgeSelect(state, val) {
    state.ageSelect = val;
  },
  // eslint-disable-next-line
  setContratSelects(state, val) {
    state.contratSelects = val;
  },
  // eslint-disable-next-line
  setManagerSelects(state, val) {
    state.managerSelects = val;
  },
  // eslint-disable-next-line
  setSenioritySelects(state, val) {
    state.senioritySelects = val;
  },
  // eslint-disable-next-line
  setSeniorityGenderSelects(state, val) {
    state.seniorityGenderSelects = val;
  },
  // eslint-disable-next-line
  setInternalExternalSelects(state, val) {
    state.internalExternalSelects = val;
  },
  setEffectifsScenarioTarget(state, val) {
    state.effectifsScenarioTarget = val;
  },
};

const actions = {
  setGenderSelects({ commit }, val) {
    commit('setGenderSelects', val);
  },
  setAgeSelect({ commit }, val) {
    commit('setAgeSelect', val);
  },
  setContratSelects({ commit }, val) {
    commit('setContratSelects', val);
  },
  setManagerSelects({ commit }, val) {
    commit('setManagerSelects', val);
  },
  setSenioritySelects({ commit }, val) {
    commit('setSenioritySelects', val);
  },
  setSeniorityGenderSelects({ commit }, val) {
    commit('setSeniorityGenderSelects', val);
  },
  //New setInternalExternalSelects
  setInternalExternalSelects({ commit }, val) {
    commit('setInternalExternalSelects', val);
  },
  setEffectifsScenarioTarget({ commit }, val) {
    commit('setEffectifsScenarioTarget', val);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
