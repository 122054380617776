<template>
  <div v-if="item.isEnabled" :key="rerenderKey">
    <div
      :key="sum"
      v-b-toggle="item.children && item.children.length ? `collapse-${item.entity}${index}` : ''"
      class="row align-items-center text-center my-0 py-1 px-0"
    >
      <div :class="[showExternalCols ? 'col-1' : 'col-2']">
        <div class="d-flex">
          <div class="ml-2">
            <span
            class="badge badge-primary-light
            badge-pill py-2 d-flex border font-12"
            :class="itemEditClass"
          >
              <div
                :class="[showExternalCols ?
                  'ellipsis-column' : 'ellipsis-column ellipsis-column--expanded external-width']"
                v-b-popover.hover.topright="`${item.names[0].label} (${sum})`"
              >
                <span v-if="item.names"> {{ item.names[0].label }} ({{ sum }}) </span>
              </div>
            <i v-if="item.children && item.children.length" class="fal fa-search ml-2" />
            <b-link
              class="toggle-edit"
              v-else-if="editable && item.names[0].label.toLowerCase() !== 'global'"
              @click="toggleEdit"
            >
              <i class="fal fa-edit ml-2" />
            </b-link>
            </span>
            <kbd v-if="$debug" class="small">
              {{ item.entity }}
            </kbd>
          </div>
        </div>
      </div>
      <div v-if="currentItem" class="col-1 font-13">
        <div class="detail__item">
          {{ formatValueScenarioType(currentItem.currentEmployeeCount) }}
        </div>
      </div>
      <div v-if="currentItem" :class="departuresCol" class="font-13">
        <div class="row text-secondary flex-nowrap">
          <div class="detail__item">
            <TableCell
              type="negative"
              :editing="false"
              :value="formatValueScenarioType(totalDeparture)"
              :custom-value="false"
              :variation="retirementEdit + otherDepartureEdit"
              :show-variation="canShowVariation"
            />
          </div>
          <template v-if="activeDepartureDetails">
            <div class="detail__item">
              <TableCell
                type="negative"
                :editing="editing"
                :limit="limit('retirement')"
                :custom-value="getCustomValue('retirement')"
                :value="formatValueScenarioType(currentItem.departureDetails
                  && currentItem.departureDetails.retirement)"
                :variation="retirementEdit"
                :show-variation="canShowVariation"
                @changed="setValue('departureDetails', 'retirement', $event)"
              />
            </div>
            <div class="detail__item">
              <TableCell
                type="negative"
                :editing="editing"
                :limit="limit('otherDeparture')"
                :custom-value="getCustomValue('otherDeparture')"
                :value="formatValueScenarioType(currentItem.departureDetails
                  && currentItem.departureDetails.others)"
                :variation="otherDepartureEdit"
                :show-variation="canShowVariation"
                @changed="setValue('departureDetails', 'others', $event)"
              />
            </div>
          </template>
        </div>
      </div>
      <div v-if="currentItem" :class="mobilitiesCol" class="font-13">
        <div class="row text-dark flex-nowrap">
          <div class="detail__item">
            <TableCell
              type="all"
              :editing="false"
              :value="formatValueScenarioType(netMobility)"
              :custom-value="false"
              :variation="outMobilityEdit + inMobilityEdit"
              :show-variation="canShowVariation"
            />
          </div>
          <template v-if="activeMobilityDetails">
            <div class="detail__item">
              <TableCell
                type="negative"
                :editing="editing"
                :limit="limit('outMobility')"
                :custom-value="getCustomValue('outMobility')"
                :value="formatValueScenarioType(currentItem.mobilityDetails
                  && currentItem.mobilityDetails.out)"
                :variation="outMobilityEdit"
                :show-variation="canShowVariation"
                @changed="setValue('mobilityDetails', 'out', $event)"
              />
            </div>
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="editing"
                :custom-value="getCustomValue('inMobility')"
                :value="formatValueScenarioType(currentItem.mobilityDetails
                  && currentItem.mobilityDetails.in)"
                :variation="inMobilityEdit"
                :show-variation="canShowVariation"
                @changed="setValue('mobilityDetails', 'in', $event)"
              />
            </div>
          </template>
        </div>
      </div>
      <div v-if="currentItem" :class="entriesCol" class="font-13">
        <div class="row text-info flex-nowrap">
          <div class="detail__item">
            <TableCell
              type="positive"
              :editing="false"
              :value="formatValueScenarioType(totalEntry)"
              :custom-value="false"
              :variation="recruitmentEdit"
              :show-variation="canShowVariation"
            />
          </div>
          <template v-if="activeEntryDetails">
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="editing"
                :custom-value="getCustomValue('recruitment')"
                :value="formatValueScenarioType(currentItem.entryDetails
                  && currentItem.entryDetails.recruitement)"
                :variation="recruitmentEdit"
                :show-variation="canShowVariation"
                @changed="setValue('entryDetails', 'recruitement', $event)"
              />
            </div>
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="false"
                :value="formatValueScenarioType(currentItem.entryDetails
                  && currentItem.entryDetails.others)"
                :show-variation="canShowVariation"
                @changed="setValue('entryDetails', 'others', $event)"
              />
            </div>
          </template>
        </div>
      </div>
      <div v-if="currentItem" :class="[showExternalCols ? 'col-1' : 'col-2']">
        <div class="row font-13 flex-nowrap">
          <div class="align-content-center col-6 d-flex justify-content-center text-success">
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="false"
                :value="formatValueScenarioType(budgetedEmployeeCount)"
                :custom-value="false"
                :variation="budgetedEmployeeCountEdit"
                :show-variation="canShowVariation"
              />
            </div>
          </div>
          <div
            class="align-content-center col-6 d-flex flex-column justify-content-center text-dark"
          >
            {{ formatValueScenarioType(delta) }}
          </div>
        </div>
      </div>

      <!-- New column called Externes  -->
      <div
        v-if="currentItem"
        v-show="showExternalCols"
        :class="externalCol"
        class="font-13"
      >
        <div class="row text-info flex-nowrap">
          <div class="detail__item">
            <TableCell
              type="positive"
              :editing="editing"
              :custom-value="getCustomValue('budgetedExternalCount')"
              :value="formatValueScenarioType(currentItem.budgetedExternalCount)"
              :variation="budgetedExternalCountEdit"
              :show-variation="canShowVariation"
              @changed="setValue('budgetedExternalCount', null , $event)"
            />
          </div>
          <template v-if="activeExternalDetails">
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="false"
                :custom-value="getCustomValue('currentExternalCount')"
                :value="formatValueScenarioType(currentItem.currentExternalCount)"
                :variation="currentExternalCountEdit"
                :show-variation="canShowVariation"
                @changed="setValue('currentExternalCount', null , $event)"
              />
            </div>
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="false"
                :custom-value="getCustomValue('externalDelta')"
                :value="formatValueScenarioType(externalDelta)"
                :variation="budgetedExternalCountEdit - currentExternalCountEdit"
                :show-variation="canShowVariation"
                @changed="setValue('externalDelta', null , $event)"
              />
            </div>
          </template>
        </div>
      </div>

      <div v-if="currentItem" class="col-1">
        <div class="row font-13 flex-nowrap">
          <!-- Effectif projeté new column  -->
          <div
            v-show="showExternalCols"
            class="align-content-center col-5 text-success"
          >
            <div class="detail__item">
              <TableCell
                type="positive"
                :editing="false"
                :custom-value="getCustomValue('budgetedAllCount')"
                :value="formatValueScenarioType(budgetedAllCount)"
                :variation="budgetedEmployeeCountEdit + budgetedExternalCountEdit"
                :show-variation="canShowVariation"
              />
            </div>
          </div>
          <div
            class="align-content-center col-7 d-flex flex-column justify-content-center text-dark"
          >
            <span v-if="evolution === -1" class="text-danger">
              <i class="fas fa-2x fa-arrow-square-down" />
            </span>
            <span v-if="evolution === 0" class="text-warning">
              <i class="fas fa-2x fa-arrow-square-right" />
            </span>
            <span v-if="evolution === 1" class="text-success">
              <i class="fas fa-2x fa-arrow-square-up" />
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { sumEdit, hasChanged } from '@/providers/helpers/hypothesis';
import { formatCostType } from '@/providers/helpers/functions';
import TableCell from '@/components/analysis-tabs/hypothesis/TableCell.vue';
import HypothesisColSize from './colSize';

export default {
  name: 'TableTreeHeadViewer',
  components: { TableCell },
  mixins: [HypothesisColSize],
  props: {
    typeScenarioTarget: {
      type: String,
      required: false,
      default: 'effective'
    },
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
    activeDepartureDetails: {
      type: Boolean,
      default: false,
    },
    activeEntryDetails: {
      type: Boolean,
      default: false,
    },
    activeMobilityDetails: {
      type: Boolean,
      default: false,
    },
    activeExternalDetails: {
      type: Boolean,
      default: false,
    },
    showExternalCols: {
      //new active for External column
      type: Boolean,
      default: false,
    },
    depth: {
      type: Number,
      default: 0,
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    editable: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    previousYearEdits: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      editing: false,
      sumEdit,
      sum: 0,
      rerenderKey: 0,
      formatCostType,
    };
  },
  computed: {
    canShowVariation() {
      return (
        this.scenarioKey === 'target' &&
        this.currentItem?.userEdit &&
        hasChanged(this.currentItem.userEdit)
      );
    },
    itemEditClass() {
      // eslint-disable-next-line max-len
      if (this.canShowVariation) {
        if (this.sum > 0) {
          return 'border-success';
        }
        if (this.sum === 0) {
          return 'border-warning';
        }
        if (this.sum < 0) {
          return 'border-danger';
        }
      }
      return '';
    },
    budgetedEmployeeCountEdit() {
      return (
        this.recruitmentEdit +
        this.otherDepartureEdit +
        this.retirementEdit +
        (this.currentItem.entryDetails?.others || 0) +
        this.outMobilityEdit +
        this.inMobilityEdit
      );
    },
    retirementEdit() {
      return this.getItemCustomValue('retirement') || 0;
    },
    otherDepartureEdit() {
      return this.getItemCustomValue('otherDeparture') || 0;
    },
    inMobilityEdit() {
      return this.getItemCustomValue('inMobility') || 0;
    },
    outMobilityEdit() {
      return this.getItemCustomValue('outMobility') || 0;
    },
    recruitmentEdit() {
      return this.getItemCustomValue('recruitment') || 0;
    },
    budgetedExternalCountEdit() {
      return this.getItemCustomValue('budgetedExternalCount') || 0;
    },
    currentExternalCountEdit() {
      return this.getItemCustomValue('currentExternalCount') || 0;
    },
    externalDeltaEdit() {
      return this.getItemCustomValue('externalDelta') || 0;
    },
    budgetedAllCountEdit() {
      return this.getItemCustomValue('budgetedAllCount') || 0;
    },
    externalDelta() {
      const item = this.currentItem;
      let budgetedExternalCount = 0;
      let currentExternalCount = 0;
      if (this.item.entity === 'Global') {
        budgetedExternalCount = item && item.budgetedExternalCount;
        currentExternalCount = item && item.currentExternalCount;
      } else {
        // eslint-disable-next-line max-len
        budgetedExternalCount = item?.budgetedExternalCount || 0
        // eslint-disable-next-line max-len
        currentExternalCount = item?.currentExternalCount || 0
      }
      return budgetedExternalCount - currentExternalCount;
    },
    budgetedAllCount() {
      const item = this.currentItem;
      let budgetedEmployeeCount = 0;
      let budgetedExternalCount = 0;
      if (this.item.entity === 'Global') {
        budgetedEmployeeCount = item && item.budgetedEmployeeCount;
        budgetedExternalCount = item && item.budgetedExternalCount;
      } else {
        // eslint-disable-next-line max-len
        budgetedEmployeeCount = item?.budgetedEmployeeCount || 0
        // eslint-disable-next-line max-len
        budgetedExternalCount = item?.budgetedExternalCount || 0
      }
      return budgetedEmployeeCount + budgetedExternalCount;
    },
    netMobility() {
      const item = this.currentItem?.mobilityDetails;
      let inMobility = 0;
      let outMobility = 0;
      if (this.item.entity === 'Global') {
        inMobility = item && item.in;
        outMobility = item && item.out;
      } else {
        inMobility = item?.in || 0;
        outMobility = item?.out || 0;
      }
      return inMobility + outMobility;
    },
    totalEntry() {
      const item = this.currentItem?.entryDetails;
      let recruitement = 0;
      let others = 0;
      if (this.item.entity === 'Global') {
        recruitement = item && item.recruitement;
        others = item && item.others;
      } else {
        recruitement = item?.recruitement || 0;
        others = item && item.others;
      }
      return recruitement + others;
    },
    totalDeparture() {
      const item = this.currentItem?.departureDetails;
      let retirement = 0;
      let others = 0;
      if (this.item.entity === 'Global') {
        retirement = item && item.retirement;
        others = item && item.others;
      } else {
        retirement = item?.retirement || 0;
        others = item?.others || 0;
      }
      return retirement + others;
    },
    budgetedEmployeeCount() {
      const current = this.currentItem?.currentEmployeeCount || 0;
      return current + this.totalDeparture + this.netMobility + this.totalEntry;
    },
    delta() {
      const current = this.currentItem?.currentEmployeeCount || 0;
      return this.budgetedEmployeeCount - current;
    },
    evolution() {
      return Math.sign(this.delta);
    },
    currentItem() {
      return this.item?.[this.scenarioKey] || this.getDefaultItem(this.item.entity);
    },
  },
  watch: {
    item: {
      handler() {
        this.computeItemSum();
      },
      immediate: true,
      deep: true,
    },
  },
  mounted() {
    this.addEditingAttributeToItem();
  },
  methods: {
    formatValueScenarioType(value){
      return this.formatCostType(value, this.typeScenarioTarget)
      // return this.typeScenarioTarget === 'budget'
      //   ? this.formatCostType(value) : value
    },
    limit(field) {
      return this.currentItem.currentEmployeeCount +
        this.retirementEdit +
        this.otherDepartureEdit +
        this.outMobilityEdit ===
        0
        ? this.getItemCustomValue(field) || 0
        : null;
    },
    getDefaultItem(id) {
      return {
        budgetedEmployeeCount: 0,
        currentEmployeeCount: 0,
        budgetedExternalCount: 0,
        currentExternalCount: 0,
        currentExternalCount:0,
        externalDelta: 0,
        budgetedAllCount: 0,
        delta: 0,
        departureDetails: {
          others: 0,
          retirement: 0,
        },
        entity: id,
        entryDetails: {
          others: 0,
          recruitement: 0,
        },
        evolution: 0,
        mobilityDetails: {
          in: 0,
          out: 0,
        },
        netMobility: 0,
        totalDeparture: 0,
        totalEntry: 0,
      };
    },
    getItemCustomValue(field) {
      const custom = this.currentItem?.userEdit?.[field]?.customValue;
      const actual = this.currentItem?.userEdit?.[field]?.actualValue;
      return custom !== actual ? custom : false;
    },
    getValue(field, subfield, customField) {
      let value = false;
      // eslint-disable-next-line max-len
      if (
        (this.item?.children?.length || this.item.entity === 'Global') &&
        Number.isInteger(this.currentItem?.userEdit?.[customField]?.customValue)
      ) {
        value = this.currentItem?.userEdit?.[customField]?.customValue;
      } else {
        value = this.currentItem?.[field]?.[subfield];
      }
      return value;
    },
    getPreviousYearCustomValue(field) {
      // eslint-disable-next-line max-len
      const itemPreviousEdit = this.previousYearEdits?.filter(
        (e) => e.entity === this.item.entity
      )[0];
      if (itemPreviousEdit?.[field]) {
        return itemPreviousEdit[field].customValue;
      }
      return null;
    },
    getCustomValue(field) {
      let customValue = false;
      if (
        !this.currentItem?.children?.length &&
        this.scenarioKey === 'target' &&
        Number.isInteger(this.currentItem?.userEdit?.[field]?.customValue) &&
        // eslint-disable-next-line max-len
        this.currentItem?.userEdit?.[field]?.customValue !==
          this.currentItem?.userEdit?.[field]?.actualValue
      ) {
        customValue = this.currentItem.userEdit[field].customValue;
      }
      return customValue;
    },
    computeItemSum() {
      // eslint-disable-next-line max-len
      this.sum =
        this.currentItem?.userEdit && hasChanged(this.currentItem.userEdit)
          ? sumEdit(this.currentItem.userEdit)
          : 0;
    },
    addEditingAttributeToItem() {
      this.currentItem.editing = {
        entityValue: this.item.entity,
        retirement: {
          actualValue:
            this.currentItem?.userEdit?.departureDetails?.retirement?.actualValue ||
            this.currentItem?.departureDetails?.retirement,
        },
        otherDeparture: {
          actualValue:
            this.currentItem?.userEdit?.departureDetails?.others?.actualValue ||
            this.currentItem?.departureDetails?.others,
        },
        inMobility: {
          actualValue:
            this.currentItem?.userEdit?.mobilityDetails?.in?.actualValue ||
            this.currentItem?.mobilityDetails?.in,
        },
        outMobility: {
          actualValue:
            this.currentItem?.userEdit?.mobilityDetails?.out?.actualValue ||
            this.currentItem?.mobilityDetails?.out,
        },
        recruitment: {
          actualValue:
            this.currentItem?.userEdit?.entryDetails?.recruitement?.actualValue ||
            this.currentItem?.entryDetails?.recruitement,
        },
        budgetedExternalCount: {
          actualValue:
            this.currentItem?.lastVersion?.budgetedExternalCount?.actualValue ||
            this.currentItem?.budgetedExternalCount,
        },
        currentExternalCount: {
          actualValue:
            this.currentItem?.lastVersion?.currentExternalCount?.actualValue ||
            this.currentItem?.currentExternalCount,
        },
        external: {
          actualValue:
            this.currentItem?.lastVersion?.budgetedExternalCount?.actualValue ||
            this.currentItem?.budgetedExternalCount,
        },
      };
    },
    updateItemEditingAttribute() {
      if (!this.currentItem?.editing) {
        return;
      }
      // eslint-disable-next-line max-len
      this.currentItem.editing.retirement.customValue =
        this.currentItem.departureDetails.retirement;
      // eslint-disable-next-line max-len
      this.currentItem.editing.otherDeparture.customValue =
        this.currentItem.departureDetails.others;
      this.currentItem.editing.inMobility.customValue = this.currentItem.mobilityDetails.in;
      this.currentItem.editing.outMobility.customValue = this.currentItem.mobilityDetails.out;
      this.currentItem.editing.recruitment.customValue = this.currentItem.entryDetails.recruitement;
      // eslint-disable-next-line max-len,vue/max-len
      this.currentItem.editing.budgetedExternalCount.customValue = this.currentItem.budgetedExternalCount;
      // eslint-disable-next-line max-len,vue/max-len
      this.currentItem.editing.currentExternalCount.customValue = this.currentItem.currentExternalCount;
      // eslint-disable-next-line max-len,vue/max-len
      this.currentItem.editing.external.customValue = this.currentItem.budgetedExternalCount;
    },
    setValue(domain, attr, event) {
      // change the current item (in nested attributes) but it will not be saved
      this.currentItem[domain][attr] = event;
      this.updateItemEditingAttribute();
      // send the edit event to parent
      this.$emit('new:edit', { ...this.currentItem.editing });
      // tell parent that item has changed
      this.$emit('update:item', { ...this.item });
      // eslint-disable-next-line no-plusplus
      // this.rerenderKey++;
      this.$forceUpdate();
    },
    toggleEdit() {
      this.editing = !this.editing;
    },
  },
};
</script>

<style lang="scss" scoped>
.ellipsis-column {
  width: 70px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 15px;
  padding-right: 5px;
  &--expanded {
    width: 150px;
  }
}

.toggle-edit {
  z-index: 999;
}

@media (min-width: 1400px) {
  .external-width {
    width: 200px;
  }
}

</style>
