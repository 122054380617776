const state = {
  currentTab: '',
};

const getters = {
  // eslint-disable-next-line
  tableDataDetail(state, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    if (
      rootGetters['analysis/yearStatsAll'] &&
      rootGetters['analysis/yearStatsAll'].tableData &&
      rootGetters['analysis/yearStatsAll'].tableData[type] &&
      rootGetters['analysis/yearStatsAll'].tableData[type].rowMap
    ) {
      return rootGetters['analysis/yearStatsAll'].tableData[type].rowMap;
    }
    return null;
  },
  tableDataDetailByTarget(state, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    if (
      rootGetters['analysis/yearTableStatsAllByType'] &&
      rootGetters['analysis/yearTableStatsAllByType'].tableData &&
      rootGetters['analysis/yearTableStatsAllByType'].tableData[type] &&
      rootGetters['analysis/yearTableStatsAllByType'].tableData[type].rowMap
    ) {
      return rootGetters['analysis/yearTableStatsAllByType'].tableData[type].rowMap;
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
