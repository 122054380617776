<template>
  <div :key="key" class="form-row">
    <div class="col">
      <highcharts :options="chart" />
    </div>
    <div class="col-auto">
      <div class="mb-2">
        Manager
      </div>
      <div class="small">
        <div v-for="category in categories" :key="category.label" class="form-row">
          <div class="col">
            <b-form-checkbox v-model="selectedCategories" :value="category.label" name="fRigth">
              <span>{{ category.label }}</span>
            </b-form-checkbox>
          </div>
          <div class="col-auto">
            <i
              class="fas fa-circle ml-1"
              :style="{ color: category.color }"
            />
          </div>
        </div>
      </div>
      <DataLabelsSwitcher />
    </div>
  </div>
</template>

<script>
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher'
import { getOpacity } from '@/services/helpers'
import {
  extractCategoriesFromStaffData,
  extractEntitiesFromStaffData,
  extractSeriesFromStaffData
} from '@/services/staff'

const chartTypeOne = (entities, showDataLabels) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: { enabled: false },
  xAxis: {
    categories: entities,
  },
  yAxis: {
    title: null,
    labels: {
      formatter(item) {
        return `${item.value} %`
      },
    },
  },
  tooltip: {
    pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
    series: {
      dataLabels: {
        enabled: showDataLabels,
      },
    },
  },
  series: [],
})

export default {
  components: {
    DataLabelsSwitcher
  },
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      key: 0,
      getOpacity,
      series: [],
      categories: [],
      selectedCategories: [],
      labels: []
    }
  },
  computed: {
    chart () {
      const chart = chartTypeOne(this.entities, this.showDataLabels)
      chart.series = this.series || []
      return chart
    }
  },
  watch: {
    dataset: {
      handler () {
        this.refreshChart()
        this.selectedCategories = this.categories.map(cat => cat.label)
      },
      immediate: true
    },
    selectedCategories: {
      handler () {
        this.refreshChart()
      },
      immediate: true
    },
    $attrs: {
      handler () {
        this.refreshChart()
      },
      deep: true
    }
  },
  methods: {
    refreshChart () {
      this.series = extractSeriesFromStaffData(
        this.dataset,
        'manager',
        this.$attrs.scenario,
        this.selectedCategories
      )
      this.entities = extractEntitiesFromStaffData(this.dataset, 'manager')
      this.categories = extractCategoriesFromStaffData(
        this.dataset,
        'manager',
        this.$attrs.scenario
      )
      this.key++
    }
  }
}
</script>
