<template>
  <div>
    <GpHeader />
    <div v-if="activeRoute" :class="activeRoute.classes">
      <div class="">
        <template v-for="(cmpt, index) in activeRoute.components">
          <div :key="index" :class="cmpt.classes">
            <component :is="cmpt.name" :key="`right-${index}`" />
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { mapActions, mapState } from 'vuex';
import setup from '@/providers/settings/constants/setupPags';

export default {
  name: 'LayoutLoader',
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line
      vm.activeSection = to;
    });
  },
  data() {
    return {
      setup,
      activeSection: null,
    };
  },
  computed: {
    ...mapState({
      env: (state) => state.env,
    }),
    activeRoute() {
      if (!this.activeSection) return null;
      // If no route is active, set first route from the body array as main route
      const index = this.activeSection.meta.routeIndex
      return setup[this.env].body[index];
    },
  },
  async mounted() {
    // TODO: improve loading by loading filters here ONLY
    /* await this.getConfiguration()
    await this.loadBasicData() */
  },
  methods: {
    ...mapActions({
      loadBasicData: 'auth/basic/loadBasicData',
      getConfiguration: 'auth/getConfiguration',
    }),
  },
};
</script>
