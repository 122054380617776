<template>
  <b-overlay v-if="item" :show="loading" class="form-row table-competence-item">
    <div v-b-toggle="`collapse-${item._id}${index}`" class="col-3">
      <div
        class="table-competence-item__name"
        :class="{ 'table-competence-item__name--child': !item.children || !item.children.length }"
      >
        <i v-if="item.children && item.children.length" class="fas fa-caret-down mr-2" />
        <i v-if="item.children && item.children.length" class="fas fa-caret-right mr-2" />
        {{ item.names[0].label }}
      </div>
    </div>
    <div class="col-9">
      <div class="form-row table-competence-item__body">
        <div class="col">
          <div v-if="columnsToShow[0]" class="d-flex justify-content-center align-items-center">
            <CellCardTableItemCompetence
              :column-index="0"
              :dataset="jobDataset"
              scenario-key="current"
              :skills="skills"
            />
            <CellCardTableItemCompetence
              :column-index="0"
              :dataset="jobDataset"
              :scenario-key="scenarioKey"
              :skills="skills"
            />
          </div>
        </div>
        <div class="col">
          <div v-if="columnsToShow[1]" class="d-flex justify-content-center align-items-center">
            <CellCardTableItemCompetence
              :column-index="1"
              :dataset="jobDataset"
              scenario-key="current"
              :skills="skills"
            />
            <CellCardTableItemCompetence
              :column-index="1"
              :dataset="jobDataset"
              :scenario-key="scenarioKey"
              :skills="skills"
            />
          </div>
        </div>
        <div v-if="false" class="col">
          <div v-if="columnsToShow[2]" class="d-flex justify-content-center align-items-center">
            <CellCardTableItemCompetence
              :column-index="2"
              :dataset="jobDataset"
              scenario-key="current"
              :skills="skills"
            />
            <CellCardTableItemCompetence
              :column-index="2"
              :dataset="jobDataset"
              :scenario-key="scenarioKey"
              :skills="skills"
            />
          </div>
        </div>
      </div>
    </div>
    <b-collapse :id="`collapse-${item._id}${index}`" class="col-12">
      <TableItemCompetence
        v-for="(itemChild1, idx1) in item.children"
        :key="idx1"
        :item="itemChild1"
        :columns-to-show="columnsToShow"
        :index="idx1"
        :global-values="globalValues"
        :skills="skills"
        :scenario-key="scenarioKey"
        :loading="false"
      />
    </b-collapse>
  </b-overlay>
</template>

<script>
// eslint-disable-next-line max-len,vue/max-len
import CellCardTableItemCompetence from '@/components/analysis-tabs/competences/CellCardTableItemCompetence.vue';

export default {
  name: 'TableItemCompetence',
  components: { CellCardTableItemCompetence },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
    index: {
      type: Number,
      default: 0,
    },
    columnsToShow: {
      type: Array,
      required: false,
      default: () => []
    },
    skills: {
      type: Array,
      required: true,
    },
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    scenarioKey: {
      type: String,
      required: true,
    },
    loading: {
      type: Boolean,
      required: true
    }
  },
  data() {
    return {};
  },
  computed: {
    jobDataset() {
      let data = {};
      // eslint-disable-next-line no-underscore-dangle
      if (this.item._id === 'Global') {
        // eslint-disable-next-line
        data = this.globalValues?.competencyData?.globalValueMap;
      } else {
        // eslint-disable-next-line
        data = this.globalValues?.data?.stats?.valueMap?.[this.item._id]?.valueMap;
      }
      return data;
    },
  },
};
</script>

<style scoped>
.not-collapsed .fa-caret-down {
  display: none;
}
.collapsed .fa-caret-right {
  display: none;
}
</style>
