<template>
  <div>
    <div ref="graph-tabs" class="form-row justify-content-end mb-4">
      <div class="col">
        <b-button-group>
          <b-button :variant="chartT2.stepTab === 1 ? 'primary' : 'light'" @click="showTab(1)">
            <i class="fas fa-chart-scatter" />
          </b-button>
          <b-button
            :variant="chartT2.stepTab === 2 ? 'primary' : 'light'"
            class="d-none"
            @click="showTab(2)"
          >
            <i class="fas fa-chart-bar" />
          </b-button>
          <b-button :variant="chartT2.stepTab === 3 ? 'primary' : 'light'" @click="showTab(3)">
            <i class="fas fa-chart-network" />
          </b-button>
        </b-button-group>
      </div>
      <div class="col-auto">
        <ScenariosSelector
          :multi="false"
          :hidden="scenarioMetadata ? hiddenScenarios : ['target', ...hiddenScenarios]"
          :displayed-scenarios="['predicted', 'optimal', 'target', 'current']"
          :default-active="defaultScenario"
          @changed="updateSelectedCheck"
        />
      </div>
    </div>
    <hr>
    <div>
      <b-row>
        <b-col cols="6">
          <b-breadcrumb
            v-show="chartT2.stepTab !== 3"
            :items="breadcrumbs"
            class="bg-white pl-0 pt-0"
            @click="setSkills"
          />
        </b-col>
        <b-col cols="6">
          <SkillSelector
            v-if="chartT2.stepTab === 1"
            :only-children="false"
            :multi="true"
            :exact="true"
            :object-value="false"
            @selected="updateSelectedSkills"
          />
        </b-col>
      </b-row>
      <div v-show="chartT2.stepTab === 1">
        <div class="d-flex justify-content-end">
          <DataLabelsSwitcher />
        </div>
        <highcharts
          v-if="currentProcessedDataLeaf"
          ref="bubble"
          :options="currentProcessedDataLeaf"
          :update-args="[true, true]"
        />
        <div v-else>
          Pas de données
        </div>
      </div>
      <div v-show="chartT2.stepTab === 2">
        <h6 class="text-uppercase">
          <b>Nombre de compétences</b>
        </h6>
        <highcharts id="chartT2Bar" :options="chartT2Bar" />
      </div>
      <div v-show="chartT2.stepTab === 3">
        <tree-chart ref="mindmap" :check="selectedCheck" />
      </div>
      <b-popover target="xtitle" triggers="hover">
        Cet axe présente la variation des effectifs entre la date de visualisation et l’existant.
      </b-popover>
      <b-popover target="ytitle" triggers="hover">
        Cet axe présente la variation du niveau de maîtrise des compétences entre la date de
        visualisation et l’existant.
      </b-popover>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex';
import _ from 'lodash';
import TreeChart from '@/components/analysis-tabs/competences/TreeChart.vue';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import SkillSelector from '@/components/selectors/SkillSelector.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';

// eslint-disable-next-line max-len
const getNearPoints = (points, { x, y }) =>
  points.filter(
    (point) => Math.round(point.x) === Math.round(x) && Math.round(point.y) === Math.round(y)
  );

const chartT2Bar = {
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: {
    enabled: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
  },
  xAxis: {
    categories: [],
  },
  yAxis: {
    title: null,
  },
  tooltip: {
    pointFormat: '<span>{series.name}</span>: <b>{point.y}</b> ({point.percentage:.0f}%)<br/>',
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
  },
  series: [],
};

const bubble = (data, pointEventClickHandler, showDataLabels) => ({
  credits: {
    enabled: false,
  },
  chart: {
    plotBackgroundColor: '#EAEAEA',
    type: 'bubble',
    zoomType: 'xy',
    height: window.innerHeight - 50 || '80%',
  },
  plotOptions: {
    series: {
      dataLabels: {
        enabled: showDataLabels,
        formatter() {
          return this.point.title;
        },
      },
      animation: {
        defer: 200,
        duration: 1500,
      },
      maxSize: '15%',
      sizeByAbsoluteValue: false,
      cursor: 'pointer',
      point: {
        events: {
          click: pointEventClickHandler,
        },
      },
    },
  },
  title: null,
  legend: {
    title: {
      text: 'Niveau moyen',
    },
    enabled: true,
    layout: 'vertical',
    align: 'right',
    verticalAlign: 'top',
  },
  xAxis: {
    arrow: true,
    min: -120,
    max: 120,
    startOnTick: false,
    endOnTick: false,
    tickInterval: 80,
    tickLength: 0,
    gridLineWidth: 0,
    accessibility: {
      rangeDescription: 'Range: 0 to 100.',
    },
    title: {
      useHTML: true,
      text: `${'Effectifs'.toUpperCase()}
        <span class="ml-1 text-dark" style="position: relative; top: -5px">
        <i id="xtitle" tabindex="0" class="fal fa-question-circle"></i>
        </span>`,
      style: {
        color: 'var(--dark)',
        'font-weight': 'bold',
      },
      align: 'high',
    },
    plotLines: [
      {
        value: -40,
        width: 2,
        color: 'white',
      },
      {
        value: 40,
        width: 2,
        color: 'white',
      },
    ],
    labels: {
      useHTML: true,
      formatter() {
        let label;
        if (this.value < 0)
          label =
            `<div class="h6">
                <span>Décroissance</span>
                <i class="fa fa-chart-line-down text-success ml-2" />
            </div>`;
        if (this.value === 0)
          label =
            '<div class="h6"><span>Stable</span><i class="fa fa-equals text-success ml-2" /></div>';
        if (this.value > 0)
          label =
            `<div class="h6">
                <span>Croissance</span>
                <i class="fa fa-chart-line text-success ml-2" />
            </div>`;
        return label;
      },
    },
  },
  yAxis: {
    min: -120,
    max: 120,
    gridLineWidth: 0,
    plotLines: [
      {
        value: -40,
        width: 2,
        color: 'white',
      },
      {
        value: 40,
        width: 2,
        color: 'white',
      },
    ],
    startOnTick: false,
    endOnTick: false,
    tickInterval: 80,
    tickLength: 0,
    accessibility: {
      rangeDescription: 'Range: 0 to 100.',
    },
    title: {
      useHTML: true,
      text: `
        ${'Compétences'.toUpperCase()}
        <span class="ml-1 text-dark" style="position: relative; top: -5px">
            <i id="ytitle" tabindex="0" class="fal fa-question-circle"></i>
        </span>
      `,
      style: {
        color: 'var(--dark)',
        'font-weight': 'bold',
      },
      align: 'high',
      // rotation: 0,
      // textAlign: 'center',
    },
    labels: {
      useHTML: true,
      formatter() {
        let label;
        if (this.value < 0)
          label =
            `<div class="h6">
            <span>Décroissance</span>
            <i class="fa fa-chart-line-down text-success ml-2" />
            </div>`;
        if (this.value === 0)
          label =
            `<div class="h6">
            <span>Identique</span>
            <i class="fa fa-equals text-success ml-2" />
            </div>`;
        if (this.value > 0)
          label =
            `<div class="h6">
            <span>En évolution</span>
            <i class="fa fa-chart-line text-success ml-2" />
            </div>`;
        return label;
      },
    },
  },
  tooltip: {
    shared: true,
    style: {
      color: 'white',
    },
    backgroundColor: '#31415F',
    pointFormatter() {
      const points = getNearPoints(this.series.data, this);
      const phrases = points.map(
        (p) => `<b>${p.title}</b><br/><span>${p.value} collaborateurs</span>`
      );
      return phrases.join('<br />');
    },
  },
  series: data,
});

export default {
  name: 'GraphCompetence',
  components: {
    ScenariosSelector,
    TreeChart,
    SkillSelector,
    DataLabelsSwitcher,
  },
  props: {
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => 'predicted',
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      selectedCheck: 'predicted',
      chartT2: {
        stepTab: 1,
      },
      chartT2Bar,
      bubble,
      // chartT2Bubble,
      skills: [],
      breadcrumbs: [],
    };
  },
  computed: {
    hiddenScenarios() {
      return this.chartT2.stepTab === 1 ? ['current'] : [];
    },
    maxEmployeeEvolutionCount() {
      // eslint-disable-next-line max-len
      const values = this.currentDataLeaf.map(
        (s) =>
          s && s.score && s.score[this.selectedCheck] && s.score[this.selectedCheck].evolutionCount
      );
      return Math.max(...values.filter(Boolean));
    },
    maxEmployeeCount() {
      // eslint-disable-next-line max-len
      const values = this.currentDataLeaf.map(
        (s) =>
          s && s.score && s.score[this.selectedCheck] && s.score[this.selectedCheck].employeeCount
      );
      return Math.max(...values.filter(Boolean));
    },
    maxScoreEvolutionCount() {
      // eslint-disable-next-line max-len
      const values = this.currentDataLeaf.map(
        (s) =>
          s &&
          s.score &&
          s.score[this.selectedCheck] &&
          s.score[this.selectedCheck].scoreEvolutionValue
      );
      return Math.max(...values.filter(Boolean));
    },
    currentDataLeaf() {
      // eslint-disable-next-line no-underscore-dangle,max-len
      return this.skills.map((skill) => ({
        ...skill,
        score: this.globalValues[skill._id] || null,
      }));
    },
    currentProcessedDataLeaf() {
      // eslint-disable-next-line max-len
      const branch = this.currentDataLeaf
        .filter((leaf) => leaf.score)
        .map((leaf) => {
          // eslint-disable-next-line max-len
          const x = parseFloat(
            (
              (leaf.score[this.selectedCheck].evolutionCount * 100) /
              this.maxEmployeeEvolutionCount
            ).toFixed(2)
          );
          // eslint-disable-next-line max-len
          const y = parseFloat(
            (
              (leaf.score[this.selectedCheck].scoreEvolutionValue * 100) /
              this.maxScoreEvolutionCount
            ).toFixed(2)
          );
          // eslint-disable-next-line max-len
          const z = parseFloat(
            ((leaf.score[this.selectedCheck].employeeCount * 100) / this.maxEmployeeCount).toFixed(
              2
            )
          );
          return {
            // eslint-disable-next-line max-len
            x: Math.abs(x) > 100 ? Math.sign(x) * 100 : x,
            // eslint-disable-next-line max-len
            y: Math.abs(y) > 100 ? Math.sign(y) * 100 : y,
            avg: leaf.score[this.selectedCheck].averageScore,
            value: leaf.score[this.selectedCheck].employeeCount,
            // eslint-disable-next-line max-len
            z,
            title: leaf.names[0].label,
            leaf,
          };
        });
      return bubble(
        [
          {
            name: 'Niveau moyen < 1,5',
            color: '#F6675B',
            data: _.sortBy(
              branch.filter((leaf) => leaf.color < 37),
              'x'
            ),
          },
          {
            name: '1,5 < Niveau moyen < 3',
            color: '#F78836',
            data: _.sortBy(
              branch.filter((leaf) => leaf.avg >= 37 && leaf.avg < 75),
              'x'
            ),
          },
          {
            name: 'Niveau moyen > 3',
            color: '#47D49A',
            data: _.sortBy(
              branch.filter((leaf) => leaf.avg >= 75),
              'x'
            ),
          },
        ],
        this.handleBubblePointClickEvent,
        this.showDataLabels
      );
    },
    currentBranch() {
      return this.currentDataLeaf
        .filter((leaf) => leaf.score)
        .map((leaf) => ({
          // eslint-disable-next-line max-len
          x: parseFloat(
            (
              (leaf.score[this.selectedCheck].evolutionCount * 100) /
              this.maxEmployeeEvolutionCount
            ).toFixed(2)
          ),
          // eslint-disable-next-line max-len
          y: parseFloat(
            (
              (leaf.score[this.selectedCheck].scoreEvolutionValue * 100) /
              this.maxScoreEvolutionCount
            ).toFixed(2)
          ),
          avg: leaf.score[this.selectedCheck].averageScore,
          value: leaf.score[this.selectedCheck].employeeCount,
          // eslint-disable-next-line max-len
          z: parseFloat(
            ((leaf.score[this.selectedCheck].employeeCount * 100) / this.maxEmployeeCount).toFixed(
              2
            )
          ),
          title: leaf.names[0].label,
          // leaf,
        }));
    },
  },
  mounted() {
    this.updateSkills(this.skillTree);
    this.resetBreadcrumbs();
  },
  methods: {
    resetBreadcrumbs() {
      this.breadcrumbs = [
        {
          text: 'Tout',
          children: this.skillTree,
          active: true,
        },
      ];
    },
    updateSelectedSkills(skills) {

      // eslint-disable-next-line no-underscore-dangle
      const skillsObjets = skills ? skills.map((s) => this.findOptionTree(this.skillTree, s)) : [];
      this.updateSkills(skillsObjets.length ? skillsObjets : this.skillTree);
      this.resetBreadcrumbs();
    },
    updateSelectedCheck(scenarios) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedCheck = scenarios[0];
    },
    showTab(index) {
      this.chartT2.stepTab = index;
      if (index === 3 && this.$refs['graph-tabs']) {
        const rect = this.$refs['graph-tabs'].getBoundingClientRect();
        window.scrollTo({
          top: window.scrollY + rect.top,
          left: 0,
          behavior: 'smooth',
        });
      } else if (index === 1) {
        this.setActiveScenarios(['predicted']); // hack to always use predicted dataset on load
      }
    },
    handleBubblePointClickEvent(e) {
      if (e.point.options.leaf.children) {
        const current = { text: e.point.options.title, ...e.point.options.leaf };
        this.breadcrumbs.push(current);
        this.updateBreadcrumbs(current);
        this.updateSkills(e.point.options.leaf.children);
      }
    },
    /**
     * This method will update skills and implicitly redraw the graph
     * https://github.com/highcharts/highcharts-vue/issues/29
     * @param skills
     */
    updateSkills(skills) {
      this.skills = skills;
    },
    setSkills(e) {
      if (!e.target.innerText) {
        return;
      }
      const current = this.breadcrumbs.find((i) => i.text === e.target.innerText);
      if (!current || !current.children) {
        return;
      }
      this.updateBreadcrumbs(current);
      this.updateSkills(current.children);
      this.current = current;
    },
    updateBreadcrumbs(current) {
      const index = this.breadcrumbs.findIndex((i) => i === current);
      this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
      this.breadcrumbs.forEach((b, i) => {
        b.active = i === this.breadcrumbs.length - 1;
      });
    },
    ...mapMutations('auth/basic', {
      setActiveScenarios: 'setActiveScenarios',
    }),
  },
};
</script>
