// eslint-disable-next-line
export const lightOrganizationStruct = (item) => {
  /*
  delete item.v;
  delete item.createdAt;
  delete item.updatedAt;
  delete item.org;
  delete item.parentCode;
  delete item.costCentres;
  delete item.stats;
  delete item.sites;
  delete item.closedAt;
  delete item.establishedAt;
  delete item.createdFrom;
  */
  return item;
};

// eslint-disable-next-line
export const OrganizationRecursive = async (parentOrganization, organizations) => {
  const children = [];
  // eslint-disable-next-line
  for (const org of organizations) {
    const orgParentRef = org.parent;
    // eslint-disable-next-line
    if (orgParentRef && orgParentRef['#'] === parentOrganization._id) {
      // eslint-disable-next-line
      const response = await OrganizationRecursive(org, organizations);
      if (response.length) {
        org.children = response;
      }
      children.push(lightOrganizationStruct(org));
    }
  }
  return children;
};
