<template>
  <div class="card card-indicater border-0 shadow-sm mb-3 h-100">
    <div class="p-3">
      <i
        v-if="item.title"
        :id="`${item.label}-kpi`"
        class="fal fa-question-circle"
        style="position: absolute; top: 0.5rem; right: 0.5rem"
      />
      <b-popover :target="`${item.label}-kpi`" triggers="hover">
        {{ item.title }}
      </b-popover>
      <div class="text-muted pr-3" style="line-height: 1.2; height: 48px">
        <span>{{ item.name }}</span>
      </div>
      <div v-if="(!item.allowZero && item.value <= 0) || forceNoValue">
        <h6>{{ item.fallback }}</h6>
      </div>
      <div v-else>
        <h4 v-if="item.dataType === 'year'" class="mb-3">
          <div v-if="mainValue.years" class="d-inline">
            <span class="mr-1">{{ mainValue.years }}</span>
            <small class="mr-1">{{ mainValue.years === 1 ? 'an' : 'ans' }}</small>
          </div>
          <div v-if="mainValue.months" class="d-inline">
            <span class="mr-1">{{ mainValue.months }}</span>
            <small>mois</small>
          </div>
        </h4>
        <h4 v-else class="mb-3">
          {{ fixNumber(item.value) }} {{ sufix }}
        </h4>
        <div class="small" :class="item.evolution > 0 ? 'text-success' : 'text-danger'">
          <i v-if="item.evolution > 0" class="fas fa-caret-up" />
          <i v-if="item.evolution < 0" class="fas fa-caret-down" />
          <div v-if="item.dataType === 'year'" class="mx-2 d-inline font-weight-bold">
            <div v-if="evolutionValue.years" class="d-inline">
              <span class="mr-1">{{ evolutionValue.years }}</span>
              <small class="mr-1">{{ evolutionValue.years === 1 ? 'an' : 'ans' }}</small>
            </div>
            <div v-if="evolutionValue.months" class="d-inline">
              <span class="mr-1">{{ evolutionValue.months }}</span>
              <small>mois</small>
            </div>
          </div>
          <div v-else class="mx-2 d-inline">
            <b class="mr-1">{{ fixNumber(item.evolution) }}</b>
            <small>{{ evolutionSufix }}</small>
          </div>
          <i class="fas fa-chart-line" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardIndicater',
  props: {
    item: {
      type: Object,
      required: true,
    },
    forceNoValue: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  computed: {
    mainValue() {
      return this.parseYearDataTypeValue(this.item.value);
    },
    evolutionValue() {
      return this.parseYearDataTypeValue(this.item.evolution);
    },
    sufix() {
      switch (this.item.dataType) {
        case 'year':
          return 'ans';
        case 'percentage':
          return '%';
        default:
          return '';
      }
    },
    evolutionSufix() {
      switch (this.item.dataType) {
        case 'year':
          return 'ans';
        case 'percentage':
          return 'points de %';
        default:
          return '';
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.card-indicater {
  border-radius: 6px;
}
</style>
