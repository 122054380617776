<template>
  <ul class="stepper">
    <li v-for="i in 7" :key="i" :class="{ active: i <= step }">
      <div>
        <div
          class="stepper__number"
          :class="{
            'bg-primary': i <= step,
            'text-white': i <= step,
            'text-primary': i > step,
          }"
        >
          {{ i }}
        </div>
        <div class="stepper__title">
          <span v-if="i === steps.FILTER">Périmètre</span>
          <span v-if="i === steps.BASE_SCENARIO">Vieillissement</span>
          <span v-if="i === steps.STRATEGY">Stratégie RH</span>
          <span v-if="i === steps.PERIOD">Temporalité</span>
          <span v-if="i === steps.PEOPLE">Effectifs</span>
          <span v-if="i === steps.SKILLS">Compétences</span>
          <span v-if="i === steps.HYPOTHESIS_1">Hypothèses ({{ years[0] }})</span>
          <span v-if="i === steps.HYPOTHESIS_2">Hypothèses ({{ years[1] }})</span>
          <span v-if="i === steps.HYPOTHESIS_3">Hypothèses ({{ years[2] }})</span>
          <span v-if="i === steps.VALIDATION">Validation</span>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      required: true
    },
    steps: {
      type: Object,
      required: true,
    },
    years: {
      type: Array,
      required: true,
    },
    period: {
      type: String,
      required: false,
      default: () => ''
    },
  },
};
</script>

<style lang="scss" scoped>
.stepper {
  padding: 0;
  display: flex;
  justify-content: space-between;
  li {
    list-style: none;
    text-align: center;
    position: relative;
    padding: 10px 1.5rem;
    display: flex;
    justify-content: center;
    flex-basis: 100%;
    &:not(:first-child) {
      &:before {
        content: '';
        position: absolute;
        top: 25px;
        left: calc(-50% + 15px);
        right: calc(50% + 15px);
        border-top: 1px solid #ccc;
      }
    }
    &.active {
      .stepper__title {
        opacity: 1;
      }
    }
  }
  &__number {
    width: 30px;
    height: 30px;
    line-height: 28px;
    margin: auto auto 0.5rem;
    border: 1px solid;
    border-radius: 50%;
  }
  &__title {
    opacity: 0.75;
  }
}
</style>
