<template>
  <div>
    <div class="p-4">
      <b-alert show variant="warning">
        <span>Nous avons détecté des conflits entre plusieurs scénarios</span>
      </b-alert>
      <VerticalStep
        title="Choisir un type au scénario créé"
        :step="1"
      >
        <b-radio-group v-model="type">
          <b-radio value="structural">
            Structurelle
          </b-radio>
          <b-radio value="conjunctural">
            Conjoncturelle
          </b-radio>
          <b-radio value="noRecruiting">
            Sans recrutement
          </b-radio>
        </b-radio-group>
      </VerticalStep>
    </div>
    <hr>
    <div class="d-flex align-items-center justify-content-end m-4">
      <b-button variant="link" class="text-uppercase" @click="$emit('cancel')">
        Abandonner
      </b-button>
      <b-button variant="secondary" class="text-uppercase" @click="$emit('success', type)">
        Continuer
      </b-button>
    </div>
  </div>
</template>

<script>
import VerticalStep from '@/components/ui/VerticalStep'

export default {
  components: {
    VerticalStep
  },
  props: {
    scenarios: {
      type: Array,
      required: true
    },
    conflicts: {
      type: Array,
      required: true
    }
  },
  data () {
    return {
      type: null
    }
  }
}
</script>