<template>
  <div v-if="years.length">
    <div>
      Ajouter une compétence individuellement
    </div>
    <div v-if="editable">
      <SkillSelector
        :key="key"
        :disabled="!job"
        :only-children="true"
        :exact="true"
        :multi="false"
        style="margin-bottom: 15px; width: 500px;"
        @selected="onSelectSkill"
      />
    </div>
    <b-table-lite
      :key="fields.length"
      :fields="fields"
      :items="items"
      :tbody-tr-class="setRowClass"
    >
      <template #cell(label)="{ item }">
        <span>{{ item.label }}</span>
      </template>
      <template #cell(current)="{ item }">
        <b-form-rating
          readonly
          icon-empty="circle"
          icon-full="check-circle-fill"
          :stars="4"
          size="sm"
          :value="item['current']"
          class="border-0 bg-transparent rate-input"
        />
      </template>
      <template #cell(year_0)="data">
        <b-form-rating
          :readonly="!editable"
          icon-empty="circle"
          icon-full="check-circle-fill"
          :variant="(data.item && data.item._new) ? 'secondary' : 'primary'"
          show-clear
          :stars="4"
          size="sm"
          :value="data.item['year_0']"
          class="border-0 bg-transparent rate-input"
          @change="setLevel(data, $event)"
        />
      </template>
      <template #cell(year_1)="data">
        <b-form-rating
          :readonly="!editable"
          icon-empty="circle"
          icon-full="check-circle-fill"
          :variant="(data.item && data.item._new) ? 'secondary' : 'primary'"
          show-clear
          :stars="4"
          size="sm"
          :value="data.item['year_1']"
          class="border-0 bg-transparent rate-input"
          @change="setLevel(data, $event)"
        />
      </template>
      <template #cell(year_2)="data">
        <b-form-rating
          :readonly="!editable"
          icon-empty="circle"
          icon-full="check-circle-fill"
          :variant="(data.item && data.item._new) ? 'secondary' : 'primary'"
          show-clear
          :stars="4"
          size="sm"
          :value="data.item['year_2']"
          class="border-0 bg-transparent rate-input"
          @change="setLevel(data, $event)"
        />
      </template>
      <template #cell(actions)="{ item }">
        <b-button
          v-if="editable"
          variant="link"
          class="text-muted"
          @click.stop="remove(item)"
        >
          <i class="fa fa-trash" />
        </b-button>
      </template>
    </b-table-lite>
  </div>
</template>

<script>
import SkillSelector from '@/components/selectors/SkillSelector'
import { mapGetters } from 'vuex'
import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'

const DIVIDER = 25
const DEFAULT_VALUE = 75
export default {
  components: {
    SkillSelector
  },
  props: {
    editable: {
      type: Boolean,
      required: false,
      default: () => true
    },
    job: {
      type: Object,
      required: false,
      default: () => null
    },
    years: {
      type: Array,
      required: true
    },
    hypothesis: {
      type: Object,
      required: true
    },
    payload: {
      type:Object,
      required: false,
      default: () => {}
    },
    target: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data () {
    return {
      items: [],
      key: 0
    }
  },
  computed: {
    fields () {
      const columns = [
        { key: 'name', label: 'Compétences', tdClass: 'col-3' },
        { key: 'current', label: 'Existant', thClass: 'text-center', tdClass: 'col-2 text-center' },
      ]
      this.years.forEach((y, i) => {
        columns.push({
          key: `year_${i}`,
          label: `${y}`,
          thClass: 'text-center',
          tdClass: 'col-2 text-center'
        })
      })
      columns.push({ key: 'actions', label: '', tdClass: 'col-1' })
      return columns
    },
    ...mapGetters({
      skills: 'auth/basic/getDataSkills'
    })
  },
  watch: {
    job: {
      handler () {
        this.buildItems()
      },
      deep: true
    },
    target: {
      handler () {
        this.buildItems()
      },
      deep: true,
      immediate: true
    },
  },
  methods: {
    setRowClass (data) {
      return data._new ? 'text-secondary' : ''
    },
    setLevel (data, level) {
      data.item[data.field.key] = level
      this.items.forEach((i, index) => {
        if (i.id === data.item.id) {
          this.items[index] = data.item
        }
      })
      this.items = [...this.items]
      this.emitUpdate()
    },
    formatItems (items) {
      const hypothesis = {}
      const skills0 = []
      const skills1 = []
      const skills2 = []
      const mapYearKeys = {
        year_0: this.years[0],
        year_1: this.years[1],
        year_2: this.years[2]
      }
      Object.values(this.years).forEach(y => {
        hypothesis[y] = {}
      })
      items.forEach(item => {
        skills0.push({
          uid: item._original.uid,
          sk: item._original.sk,
          sc: item.year_0 * DIVIDER,
          r: item._original.r
        })
        skills1.push({
          uid: item._original.uid,
          sk: item._original.sk,
          sc: item.year_1 * DIVIDER,
          r: item._original.r
        })
        skills2.push({
          uid: item._original.uid,
          sk: item._original.sk,
          sc: item.year_2 * DIVIDER,
          r: item._original.r
        })
      })
      hypothesis[mapYearKeys.year_0][this.job._id] = skills0
      hypothesis[mapYearKeys.year_1][this.job._id] = skills1
      hypothesis[mapYearKeys.year_2][this.job._id] = skills2
      return hypothesis
    },
    buildItems () {
      if (this.job) {
        if (
          this.target
          && this.payload
          && this.payload.editedSkills
          && this.payload.scenarioJobs
          && this.payload.scenarioJobs.map(j => j.id).includes(this.job._id)
        ) {
          this.items = this.formatSkillsFromPayload(this.payload.editedSkills, this.job._id)
        } else {
          if (this.hypothesis?.[this.job?._id]) {
            this.items = this.hypothesis[this.job._id]?.items?.editable || []
          } else {
            this.items = this.formatSkills(this.job)
          }
        }
      } else {
        this.items = []
      }
      this.key++
    },
    getSkillName (skillId, attr = '_key') {
      return this.findOptionTree(this.skillTree, skillId, attr);
    },
    formatSkillsFromPayload (skills, jobId) {
      let skillsArray = []
      // fill metadata
      // fill first year value
      const years = Object.keys(skills)
      Object.keys(skills[years[0]]).forEach(job => {
        if (job === jobId) {
          skillsArray = [...skills[years[0]][job]]
          skills[years[0]][job].forEach((skill, i) => {
            skill['id'] = skill['Skill']
            skill['_new'] = !skill['ID']
            skill['name'] = this.getSkillName(skill['Skill'], '_key').names[0].label
            skill['current'] = !skill['ID'] ? 0 : skill['Score'] / DIVIDER
            skill['year_0'] = skill['Score'] / DIVIDER
            skill['year_1'] = skills[years[1]][job][i]['Score'] / DIVIDER
            skill['year_2'] = skills[years[2]][job][i]['Score'] / DIVIDER
          })
        }
      })
      return skillsArray
    },
    formatSkills (job) {
      if (!job.skills) {
        return []
      }
      return job.skills.map(skill => {
        const skillObject = this.getSkillName(skill.sk)
        return {
          id: skill.sk,
          _original: skill,
          _new: false,
          name: skillObject.names?.[0]?.label || '',
          current: skill.sc / DIVIDER,
          year_0: skill.sc / DIVIDER,
          year_1: skill.sc / DIVIDER,
          year_2: skill.sc / DIVIDER
        }
      })
    },
    formatNewSkill (skill) {
      const skillObject = this.getSkillName(skill, '_id')
      return {
        id: skill,
        _original: {
          uid: null,
          sk: skillObject._key,
          sc: DEFAULT_VALUE,
          r: 0
        },
        _new: true,
        name: skillObject.names?.[0]?.label || '',
        current: 0,
        year_0: DEFAULT_VALUE / DIVIDER,
        year_1: DEFAULT_VALUE / DIVIDER,
        year_2: DEFAULT_VALUE / DIVIDER
      }
    },
    remove (item) {
      this.items = this.items.filter(s => s.id !== item.id)
      this.$forceUpdate()
      this.emitUpdate()
    },
    onSelectSkill (skill) {
      if (skill) {
        const skillId = skill.split('/')[1]
        // eslint-disable-next-line
        const existSkill = this.items.find(skillItem => skillItem.id == skillId || skillItem.id === skill)
        if (existSkill) {
          this.$bvToast.toast('La compétition sélectionnée est déjà ajoutée', {
            title: `Compétences`,
            variant: 'warning',
            solid: true
          })
        } else {
          this.items.push(this.formatNewSkill(skill))
          this.emitUpdate()
          setTimeout(() => this.key++, 100)
        }
      }
    },
    emitUpdate () {
      this.$emit('update', { formatted: this.formatItems(this.items), editable: this.items })
    }
  }
}
</script>

<style>
.rate-input {
  margin: auto;
  width: 100px;
}
</style>
