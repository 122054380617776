<template>
  <b-alert show variant="warning" class="my-4">
    <p style="white-space: pre-line">
      {{ text }}
    </p>
  </b-alert>
</template>
<script>
export default {
  name: 'NoData',
  props: {
    text: {
      type: String,
      required: false,
      default: () => `
      La population filtrée comporte moins de 10 personnes.
      Cette taille ne nous permet pas d’établir des prévisions fiables.
      Pour afficher des prévisions, merci de filtrer un périmètre de plus de 10 personnes.
      `,
    },
  },
};
</script>
