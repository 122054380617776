<template>
  <div v-if="loadingScreen" class="full-loader">
    <p class="full-loader__title">
      {{ label }}
    </p>
    <div class="lds-roller">
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
      <div />
    </div>
  </div>
</template>
<script>
import { mapGetters } from 'vuex';

export default {
  name: 'FullLoader',
  props: {
    label: {
      type: String,
      default: 'Recherche...',
    },
  },
  computed: {
    ...mapGetters({
      loadingScreen: 'app/loadingScreen',
    }),
  },
};
</script>
