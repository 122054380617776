<template>
  <div>
    <div class="d-inline-block rounded text-center">
      <span v-if="!editing">{{ formatter(value) }}</span>
      <div v-else-if="editing && age"
        class="position-relative"
        style="padding-bottom: 11px;"
      >
        <b-input-group size="sm">
          <b-input
            type="number"
            :value="value.years"
            min="0"
            class="text-monospace"
            style="font-size: 13px; padding-left: 0.3rem; width: 50px"
            @change="onUpdateAgeYears"
          />
          <b-input
            type="number"
            :value="value.months"
            class="text-monospace"
            min="0"
            max="11"
            style="font-size: 13px; padding-left: 0.3rem; width: 50px"
            @change="onUpdateAgeMonths"
          />
        </b-input-group>
        <div
          class="text-muted d-flex justify-content-around position-absolute small"
          style="left: 0; right: 0; bottom: -7px;"
        >
          <span>ans</span>
          <span>mois</span>
        </div>
      </div>
      <b-form-input
        v-else
        v-model="editableValue"
        size="sm"
        type="number"
        class="text-monospace"
        step="0.1"
        style="font-size: 13px; padding-left: 0.3rem; width: 65px"
        @change="onUpdate"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true,
    },
    editing: {
      type: Boolean,
      required: true,
    },
    value: {
      type: [Number, String, Object],
      required: true,
    },
    formatter: {
      type: Function,
      required: false,
      default: (value) => value,
    },
    setter: {
      type: Function,
      required: false,
      default: (value) => value,
    },
    age: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      editableValue: null,
    };
  },
  watch: {
    value: {
      handler (nv, ov) {
        this.editableValue = typeof this.value !== 'object' ? parseFloat(this.value) : this.value;
      },
      immediate: true
    }
  },
  methods: {
    onUpdate() {
      this.$emit('update', { key: this.id, value: this.setter(this.editableValue) });
    },
    onUpdateAgeYears(years) {
      this.editableValue.years = years;
      this.onUpdate();
    },
    onUpdateAgeMonths(months) {
      this.editableValue.months = months;
      this.onUpdate();
    },
  },
};
</script>
