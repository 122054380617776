/* eslint-disable */
/**
 * We have 3 columns to collapse.
 * The number of probabilities are 3^3 - 1 = 8 choices.
 * Each column is based on 2 bootstrap columns in the grid, minimum is 1 col, max is 3
 * when others are closed.
 * We can optimize space only 2 columns are closed.
 * o = opened, c = closed
 * A|B|C A|B|C A|B|C
 * o|o|o 2|2|2 2|2|2
 * o|o|c 2|2|1 2|2|2
 * o|c|c 2|1|1 4|1|1 <= optimized
 * o|c|o 2|1|2 2|2|2
 * c|o|o 1|2|2 2|2|2
 * c|o|c 1|2|1 1|4|1 <= optimized
 * c|c|o 1|1|2 1|1|4 <= optimized
 * c|c|c 1|1|1 2|2|2
 */

export default {
  computed: {
    blankCol() {
      if (!this.activeDepartureDetails && !this.activeMobilityDetails && !this.activeEntryDetails && !this.activeExternalDetails) {
        return "col-1";
      } else {
        if ((!this.activeDepartureDetails && !this.activeMobilityDetails && this.activeEntryDetails && this.activeExternalDetails)
        || (this.activeDepartureDetails && !this.activeMobilityDetails && !this.activeEntryDetails && !this.activeExternalDetails)
        || (!this.activeDepartureDetails && !this.activeMobilityDetails && this.activeEntryDetails && !this.activeExternalDetails)
        || (!this.activeDepartureDetails && this.activeMobilityDetails && !this.activeEntryDetails && !this.activeExternalDetails)
        || (!this.activeDepartureDetails && !this.activeMobilityDetails && !this.activeEntryDetails && !this.activeExternalDetails)
        || (!this.activeDepartureDetails && !this.activeMobilityDetails && !this.activeEntryDetails && this.activeExternalDetails)
        || (this.activeDepartureDetails && !this.activeMobilityDetails && this.activeEntryDetails && !this.activeExternalDetails)) {
          return "col-1";
        } else {
          return "col-1";
        }
      }
    },
    departuresCol() {
      if (this.activeDepartureDetails && !this.activeMobilityDetails && !this.activeEntryDetails && !this.activeExternalDetails) {
        return "col-2";
      } else {
        if ((!this.activeDepartureDetails && !this.activeMobilityDetails && !this.activeEntryDetails && this.activeExternalDetails)        
        || (!this.activeDepartureDetails && this.activeMobilityDetails && !this.activeEntryDetails && !this.activeExternalDetails)) {
          return "col-2";
        } else {
          return "col-2";
        }
      }
    },
    mobilitiesCol() {
      if (this.activeMobilityDetails && !this.activeEntryDetails && !this.activeDepartureDetails && !this.activeExternalDetail) {
        return "col-2";
      } else {
        if ((!this.activeMobilityDetails && !this.activeEntryDetails && !this.activeDepartureDetails && this.activeExternalDetail)        
        || (!this.activeMobilityDetails && this.activeEntryDetails && !this.activeDepartureDetails && !this.activeExternalDetail)) {
          return "col-2";
        } else {
          return "col-2";
        }
      }
    },
    entriesCol() {
      if ((this.activeEntryDetails && !this.activeMobilityDetails && !this.activeDepartureDetails && !this.activeExternalDetail) 
        || (!this.activeEntryDetails && !this.activeMobilityDetails && this.activeDepartureDetails && !this.activeExternalDetail)) {
        return "col-2";
      } else {
        if ((!this.activeEntryDetails && !this.activeMobilityDetails && !this.activeDepartureDetails && this.activeExternalDetail)
        || (!this.activeEntryDetails && !this.activeMobilityDetails && this.activeDepartureDetails && !this.activeExternalDetail)
        || (!this.activeEntryDetails && this.activeMobilityDetails && this.activeDepartureDetails && this.activeExternalDetail)) {
          return "col-2";
        } else {
          return "col-2";
        }
      }
    },
    externalCol() {
      if (this.activeExternalDetails && !this.activeEntrylDetail && !this.activeMobilityDetails && !this.activeDepartureDetails) {
        return "col-2";
      } else {
        if ((!this.activeExternalDetails && !this.activeEntryDetails && !this.activeMobilityDetails && this.activeDepartureDetails)
        || (!this.activeExternalDetails && this.activeEntryDetails && !this.activeMobilityDetails && this.activeDepartureDetails)) {
          return "col-2";
        } else {
          return "col-2";
        }
      }
    },
  },
};