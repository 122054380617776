<template>
  <div>
    <b-tab v-if="tab" title="Effectifs">
      <slot />
      <hr class="my-4">
      <div v-if="cannotView">
        <b-alert show variant="warning" class="my-4">
          Veuillez sélectionner la variable d'analyse "Métiers" ou "Entités" pour afficher cette
          page.
        </b-alert>
      </div>
      <div v-else-if="notAvailable">
        <b-alert show variant="warning" class="my-4">
          Les données ne sont pas disponibles pour ces paramètres.
        </b-alert>
      </div>
      <HypothesisTableViewer
        v-else
        :key="filters.year"
        :rows="items"
        :current-scenario="scenario"
        :edits="edits"
        :selection="selection"
        :allowed="perimeter"
        :filters="filters"
        :can-filter="false"
      />
      <div v-if="newJobsItems.length" class="my-5">
        <b-row>
          <b-col class="d-flex justify-content-start align-items-center">
            <div class="h5 m-0 text-uppercase font-weight-bold" style="text-decoration: underline">
              NOUVEAUX METIERS CREES POUR CE SCENARIO
            </div>
          </b-col>
        </b-row>
        <hr>
        <CustomJobHypothesisTableViewer
          v-if="newJobsItems && newJobsItems.length >= 1"
          :rows="newJobsItems"
          :current-scenario="scenario"
          :selection="selection"
          :filters="{ type: 'Job' }"
          :can-filter="false"
          :editable="false"
        />
      </div>
      <!-- <pre>{{ newJobsItems }}</pre> -->
    </b-tab>
    <div v-else>
      <slot />
      <hr class="my-4">
      <div v-if="cannotView">
        <b-alert show variant="warning" class="my-4">
          Veuillez sélectionner la variable d'analyse "Métiers" ou "Entités" pour afficher cette
          page.
        </b-alert>
      </div>
      <div v-else-if="notAvailable">
        <b-alert show variant="warning" class="my-4">
          Les données ne sont pas disponibles pour ces paramètres.
        </b-alert>
      </div>
      <HypothesisTableViewer
        v-else
        :key="filters.year"
        :rows="items"
        :current-scenario="scenario"
        :edits="edits"
        :selection="selection"
        :allowed="perimeter"
        :filters="filters"
        :can-filter="false"
      />
    </div>
  </div>
</template>

<script>
import HypothesisTableViewer from '@/components/analysis-tabs/hypothesis/HypothesisTableViewer'
import CustomJobHypothesisTableViewer
  from '@/components/analysis-tabs/hypothesis/customjob/CustomJobHypothesisTableViewer'
import {mapActions, mapGetters} from 'vuex'
import HypothesisMixin from '@/mixins/HypothesisMixin'
import { extractCustomJobsRowsFromMainRows } from '@/services/jobs'

export default {
  components: {
    HypothesisTableViewer,
    CustomJobHypothesisTableViewer
  },
  mixins: [HypothesisMixin],
  props: {
    tab: {
      type: Boolean,
      required: false,
      default: () => true
    },
    filters: {
      type: Object,
      required: true
    },
    scenario: {
      type: String,
      required: true
    },
    perimeter: {
      type: Object,
      required: true
    },
    metadata: {
      type: Object,
      required: false,
      default: () => null
    }
  },
  data () {
    return {
      filtersKeys: {
        Job: 'jobs',
        Unit: 'units',
        Site: 'sites',
        CostCentre: 'costCentres',
      },
      newJobsItems: [],
      loading: false
    }
  },
  computed: {
    notAvailable() {
      return this.filters.type === 'Unit' && this.scenario === 'target';
    },
    cannotView() {
      return false
    },
    edits() {
      return this.metadata?.yearChangesMap?.[this.filters?.year] || [];
    },
    selection() {
      return this.metadata?.filter?.[this.filtersKeys?.[this.filters?.type]] || [];
    },
    items() {
      let rows = [];
      rows = this.parseItemsTreeToNestedRowsArray(
        this.tableDataDetail,
        this.scenario,
        this.filters.type
      );
      return rows;
    },
    ...mapGetters('analysis', {
      tableDataDetail: 'hypoteshis/tableDataDetail'
    })
  },
  async mounted () {
    await this.loadData()
  },
  methods: {
    async loadNewJobItems () {
      if (this.metadata?.customJobs?.length) {
        const promises = []
        this.metadata.customJobs.forEach(id => {
          const keyParts = id.split('/')
          promises.push(this.fetchCustomJob(keyParts[1]))
        })
        const customJobs = await Promise.all(promises)

        // get values in main table from ids
        return extractCustomJobsRowsFromMainRows(
          customJobs,
          this.tableDataDetail,
          this.filters.year
        )
      } else {
        return []
      }
    },
    async loadData () {
      try {
        this.loading = true
        this.newJobsItems = await this.loadNewJobItems()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    ...mapActions({
      fetchCustomJob: 'jobs/fetchCustomJobMetadata'
    })
  }
}
</script>
