<template>
  <b-card no-body style="overflow: hidden">
    <b-tabs
      card
      vertical
      pills
      justified
      nav-class="p-0 border-right"
      nav-wrapper-class="tabs-header-v-large"
      lazy
    >
      <b-tab v-for="i in tabs" :key="i.id" :title="i.name">
        <GenderChart
          v-if="i.id === 'gender'"
          :dataset="dataset"
          v-bind="$attrs"
        />
        <ContractChart
          v-if="i.id === 'contrat'"
          :dataset="dataset"
          v-bind="$attrs"
        />
        <AgeChart
          v-if="i.id === 'age'"
          :dataset="dataset"
          v-bind="$attrs"
        />
        <ManagerChart
          v-if="i.id === 'manager'"
          :dataset="dataset"
          v-bind="$attrs"
        />
        <SeniorityChart
          v-if="i.id === 'seniority'"
          :dataset="dataset"
          v-bind="$attrs"
        />
        <SeniorityGenderChart
          v-if="i.id === 'seniorityGender'"
          :dataset="dataset"
          v-bind="$attrs"
        />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import GenderChart from '@/components/charts/GenderChart'
import ContractChart from '@/components/charts/ContractChart'
import ManagerChart from '@/components/charts/ManagerChart'
import SeniorityChart from '@/components/charts/SeniorityChart'
import SeniorityGenderChart from '@/components/charts/SeniorityGenderChart'
import AgeChart from '@/components/charts/AgeChart'

const tabs = [
  { id: 'gender', name: 'Mixité' },
  { id: 'contrat', name: 'Type de contrat' },
  { id: 'age', name: 'Age' },
  { id: 'manager', name: 'Manager' },
  { id: 'seniority', name: 'Ancienneté' },
  { id: 'seniorityGender', name: 'Ancienneté et Mixité' },
]

export default {
  components: {
    SeniorityChart,
    ManagerChart,
    GenderChart,
    ContractChart,
    SeniorityGenderChart,
    AgeChart
  },
  props: {
    dataset: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      tabs
    }
  }
}
</script>
