<template>
  <div
    class="card-validate"
    :style="`border-top-color: ${data.color}`"
    style="cursor: pointer"
    @click="$emit('selectType', data)"
  >
    <div class="content">
      <div class="icon-circle" :style="`background: ${data.color}`">
        <!-- <i class="fal fa-line-chart"></i> -->
        <i class="fal fa-analytics" />
        <!-- <i class="fas fa-clock"></i> -->
      </div>
      <h3 class="text-uppercase my-3">
        {{ data.title }}
      </h3>
      <div class="description">
        {{ data.desciption }}
      </div>
    </div>
    <div class="mt-3 text-primary">
      <i v-if="data.id === selected.id" class="fas fa-check-circle fa-lg cursor-pointer" />
      <i v-else class="fal fa-circle fa-lg cursor-pointer" />
    </div>
  </div>
</template>
<script>
export default {
  name: 'CardValidate',
  props: {
    data: {
      type: Object,
      required: false,
      default: () => {}
    },
    selected: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {};
  },
};
</script>
<style lang="scss" scoped>
.cursor-pointer {
  cursor: pointer;
}
.card-validate {
  padding: 2rem;
  text-align: center;
  border-radius: 0.5rem;
  box-shadow: 0 3px 5px rgba(#000, 0.2);
  border-top-width: 9px;
  border-top-style: solid;
  height: 100%;
  .content {
    min-height: 220px;
  }
  h3 {
    font-size: 22px;
  }
}
.icon-circle {
  color: #fff;
  background: green;
  font-size: 24px;
  border-radius: 50%;
  width: 64px;
  height: 64px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
