import uniqBy from 'lodash.uniqby';

export const buildEditObject = (edit) => ({
  entityValue: edit.entity,
  retirement: {
    actualValue: edit.departureDetails.retirement,
    customValue: edit.departureDetails.retirement,
  },
  otherDeparture: {
    actualValue: edit.departureDetails.others,
    customValue: edit.departureDetails.others,
  },
  inMobility: {
    actualValue: edit.mobilityDetails.in,
    customValue: edit.mobilityDetails.in,
  },
  outMobility: {
    actualValue: edit.mobilityDetails.out,
    customValue: edit.mobilityDetails.out,
  },
  recruitment: {
    actualValue: edit.entryDetails.recruitement,
    customValue: edit.entryDetails.recruitement,
  },
  external: {
    actualValue: edit.budgetedExternalCount,
    customValue: edit.budgetedExternalCount,
  },
});

export const buildEditObjectDefault = (id) => ({
  entityValue: id,
  retirement: {
    actualValue: 0,
    customValue: 0,
  },
  otherDeparture: {
    actualValue: 0,
    customValue: 0,
  },
  inMobility: {
    actualValue: 0,
    customValue: 0,
  },
  outMobility: {
    actualValue: 0,
    customValue: 0,
  },
  recruitment: {
    actualValue: 0,
    customValue: 0,
  },
  external: {
    actualValue: 0,
    customValue: 0,
  }
});

const replaceValueInPayload = (edits, backupEdits) => {
  if (backupEdits?.length) {
    for (let i = 0; i < backupEdits.length; i++) {
      if(!backupEdits[i].original) {
        backupEdits[i].inMobility.customValue = edits[i].inMobility.customValue || 0
        backupEdits[i].outMobility.customValue = edits[i].outMobility.customValue || 0
        backupEdits[i].retirement.customValue = edits[i].retirement.customValue || 0
        backupEdits[i].recruitment.customValue = edits[i].recruitment.customValue || 0
        backupEdits[i].otherDeparture.customValue = edits[i].otherDeparture.customValue || 0
        backupEdits[i].external.customValue = edits[i].external.customValue || 0
      } else {
        // eslint-disable-next-line max-len,vue/max-len
        backupEdits[i].inMobility.customValue = backupEdits[i].original.mobilityDetails.in - backupEdits[i].inMobility.actualValue + backupEdits[i].inMobility.customValue;
        // eslint-disable-next-line max-len,vue/max-len
        backupEdits[i].outMobility.customValue = backupEdits[i].original.mobilityDetails.out - backupEdits[i].outMobility.actualValue + backupEdits[i].outMobility.customValue;
        // eslint-disable-next-line max-len,vue/max-len
        backupEdits[i].retirement.customValue = backupEdits[i].original.departureDetails.retirement - backupEdits[i].retirement.actualValue + backupEdits[i].retirement.customValue;
        // eslint-disable-next-line max-len,vue/max-len
        backupEdits[i].recruitment.customValue = backupEdits[i].original.entryDetails.recruitement - backupEdits[i].recruitment.actualValue + backupEdits[i].recruitment.customValue;
        // eslint-disable-next-line max-len,vue/max-len
        backupEdits[i].otherDeparture.customValue = backupEdits[i].original.departureDetails.others - backupEdits[i].otherDeparture.actualValue + backupEdits[i].otherDeparture.customValue;
        // eslint-disable-next-line max-len,vue/max-len
        backupEdits[i].external.customValue = backupEdits[i].original.budgetedExternalCount - backupEdits[i].external.actualValue + backupEdits[i].external.customValue;
      }
    }
    return backupEdits;
  } else {
    return edits
  }
};

export const buildScenarioYearGlobalRate = () => {};
export const buildScenarioYearRateMap = () => {};

/*
 * Keep only previous edits to call API /table
 */
// eslint-disable-next-line, max-len
export const buildScenarioYearChangesMapPayload = (
  scenarioTableData,
  years,
  year,
  edits,
  editsBackup
) => {
  years.forEach((y) => {
    if (!edits[y]) {
      edits[y] = [];
    }
  });
  // set valid yearChangesMap with lines for each year for each edit
  const yearChangesMap = {};
  let allIds = [];
  Object.keys(edits).forEach((key) => {
    allIds = allIds.concat(edits[key].map((o) => o.entityValue));
    // for each edit item, replace values
    yearChangesMap[key] = replaceValueInPayload(edits[key], editsBackup[key]);
  });
  Object.keys(edits)
    .reverse()
    .forEach((key) => {
      allIds.forEach((id) => {
        // if there is no edit for this id in this year
        if (!edits[key].filter((o) => o.entityValue === id).length) {
          // retrieve line for this id in scenarioTableData
          const item = scenarioTableData[key]?.Job?.rowMap?.[id]?.target;
          if (item) {
            edits[key].push(buildEditObject(item));
          } else {
            console.log('ELSE ERROR', id)
            edits[key].push(buildEditObjectDefault(id));
          }
        }
      });
    });
  // clean up
  Object.keys(yearChangesMap).forEach((k) => {
    if (!yearChangesMap[k].length || (year && k >= year)) {
      delete yearChangesMap[k];
    }
  });
  return yearChangesMap;
};

export const deduplicateNodes = (nodes, key) => uniqBy(nodes, key);
