<template>
  <div>
    <h5 class="text-uppercase mb-4">
      <span class="fa-stack font-12 align-bottom">
        <i class="fas fa-square fa-stack-2x" :class="iconBgClass" />
        <i class="fas fa-stack-1x fa-inverse" :class="iconClass" />
      </span>
      <span class="mx-2 text-uppercase">{{ title }}</span>
      <span class="fa-stack font-12 align-bottom">
        <i class="fas fa-square fa-stack-2x" :class="iconBgClass" />
        <i class="fas fa-stack-1x fa-inverse" :class="iconClass" />
      </span>
    </h5>
    <template v-if="dataset">
      <div v-for="attr in dataset" :key="attr.entity">
        <h6 v-if="attr.data && attr.data.names && attr.data.names[0]">
          {{ attr.data.names[0].label }}
          <small
            class="ml-2"
            :class="attr.value.evolutionCount >= 0 ? 'text-success' : 'text-danger'"
          >
            <i
              class="fas"
              :class="attr.value.evolutionCount >= 0 ? 'fa-caret-up' : 'fa-caret-down'"
            />
            <b class="mx-1">{{ attr.value.evolutionCount }}</b>
            <i class="fas fa-user" />
          </small>
        </h6>
        <b-progress
          style="background-color: white"
          height="30px"
          :value="attr.value.employeeCount"
          :max="maxValue * 1.2"
          class="progress-text-dark"
          show-progress
        >
          <slot>
            <b-progress-bar
              show-progress
              :value="attr.value.employeeCount"
              class="progress-competence mb-3"
            >
              <b>{{ attr.value.employeeCount }}</b>
            </b-progress-bar>
          </slot>
        </b-progress>
      </div>
    </template>
    <p v-else>
      Aucune donnée disponible
    </p>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    dataset: {
      type: Array,
      required: true,
    },
    maxValue: {
      type: Number,
      required: true,
    },
    iconClass: {
      type: String,
      required: false,
      default: () => '',
    },
    iconBgClass: {
      type: String,
      required: false,
      default: () => '',
    },
  },
};
</script>
<style lang="scss" scoped>
.progress-competence {
  height: 26px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  white-space: nowrap;
  transition: width 0.6s ease;
  border-radius: 15px;
}

.progress-competence b {
  font-size: 15px;
  margin-left: 102%;
}
</style>
