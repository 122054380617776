<template>
  <div>
    <div>
      <b-button variant="link" @click="show">
        <i class="fa fa-plus-circle" />
        <span class="ml-2">Ajouter des hypothèses spécifiques sur un périmètre</span>
      </b-button>
    </div>
    <b-modal
      v-model="open"
      ok-only
      ok-title="Ajouter"
      :ok-disabled="!currentRates"
      content-class="rounded-xl border-0"
      footer-class="d-flex justify-content-center"
      header-class="border-0 d-flex justify-content-center align-items-center text-dark p-4"
      size="xl"
      @ok="save"
    >
      <template #modal-header>
        <i class="fa fa-plus-circle fa-3x" />
        <span class="h5 my-0 ml-3">Ajouter des hypothèses spécifiques</span>
        <b-btn-close @click="close" />
      </template>
      <div>
        <VerticalStep
          title="Définissez le périmètre sur lequel vous souhaitez
           appliquer des hypothèses spécifiques"
          :step="1"
        >
          <b-form-group label="Métiers">
            <JobSelector
              :allowed="filters.jobs"
              style="max-width: 500px"
              @selected="onUpdateJobs"
            />
          </b-form-group>
        </VerticalStep>
        <VerticalStep
          :step="2"
          title="Définissez vos hypothèses pour ce périmètre"
        >
          <b-table-lite
            :key="renderKey"
            :fields="fields"
            :items="items"
            class="text-center"
          >
            <template #cell()="{ item,field }">
              <RatesCell
                :id="field.key"
                :value="round(item[field.key])"
                :editing="!!currentRates"
                :setter="percentToFloat"
                @update="onUpdateCell($event, field.key, item.key)"
              />
            </template>
            <template #cell(minimumRetirementAge)="{ item, field }">
              <RatesCell
                :id="field.key"
                :editing="!!currentRates"
                :age="true"
                :value="parseYearDataTypeValue(item[field.key])"
                :formatter="withYearSuffix"
                :setter="ageObjectToFloat"
                @update="onUpdateCell($event, field.key, item.key)"
              />
            </template>
            <template #cell(label)="{ item }">
              <div style="width: 69px;">
                <span>{{ item.label }}</span>
              </div>
            </template>
          </b-table-lite>
        </VerticalStep>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  buildDefaultRateItem,
  findJobRatesFromRates,
  percentToFloat,
  ageObjectToFloat,
  withYearSuffix,
  round, computeAverageRatesFromManyRates
} from '@/services/rates'
import JobSelector from '@/components/selectors/JobSelector'
import VerticalStep from '@/components/ui/VerticalStep'
import RatesCell from '@/components/scenario/rates/RatesCell'

export default {
  components: { RatesCell, VerticalStep, JobSelector },
  props: {
    rates: {
      type: Object,
      required: true,
    },
    filters: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      renderKey: 0,
      items: [],
      open: false,
      currentRates: null,
      fields: [
        { key: 'label', label: '' },
        { key: 'departureRate', label:'Taux de départ (hors retraite)', tdClass: 'text-secondary' },
        { key: 'retirementRate', label: 'Taux de départ à la retraite', tdClass: 'text-secondary' },
        // eslint-disable-next-line vue/max-len
        {
          key: 'minimumRetirementAge',
          label: 'Age minimum départ retraite',
          tdClass: 'text-secondary',
        },
        { key: 'mobilityRate', label: 'Taux de mobilité interne', tdClass: 'text-dark' },
        { key: 'recruitingRate', label: 'Taux de recrutement', tdClass: 'text-info' },
        // Added new field called "externalConsultantRate" for "Taux d'externes" new column
        { key: 'externalConsultantRate', label: 'Taux d’externes', tdClass: 'text-info' },
      ],
      lastCustomRates: {},
      percentToFloat,
      ageObjectToFloat,
      withYearSuffix,
      round
    }
  },
  mounted () {
    this.items = this.buildItems(this.currentRates)
  },
  methods: {
    buildItems (inputRates) {
      let keys = Object
        .keys(this.rates.yearGlobalRate)
        .map(key => buildDefaultRateItem(key, key, this.rates.target.globalRates))
      keys.unshift(buildDefaultRateItem('target', 'Sur 3 ans', this.rates.target.globalRates))

      // if currentRates is set
      if (inputRates) {
        keys = keys.map(rate => {
          if (rate.key === 'target') {
            return {
              key: rate.key,
              label: rate.label,
              jobs: inputRates.jobs,
              ...inputRates.rates[rate.key]
            }
          } else {
            return {
              key: rate.key,
              label: rate.label,
              jobs: inputRates.jobs,
              ...inputRates.rates.yearGlobalRate[rate.key]
            }
          }
        })
      }
      return keys
    },
    show() {
      this.open = true
      this.currentRates = null
    },
    close() {
      this.open = false
    },
    onUpdateJobs (jobs) {
      if (!jobs.length && !jobs[0]?._id) {
        this.items = this.buildItems(null)
        return
      }

      // get values for all jobs
      const jobsRates = []
      jobs.forEach(job => {
        jobsRates.push(findJobRatesFromRates(job._id, this.rates))
      })

      // get average
      const averageRates = computeAverageRatesFromManyRates(jobsRates)

      this.currentRates = {
        jobs: jobs.map(j => j._id),
        rates: averageRates
      }
      this.lastCustomRates = JSON.parse(JSON.stringify(this.currentRates))
      this.items = this.buildItems(this.currentRates)
    },
    onUpdateCell (valueObject, field, item) {
      if (item === 'target') {
        this.currentRates.rates[item][field] = parseFloat(valueObject.value)
        // override children values
        this.setChildrenValues(field, parseFloat(valueObject.value))
      } else {
        this.currentRates.rates.yearGlobalRate[item][field] = parseFloat(valueObject.value)
      }
      this.items = this.buildItems(this.currentRates) // this.renderKey++
    },
    setChildrenValues (field, value) {
      Object.keys(this.currentRates.rates.yearGlobalRate).map(item => {
        this.currentRates.rates.yearGlobalRate[item][field] = value
      })
    },
    save () {
      this.$emit('created', this.lastCustomRates)
      this.$emit('add', this.currentRates)
      this.close()
    }
  },
};
</script>