<template>
  <div class="py-3">
    <b-checkbox v-model="isActive" switch @input="updateIsActive">
      <small :class="isActive ? '' : 'text-muted'">Afficher les étiquettes de données</small>
    </b-checkbox>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex';

export default {
  data() {
    return {
      isActive: false,
    };
  },
  computed: {
    ...mapGetters({
      showDataLabels: 'app/showDataLabels',
    }),
  },
  mounted() {
    this.isActive = this.showDataLabels;
  },
  methods: {
    updateIsActive() {
      this.setDataLabels(this.isActive);
    },
    ...mapActions({
      setDataLabels: 'app/setDataLabels',
    }),
  },
};
</script>
