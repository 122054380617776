<template>
  <div class="my-3 table-responsive">
    <table class="table table-middle small">
      <thead class="text-muted">
        <tr>
          <th />
          <th>Nom</th>
          <th>Date de création</th>
          <th>Date de modification</th>
          <th />
          <th class="text-center">
            Statut
          </th>
          <th v-if="!archive && !shared && !readonly" class="text-center">
            Partages
          </th>
          <th v-if="shared" class="text-center">
            Créateur
          </th>
          <th v-if="!readonly" />
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, key) in scenarios"
          :key="key"
          :class="{ 'bg-primary-lighten': mergingScenarios.includes(item.ID) }"
        >
          <td :class="`${item.isReady ? 'text-primary' : 'text-muted'}`">
            <div v-if="isMerging">
              <b-check
                :disabled="!canBeMerged(item)"
                :checked="mergingScenarios.includes(item.ID)"
                @change="select(item)"
              />
            </div>
            <div v-else-if="isComparing">
              <b-check
                :disabled="!canBeCompared(item)"
                :checked="comparingScenarios.includes(item.ID)"
                @change="compareSelection(item)"
              />
            </div>
            <i
              v-else
              v-b-tooltip
              class="fas fa-star"
              :title="getItemTooltip(item)"
            />
          </td>
          <td>
            <b-link v-if="archive" @click="viewArchived(item)">
              {{ item.label }}
            </b-link>
            <b-link v-if="!archive && item.isReady" @click="view(item)">
              {{ item.label }}
            </b-link>
            <span v-if="!item.isReady && !archive">{{ item.label }}</span>
            <div v-if="false">
              <small>{{ getItemJobs(item) }}</small>
            </div>
            <div v-if="false">
              <small>{{ getItemSites(item) }}</small>
            </div>
          </td>
          <td class="text-nowrap" style="opacity: 0.8">
            {{ formatDate(item.createdAt) }}
          </td>
          <td class="text-nowrap" style="opacity: 0.8">
            {{ formatDate(item.updatedAt) }}
          </td>
          <td>
            <CommentTooltip v-if="item.ID && item.comment" :id="item.ID" :comment="item.comment" />
          </td>
          <td class="text-center">
            <b-badge
              :variant="getStatus(item) === 'Privé' ? 'primary' : 'secondary'"
              pill
              class="py-2 w-100"
            >
              {{ getStatus(item) }}
            </b-badge>
          </td>
          <td v-if="!readonly && !archive && !shared" class="text-center">
            <b-avatar-group size="40px">
              <b-avatar
                v-for="user in getUsersFromList(item.sharedUsers)"
                :key="user.id"
                v-b-tooltip
                :text="getFirstLetters(`${user.firstName.trim()} ${user.lastName.trim()}`)"
                class="text-capitalize"
                :title="`${user.firstName.trim()} ${user.lastName.trim()}`"
              />
            </b-avatar-group>
          </td>
          <td v-if="shared" class="text-center">
            <b-avatar-group class="d-flex justify-content-center">
              <b-avatar
                v-b-tooltip
                :text="getFirstLetters(getOwnerName(item))"
                :title="getOwnerName(item)"
              />
            </b-avatar-group>
          </td>
          <td v-if="!readonly" class="text-nowrap text-center">
            <b-dropdown
              v-if="!item.default"
              no-caret
              right
              variant="link btn-sm"
              toggle-class="text-decoration-none"
              boundary="viewport"
            >
              <template #button-content>
                <span class="text-muted">
                  <i class="fas fa-ellipsis-v fa-lg" />
                </span>
              </template>
              <b-dropdown-item
                v-if="!archive && !shared"
                :disabled="isPending(item)"
                @click="edit(item)"
              >
                <i class="fal fa-pencil" />
                <span class="ml-2">Editer</span>
              </b-dropdown-item>
              <ShareScenarioModal
                :key="key"
                :disabled="!item.isFinalized || !item.isReady"
                :users="users"
                :shared-with="item.sharedUsers || []"
                @share="share(item, $event)"
              >
                <b-dropdown-item
                  v-if="!archive && !shared"
                  :disabled="!item.isFinalized || !item.isReady"
                >
                  <i class="fal fa-share" />
                  <span class="ml-2">Partager</span>
                </b-dropdown-item>
              </ShareScenarioModal>
              <b-dropdown-item v-if="!archive" @click="duplicate(item)">
                <i class="fal fa-clone" />
                <span class="ml-2">Dupliquer</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!archive"
                :disabled="!!mergingScenarios.length || !item.isReady"
                @click="merge(item)"
              >
                <i class="fal fa-puzzle-piece" />
                <span class="ml-2">Assembler</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="!archive && !shared" @click="handleArchiveScenario(item)">
                <i class="fal fa-archive" />
                <span class="ml-2">Archiver</span>
              </b-dropdown-item>
              <b-dropdown-item v-if="archive" @click="handleRestoreScenario(item)">
                <i class="fal fa-undo" />
                <span class="ml-2">Restaurer</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-if="!shared"
                :disabled="isPending(item)"
                @click="confirmDeleteScenario(item)"
              >
                <i class="fal fa-trash-alt" />
                <span class="ml-2">Supprimer</span>
              </b-dropdown-item>
            </b-dropdown>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
import ShareScenarioModal from '@/components/ShareScenarioModal.vue'
import CommentTooltip from '@/components/ui/CommentTooltip'

export default {
  components: {
    CommentTooltip,
    ShareScenarioModal,
  },
  props: {
    status: {
      type: String,
      required: false,
      default: () => 'SELECT'
    },
    scenarios: {
      type: Array,
      required: true,
    },
    users: {
      type: Array,
      required: true,
    },
    archive: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    shared: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    mergingScenarios: {
      type: Array,
      required: false,
      default: () => []
    },
    comparingScenarios: {
      type: Array,
      required: false,
      default: () => []
    },
    selectableComparingScenarios: {
      type: Array,
      required: false,
      default: () => []
    },
    selectableMergingScenarios: {
      type: Array,
      required: false,
      default: () => []
    }
  },
  data() {
    return {
      sharing: false,
    }
  },
  computed: {
    isMerging () {
      return this.status === 'MERGE' && this.mergingScenarios.length
    },
    isComparing () {
      return this.status === 'COMPARE' && this.mergingScenarios.length === 0
    },
    isSelecting () {
      return this.status === 'SELECT'
        && this.mergingScenarios.length === 0
        && this.comparingScenarios.length === 0
    }
  },
  methods: {
    getItemJobs (item) {
      return item?.filter?.jobs?.toString() || '-'
    },
    getItemSites (item) {
      return item?.filter?.sites?.toString() || '-'
    },
    canBeCompared (item) {
      if (!item.isReady || item.default) {
        return false
      }
      if (this.isComparing
          && this.comparingScenarios.length === 1
          && this.selectableComparingScenarios.length
          && !this.comparingScenarios.includes(item.ID)
      ) {
        return this.selectableComparingScenarios.map(s => s.ID).includes(item.ID)
      }
      if (this.comparingScenarios.length === 2 && !this.comparingScenarios.includes(item.ID)) {
        return false
      }
      return true
    },
    canBeMerged (item) {
      if (!item.isReady || item.default) {
        return false
      }
      if (this.isMerging
          && this.mergingScenarios.length
          && this.selectableMergingScenarios.length
      ) {
        return this.selectableMergingScenarios.map(s => s.ID).includes(item.ID)
      }
      return true
    },
    select (scenario) {
      this.$emit('update-merge', scenario?.ID)
    },
    compareSelection (scenario) {
      this.$emit('update-compare', scenario?.ID)
    },
    merge (scenario) {
      this.$emit('merge', scenario?.ID)
    },
    getStatus(scenario) {
      if (this.archive) {
        return 'Privé';
      }
      if (scenario.default || scenario.sharedUsers?.length) {
        return 'Partagé';
      }
      return 'Privé';
    },
    getUsersFromList(ids) {
      if (!ids) return [];
      return this.users.filter((user) => ids.includes(user.id));
    },
    getOwnerName(item) {
      const index = this.users.findIndex((user) => item.owner === user.id);
      const user = this.users[index];
      return user ? `${user.firstName} ${user.lastName}` : '-';
    },
    getItemTooltip(item) {
      if (item.isReady) {
        return 'Le scénario est prêt';
      }
      if (this.isPending(item)) {
        return 'En cours de traitement';
      }
      return 'Brouillon';
    },
    isPending(item) {
      return !item.isReady && item.isFinalized;
    },
    formatDate(str) {
      if (!str) return '';
      const d = Date.parse(str);
      return new Date(d).toLocaleDateString();
    },
    view(item) {
      if (item.isReady) {
        this.$router.push(`/collab/analysis/${item.ID}?default=${item.default ? '1' : '0'}`);
      }
    },
    viewArchived(item) {
      this.$router.push(`/collab/scenario/view/${item.ID}`);
    },
    edit(item) {
      this.$router.push(`/collab/scenario/edit/${item.ID}`);
    },
    async share(scenario, users) {
      try {
        this.setLoaderStatus(true);
        await this.shareScenario({ scenario, users });
        this.$emit('changed');
        this.$bvToast.toast('Le scénario a été partagé avec succès.', {
          title: 'Partage',
          variant: 'success',
          solid: true,
        });
      } catch (err) {
        console.log('err', err);
        this.setLoaderStatus(false);
      }
    },
    async deletingScenario(scenario) {
      try {
        this.setLoaderStatus(true);
        await this.deleteScenario(scenario);
        this.$emit('changed');
      } catch (err) {
        console.log('err', err);
        this.setLoaderStatus(false);
      }
    },
    confirmDeleteScenario(scenario) {
      const opts = {
        okTitle: 'Supprimer',
        cancelTitle: 'Annuler',
      };
      this.$bvModal
        .msgBoxConfirm('Etes-vous sûr de vouloir supprimer ce scénario ?', opts)
        .then((value) => {
          if (value) {
            this.deletingScenario(scenario);
          }
        })
        .catch((err) => console.error('error', err));
    },
    async duplicate(scenario) {
      try {
        this.setLoaderStatus(true);
        await this.duplicateScenario(scenario);
        await this.$emit('changed');
      } catch (err) {
        console.log('err', err);
        this.setLoaderStatus(false);
      }
    },
    async handleArchiveScenario(scenario) {
      try {
        this.setLoaderStatus(true);
        await this.archiveScenario(scenario.ID);
        await this.$emit('changed');
      } catch (err) {
        console.log('err', err);
        this.setLoaderStatus(false);
      }
    },
    async handleRestoreScenario(scenario) {
      try {
        this.setLoaderStatus(true);
        await this.restoreScenario(scenario.ID);
        await this.$emit('changed');
      } catch (err) {
        console.log('err', err);
        this.setLoaderStatus(false);
      }
    },
    getFirstLetters(str) {
      const words = str.trim().split(' ');
      const letters = words.map((w) => w[0]);
      return letters.join('').toUpperCase();
    },
    ...mapActions({
      deleteScenario: 'scenarios/deleteScenario',
      duplicateScenario: 'scenarios/duplicateScenario',
      archiveScenario: 'scenarios/archiveScenario',
      restoreScenario: 'scenarios/restoreScenario',
      setLoaderStatus: 'app/setLoaderStatus',
      shareScenario: 'scenarios/shareScenario',
    }),
  },
};
</script>
