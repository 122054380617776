<template>
  <b-overlay :show="!Object.keys(rows).length">
    <RatesTableRowHeader />
    <RatesTableRow v-if="rows && rows.current" title="Existant" :item="rows.current" />
    <!-- <RatesTableRow
      title="Scénario cible"
      :item="rows.target"
      :children="rows.yearGlobalRate"
      restorable
      :editable="!readonly"
      @toggle="toggle"
      @restore="restore"
      @editing="close"
      @update="saveRates($event)"
    /> -->
    <RatesTableRowYear
      v-for="(yearValue, year) in rows.yearGlobalRate"
      :key="`target-year-${year}`"
      :title="`Cible ${year}`"
      :year="year"
      :item="yearValue"
      restorable
      :editable="!readonly"
      @toggle="toggle"
      @restore="restore"
      @editing="close"
      @update="saveRates($event)"
    />
    <RatesTableRow
      v-for="(custom, i) in rows.customs"
      :key="i"
      :title="custom.shortTitle"
      :item="custom.item"
      :children="custom.children"
      :editable="!readonly"
      removable
      @remove="remove(i, custom)"
    />
  </b-overlay>
</template>

<script>
import RatesTableRowHeader from '@/components/scenario/rates/RatesTableRowHeader.vue';
import RatesTableRow from '@/components/scenario/rates/RatesTableRow.vue';
import RatesTableRowYear from '@/components/scenario/rates/RatesTableRowYear.vue';

export default {
  components: {
    RatesTableRowHeader,
    RatesTableRow,
    RatesTableRowYear
  },
  props: {
    rows: {
      type: Object,
      required: true,
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false
    }
  },
  data() {
    return {
      open: false,
      key: 0,
    };
  },
  methods: {
    toggle() {
      this.open = !this.open;
    },
    close() {
      this.open = false;
    },
    saveRates(data) {
      this.$emit('update:rates', data);
    },
    rerender() {
      // eslint-disable-next-line no-plusplus
      this.key++;
    },
    restore() {
      this.$emit('restore')
    },
    remove(index, item) {
      this.$emit('remove', { index, item })
    },
  },
};
</script>
