<template>
  <b-card no-body>
    <b-tabs
      card
      pills
      justified
      nav-class="p-0 border-right"
      nav-wrapper-class="tabs-header-h"
      lazy
    >
      <b-tab active title="Départs hors retraite">
        <PopulationTab v-bind="$attrs" :dataset="$attrs['data-out']" />
      </b-tab>
      <b-tab title="Arrivées">
        <PopulationTab v-bind="$attrs" :dataset="$attrs['data-in']" />
      </b-tab>
    </b-tabs>
  </b-card>
</template>

<script>
import PopulationTab from '@/components/staff/PopulationTab'
export default {
  components: { PopulationTab }
}
</script>
