<template>
  <b-tab title="Commentaire">
    <slot />
    <hr class="my-4">
    <div>
      <b>Commentaire</b>
    </div>
    <div class="my-4 p-2">
      {{ metadata.comment || 'Aucun commentaire' }}
    </div>
  </b-tab>
</template>

<script>
export default {
  props: {
    metadata: {
      type: Object,
      required: true
    }
  }
}
</script>
