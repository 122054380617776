<template>
  <div class="main">
    <div class="zoom-buttons">
      <b-button-group vertical>
        <b-button variant="primary" class="mb-1 zoomin">
          <i class="fas fa-plus" />
        </b-button>
        <b-button variant="primary" class="zoomout">
          <i class="fas fa-minus" />
        </b-button>
      </b-button-group>
    </div>
    <svg class="d3-tree-vii width-100-percent container-border">
      <g class="container" />
    </svg>
    <div id="tooltip" class="d-none">
      <div id="tooltip__container">
        <p id="tooltip__title" class="text-center font-weight-bold" />
        <p id="tooltip__description" />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex';
import * as d3 from 'd3';

const colors = [
  '#7cb5ec',
  '#434348',
  '#90ed7d',
  '#f7a35c',
  '#8085e9',
  '#f15c80',
  '#e4d354',
  '#2b908f',
  '#f45b5b',
  '#91e8e1',
];

export default {
  name: 'TreeChart',
  props: {
    check: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      treeData: null,
      index: 0,
      duration: 750,
      root: null,
      nodes: [],
      links: [],
      dTreeData: null,
      margin: {
        top: 20,
        right: 90,
        bottom: 50,
        left: 100,
      },
      selectedNode: null,
      svg: null,
      container: null,
      zoomRatio: 1.2,
    };
  },
  mounted() {
    this.treeData = {
      name: 'Compétences',
      color: '#E9DB00',
    };
    this.treeData.children = this.formatSkillTree(JSON.parse(JSON.stringify(this.skillTree)));
    this.initSvg(this.treeData);
  },
  computed: {
    treemap() {
      return d3.tree().nodeSize([45, 60]);
    },
    ...mapGetters('analysis/competences', {
      globalValues: 'globalValuesCompetence',
    }),
  },
  watch: {
    globalValues () {
      this.refreshAllData();
    },
    check () {
      this.refreshAllData();
    }
  },
  methods: {
    refreshAllData() {
      // when the check changed (e.g. predicted => current)
      // we reload data
      this.treeData.children = this.formatSkillTree(JSON.parse(JSON.stringify(this.skillTree)));

      // we refresh svg rendering with new data set
      this.refreshTreeWithData(this.treeData)
    },
    refreshTreeWithData(data) {
      this.root = this.getRoot(data);
      this.root.x0 = 0;
      this.root.y0 = 0;
      this.update(this.root);
    },
    getRandomInt(min, max) {
      return Math.floor(Math.random() * (max - min + 1)) + min;
    },
    formatSkillTree(items) {
      const children = [];
      for (let [idx, skill] of items.entries()) {
        const globalValue = this.globalValues[skill._id];
        if (globalValue) {
          const val = Math.round(globalValue[this.check].averageScore/25);
          const empCount = globalValue[this.check].employeeCount;
          if (skill.children) {
            skill = {
              id: skill._id,
              name: skill.names[0].label,
              color: colors[idx % 10],
              children: this.recursiveSkillTree(skill.children, idx % 10),
              averageScore: val,
              employeeCount: empCount,
            };
          } else {
            skill = {
              id: skill._id,
              name: skill.names[0].label,
              color: colors[idx % 10],
              averageScore: val,
              employeeCount: empCount,
            };
          }
          children.push(skill);
        }
      }
      return children;
    },
    recursiveSkillTree(items, idxColor) {
      const children = [];
      for (let skill of items) {
        const globalValue = this.globalValues[skill._id]
        if (globalValue) {
          const val = Math.round(globalValue[this.check].averageScore/25);
          const empCount = globalValue[this.check].employeeCount;
          if (skill.children) {
            skill = {
              id: skill._id,
              name: skill.names[0].label,
              color: colors[idxColor],
              children: this.recursiveSkillTree(skill.children, idxColor),
              averageScore: val,
              employeeCount: empCount,
            };
          } else {
            skill = {
              id: skill._id,
              name: skill.names[0].label,
              color: colors[idxColor],
              averageScore: val,
              employeeCount: empCount,
            };
          }
          children.push(skill);
        }
      }
      return children;
    },
    initSvg(treeData) {
      const { clientWidth } = document.body;
      const width = Math.floor(clientWidth * 0.4);
      this.svg = d3.select('svg.d3-tree-vii').attr('viewBox', [50, -10, width, 30]);
      this.container = this.svg.select('g.container');
      this.setZoomOption();
      this.refreshTreeWithData(treeData)
    },
    setZoomOption() {
      const zoom = d3
        .zoom()
        .scaleExtent([0.3 , 8])
        .on('zoom', () => {
          d3.select('g.container').attr('transform', d3.event.transform);
        });
      d3.select('.zoomin').on('click', () => {
        zoom.scaleBy(this.svg.transition().duration(this.duration), this.zoomRatio);
      });
      d3.select('.zoomout').on('click', () => {
        zoom.scaleBy(this.svg.transition().duration(this.duration), 1 / this.zoomRatio);
      });
      this.svg.call(zoom).on('wheel', null);
      zoom.scaleBy(this.svg.transition().duration(this.duration), 0.6);
    },
    getRoot(treeData) {
      const root = d3.hierarchy(treeData, (d) => d.children);
      root.descendants().forEach((d) => {
        if (d.children) {
          d.children.forEach((child) => {
            if (child.children) {
              this.collapseChildren(child);
            }
          });
        }
      });
      const { clientHeight } = document.body;
      root.x0 = (clientHeight - 72) / 2;
      root.y0 = 0;
      return root;
    },
    collapseChildren(child) {
      child._children = child.children;
      child.children = null;
      child._children.forEach((item) => {
        if (item.children) {
          this.collapseChildren(item);
        }
      });
    },
    clickNode(d) {
      if (d.children || d._children) {
        if (d.children) {
          this.$set(d, '_children', d.children);
          d.children = null;
        } else if (d._children) {
          this.$set(d, 'children', d._children);
          d._children = null;
        }
        this.$nextTick(() => {
          this.update(d);
          this.$emit('selection', d)
          /* setTimeout(() => {
            this.mouseoverNode(d);
          }, this.duration + 50); */
        });
      }
    },
    mouseoverNode(d) {
      const ids = this.getRelatedSkills(d.data.id);
      for (const id of ids) {
        d3.select("path[id='" + id + "']").attr('stroke-width', 4);
        d3.select("circle[id='circle_" + id + "']").attr('stroke-width', 4);
      }
    },
    mouseleaveNode(d) {
      const ids = this.getRelatedSkills(d.data.id);
      for (const id of ids) {
        d3.select("path[id='" + id + "']").attr('stroke-width', 1);
        d3.select("circle[id='circle_" + id + "']").attr('stroke-width', 1);
      }
    },
    getRelatedSkills(id) {
      const node = this.recursiveRelatedSkills(this.root.children, id);
      let childrenIds = [];
      let parentIds = [];
      if (node) {
        if (node.children) childrenIds = this.setChildrenIds(node.children);
        parentIds = this.setParentIds(node.parent);
      }
      return [...childrenIds, id, ...parentIds];
    },
    recursiveRelatedSkills(skills, id) {
      for (const skill of skills) {
        if (skill.data.id === id) {
          return skill;
        } else {
          if (skill.children) {
            const node = this.recursiveRelatedSkills(skill.children, id);
            if (node) return node;
          }
        }
      }
      return null;
    },
    setChildrenIds(arr) {
      const ids = [];
      for (const item of arr) {
        ids.push(item.data.id);
        if (item.children) ids.push(...this.setChildrenIds(item.children));
      }
      return ids;
    },
    setParentIds(obj) {
      let ids = [];
      if (obj.parent) ids.push(...this.setParentIds(obj.parent));
      if (obj.data.id) ids.push(obj.data.id);
      return ids;
    },
    diagonal(s, d) {
      return `M ${d.y} ${d.x}
              C ${(s.y + d.y) / 2} ${d.x},
              ${(s.y + d.y) / 2} ${s.x},
              ${s.y} ${s.x}`;
    },
    getNodesAndLinks() {
      this.dTreeData = this.treemap(this.root);
      this.nodes = this.dTreeData.descendants();
      this.links = this.dTreeData.descendants().slice(1);
    },
    rectSizeAndMargin(d) {
      if (!(d.children || d._children)) {
        if (d.data.averageScore === 1) return '60';
        if (d.data.averageScore === 2) return '80';
        if (d.data.averageScore === 3) return '100';
        if (d.data.averageScore === 4) return '120';
      }
      return '0';
    },

    // horizontal text
    update(source) {
      this.getNodesAndLinks();
      this.nodes.forEach((d) => {
        d.y = d.depth * 360;
      });
      const node = this.container.selectAll('g.node').data(this.nodes, (d) => {
        return d.id || d.id + this.index;
      });

      const nodeEnter = node
        .enter()
        .append('g')
        .attr('class', 'node')
        .on('click', this.clickNode)
        .attr('transform', (d) => {
          return `translate(${d.y}, ${d.x})`;
        })
        .on('mouseover', this.mouseoverNode)
        .on('mouseleave', this.mouseleaveNode);

      // Add circle for the nodes
      nodeEnter
        .append('circle')
        .attr('class', 'node')
        .attr('r', 1e-6)
        .style('fill', (d) => {
          return d.children || d._children ? d.data.color : '#fff' || '#c9e4ff';
        });

      nodeEnter
        .append('text')
        .attr('dy', '.35em')
        .attr('x', (d) => {
          if (d.data.name === 'Compétences') return -13;
          return d.children || d._children ? 13 : parseInt(this.rectSizeAndMargin(d)) + 10;
        })
        .attr('text-anchor', function(d) {
          // return d.children || d._children ? 'end' : 'start';
          if (d.data.name === 'Compétences') return 'end';
          return 'start';
        })
        .text(function(d) {
          const isLeaf = !(d.children || d._children);
          // if (!isLeaf) {
          const val = d.data.employeeCount ? `(${d.data.employeeCount})` : '';
          return d.data.name.length > 30
            ? `${d.data.name.substring(0, 30)}... ${val}`
            : `${d.data.name} ${val}`;
          // }
          return d.data.name;
        })
        .attr('class', 'update');

      nodeEnter
        .append('rect')
        .attr('class', 'rect-node')
        .attr('id', (d) => { return `rect_${d.data.id}`; })
        .attr('width', 1e-6)
        .attr('y', -4)
        .attr('x', 4)
        .attr('height', 8)
        .on('mouseover', (d) => {
          d3.select("div[id='tooltip']")
            .style('right', '50px')
            .style('top', '0')
            .style('border', `4px solid ${d.data.color}`)
            .attr('class', 'd-block');
          d3.select("div[id='tooltip__container']")
            .style('background-color', () => { return d.data.color; });
          d3.select("p[id='tooltip__title']")
            .text(() => {
              // if (d.data.name.length > 48) return `${d.data.name.substring(0, 48)}...`;
              return d.data.name;
            });
          const span = d3.select("p[id='tooltip__description']")
            .text(`Niveau moyen: `)
            .append('span');
          for (let i = 1; i <= d.data.averageScore; i++) {
            span
              .append('i')
              .style('color', 'yellow')
              .attr('class', 'fas fa-star ml-1');
          }
          for (let i = d.data.averageScore; i < 4; i++) {
            span
              .append('i')
              .style('color', 'white')
              .attr('class', 'fas fa-star ml-1');
          }
          d3.select("p[id='tooltip__description']")
            .append('div')
            .text(`Nombre d'employés: `)
            .append('span')
            .text(d.data.employeeCount);
        })
        .on('mouseleave', () => {
          d3.select("div[id='tooltip']")
            .attr('class', 'd-none');
        })
        .style('fill', (d) => {
          return d.data.color;
        });

      // Transition nodes to their new position.
      const nodeUpdate = nodeEnter
        .merge(node)
        .transition()
        .duration(this.duration);

      // Update the node attributes and style
      nodeUpdate
        .select('circle.node')
        .attr('r', (d) => {
          if (!(d.children || d._children)) return '0';
          return '10';
        })
        .style('fill', (d) => {
          return d.children || d._children ? d.data.color : '#fff';
        })
        .style('stroke', (d) => {
          return d.data.color;
        })
        .style('stroke-width', 1)
        .attr('id', (d) => {
          return `circle_${d.data.id}`;
        })
        .attr('cursor', (d) => {
          return d.data.children ? 'pointer' : 'default';
        });

      nodeUpdate
        .select('rect.rect-node')
        .style('display', (d) => {
          return d.children || d._children ? 'none' : '';
        })
        .attr('width', (d) => {
          return this.rectSizeAndMargin(d);
        })

      nodeUpdate
        .select('text')
        .style('fill-opacity', 1);

      // Transition exiting nodes to the parent's new position.
      const nodeExit = node
        .exit()
        .transition()
        .duration(this.duration)
        .remove();
      nodeExit.select('circle').attr('r', 1e-6);
      nodeExit.select('rect').attr('width', 1e-6);
      nodeExit.select('text').style('fill-opacity', 1e-6);

      // *************************** Links section *************************** //

      // Update the links…
      const link = this.container.selectAll('path.link').data(this.links, (d) => d.id);

      // Enter any new links at the parent's previous position.
      const linkEnter = link
        .enter()
        .insert('path', 'g')
        .attr('class', 'link')
        .attr('d', () => {
          const o = { x: source.x0, y: source.y0 };
          return this.diagonal(o, o);
        })
        .attr('id', (d) => {
          return d.data.id;
        })
        .attr('fill', 'none')
        .attr('stroke-width', 1)
        .attr('stroke', (d) => {
          return d.data.color;
        });

      // Transition links to their new position.
      const linkUpdate = linkEnter.merge(link);
      linkUpdate
        .transition()
        .duration(this.duration)
        .attr('d', (d) => this.diagonal(d, d.parent));

      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(this.duration)
        .attr('d', () => {
          const o = { x: source.x, y: source.y };
          return this.diagonal(o, o);
        })
        .remove();

      // Stash the old positions for transition.
      this.nodes.forEach((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    },

    // curved text
    /* update(source) {
      // let self = this
      this.getNodesAndLinks();
      this.nodes.forEach((d) => {
        d.y = d.depth * 180;
      });
      const node = this.container
        .selectAll('g.node')
        .data(this.nodes, function(d) { // eslint-disable-line
          return d.id || d.id + this.index;
        });
      // Enter any new sources at the parent's previous position.
      const nodeEnter = node
        .enter()
        .append('g')
        .attr('class', 'node')
        .on('click', this.clickNode)
        // .on('dblclick', this.dblclickNode)
        .attr('transform', function() { // eslint-disable-line
          return `translate(${source.y0},${source.x0})`;
        });
      // Add circle for the nodes
      nodeEnter.append('circle')
        .attr('class', 'node')
        .attr('r', 1e-6)
        .style('fill', function(d) { // eslint-disable-line
          return d._children ? '#31415F' : (d.data.color || '#c9e4ff'); // eslint-disable-line
        });

      nodeEnter
        .append('text')
        .attr('dy', '.35em')
        .attr('x', (d) => d.children || d._children ? -13 : 13) // eslint-disable-line
        .attr('text-anchor', function(d) { // eslint-disable-line
          return d.children || d._children ? 'end' : 'start'; // eslint-disable-line
        })
        .text(function(d) { // eslint-disable-line
          const val = d.data.value ? `(${d.data.value})` : '';
          return d.data.name.length > 16
            ? `${d.data.name.substring(0, 16)}...(${val})`
            : `${d.data.name}${val}`;
        })
        .attr('class', 'update');
      // Transition nodes to their new position.
      const nodeUpdate = nodeEnter
        .merge(node)
        .transition()
        .duration(this.duration)
        .attr('transform', function(d) { // eslint-disable-line
          return `translate(${d.y}, ${d.x})`;
        });
      // Update the node attributes and style
      nodeUpdate.select('circle.node')
        .attr('r', 10)
        .style('fill', function(d) { // eslint-disable-line
          return d._children // eslint-disable-line
            ? '#31415F'
            : (d.data.value === 0 ? '#fff' : (d.data.color || '#c9e4ff'));
        })
        .style('stroke', function(d) { // eslint-disable-line
          return d.data.value === 0 ? '#4783FC' : (d.data.color || '#c9e4ff');
        })
        .style('stroke-width', function(d) { // eslint-disable-line
          return d.data.value === 0 ? '2px' : 0;
        })
        .attr('cursor', 'pointer');
      nodeUpdate.select('text').style('fill-opacity', 1);
      // update the text
      node.select('text')
        .attr('dy', '.35em')
        .attr('x', function(d) { // eslint-disable-line
          return d.children || d._children ? -13 : 13; // eslint-disable-line
        })
        .attr('text-anchor', function(d) { // eslint-disable-line
          return d.children || d._children ? 'end' : 'start'; // eslint-disable-line
        })
        .text(function(d) { // eslint-disable-line
          const val = d.data.value ? `(${d.data.value})` : '';
          return d.data.name.length > 16
            ? `${d.data.name.substring(0, 16)}...${val}`
            : `${d.data.name}${val}`;
        });
      // Transition exiting nodes to the parent's new position.
      const nodeExit = node
        .exit()
        .transition()
        .duration(this.duration)
        .attr('transform', function() { // eslint-disable-line
          return `translate(${source.y}, ${source.x})`;
        })
        .remove();
      nodeExit.select('circle').attr('r', 1e-6);
      nodeExit.select('text').style('fill-opacity', 1e-6);
      // *************************** Links section *************************** //
      // Update the links…
      const link = this.container.selectAll('path.link').data(this.links, (d) => d.id);
      // Enter any new links at the parent's previous position.
      const linkEnter = link
        .enter()
        .insert('path', 'g')
        .attr('class', 'link')
        .attr('d', () => {
          const o = { x: source.x0, y: source.y0 };
          return this.diagonal(o, o);
        })
        .attr('fill', 'none')
        .attr('stroke-width', 1)
        .attr('stroke', '#ccc');
      // Transition links to their new position.
      const linkUpdate = linkEnter.merge(link);
      linkUpdate
        .transition()
        .duration(this.duration)
        .attr('d', (d) => this.diagonal(d, d.parent));
      // Transition exiting nodes to the parent's new position.
      link
        .exit()
        .transition()
        .duration(this.duration)
        .attr('d', () => {
          const o = { x: source.x, y: source.y };
          return this.diagonal(o, o);
        })
        .remove();
      // Stash the old positions for transition.
      this.nodes.forEach((d) => {
        d.x0 = d.x;
        d.y0 = d.y;
      });
    }, */
  },
};
</script>
<style lang="scss" scoped>
.width-100-percent {
  width: 100%;
  height: calc(100vh - 74px);
}
.main {
  font-family: "AvertaRegular", Helvetica, sans-serif;
  position: relative;
  .zoom-buttons {
    position: absolute;
    right: 0;
    top: 0;
  }
}
#tooltip {
  padding: 1px;
  position: absolute;
  background-color: white;
  &__container {
    padding: 10px;
    pointer-events: none;
    font-family: "AvertaRegular", Helvetica, sans-serif;
    font-size: 12px;
    border-radius: 5px;
    z-index: 6;
    width: 250px;
    border: 3px solid white;
    color: white;
  }
  &__title {
    font-size: 16px;
  }
}
</style>
