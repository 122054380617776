<template>
  <div>
    <b-card class="shadow-sm border-0 mb-3">
      <div class="row">
        <div class="col-6">
          <div class="d-flex align-items-center">
            <main-filter-switcher
              :g-filters="gFilters"
              :selected-fn="selectMainFilterItem"
              :multi="true"
              :allowed="perimeter"
              :selection-disabled="true"
              :global-option="false"
              :global-selected-disabled="false"
              :object-value="true"
            />
            <b-button size="sm" class="ml-1" @click="loadData"> Valider </b-button>
          </div>
        </div>
        <div class="col-6 text-right px-0">
          <ScenariosSelector
            :multi="false"
            :hidden="scenarioMetadata ? [] : ['target']"
            :displayed-scenarios="['predicted', 'optimal', 'target', 'current']"
            :default-active="currentScenario || defaultScenario"
            @changed="updateCurrentScenario"
          />
        </div>
      </div>
    </b-card>
    <DownloadFileModal
      :show-modal="showDownloadModal"
      :display-download-loader.sync="displayDownloadLoader"
      @set-modal-visibility="setDownloadFileModalVisibility"
      @set-random-string="setRandomString"
      @download-data="downloadData"
    >
      <template v-slot:open>
        <div class="ml-2 col-12 px-0">
          <div class="px-0 py-2">
            <b-button variant="outline-secondary" @click="openDownloadModal">
              <i class="fa fa-download mr-2" />
              <span class="ml-2">PREVISIONS DE RETRAITES</span>
            </b-button>
          </div>
        </div>
      </template>
      <template v-slot:description>
        <p>
          Vous pouvez télécharger en format excel les données de « prévision départs retraites »
          pour le périmètre que vous avez sélectionné.
        </p>
        <p>
          Il s'agit de données stratégiques et personnelles de collaborateurs. Aussi,
          <span class="text-underline"
            >l'usage de ces données correspond à un important niveau de confidentialité.</span
          >
        </p>
        <p>
          Le fichier excel que vous téléchargez est protégé par un mot de passe qui vous est
          <span class="text-underline text-bold">affiché UNE SEULE FOIS</span> lorsque vous
          déclenchez le téléchargement.
        </p>
        <p class="text-underline text-bold">Pensez à bien noter le mot de passe.</p>
      </template>
    </DownloadFileModal>
    <div v-if="!workforceCount">
      <NoData />
    </div>
    <div v-else>
      <div class="row mb-4">
        <b-overlay :show="loading" class="col-4">
          <MainIndicatorCard
            :value="staffOut"
            label="Départs hors retraite sur l'année de projection"
          />
          <TurnoverChart :chart="chart" />
        </b-overlay>
        <b-overlay :show="loading" class="col-4">
          <AttritionReasons :attrition-reasons="attritionReasons" />
        </b-overlay>
        <b-overlay :show="loading" class="col-4">
          <TopMoveOut :attrition-top-per-attribute="attritionTopPerAttribute" />
        </b-overlay>
      </div>
      <b-overlay :show="loading">
        <StaffChartsTabs
          :metadata="scenarioMetadata"
          :g-filters="gFilters"
          :perimeter="perimeter"
          :scenario="currentScenario"
          :data-in="dataStaffIn"
          :data-out="dataStaffOut"
        />
      </b-overlay>
    </div>
  </div>
</template>

<script>
import NoData from "@/components/all/NoData.vue";
import MainFilterSwitcher from "@/components/analysis-tabs/effectifs/MainFilterSwitcher";
import { mapActions, mapMutations, mapState } from "vuex";
import ScenariosSelector from "@/components/shared/ScenariosSelector";
import MainIndicatorCard from "@/components/staff/MainIndicatorCard";
import DownloadFileModal from "@/components/analysis-tabs/modals/DownloadFileModal";
import {
  formatAttritionPerAttribute,
  formatInPopulationData,
  formatOutPopulationData,
  formatStaffData,
  formatTurnoverChartData,
  getStaffOut,
  reasons,
} from "@/services/staff";
import { buildFilter, filterAttributesMap, getCustomFilterFrom } from "@/services/helpers";
import TurnoverChart from "@/components/staff/TurnoverChart";
import AttritionReasons from "@/components/staff/AttritionReasons";
import TopMoveOut from "@/components/staff/TopMoveOut";
import StaffChartsTabs from "@/components/staff/StaffChartsTabs";
import { saveAs } from 'file-saver';

export default {
  components: {
    AttritionReasons,
    TurnoverChart,
    MainIndicatorCard,
    ScenariosSelector,
    NoData,
    MainFilterSwitcher,
    TopMoveOut,
    StaffChartsTabs,
    DownloadFileModal,
  },
  props: {
    staffData: {
      type: Object,
      required: true,
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {},
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => "predicted",
    },
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    perimeter: {
      type: Object,
      required: false,
      default: () => {},
    },
    years: {
      type: Array,
      required: true,
    },
    globalFilter: {
      type: Object,
      required: true,
      default: () => {},
    },
  },
  data() {
    return {
      filter: 1,
      currentScenario: "predicted",
      reasons,
      localStaffData: null,
      filters: {},
      loading: false,
      currentLabels: [],
      currentSelection: [],
      showDownloadModal: false,
      randomString: null,
      displayDownloadLoader: false
    };
  },
  computed: {
    dataStaffIn() {
      // eslint-disable-next-line max-len,vue/max-len
      return formatInPopulationData(this.localStaffData, this.gFilters.year, this.gFilters.type);
    },
    dataStaffOut() {
      // eslint-disable-next-line max-len,vue/max-len
      return formatOutPopulationData(this.localStaffData, this.gFilters.year, this.gFilters.type);
    },
    staffOut() {
      return getStaffOut(this.localStaffData, this.gFilters.year, this.currentScenario);
    },
    attritionReasons() {
      return this.localStaffData?.formatted?.reasons;
    },
    attritionTopPerAttribute() {
      const { tree, getNameFn } = this.getCurrentTypeOptions(this.gFilters.type);
      return formatAttritionPerAttribute(
        this.localStaffData,
        this.gFilters.type,
        this.gFilters.year,
        this.currentScenario,
        tree,
        getNameFn
      );
    },
    chart() {
      return formatTurnoverChartData(this.localStaffData, this.currentScenario, this.years);
    },
    ...mapState("analysis", {
      gFilters: (state) => state.filters,
    }),
  },
  watch: {
    staffData: {
      handler() {
        this.localStaffData = formatStaffData(this.staffData);
      },
      immediate: true,
    },
  },
  mounted() {
    this.setActiveScenarios(["target"]);
  },
  methods: {
    async loadData() {
      try {
        this.loading = true;
        const { attritionData } = await this.getScenarioStaffResults({
          id: this.scenarioMetadata?._key || null,
          filters: { ...this.filters },
        });
        this.localStaffData = formatStaffData(attritionData);
      } catch (err) {
        console.log(err);
      } finally {
        this.loading = false;
      }
    },
    async selectMainFilterItem(type, items) {
      const defaultFilter = this.scenarioMetadata ? { ...this.scenarioMetadata.filter } : {};
      defaultFilter[filterAttributesMap[type]] = items.map((item) => item._id);
      const allowedScopeFilter = buildFilter(defaultFilter);
      const customFilter = getCustomFilterFrom(
        defaultFilter,
        allowedScopeFilter,
        this.perimeter,
        this
      );
      this.filters = customFilter.filter;
    },
    openDownloadModal() {
      this.showDownloadModal = true;
    },
    setDownloadFileModalVisibility(value) {
      this.showDownloadModal = value;
    },
    setRandomString(value) {
      this.randomString = value;
    },
    async downloadData() {
      const filter = { ...this.globalFilter }
      for (const key of Object.keys(filter)) {
        filter[key] = filter[key] || []
      }
      const body = {
        id: this.scenarioMetadata?._key,
        filters: {
          password: this.randomString,
          filterAttribute: this.gFilters.type,
          ...filter
        }
      }
      if (this.$route?.query?.id2) body['id2'] = this.$route.query.id2;
      const data = await this.downloadRetirementZip(body);
      this.displayDownloadLoader = false;
      saveAs(new Blob([data]), "Retirement.zip")
    },
    updateCurrentScenario(scenario) {
      [this.currentScenario] = scenario;
    },
    ...mapMutations({
      setActiveScenarios: "basic/setActiveScenarios",
    }),
    ...mapActions({
      getScenarioStaffResults: "analysis/loadScenarioAttritionData",
      downloadRetirementZip: "analysis/downloadRetirementZip",
    }),
  },
};
</script>

<style lang="scss">
.text-underline {
  text-decoration-line: underline;
}
.text-bold {
  font-weight: bold;
}
</style>
