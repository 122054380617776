<template>
  <div>
    <div>
      <b-button variant="primary-lighten" class="text-primary text-uppercase" @click="open">
        <i class="fa fa-plus" />
        <span class="ml-2">Ajouter un nouveau métier</span>
      </b-button>
    </div>
    <b-modal
      v-model="show"
      hide-footer
      content-class="rounded-0 border-0 min-vh-100 min-vw-100"
      body-class="p-0"
      header-class="border-0 d-flex justify-content-center align-items-center text-dark p-4"
      modal-class="m-0 p-0"
      dialog-class="m-0"
      scrollable
    >
      <template #modal-header>
        <i class="fa fa-plus" />
        <span class="h5 my-0 ml-3">Ajouter un nouveau métier</span>
        <b-btn-close @click="close" />
      </template>
      <b-container>
        <b-overlay :show="loading">
          <VerticalStep class="my-4" :step="1" title="Définir les informations générales sur le métier">
            <b-form>
              <b-form-row>
                <b-col>
                  <b-form-group label="Nom du métier" label-class="font-weight-bold">
                    <b-form-input
                      v-model="job.name"
                      placeholder="Saisir le nom du métier à créer"
                      :state="validateState($v.job.name)"
                    />
                    <b-form-invalid-feedback v-if="!$v.job.name.required">
                      Ce champs est requis
                    </b-form-invalid-feedback>
                  </b-form-group>
                  <b-form-group label="Description" label-class="font-weight-bold">
                    <b-form-textarea
                      v-model="job.description"
                      rows="3"
                      placeholder="Décrire les principales activités et responsabilités du métier"
                      :state="validateState($v.job.description)"
                    />
                    <b-form-invalid-feedback v-if="!$v.job.description.required">
                      Ce champs est requis
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group label="Famille métier" label-class="font-weight-bold">
                    <JobSelector
                      :only-children="true"
                      :only-parents="true"
                      :multi="false"
                      :class="validateState($v.job.family) ? 'is-invalid' : 'is-valid'"
                      @selected="setJobFamily"
                    />
                    <b-form-invalid-feedback v-if="!$v.job.family.required">
                      Ce champs est requis
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Rôle managérial" label-class="font-weight-bold">
                    <b-form-select
                      v-model="job.manager"
                      :options="managersOptions"
                      :state="validateState($v.job.manager)"
                    />
                    <b-form-invalid-feedback v-if="!$v.job.manager.required">
                      Ce champs est requis
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
              <b-form-row>
                <b-col>
                  <b-form-group label="Date cible de création" label-class="font-weight-bold">
                    <b-form-datepicker
                      v-model="job.date"
                      :min="from"
                      :max="to"
                      :state="validateState($v.job.date)"
                      label-no-date-selected="XX/XX/XXXX"
                      :date-format-options="{
                        'year': 'numeric',
                        'month': 'numeric',
                        'day': 'numeric'
                      }"
                    />
                    <b-form-invalid-feedback v-if="!$v.job.date.required">
                      Ce champs est requis
                    </b-form-invalid-feedback>
                    <div v-if="!checkDate()" class="small text-left text-danger">
                      La date de création est incohérente avec les effectifs saisis par année
                    </div>
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group label="Expérience requise" label-class="font-weight-bold">
                    <b-form-select
                      v-model="job.experience"
                      :options="experiencesOptions"
                      :state="validateState($v.job.experience)"
                    />
                    <b-form-invalid-feedback v-if="!$v.job.experience.required">
                      Ce champs est requis
                    </b-form-invalid-feedback>
                  </b-form-group>
                </b-col>
              </b-form-row>
            </b-form>
          </VerticalStep>
          <VerticalStep class="my-4" :step="2" title="Définir le périmètre sur lequel travailler">
            <b-form>
              <b-form-row>
                <b-col>
                  <b>Structures de coûts</b>
                  <filter-selector
                    key-option="costCentres"
                    :object-value="false"
                    :selection-disabled="true"
                    @selected="setCostCentres"
                  />
                  <b-form-invalid-feedback v-if="!$v.job.costCentres.required">
                    Ce champs est requis
                  </b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <b>Entité</b>
                  <UnitSelector :object-value="false" @selected="setUnits" />
                  <b-form-invalid-feedback v-if="!$v.job.units.required">
                    Ce champs est requis
                  </b-form-invalid-feedback>
                </b-col>
                <b-col>
                  <b>Zone géographique</b>
                  <filter-tree-selector
                    :options="siteTree"
                    :object-value="false"
                    :selection-disabled="true"
                    @selected="setSites"
                  />
                  <b-form-invalid-feedback v-if="!$v.job.sites.required">
                    Ce champs est requis
                  </b-form-invalid-feedback>
                </b-col>
              </b-form-row>
            </b-form>
          </VerticalStep>
          <VerticalStep class="my-4" :step="3" title="Définir la cible d'effectifs">
            <b-row class="align-items-end">
              <b-col cols="4" />
              <b-col>
                <b-form class="text-center">
                  <b-form-row>
                    <b-col>
                      <div>{{ job.years[0].label }}</div>
                    </b-col>
                    <b-col>
                      <div>{{ job.years[1].label }}</div>
                    </b-col>
                    <b-col>
                      <div>{{ job.years[2].label }}</div>
                    </b-col>
                  </b-form-row>
                </b-form>
              </b-col>
            </b-row>
            <b-row class="align-items-center mb-2">
              <b-col cols="4">
                <span>Saisir les effectifs cibles par année</span>
              </b-col>
              <b-col>
                <b-form class="text-center">
                  <b-form-row>
                    <b-col>
                      <b-input
                        v-model="job.years[0].value"
                        type="number"
                        :min="0"
                        :max="100000"
                      />
                    </b-col>
                    <b-col>
                      <b-input
                        v-model="job.years[1].value"
                        type="number"
                        :min="0"
                        :max="100000"
                      />
                    </b-col>
                    <b-col>
                      <b-input
                        v-model="job.years[2].value"
                        type="number"
                        :min="0"
                        :max="100000"
                      />
                    </b-col>
                  </b-form-row>
                  <div v-if="!checkDate()" class="small text-left text-danger">
                    La date de création est incohérente avec les effectifs saisis par année
                  </div>
                  <div v-if="!checkYearsValues()" class="small text-left text-danger">
                    L'effectif cible doit être supérieur à 0 sur au moins une année
                  </div>
                </b-form>
              </b-col>
            </b-row>
          </verticalstep>
          <VerticalStep class="my-4" :step="4" title="Ajouter des compétences pour ce métier">
            <b-row>
              <b-col>
                <div class="d-flex mr-3 justify-content-start align-items-center">
                  <SkillSelector
                    :key="key"
                    :multi="true"
                    :only-children="true"
                    :exact="true"
                    @selected="updateSkills"
                  />
                  <b-button class="mx-2" @click="addSkills">
                    Ajouter
                  </b-button>
                </div>
              </b-col>
              <b-col>
                <div class="d-flex ml-3 justify-content-end align-items-center">
                  <JobSelector
                    :key="key"
                    :global-option="false"
                    :multi="false"
                    style="max-width: 500px"
                    @selected="updateJobs"
                  />
                  <b-button class="mx-2" @click="addJobSkills">
                    Ajouter
                  </b-button>
                </div>
              </b-col>
            </b-row>
            <div class="my-3">
              <b-table-lite
                :fields="fields"
                :items="job.skills"
              >
                <template #cell(label)="{ item }">
                  <span>{{ item.label }}</span>
                </template>
                <template #cell(current)="{ item }">
                  <b-form-rating
                    readonly
                    icon-empty="circle"
                    icon-full="check-circle-fill"
                    :stars="4"
                    size="sm"
                    :value="item['current']"
                    class="border-0 bg-transparent rate-input"
                  />
                </template>
                <template #cell(year_0)="data">
                  <b-form-rating
                    :readonly="!editable"
                    icon-empty="circle"
                    icon-full="check-circle-fill"
                    :variant="(data.item && data.item._new) ? 'secondary' : 'primary'"
                    show-clear
                    :stars="4"
                    size="sm"
                    :value="data.item['year_0']"
                    class="border-0 bg-transparent rate-input"
                    @change="setLevel(data, $event)"
                  />
                </template>
                <template #cell(year_1)="data">
                  <b-form-rating
                    :readonly="!editable"
                    icon-empty="circle"
                    icon-full="check-circle-fill"
                    :variant="(data.item && data.item._new) ? 'secondary' : 'primary'"
                    show-clear
                    :stars="4"
                    size="sm"
                    :value="data.item['year_1']"
                    class="border-0 bg-transparent rate-input"
                    @change="setLevel(data, $event)"
                  />
                </template>
                <template #cell(year_2)="data">
                  <b-form-rating
                    :readonly="!editable"
                    icon-empty="circle"
                    icon-full="check-circle-fill"
                    :variant="(data.item && data.item._new) ? 'secondary' : 'primary'"
                    show-clear
                    :stars="4"
                    size="sm"
                    :value="data.item['year_2']"
                    class="border-0 bg-transparent rate-input"
                    @change="setLevel(data, $event)"
                  />
                </template>
                <template #cell(actions)="{ item }">
                  <b-button
                    v-if="editable"
                    variant="link"
                    class="text-muted"
                    @click.stop="remove(item)"
                  >
                    <i class="fa fa-trash" />
                  </b-button>
                </template>
              </b-table-lite>
            </div>
          </VerticalStep>
          <div class="position-sticky bg-light my-3 p-3 text-center" style="bottom: 0">
            <span
              v-b-tooltip.top
              class="d-inline-block"
              tabindex="0"
              title="Veuillez renseigner tous les champs du formulaire"
            >
              <b-button variant="primary" :disabled="!canAddNewJob" @click="addNewJob">
                Valider
              </b-button>
            </span>
          </div>
        </b-overlay>
      </b-container>
    </b-modal>
  </div>
</template>

<script>
import VerticalStep from '@/components/ui/VerticalStep'
import { mapActions } from 'vuex'
import FilterTreeSelector from '@/components/selectors/FilterTreeSelector'
import FilterSelector from '@/components/selectors/FilterSelector'
import {
  formatSkillToPayload,
  formatSkillToSkillsTableEditorItem,
  getSkillObject,
  getSkillsObjects
} from '@/services/skills'
import { minLength, required, minValue, maxValue } from 'vuelidate/lib/validators'
import { buildNewCustomJobPayload } from '@/services/jobs'

export default {
  components: { VerticalStep, FilterTreeSelector, FilterSelector },
  props: {},
  data () {
    return {
      loading: false,
      show: false,
      experiencesOptions: [
        { text: 'Sélectionnez l’expérience requise', value: null, disabled: true },
        { text: 'Débutant', value: 'BEGINNER' },
        { text: 'Moins de 3 ans d’expérience', value: 'UNDER_3_YEARS' },
        { text: 'Entre 3 et 5 ans d’expérience', value: '3_TO_5_YEARS' },
        { text: 'Entre 5 et 10 ans d’expérience', value: '5_TO_10_YEARS' },
        { text: 'Plus de 10 ans d’expérience', value: 'AFTER_10_YEARS' }
      ],
      managersOptions: [
        { text: 'Indiquez s’il s’agit d’un manager', value: null, disabled: true },
        { text: 'Oui', value: true },
        { text: 'Non', value: false }
      ],
      job: {
        name: '',
        family: '',
        description: '',
        date: '',
        experience: null,
        manager: null,
        units: null,
        sites: null,
        costCentres: null,
        years: [
          { label: 2023, value: 0 },
          { label: 2024, value: 0 },
          { label: 2025, value: 0 }
        ],
        skills: []
      },
      from: '',
      to: '',
      years: [],
      skills: [],
      jobSkills: [],
      key: 0,
      editable: true,
      errors: {
        date: false
      }
    }
  },
  computed: {
    canAddNewJob () {
      // const skills = this.job.skills.filter(sk => {
      //   return sk.year_0 > 0 || sk.year_1 > 0 || sk.year_2 > 0
      // })
      // return skills.length > 0
      return this.$v.job.$invalid === false && this.checkDate() && this.checkYearsValues()
    },
    fields () {
      const columns = [
        { key: 'name', label: 'Compétences', tdClass: 'col-3' },
        { key: 'current', label: 'Existant', thClass: 'text-center', tdClass: 'col-2 text-center' },
      ]
      this.years.forEach((y, i) => {
        columns.push({
          key: `year_${i}`,
          label: `${y}`,
          thClass: 'text-center',
          tdClass: 'col-2 text-center'
        })
      })
      columns.push({ key: 'actions', label: '', tdClass: 'col-1' })
      return columns
    }
  },
  validations: {
    job: {
      name: { required },
      description: { required },
      family: { required },
      date: { required },
      experience: { required },
      manager: { required },
      units: { required },
      sites: { required },
      costCentres: { required },
      skills: { required, minLength: minLength(1) },
      years: {
        custom: (value) => value.filter(v => v.value > 0).length > 0,
        $each: {
          value: {
            required,
            minValue: minValue(0),
            maxValue: maxValue(100000)
          }
        }
      }
    }
  },
  async mounted () {
    this.years = await this.getPredictionYears();
    this.from = new Date(this.years[0], 0, 1)
    this.to = new Date(this.years[this.years.length - 1], 11, 31)
  },
  methods: {
    checkYearsValues () {
      this.errors.years = false
      const isValid =  this.job.years.filter(v => v.value > 0).length > 0
      if (!isValid) {
        this.errors.years = true
      }
      return isValid
    },
    checkDate () {
      this.errors.date = false
      // check date
      let dateIsValid = true
      const start = this.job.date.split('-')[0]
      this.job.years.forEach(year => {
        if (parseInt(year.label) < parseInt(start) && year.value > 0) {
          dateIsValid = false
          this.errors.date = true
        }
      })
      return dateIsValid
    },
    remove (item) {
      this.job.skills = this.job.skills.filter(s => s.uid !== item.uid)
    },
    setLevel (data, level) {
      data.item[data.field.key] = level || 0
    },
    async addNewJob () {
      this.$v.job.$touch()
      if (this.$v.job.$anyError) {
        return
      }
      try {
        this.loading = true
        const formData = { ...this.job }
        const start = formData.date.split('-')[0]
        const payload = buildNewCustomJobPayload(formData, this.years)
        const job = await this.createNewCustomJob(payload)
        const data = await this.fetchNewJob({ id: job._key, year: parseInt(start) })
        this.$emit('add', { job, data, formData })
        this.resetForm()
        this.close()
      } catch (err) {
        this.$bvToast.toast('Une erreur est survenue.', { variant: 'danger', solid: true })
      } finally {
        this.loading = false
      }
    },
    resetForm() {
      this.$v.job.$reset()
      this.job = {
        name: '',
        family: '',
        description: '',
        date: '',
        experience: null,
        manager: null,
        units: null,
        sites: null,
        costCentres: null,
        years: [
          { label: 2023, value: 0 },
          { label: 2024, value: 0 },
          { label: 2025, value: 0 }
        ],
        skills: []
      }
    },
    updateJobs (job) {
      if (!job) {
        return
      }
      // get job skills
      const skills = job.skills
      if (!skills) {
        return
      }
      // keep skills
      this.jobSkills = getSkillsObjects(skills, '_key', this.skillTree)
    },
    updateSkills (skillsIds) {
      if (!skillsIds?.length) {
        return
      }
      const skills = []
      skillsIds.forEach(skillId => {
        const skill = getSkillObject(skillId, '_id', this.skillTree)
        if (skill) {
          skills.push(skill)
        }
      })
      // keep skills
      this.skills = skills
    },
    addSkills () {
      // add skills to new job skills
      this.addSkillsToNewJobSkills(this.skills)
      this.skills = []
      this.key++
    },
    addJobSkills () {
      // add skills to new job skills
      this.addSkillsToNewJobSkills(this.jobSkills)
      this.jobSkills = []
      this.key++
    },
    addSkillsToNewJobSkills (skills) {
      skills.forEach(skill => {
        // check if skill exists in new job skills
        if (!this.job.skills.filter(jsk => jsk.uid === skill._id).length) {
          // if not, we can add skill in new job skills
          this.job.skills.push(formatSkillToSkillsTableEditorItem(skill))
        }
      })
    },
    cancel () {
      this.$emit('cancel')
      this.close()
    },
    open () {
      this.show = true
    },
    close () {
      this.show = false
    },
    setCostCentres (costCentres) {
      this.job.costCentres = costCentres
    },
    setUnits (units) {
      this.job.units = units
    },
    setSites (sites) {
      this.job.sites = sites
    },
    setJobFamily (job) {
      if (job) {
        this.job.family = job?._id
      }
    },
    validateState(item) {
      const { $dirty, $error } = item;
      return $dirty ? !$error : null;
    },
    ...mapActions({
      getPredictionYears: 'scenarios/getPredictionYears',
      createNewCustomJob: 'jobs/createCustomJob',
      fetchNewJob: 'jobs/fetchCustomJob'
    })
  }
}
</script>

<style scoped>
.modal-dialog {
  width: 100%;
  max-width: 100%;
}
</style>
