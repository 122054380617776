import { mapActions } from 'vuex';

const SetChartSelectsMixin = {
  props: {
    keySetSelects: {
      type: String,
      required: true,
    },
  },
  methods: {
    ...mapActions('analysis/effectifs', [
      'setGenderSelects',
      'setAgeSelect',
      'setContratSelects',
      'setManagerSelects',
      'setSenioritySelects',
      'setSeniorityGenderSelects',
      'setInternalExternalSelects',
    ]),
    ...mapActions({
      setComparativesSelects: 'analysis/setComparativesSelects',
    }),
    setSelect(type, val) {
      if (type === 'setGenderSelects') this.setGenderSelects(val);
      else if (type === 'setAgeSelect') this.setAgeSelect(val);
      else if (type === 'setContratSelects') this.setContratSelects(val);
      else if (type === 'setManagerSelects') this.setManagerSelects(val);
      else if (type === 'setSenioritySelects') this.setSenioritySelects(val);
      else if (type === 'setSeniorityGenderSelects') this.setSeniorityGenderSelects(val);
      else if (type === 'setInternalExternalSelects') this.setInternalExternalSelects(val);
      else if (type === 'setComparativesSelects') this.setComparativesSelects(val);
      else console.log('This type does not exist');
    },
  },
};
export default SetChartSelectsMixin;
