const state = {
  currentTab: '',
};

const getters = {
  // eslint-disable-next-line
  mobilityGraph(state, getters, { analysis }, rootGetters) {
    const { type, year } = analysis.filters;
    if (!year) return null;
    if (
      rootGetters['analysis/yearStatsAll'] &&
      rootGetters['analysis/yearStatsAll'].mobilityData &&
      rootGetters['analysis/yearStatsAll'].mobilityData[type] &&
      rootGetters['analysis/yearStatsAll'].mobilityData[type].entityValueMap
    ) {
      return rootGetters['analysis/yearStatsAll'].mobilityData[type].entityValueMap;
    }
    return null;
  },
};

export default {
  namespaced: true,
  state,
  getters,
};
