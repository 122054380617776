import global from '@/providers/helpers/global'

export const buildPayloadFromScenarioCustomSkills = (jobs) => {
  const map = {}
  if (!jobs || !Object.values(jobs).length) {
    return map
  }
  Object.keys(jobs).forEach(job => {
    map[job] = []
    Object.keys(jobs[job]).forEach(sk => {
      const skill = jobs[job][sk]
      if ((!skill?.['Skill'] && !skill?.['sk'])
          && (!skill?.['Score'] && !skill?.['sc'])
          && (!skill?.['ID'] && !skill?.['uid'])) {
        console.log('cannot set payload for the skill', skill)
      } else {
        map[job].push({
          r: '',
          sk: skill['Skill'] || skill['sk'] ,
          sc: skill['Score'] || skill['sc'] ,
          uid: skill['ID'] || skill['uid']
        })
      }
    })
  })

  return map
}

const DIVIDER = 25

export const getSkillsObjects = (skills, attr = '_key', skillTree) => {
  console.log(skills, skillTree)
  return skills.map(skill => {
    return getSkillObject(skill.sk, attr, skillTree)
  })
}

export const formatSkillToPayload = (skill) => {
  const payload = {}
  payload.uid = skill._id
  payload.r = 0
  payload.sc = 0
  payload.sk = skill._key
  return payload
}

export const formatSkillToSkillsTableEditorItem = (skill) => {
  const payload = {}
  payload.uid = skill._id
  // payload.r = 0
  // payload.sc = 0
  // payload.sk = skill._key
  payload.name = skill?.names?.[0]?.label || '-'
  payload.current = 0
  payload['year_0'] = 3
  payload['year_1'] = 3
  payload['year_2'] = 3
  return payload
}

export const getSkillObject = (skillId, attr = '_key', skillTree = null) => {
  const tree = skillTree || this?.skillTree || {}
  return global.methods.findOptionTree(tree, skillId, attr);
}

export const formatSkills = (job) => {
  if (!job.skills) {
    return []
  }
  return job.skills.map(skill => {
    const skillObject = getSkillObject(skill.sk)
    return {
      id: skill.sk,
      _original: skill,
      _new: false,
      name: skillObject.names?.[0]?.label || '',
      current: skill.sc / DIVIDER,
      year_0: skill.sc / DIVIDER,
      year_1: skill.sc / DIVIDER,
      year_2: skill.sc / DIVIDER
    }
  })
}

export const getSkillNameById = (id, skillTree) => {
  const node = global.methods.findOptionTree(skillTree, id)
  return node?.names?.[0]?.label || id
}
