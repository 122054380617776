<template>
  <div v-if="hasData" :key="JSON.stringify(currentSelection)">
    <div class="row justify-content-between align-items-center mb-4">
      <div class="col-lg-5 mb-2">
        <main-filter-switcher
          :g-filters="gFilters"
          :selected-fn="selectMainFilterItem"
          :multi="true"
          :selection="selection"
          :allowed="allowed"
          :selection-disabled="false"
          :global-option="true"
          :global-selected-disabled="true"
          :object-value="true"
        />
      </div>
      <div class="col-auto">
        <ScenariosSelector
          :hidden="scenarioMetadata ? [] : ['target']"
          @changed="updateContratSeries"
        />
      </div>
    </div>
    <hr>
    <div>
      <div class="form-row">
        <div class="col">
          <highcharts :options="chartContrat" />
        </div>
        <div class="col-auto">
          <div class="mb-2">
            Type de contrat
          </div>
          <div class="small">
            <div v-for="(i, idx) in categoriesContrat" :key="i" class="form-row">
              <div class="col">
                <b-form-checkbox v-model="ctLegendRight" :value="i" name="fRigth">
                  <span>{{ i }}</span>
                </b-form-checkbox>
              </div>
              <div class="col-auto">
                <i
                  class="fas fa-circle ml-1 text-primary"
                  :style="{ opacity: getOpacity(categoriesContrat, idx) }"
                />
              </div>
            </div>
          </div>
          <DataLabelsSwitcher />
        </div>
      </div>
    </div>
  </div>
  <div v-else>
    No data
  </div>
</template>
<script>
import { formatCostType } from '@/providers/helpers/functions';
import MainFilterSwitcher from '@/components/analysis-tabs/effectifs/MainFilterSwitcher.vue';
import MainAnalysisFilterMixin from '@/mixins/MainAnalysisFilterMixin';
import SetChartSelectsMixin from '@/mixins/SetChartSelectsMixin';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import DataLabelsSwitcher from '@/components/shared/DataLabelsSwitcher.vue';

const chartTypeTwo = (categories, showDataLabels, scenarioTarget) => ({
  credits: {
    enabled: false,
  },
  chart: {
    type: 'column',
  },
  title: null,
  legend: { enabled: false },
  xAxis: {
    categories,
  },
  yAxis: {
    title: null,
    gridLineWidth: 0,
    labels: {
      formatter(item) {
        return `${item.value} %`;
      },
    },
  },
  tooltip: {
    formatter: function () {
      const { y, x, series, percentage } = this;
      const formattedValue = formatCostType(y, scenarioTarget);
      // eslint-disable-next-line vue/max-len
      return `<b>${x}<br>${series.name}</b> : <b>${formattedValue}</b> (${percentage.toFixed(0)}%)`;
    }
  },
  plotOptions: {
    column: {
      stacking: 'percent',
    },
    series: {
      dataLabels: {
        enabled: showDataLabels,
        formatter: function () {
          const value = this.y;
          const formattedValue = formatCostType(value, scenarioTarget);
          return formattedValue
        }
      },
    },
  },
  series: [],
});

export default {
  name: 'ChartTypeContrat',
  components: { MainFilterSwitcher, ScenariosSelector, DataLabelsSwitcher },
  mixins: [MainAnalysisFilterMixin, SetChartSelectsMixin],
  props: {
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
  },
  data() {
    return {
      ctLegendRight: [],
      contratSeries: [],
      refreshSeriesCallback: 'updateContratSeries',
    };
  },
  computed: {
    hasData() {
      return !!this.dataEffectifs[this.keyGetter];
    },
    categoriesContrat() {
      const { categories } = this.dataEffectifs[this.keyGetter];
      return categories;
    },
    chartContrat() {
      const chart = chartTypeTwo(this.labels, this.showDataLabels, this.scenarioTarget);
      chart.series = this.contratSeries;
      return chart;
    },
  },
  watch: {
    ctLegendRight: {
      handler() {
        this.updateContratSeries(this.currentChecks);
      },
      deep: true,
      immediate: true,
    },
  },
  mounted() {
    this.ctLegendRight = this.categoriesContrat;
  },
  methods: {
    getOpacity(items, idx) {
      const val = (1 / items.length) * (idx + 1);
      return val.toFixed(2);
    },
    updateContratSeries(scenarios) {
      const { data: dtContrat, categories } = this.dataEffectifs[this.keyGetter];
      if (!categories || !categories.length) return;
      this.contratSeries = [];
      this.ctLegendRight.forEach((i) => {
        scenarios.forEach((entity) => {
          if (dtContrat[entity][i]) this.contratSeries.push(dtContrat[entity][i]);
        });
      });
    },
  },
};
</script>
