<template>
  <div class="d-inline-block rounded text-center" :class="variationClass" style="width: 55px">
    <span>{{ editableValue }}</span>
    <pre v-if="$debug">
      {{ `e: ${editableValue}` }}
      {{ `v: ${value}` }}
      {{ `c: ${customValue}` }}
      {{ `va: ${variation}` }}
    </pre>
  </div>
</template>

<script>
export default {
  props: {
    value: {
      type: [Number, String, null],
      required: true,
    },
    customValue: {
      type: [Boolean, Number, String],
      required: false,
      default: () => false,
    },
    type: {
      type: String,
      required: false,
      default: () => 'all',
    },
    variation: {
      type: Number,
      required: false,
      default: () => 0,
    },
    showVariation: {
      type: Boolean,
      required: false,
      default: () => false,
    },
  },
  data() {
    return {
      editableValue: null,
    };
  },
  computed: {
    variationClass() {
      if (this.showVariation && this.variation > 0) {
        return 'bg-success text-white';
      }
      if (this.showVariation && this.variation < 0) {
        return 'bg-danger text-white';
      }
      return '';
    },
  },
  mounted() {
    const val = Number.isInteger(this.customValue) ? this.customValue : this.value;
    // this.editableValue = parseInt(`${val}`, 10);
    this.editableValue = this.value
  },
  methods: {
    onChanged() {
      if (this.limit === this.editableValue) return;
      this.$emit('changed', parseInt(this.editableValue, 10));
    },
  },
};
</script>
