<template>
  <b-tab title="Compétences" @click="init">
    <slot />
    <hr class="my-4">
    <div>
      <div>
        Pour consulter les compétences cibles associées à un métier,
        sélectionnez le métier dans le moteur de recherche ci-dessous :
      </div>
      <JobSelector
        :key="key"
        :allowed="jobs"
        :global-option="false"
        :multi="false"
        :only-children="true"
        :selection="currentJob ? [currentJob] : []"
        style="max-width: 500px"
        @selected="select"
      />
      <div v-if="scenarioJobs && scenarioJobs.length && isTarget" class="mt-3">
        <div>
          Métier(s) modifié(s) pour ce scénario:
        </div>
        <div v-for="(job, i) in scenarioJobs" :key="i">
          <b-badge
            :variant="currentJob && currentJob._id === job._id ? 'secondary' : 'gray-300'"
            @click="select(job, true)"
          >
            {{ job.name }}
          </b-badge>
        </div>
      </div>
      <div class="mt-3">
        <SkillsTableEditor
          :editable="false"
          :job="currentJob"
          :payload="{ scenarioJobs, editedSkills }"
          :years="years"
          :hypothesis="hypothesis"
          :target="isTarget"
        />
      </div>
    </div>
  </b-tab>
</template>

<script>
import SkillsTableEditor from '@/components/scenario/skills/SkillsTableEditor'
import JobSelector from '@/components/selectors/JobSelector'
import { getJobNameById } from '@/services/jobs'

export default {
  components: {
    SkillsTableEditor,
    JobSelector
  },
  props: {
    jobs: {
      type: Array,
      required: true
    },
    years: {
      type: Array,
      required: true
    },
    editedSkills: {
      type: Object,
      required: true
    },
    scenario: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      currentJob: null,
      hypothesis: {},
      scenarioJobs: [],
      key: 0
    }
  },
  computed: {
    isTarget () {
      return this.scenario === 'target'
    }
  },
  watch: {
    editedSkills: {
      handler() {
        this.scenarioJobs = this.extractEditedJobs(this.editedSkills)
      },
      immediate: true
    }
  },
  methods: {
    init () {
      this.currentJob = null
    },
    select (jobObject, refresh = false) {
      if (!jobObject) {
        return
      }
      this.currentJob = { ...jobObject }
      if (refresh) {
        this.key++
      }
    },
    extractEditedJobs (editedSkills) {
      if (Object.keys(editedSkills).length) {
        const keys = Object.keys(editedSkills)
        const jobs = Object.keys(editedSkills[keys[0]])
        return jobs.map(j => ({
          name: getJobNameById(j, this.jobTree),
          id: j,
          ...this.findOptionTree(this.jobTree, j)
        }))
      } else {
        return []
      }
    }
  }
}
</script>
