<template>
  <div class="mb-5">
    <b-row>
      <b-col>
        <div>Appliquez votre stratégie RH sur le périmètre sélectionné</div>
      </b-col>
      <b-col class="text-right">
        <b-radio-group v-model="localScenario" disabled>
          <b-radio value="structural">
            Structurelle
          </b-radio>
          <b-radio value="conjunctural">
            Conjoncturelle
          </b-radio>
          <b-radio value="noRecruiting">
            Sans recrutement
          </b-radio>
        </b-radio-group>
      </b-col>
    </b-row>
    <hr>
    <div class="mb-5">
      <b-overlay :show="loading">
        <div v-if="rates">
          <RatesTable
            :key="key"
            :rows="rates"
            @update:rates="setRates"
            @restore="onRestore"
            @remove="onRemove"
          />
          <CustomRatesModal
            :rates="rates"
            :filters="filters"
            @add="addRates"
            @created="addCleanCustomRates"
          />
        </div>
        <div v-else style="height: 300px" />
      </b-overlay>
    </div>
    <div class="row align-content-between">
      <div class="col">
        <button
          class="btn btn-outline-dark text-uppercase"
          type="button"
          @click="$emit('previous')"
        >
          <i class="fal fa-angle-left mr-2" />
          <span>PRÉCÉDENT</span>
        </button>
      </div>
      <div class="col-auto">
        <b-button
          v-if="false"
          variant="link"
          class="text-uppercase"
          :disabled="!ratesCompleted"
          @click="$emit('saveDraft', { activeStep: 'STRATEGY' })"
        >
          Enregistrer en tant que brouillon
        </b-button>
        <button
          class="btn btn-secondary text-uppercase"
          type="button"
          :disabled="!ratesCompleted"
          @click="next"
        >
          <span>Valider</span>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { merge } from 'lodash';
import RatesTable from '@/components/scenario/rates/RatesTable.vue';
import CustomRatesModal from '@/components/scenario/rates/CustomRatesModal.vue';
import {
  buildRatesCustomsFromYearRateMap,
  castCustomRateToYearRateMap,
  castObjectToItemRow,
  removeJobFromYearRateMap
} from '@/services/rates'

export default {
  components: {
    RatesTable,
    CustomRatesModal,
  },
  props: {
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    type: {
      type: String,
      required: true,
    },
    filters: {
      type: Object,
      required: true,
    },
    yearsMap: {
      type: Array,
      required: true,
    },
    payloadRates: {
      type: Object,
      required: false,
      default: () => {}
    }
  },
  data() {
    return {
      localScenario: '',
      loading: false,
      rates: null,
      yearGlobalRate: {},
      yearRateMap: {},
      copy: null,
      key: 0
    };
  },
  computed: {
    ratesCompleted() {
      return true;
    },
  },
  async mounted() {
    this.localScenario = this.type
    await this.loadData();
  },
  methods: {
    async next() {
      const rates = {
        yearGlobalRate: this.yearGlobalRate,
        yearRateMap: this.yearRateMap,
        rates: this.rates
      }
      await this.fetchGenericRates2(rates)
      this.$emit('updateRates', rates)
    },
    setRates({ rates, key }) {
      const cloneRates = JSON.parse(JSON.stringify(rates))
      if (key === 'global') {
        Object.keys(this.yearGlobalRate).forEach((prop) => {
          this.yearGlobalRate[prop] = rates.globalRates;
          this.rates.yearGlobalRate[prop]['globalRates'] = rates.globalRates
          this.rates.yearGlobalRate[prop]['globalRates'] = rates.globalRates
          this.yearGlobalRate[prop] = cloneRates.globalRates;
          this.$set(this.rates.yearGlobalRate[prop], "globalRates", cloneRates.globalRates);
        });
        this.rates.target.globalRates = rates.globalRates
        this.rates.target.globalRates = rates.globalRates
        this.rates.target.globalRates = cloneRates.globalRates
      } else {
        this.yearGlobalRate[key] = rates.globalRates;
        this.rates.yearGlobalRate[key]['globalRates'] = rates.globalRates
        this.rates.yearGlobalRate[key]['globalRates'] = rates.globalRates
        this.yearGlobalRate[key] = cloneRates.globalRates;
        this.$set(this.rates.yearGlobalRate[key], "globalRates", cloneRates.globalRates);
      }
      // this.rates.yearGlobalRate = this.yearGlobalRate
    },
    addCleanCustomRates (rates) {
      const copy = JSON.parse(JSON.stringify(this.copy))
      copy.customs.push(castObjectToItemRow(rates, this.jobTree))
      this.backup(copy)
    },
    addRates (rates) {
      this.rates.customs.push(castObjectToItemRow(rates, this.jobTree))
      this.yearRateMap = merge(this.yearRateMap, castCustomRateToYearRateMap(rates))
      this.rerender()
    },
    loadFromProps (payloadRates) {
      if (!payloadRates?.rates || !payloadRates?.yearGlobalRate) {
        return
      }
      if (Object.keys(payloadRates.rates).length) {
        this.rates = payloadRates.rates
      }
      if (Object.keys(payloadRates.yearGlobalRate).length) {
        this.yearGlobalRate = payloadRates.yearGlobalRate
      }
      if (Object.keys(payloadRates.yearRateMap).length) {
        this.yearRateMap = payloadRates.yearRateMap
        if (!this.rates?.customs?.length) {
          // eslint-disable-next-line max-len,vue/max-len
          this.rates.customs = buildRatesCustomsFromYearRateMap(this.yearGlobalRate, this.yearRateMap, this.jobTree)
        }
      }
    },
    async loadData () {
      try {
        this.loading = true
        if(localStorage.getItem("rates")){
          this.rates = await this.fetchGenericRates({ type: this.type, filters: this.filters })
        }else{
          this.rates = await this.fetchGenericRates3("rates",
          { type: this.type, filters: this.filters })
        }
        const rates = await this.fetchGenericRates({ type: this.type, filters: this.filters })
        rates.yearGlobalRate = {}
        rates.customs = []
        this.yearsMap.forEach((y) => {
          if (rates?.target) {
            this.yearGlobalRate[y] = JSON.parse(JSON.stringify(rates.target.globalRates))
            rates.yearGlobalRate[y.toString()] = JSON.parse(JSON.stringify(rates.target))
          }
        })
        this.rates = JSON.parse(JSON.stringify(rates))
        this.loadFromProps(this.payloadRates)
        this.backup()
      } catch (err) {
        console.log(err)
      } finally {
        this.loading = false
      }
    },
    backup (toCopy = null) {
      this.copy = JSON.parse(JSON.stringify(toCopy || this.rates))
    },
    onRestore() {
      this.rates = JSON.parse(JSON.stringify(this.copy))
      // on reset global value, we remove any value in yearGlobalRate
      this.yearGlobalRate = {}
      this.$forceUpdate()
      this.rerender()
    },
    onRemove({ index, item }) {
      this.rates.customs.splice(index, 1)
      // on remove custom rate value, we remove the job's custom rate value in yearRateMap
      this.yearRateMap = removeJobFromYearRateMap(this.yearRateMap, item?.jobs || [])
      this.backup()
      this.$forceUpdate()
      this.rerender()
    },
    rerender() {
      // eslint-disable-next-line no-plusplus
      this.key++;
    },
    ...mapActions({
      fetchGenericRates: 'scenarios/fetchGenericRates',
      fetchGenericRates2: 'scenarios/fetchGenericRates2',
      fetchGenericRates3: 'scenarios/fetchGenericRates3',
    }),
  },
};
</script>
