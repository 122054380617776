<template>
  <div class="app-loader">
    <p class="app-loader__title">
      {{ label }}
    </p>
    <div class="lds-facebook">
      <div />
      <div />
      <div />
    </div>
  </div>
</template>
<script>
export default {
  name: 'AppLoader',
  props: {
    label: {
      type: String,
      default: 'Chargement ...'
    },
  },
};
</script>
