<template>
  <b-card ref="tab">
    <div class="d-flex justify-content-between" style="margin-right: 60px">
      <div class="d-flex">
        <!-- <b-breadcrumb :items="breadcrumbs" class="py-0 mb-0" @click="setCurrentItem" /> -->
        <DownloadFileModal
          :show-modal="showDownloadModal"
          :display-download-loader.sync="displayDownloadLoader"
          @set-modal-visibility="setDownloadFileModalVisibility"
          @set-random-string="setRandomString"
          @download-data="downloadData"
        >
          <template v-slot:open>
            <div class="col-12 ml-3 mb-0 px-0">
              <div class="px-0 py-2">
                <b-button variant="outline-secondary" @click="openDownloadModal">
                  <i class="fa fa-download mr-2" />
                  <span class="ml-2">TELECHARGER L'EXCEL</span>
                </b-button>
              </div>
            </div>
          </template>
          <template v-slot:description>
            <p>
              Vous pouvez télécharger en format excel les données de « prévision des mobilité »
              pour le périmètre que vous avez sélectionné.
            </p>
            <p>
              Il s'agit de données stratégiques et personnelles de collaborateurs. Aussi,
              <span class="text-underline"
                >l'usage de ces données correspond à un important niveau de confidentialité.</span
              >
            </p>
            <p>
              Le fichier excel que vous téléchargez est protégé par un mot de passe qui vous est
              <span class="text-underline text-bold">affiché UNE SEULE FOIS</span> lorsque vous
              déclenchez le téléchargement.
            </p>
            <p class="text-underline text-bold">Pensez à bien noter le mot de passe.</p>
          </template>
        </DownloadFileModal>
      </div>
      <div class="mr-3">
        <ScenariosSelector
          :multi="false"
          :hidden="scenarioMetadata ? [] : ['target']"
          :displayed-scenarios="['predicted', 'optimal', 'target']"
          :default-active="defaultScenario"
          @changed="updateSelectedCheck"
        />
      </div>
    </div>
    <!-- <div class="zoom-buttons">
      <b-button-group vertical>
        <b-button variant="primary" class="mb-1 zoomin"><i class="fas fa-plus"></i></b-button>
        <b-button variant="primary" class="zoomout"><i class="fas fa-minus"></i></b-button>
      </b-button-group>
    </div>
    <svg class="d3-tree-vii width-100-percent container-border">
      <g class="container" />
    </svg>
    <hr />
    <br /> -->
    <div
      v-if="
        !invalid &&
          workforceCount &&
          gFilters &&
          gFilters.type &&
          ['Job', 'Unit'].includes(gFilters.type) &&
          serie &&
          serie.length
      "
      class="row"
    >
      <div class="col position-relative">
        <div id="wrapper">
          <highcharts
            id="sankey-diagram"
            ref="diagram"
            class="overflow-auto"
            :options="chartOptions"
          />
        </div>
      </div>
      <div class="position-absolute" style="top: 10px; right: 10px">
        <div class="zoom-buttons">
          <b-button-group horizontal>
            <b-button variant="primary" class="mr-1 zoomin" @click="zoomIn">
              <i class="fas fa-plus" />
            </b-button>
            <b-button variant="primary" class="ml-1 zoomout" @click="zoomOut">
              <i class="fas fa-minus" />
            </b-button>
          </b-button-group>
        </div>
      </div>
      <!-- <div class="col-3">
        <div class="mb-2">
          Nombre de collaborateurs
        </div>
        <div>
          <div class="d-flex align-items-center mb-2">
            <span class="bg-primary" style="height: 4px; width: 50px"></span>
            <span class="ml-2" style="line-height: 1">1-5</span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <span class="bg-primary" style="height: 8px; width: 50px"></span>
            <span class="ml-2" style="line-height: 1">5-10</span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <span class="bg-primary" style="height: 13px; width: 50px"></span>
            <span class="ml-2" style="line-height: 1">10-15</span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <span class="bg-primary" style="height: 18px; width: 50px"></span>
            <span class="ml-2" style="line-height: 1">15-20</span>
          </div>
          <div class="d-flex align-items-center mb-2">
            <span class="bg-primary" style="height: 25px; width: 50px"></span>
            <span class="ml-2" style="line-height: 1.2">+20</span>
          </div>
        </div>
      </div> -->
    </div>
    <div v-else-if="(serie && !serie.length) || invalid || !workforceCount">
      <NoData
        text="Le scénario ne comporte pas de mobilités sur la période d’observation sélectionnée"
      />
    </div>
    <div v-else>
      <b-alert show variant="warning" class="my-4">
        Veuillez sélectionner la variable d'analyse "Métiers" ou "Entités" pour afficher cette page.
      </b-alert>
    </div>
  </b-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import randomColor from 'randomcolor';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import NoData from '@/components/all/NoData.vue';
import DownloadFileModal from "@/components/analysis-tabs/modals/DownloadFileModal";
// import { Highcharts } from 'highcharts';
/* eslint-disable */

const oldnodesColor = {
  'Job': {
    'Global': '#2f7ed8',
    'ASSISTANCE': '#C5F9F3',
    'JURIDIQUE': '#ffe6e6',
    'MARKETING / DIGITAL & DATA ': '#F0FCA5',
    'ORGANISATION / PROJET / PILOTAGE': '#1aadce',
    'COMMUNICATION': '#FC1D43',
    'RESSOURCES HUMAINES': '#f28f43',
    'RISQUES, CONFORMITE ET CONTRÔLE': '#77a1e5',
    'FINANCE': '#C0E2FA',
    'GESTION DES OPERATIONS': '#a6c96a',
    'STRATEGIE ET ETUDES ECONOMIQUES ET FINANCIERES': '#F6CAF5',
    'IMMOBILIER & LOGISTIQUE': '#C3F4F9',
    'SYSTEMES D\'INFORMATION': '#ECC0FA',
    'ASSURANCES': '#908A15',
    'AUTRES': '#9A9C60',
    'COMMERCIAL ET SUPPORT COMMERCIAL': '#CDEAED',
    'SOCIAL ET MEDICAL': '#DDECF9',
    'DIRIGEANTS': '#F7B3B9',
    'QUALITE & RSE': '#FFEDE9',
  },
};

export default {
  name: 'Mobilite',
  components: { NoData, ScenariosSelector, DownloadFileModal },
  props: {
    workforceCount: {
      type: Number,
      required: false,
      default: () => 0,
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => 'predicted',
    },
    scenarioMetadata: {
      type: Object,
      required: false,
    },
  },
  data() {
    return {
      nodesColor: {},
      previousColors: '',
      invalid: false,
      selectedCheck: 'predicted',
      zoomRatio: 1.2,
      nodesArray: [],
      tabsMobility: [],
      breadcrumbs: [
        {
          text: 'Tout',
          children: null,
          active: true,
          _id: null,
        }
      ],
      loadGraph: true,
      firstJSelected: null,
      secondJSelected: null,
      chartOptions: {
        credits: {
          enabled: false,
        },
        title: null,
        accessibility: {
          point: {
            valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.',
          },
        },
        chart: {},
        series: [
          {
            states: {
              inactive: {
                linkOpacity: 0,
              },
              hover: {
                enabled: true,
                linkOpacity: 5,
              },
            },
            dataLabels: {
              allowOverlap: true,
              padding: 30,
              overflow:'allow',
              crop: false,
              color: '#31415F',
              style: {
                fontSize: '0.6rem',
                fontFamily: 'Segoe UI',
                textOutline: '0px',
              },
            },
            padding: 100,
            linkOpacity: 0.08,
            nodePadding: 10,
            nodeWidth: 20,
            keys: ['from', 'to', 'weight'],
            minLinkWidth: 7,
            data: [],
            nodes: [],
            type: 'sankey',
            name: '',
          },
        ],
      },
      showDownloadModal: false,
      displayDownloadLoader: false
    };
  },
  computed: {
    serie() {
      if (!this.mobilityGraph) {
        return null;
      } else {
        return Object.values(this.mobilityGraph); //this.mobilityGraph.map((d) => d); //
      }
    },
    ...mapState({
      gFilters: (state) => state.analysis.filters,
    }),
    ...mapGetters('analysis', {
      mobilityGraph: 'mobility/mobilityGraph',
    }),
    currentTabIdx() {
      return this.tabsMobility.findIndex((item) => item.active);
    },
  },
  mounted() {
    const { offsetTop } = this.$refs.tab;
    window.scrollTo({
      top: offsetTop,
      left: 0,
      behavior: 'smooth'
    });
  },
  methods: {
    getCollection(type) {
      let collection = null;
      switch (type) {
        case 'Job':
          collection = this.jobTree;
          break;
        case 'Unit':
          collection = this.unitTree;
          break;
      }
      return collection;
    },
    serieIsNotValid(serie) {
      let isEmpty = true;
      serie.forEach((s) => {
        if (s[this.selectedCheck] && (
            (s[this.selectedCheck].targets && Object.keys(s[this.selectedCheck].targets).length) ||
            (s[this.selectedCheck].sources && Object.keys(s[this.selectedCheck].sources).length)
          )) {
          isEmpty = false;
        }
      })
      return isEmpty;
    },
    updateSelectedCheck(scenario) {
      [this.selectedCheck] = scenario;
    },
    setCurrentItem(e) {
      if (!e.target.innerText) {
        return;
      }
      const current = this.breadcrumbs.find((i) => i.text === e.target.innerText);
      if (current._id && (!current || !current.children)) {
        return;
      }
      this.updateBreadcrumbs(current);
      this.drawMobilityGraphGeneric(current._id, true);
    },
    handleClickOnGraphGeneric(ev) {
      const collection = this.getCollection(this.gFilters.type);
      const itemFound = this.findOptionTree(collection, ev.point.id, '_id', 'children');
      // eslint-disable-next-line no-alert
      // eslint-disable-next-line no-console
      if (ev.point.id.includes(this.gFilters.type) && itemFound.children?.length) {
        this.drawMobilityGraphGeneric(ev.point.id, true);
        const current = {
          text: itemFound.names[0].label,
          children: itemFound.children,
          active: true,
          ...itemFound
        }
        this.addBreadcrumbItem(current)
      }
    },
    addBreadcrumbItem(item) {
      this.breadcrumbs.forEach((item) => item.active = false)
      this.breadcrumbs.push(item)
    },
    updateBreadcrumbs(current) {
      const index = this.breadcrumbs.findIndex((i) => i === current);
      this.breadcrumbs = this.breadcrumbs.slice(0, index + 1);
      this.breadcrumbs.forEach((b, i) => {
        b.active = i === this.breadcrumbs.length - 1;
      });
    },
    zoomIn() {
      this.zoomRatio += 0.2;
      const w = this.$refs.diagram.$el.clientWidth;
      const h = this.$refs.diagram.$el.clientHeight;
      this.$set(this.chartOptions, 'chart', {
        width: w * this.zoomRatio,
        height: h * this.zoomRatio,
      });
      this.$refs.diagram.chart.reflow();
    },
    zoomOut() {
      this.zoomRatio -= 0.2;
      const w = this.$refs.diagram.$el.clientWidth;
      const h = this.$refs.diagram.$el.clientHeight;
      this.$set(this.chartOptions, 'chart', {
        width: w * this.zoomRatio,
        height: h * this.zoomRatio,
      });
      this.$refs.diagram.chart.reflow();
    },
    removeDuplicates(originalArray, prop) {
      let newArray = [];
      let lookupObject = {};

      for (let i in originalArray) {
        lookupObject[originalArray[i][prop]] = originalArray[i];
      }

      for (let i in lookupObject) {
        newArray.push(lookupObject[i]);
      }
      return newArray;
    },
    setNodesColor(label) {
      if (!this.nodesColor[this.gFilters.type] || !this.nodesColor[this.gFilters.type][label]) {
        this.nodesColor[this.gFilters.type] = this.nodesColor[this.gFilters.type] || {}
        const arr = this.nodesColor[this.gFilters.type];
        const luminosity = ['bright', 'dark', 'light'][arr.length % 3];
        const hue = ['red', 'orange', 'yellow', 'green', 'blue', 'purple', 'pink'];
        const color = hue.sort(() => 0.5 - Math.random()).filter(h => h !== this.previousColor);
        arr[label] = randomColor({ luminosity, hue: color[0] });
        this.previousColor = color[0];
      }
      return this.nodesColor[this.gFilters.type][label]
    },
    changeFamily(ev) {
      const targetTabIdx = this.tabsMobility.findIndex((item) => item.text === ev.target.innerText);
      if (targetTabIdx < this.currentTabIdx) {
        const tabs = [...this.tabsMobility].map((item) => ({
          ...item,
          active: false,
        }));
        tabs[targetTabIdx].active = true;
        if (targetTabIdx === 0) this.drawMobilityGraphGeneric(null, true);
        if (targetTabIdx === 1 && this.firstJSelected) {
          this.drawMobilityGraphGeneric(this.firstJSelected, true);
        }
        this.tabsMobility = tabs;
      }
    },
    changeFamilyByGraph() {
      const tabs = [...this.tabsMobility].map((item) => ({
        ...item,
        active: false,
      }));
      tabs[this.currentTabIdx + 1].active = true;
      this.tabsMobility = tabs;
    },
    drawMobilityGraphGeneric(id = null, fromEvent = false) {
      if (this.serieIsNotValid(this.serie)) {
        this.invalid = true;
        return;
      }
      this.invalid = false;
      this.loadGraph = true;
      const collection = this.getCollection(this.gFilters.type);
      const data = [];
      const nodes = [];
      let items = [];
      if (!id) {
        // eslint-disable-next-line no-underscore-dangle
        items = collection.map((j) => j._id);
      } else {
        const itemFound = this.findOptionTree(collection, id, '_id', 'children');
        if (itemFound) {
          // eslint-disable-next-line no-underscore-dangle
          items = itemFound.children.length ? itemFound.children.map((j) => j._id) : [];
          if (this.currentTabIdx === 0) this.firstJSelected = id;
          if (this.currentTabIdx === 1) this.secondJSelected = id;
          if (!fromEvent) this.changeFamilyByGraph();
        }
      }
      // eslint-disable-next-line no-restricted-syntax
      for (const item of items) {
        // eslint-disable-next-line no-restricted-syntax
        const itemFound = this.findOptionTree(collection, item, '_id', 'children');
        if(itemFound.names[0].label == 'Global'){
          nodes.push({
            id: item,
            dataLabels:{
              enabled:false,
            },
            color: this.setNodesColor(itemFound.names[0].label),
          });
        }
        nodes.push({
          id: item,
          name: itemFound.names[0].label,
          dataLabels: {
            align: 'left',
          },
          color: this.setNodesColor(itemFound.names[0].label),
        });
        if (this.mobilityGraph[item] && this.mobilityGraph[item][this.selectedCheck] && this.mobilityGraph[item][this.selectedCheck].targets) {
          // eslint-disable-next-line no-restricted-syntax
          for (const [key, value] of Object.entries(this.mobilityGraph[item][this.selectedCheck].targets)) {
            const itemFoundKey = this.findOptionTree(collection, key, '_id', 'children');
            const result = [item, `${key}-R`, value];
            nodes.push({
              id: `${key}-R`,
              dataLabels: {
                align: 'right',
              },
              name: itemFoundKey ? itemFoundKey.names[0].label : key,
              color: this.setNodesColor(itemFoundKey.names[0].label),
            });
            data.push(result);
          }
        }
      }
      this.$set(this.chartOptions, 'plotOptions', {
        series: {
          cursor: 'pointer',
          point: {
            events: {
              // eslint-disable-next-line func-names
              // eslint-disable-next-line object-shorthand
              click: this.handleClickOnGraphGeneric,
            },
          },
        },
      });
      this.$set(this.chartOptions.series[0], 'data', data);
      this.$set(this.chartOptions.series[0], 'nodes', nodes);
      this.loadGraph = false;
    },
    openDownloadModal() {
      this.showDownloadModal = true;
    },
    setDownloadFileModalVisibility(value) {
      this.showDownloadModal = value;
    },
    setRandomString(value) {
      this.randomString = value;
    },
    async downloadData() {
      const filter = { ...this.globalFilter }
      for (const key of Object.keys(filter)) {
        filter[key] = filter[key] || []
      }
      const body = {
        id: this.scenarioMetadata?._key,
        filters: {
          password: this.randomString,
          filterAttribute: this.gFilters.type,
          ...filter
        }
      }
      if (this.$route?.query?.id2) body['id2'] = this.$route.query.id2;
      const data = await this.downloadMobilityZip(body);
      this.displayDownloadLoader = false;
      saveAs(new Blob([data]), "Mobility.zip")
    },
    ...mapActions({
      downloadMobilityZip: "analysis/downloadMobilityZip",
    }),
  },
  watch: {
    mobilityGraph: {
      handler(curr) {
        if (curr) {
          this.drawMobilityGraphGeneric();
          // if (this.currentTabIdx === 0) this.drawMobilityGraph();
          // if (this.currentTabIdx === 1) this.drawMobilityGraph(this.firstJSelected, true);
          // if (this.currentTabIdx === 2) this.drawMobilityGraph(this.secondJSelected, true);
        }
      },
      immediate: true,
    },
    selectedCheck() {
      this.drawMobilityGraphGeneric();
      this.breadcrumbs = this.breadcrumbs.slice(0, 1);
    }
  },
};
</script>
<style scoped>
#sankey-diagram {
  padding-top: 0;
  height: 80vh;
  font-family: 'Times New Roman', serif;
  font-weight: bold;
}
</style>
