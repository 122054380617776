<template>
    <b-card
      class="my-2"
      body-class="p-1"
      style="border-radius: 10px"
      :class="isCurrent ? 'bg-global-row' : 'bg-white shadow-sm'"
    >
      <RatesTableHead
        :title="title"
        :item="item"
        :depth="1"
        :removable="removable"
        :restorable="restorable"
        :editable="editable"
        @editing="toggleEdit"
        @collapse="collapse"
        @close="collapse"
        @update="onUpdate($event, year)"
        @restore="restore"
        @remove="remove"
      />
    </b-card>
  </template>
  
  <script>
  import RatesTableHead from '@/components/scenario/rates/RatesTableHead.vue';
  
  export default {
    name: 'RatesTableRowYear',
    components: {
      RatesTableHead,
    },
    props: {
      title: {
        type: String,
        required: true,
      },
      item: {
        type: Object,
        required: true,
      },
      depth: {
        type: Number,
        required: false,
        default: () => 0,
      },
      year: {
        type: String,
        required: true
      },
      removable: {
        type: Boolean,
        required: false,
        default: () => false
      },
      restorable: {
        type: Boolean,
        required: false,
        default: () => false
      },
      editable: {
        type: Boolean,
        required: false,
        default: () => true
      }
    },
    data() {
      return {
        editing: false,
        open: false,
      };
    },
    computed: {
      isCurrent() {
        return this.title.toLowerCase() === 'existant';
      },
      isTarget() {
        return this.title.toLowerCase() === 'scénario cible';
      },
    },
    watch: {
      children: {
        handler(val) {
          console.log(val)
        },
        deep: true,
      },
    },
    methods: {
      collapse() {
        this.open = !this.open;
      },
      toggleEdit() {
        this.editing = !this.editing;
        if (this.editing) {
          this.$emit('editing');
        }
      },
      onUpdate(data, key) {
        this.$emit('update', { rates: data, key });
      },
      restore() {
        this.$emit('restore')
      },
      remove() {
        this.$emit('remove')
      },
    },
  };
  </script>
  