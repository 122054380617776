<template>
  <div v-if="skills">
    <div class="d-flex justify-content-end">
      <ScenariosSelector
        :multi="false"
        :hidden="scenarioMetadata ? [] : ['target']"
        :displayed-scenarios="['predicted', 'optimal', 'target']"
        :default-active="defaultScenario"
        @changed="updateCurrentScenario"
      />
    </div>
    <hr class="my-4">
    <b-row>
      <b-col cols="6" offset="6">
        <SkillSelector
          :only-children="false"
          :exact="true"
          :multi="true"
          :object-value="false"
          @selected="filterBySkills"
        />
      </b-col>
    </b-row>
    <div class="row text-center my-3">
      <div class="col-4 text-left">
        <span class="text-muted pl-3">Compétence</span>
        <span id="skill" class="ml-1 text-dark" style="position: relative; top: -5px">
          <i class="fal fa-question-circle" />
        </span>
        <b-popover target="skill" triggers="hover">
          Niveau moyen et nombre de collaborateurs maîtrisant la compétence à la date de projection
          sélectionnée, selon le scénario sélectionné.
        </b-popover>
      </div>
      <div class="col-2">
        <span class="text-muted">Tendance</span>
        <span id="trend" class="ml-1 text-dark" style="position: relative; top: -5px">
          <i class="fal fa-question-circle" />
        </span>
        <b-popover target="trend" triggers="hover">
          Comparaison du nombre de personnes ayant la compétence à la date de projection
          sélectionnée avec le nombre de personnes ayant la compétence actuellement. Si la flèche
          monte, cela signifie que plus de personnes maitriseront la compétence à la date de
          projection.
        </b-popover>
      </div>
      <div class="col-3">
        <span class="text-muted">Aujourd'hui</span>
      </div>
      <div class="col-3">
        <span class="text-muted">Au 31/12/{{ gFilters.year }}</span>
      </div>
    </div>
    <!-- item -->
    <div :key="rerenderKey">
      <div v-for="(item, idx) in skillTree" :key="idx">
        <div class="my-2">
          <CompetenceTree
            v-if="item.isVisible"
            :competence="item"
            :index="idx"
            :global-values="globalValues"
            :current-year-global-values="currentYearGlobalValues"
            :scenario-key="selectedCheck"
            :max-depth="maxDepth"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import CompetenceTree from '@/components/analysis-tabs/competences/CompetenceTree.vue';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';
import SkillSelector from '@/components/selectors/SkillSelector.vue';

const getDepth = (node, depth = 0) => {
  if (node.children && node.children.length > 0) {
    const childDepths = [];
    depth += 1;
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < node.children.length; i++) {
      childDepths.push(getDepth(node.children[i]));
    }
    return depth + Math.max(...childDepths);
  }
  return depth;
};

export default {
  name: 'BarCompetence',
  components: {
    SkillSelector,
    CompetenceTree,
    ScenariosSelector,
  },
  props: {
    globalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    currentYearGlobalValues: {
      type: Object,
      default() {
        return {};
      },
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => 'predicted',
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      selectedCheck: 'predicted',
      rerenderKey: 0,
      skills: [],
    };
  },
  computed: {
    maxDepth() {
      const max = [];
      this.skills.forEach((item) => {
        max.push(getDepth(item, 1));
      });
      return Math.max(...max);
    },
    filteredSkills() {
      return this.skillTree;
    },
    ...mapState({
      gFilters: (state) => state.analysis.filters,
      yearPredictedStatsMap: (state) => state.analysis.allData.yearPredictedStatsMap,
    }),
    yearFilter() {
      return Object.keys(this.yearPredictedStatsMap).findIndex((y) => y === this.gFilters.year) + 1;
    },
  },
  mounted() {
    this.skills = Object.values({ ...this.skillTree });
    this.refreshSkills(true);
  },
  methods: {
    depthDecorator(array, depth = 1) {
      return array.map((child) =>
        Object.assign(child, {
          depth,
          children: this.depthDecorator(child.children || [], depth + 1),
        })
      );
    },
    refreshSkills(value) {
      const tree = { children: this.skills };
      this.loopInTree(tree, (n) => {
        n.isVisible = value;
      });
      this.depthDecorator(this.skills, 1);
      this.rerenderKey += 1;
    },
    filterBySkills(skills) {
      if (skills && skills.length) {
        this.refreshSkills(false);
        const tree = { children: this.skillTree };
        skills.forEach((skill) => {
          const nodes = this.getPath(tree, skill, '_id').filter(Boolean);
          const node = this.findOptionTree(this.skillTree, skill, '_id');
          const children = this.getNodeChildren({ children: [node] });
          // eslint-disable-next-line no-underscore-dangle
          const all = [...nodes, ...children.map((c) => c._id)];
          all.forEach((n) => {
            this.loopInTree(tree, (s) => {
              // eslint-disable-next-line no-underscore-dangle
              if (n === s._id) {
                s.isVisible = true;
              }
            });
            // this.enableLeafInTree(tree, n);
          });
        });
      } else {
        this.refreshSkills(true);
      }
      this.$forceUpdate();
      // this.rerenderKey += 1;
    },
    updateCurrentScenario(scenario) {
      [this.selectedCheck] = scenario;
    },
  },
};
</script>
