import ConfigService from './config';

class DataService extends ConfigService {
  getBasicData(ids = null) {
    let url = `${this.apiUrl}/filter`
    if (ids) {
      const { id, id2 } = ids
      if (id) {
        url = `${url}?id=${id}`
      }
      if (id2) {
        url = `${url}&id2=${id2}`
      }
    }
    return super.get(url);
  }

  getAnalysisData(filters = null) {
    if (!filters) {
      filters = {};
    }
    return super.post(`${this.apiUrl}/results`, filters);
  }

  getScenarioBasicResults(id, id2 = null, filters = null, type = 'effective') {
    if (!filters) {
      filters = {};
    }
    let url = `${this.apiUrl}/results/basic?type=${type}`
    if (id) {
      url = `${url}&id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters);
  }

  getScenarioGraphResults(id, id2 = null, filters = null, type = 'effective') {
    if (!filters) {
      filters = {};
    }
    let url = `${this.apiUrl}/results/graph?type=${type}`
    if (id) {
      url = `${url}&id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters);
  }

  getScenarioCompetencyResults(id, id2 = null, filters = null) {
    if (!filters) {
      filters = {};
    }
    let url = `${this.apiUrl}/results/competency`
    if (id) {
      url = `${url}?id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters);
  }

  getScenarioTableResults(id, id2 = null, filters = null, type = 'effective') {
    if (!filters) {
      filters = {};
    }
    let url = `${this.apiUrl}/results/table?type=${type}`
    if (id) {
      url = `${url}&id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters);
  }

  getScenarioMobilityResults(id, id2 = null, filters = null) {
    if (!filters) {
      filters = {};
    }
    let url = `${this.apiUrl}/results/mobility`
    if (id) {
      url = `${url}?id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters);
  }

  getScenarioAttritionResults (id, id2 = null, filters = null) {
    if (!filters) {
      filters = {}
    }
    let url = `${this.apiUrl}/results/attrition`
    if (id) {
      url = `${url}?id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters)
  }

  getAnalysisSkillStatData(id, id2 = null, filters = null) {
    if (!filters) {
      return false;
    }
    let url = `${this.apiUrl}/skillStats`
    if (id) {
      url = `${url}?id=${id}`
    }
    if (id2) {
      url = `${url}&id2=${id2}`
    }
    return super.post(url, filters);
  }

  getScenarioData(id, filters = {}) {
    return super.post(`${this.apiUrl}/results?id=${id}`, filters);
  }

  getRetirementZip(id, id2 = null, filters = null) {
    let url = `${this.apiUrl}/exports/retirement`;
    if (id) {
      url = `${url}?id=${id}`;
    }
    if (id2) {
      url = `${url}&id2=${id2}`;
    }
    return super.post(url, filters, { responseType: "blob" });
  }

  getStaffingTableZip(id, id2 = null, filters = null) {
    let url = `${this.apiUrl}/exports/table`;
    if (id) {
      url = `${url}?id=${id}`;
    }
    if (id2) {
      url = `${url}&id2=${id2}`;
    }
    return super.post(url, filters, { responseType: "blob" });
  }

  getMobilityZip(id, id2 = null, filters = null) {
    let url = `${this.apiUrl}/exports/mobility`;
    if (id) {
      url = `${url}?id=${id}`;
    }
    if (id2) {
      url = `${url}&id2=${id2}`;
    }
    return super.post(url, filters, { responseType: "blob" });
  }
}

export default new DataService();
