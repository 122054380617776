<template>
  <div v-if="options && options.length">
    <template v-if="objectValue">
      <TreeSelect
        v-model="optionSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :options="treeOptions"
        :disabled="disabled"
        :placeholder="placeholder"
        :disable-fuzzy-matching="true"
        value-format="object"
      />
    </template>
    <template v-else>
      <TreeSelect
        v-model="optionSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :options="treeOptions"
        :disabled="disabled"
        :placeholder="placeholder"
        :disable-fuzzy-matching="true"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: 'FilterTreeSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    globalOption: {
      type: Boolean,
      default: false,
    },
    globalSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    objectValue: {
      type: Boolean,
      default: true,
    },
    options: {
      type: Array,
      required: true,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Sélectionner une valeur',
      // default: () => 'Sélectionner une valeur (laisser vide pour tout sélectionner)',
    },
  },
  data() {
    return {
      normalizer(node) {
        return {
          // eslint-disable-next-line
          id: node._id,
          // eslint-disable-next-line
          label: node.name || node.names && node.names[0].label,
        };
      },
      optionSelected: [],
    };
  },
  computed: {
    treeOptions() {
      const options = this.resetTreeOptions(this.options);
      if (this.selection) {
        this.selection.forEach((s) => {
          const node = this.findOptionTree(options, s);
          if (node) {
            node.isDisabled = this.selectionDisabled;
          }
        });
      }
      return options;
    },
    // stateOptions was implemented in this way because mapState returns
    // its value at compile time and props are assigned at runtime
    stateOptions() {
      return this.$store.state.auth.basic[this.keyOption];
    },
    oldOptions() {
      const opts = this.resetTreeOptions(this.stateOptions);
      // eslint-disable-next-line
      if (this.globalOption && opts.length && opts[0]._id !== 'Global') {
        opts.unshift({
          _id: 'Global',
          names: [
            {
              label: 'Global',
            },
          ],
          isDisabled: this.globalSelectedDisabled,
        });
      }
      return opts;
    },
  },
  watch: {
    optionSelected(curr) {
      if (curr) {
        this.$emit('selected', curr);
      }
    },
    /*
    globalSelectedDisabled: {
      handler(val) {
        if (val) {
          this.optionSelected = ['Global'];
        }
      },
      immediate: true,
    },
    */
  },
  mounted() {
    if (this.globalSelectedDisabled) {
      const global = {
        _id: 'Global',
        names: [
          {
            label: 'Global',
          },
        ],
        isDisabled: this.globalSelectedDisabled,
      };
      this.optionSelected = [this.objectValue ? global : 'Global'];
    }
    if (this.selection) {
      const options = [...this.optionSelected, ...this.selection];
      this.optionSelected = Array
        // eslint-disable-next-line no-underscore-dangle
        .from(new Set(options.map((a) => a._id ?? a)))
        // eslint-disable-next-line no-underscore-dangle
        .map((_id) => options.find((a) => a._id === _id || a === _id));
    }
  },
};
</script>
