<template>
  <div>
    <b-row>
      <b-col cols="1" class="d-flex justify-content-center">
        <b-avatar :text="step.toString()" variant="primary" />
      </b-col>
      <b-col class="d-flex align-items-center">
        <span class="font-weight-bold">
          {{ title }}
        </span>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="1">
        <div class="h-100 border-left" style="transform: translateX(50%)" />
      </b-col>
      <b-col class="py-3">
        <slot />
      </b-col>
    </b-row>
  </div>
</template>

<script>
export default {
  props: {
    step: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
  },
};
</script>
