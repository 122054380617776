<template>
  <div>
    <template v-if="objectValue">
      <TreeSelect
        v-if="unitOptions.length"
        ref="object"
        v-model="unitSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :options="unitOptions"
        :disabled="disabled"
        :placeholder="placeholder"
        value-format="object"
        :limit="limit"
        :limit-text="limitText"
        :disable-fuzzy-matching="true"
      />
    </template>
    <template v-else>
      <TreeSelect
        v-if="unitOptions.length"
        ref="notobject"
        v-model="unitSelected"
        :multiple="multi"
        :normalizer="normalizer"
        :options="unitOptions"
        :disabled="disabled"
        :placeholder="placeholder"
        :limit="limit"
        :limit-text="limitText"
        :disable-fuzzy-matching="true"
      />
    </template>
  </div>
</template>
<script>
export default {
  name: 'UnitSelector',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    globalOption: {
      type: Boolean,
      default: false,
    },
    globalSelectedDisabled: {
      type: Boolean,
      default: false,
    },
    multi: {
      type: Boolean,
      default: true,
    },
    objectValue: {
      type: Boolean,
      default: true,
    },
    selection: {
      type: Array,
      required: false,
      default: () => [],
    },
    selectionDisabled: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    placeholder: {
      type: String,
      required: false,
      default: () => 'Sélectionner une entité',
      // default: () => 'Sélectionner une entité (laisser vide pour tout sélectionner)',
    },
    allowed: {
      type: Array,
      required: false,
      default: () => [],
    },
    limit: {
      type: Number,
      required: false,
      default: () => 3
    },
    limitText: {
      type: Function,
      required: false,
      default: () => () => ''
    },
  },
  data() {
    return {
      normalizer(node) {
        return {
          // eslint-disable-next-line
          id: node._id,
          label: node.names && node.names[0].label,
          comment: node.description,
        };
      },
      unitSelected: [],
    };
  },
  computed: {
    unitOptions() {
      const options = this.resetTreeOptions(this.unitTree);
      // eslint-disable-next-line
      if (this.globalOption && options.length && options[0]._id !== 'Global') {
        options.unshift({
          _id: 'Global',
          names: [
            {
              label: 'Global',
            },
          ],
          isDisabled: this.globalSelectedDisabled,
        });
      }
      // Only allow allowed leafs with parents and children, and disable others.
      if (this.allowed.length) {
        // disabled all options and children
        this.loopInTree({ children: options }, (o) => {
          o.isDisabled = true;
        });
        // enable only allowed leaf and children
        const parents = this.getNodesWithParents(this.allowed, options);
        this.loopInTree({ children: options }, (n) => {
          // eslint-disable-next-line no-underscore-dangle
          if (!parents.includes(n._id)) {
            n.isDisabled = true;
          } else {
            n.isDisabled = false;
          }
        });
        this.allowed.forEach((a) => {
          const node = this.findOptionTree(options, a);
          const children = this.getNodeChildren(node);
          children.forEach((c) => {
            c.isDisabled = false;
          });
        });
      }
      return options;
    },
  },
  watch: {
    unitSelected(curr) {
      if (curr) {
        setTimeout(() => {
          this.$emit('selected', curr);
        }, 0);
      }
    },
  },
  mounted() {
    if (this.globalSelectedDisabled) {
      const global = {
        _id: 'Global',
        names: [
          {
            label: 'Global',
          },
        ],
        isDisabled: this.globalSelectedDisabled,
      };
      this.unitSelected = [this.objectValue ? global : 'Global'];
    }
    if (this.selection) {
      const options = [...this.unitSelected, ...this.selection];
      this.unitSelected = Array
        // eslint-disable-next-line no-underscore-dangle
        .from(new Set(options.map((a) => a._id ?? a)))
        // eslint-disable-next-line no-underscore-dangle
        .map((_id) => options.find((a) => a._id === _id || a === _id));
    }
  },
};
</script>

<style scoped>
::v-deep .vue-treeselect__limit-tip-text {
  cursor: pointer;
  display: inline-table;
  background: #e3f2fd;
  padding: 2px 0;
  border: 1px solid transparent;
  border-radius: 2px;
  color: #039be5;
  font-size: 12px;
  vertical-align: top;
}
</style>
