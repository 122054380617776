<template>
  <div>
    <div class="d-flex justify-content-end">
      <ScenariosSelector
        :multi="false"
        :default-active="defaultScenario"
        :hidden="scenarioMetadata ? [] : ['target']"
        :displayed-scenarios="['predicted', 'optimal', 'current', 'target']"
        @changed="updateSelectedCheck"
      />
    </div>
    <hr class="my-4">
    <div class="row">
      <div class="col-md-7">
        <highcharts v-if="chartRepartition" :options="chartRepartition" />
        <p v-else>
          Aucune donnée disponible
        </p>
      </div>
      <div v-if="totals && maxValueTotal" class="col-md-5">
        <CompetencesList
          title="Top 10 des compétences"
          :max-value="maxValueTotal"
          :dataset="totals"
          icon-class="fa-star"
          icon-bg-class="text-primary"
        />
      </div>
    </div>
    <b-row class="mt-4">
      <b-col
        v-if="attritions && maxValueAttrition && selectedCheck !== 'current'"
        cols="12"
        md="6"
        lg="6"
        xl="6"
      >
        <CompetencesList
          title="Attrition des compétences"
          :max-value="maxValueAttrition"
          :dataset="attritions"
          icon-class="fa-arrow-down"
          icon-bg-class="text-danger"
        />
      </b-col>
      <b-col
        v-if="augmentations && maxValueAugmentation && selectedCheck !== 'current'"
        cols="12"
        md="6"
        lg="6"
        xl="6"
      >
        <CompetencesList
          title="Développement des compétences"
          :max-value="maxValueAugmentation"
          :dataset="augmentations"
          icon-class="fa-arrow-up"
          icon-bg-class="text-success"
        />
      </b-col>
    </b-row>
  </div>
</template>

<script>
import CompetencesList from '@/components/analysis-tabs/competences/CompetencesList.vue';
import ScenariosSelector from '@/components/shared/ScenariosSelector.vue';

const chartDonut = (data) => ({
  credits: {
    enabled: false,
  },
  chart: {
    plotBackgroundColor: null,
    plotBorderWidth: null,
    plotShadow: false,
    type: 'pie',
  },
  title: {
    text: '<b class="text-uppercase h5">Domaines de compétences</b>',
  },
  plotOptions: {
    pie: {
      allowPointSelect: true,
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
      },
      showInLegend: false,
    },
  },
  colors: [
    '#4783FC',
    '#34D1D1',
    '#FFC738',
    '#9D4FEA',
    '#FD675B',
    '#34CC8E',
    '#FF9417',
    '#60C2FF',
    '#47FC6A',
    '#FD5BE4',
  ],
  series: [
    {
      colorByPoint: true,
      minPointSize: 10,
      innerSize: '45%',
      zMin: 0,
      name: 'Nombre de collaborateurs',
      data,
    },
  ],
});

export default {
  name: 'RepartitionCompetence',
  components: {
    CompetencesList,
    ScenariosSelector,
  },
  props: {
    graphData: {
      type: Object,
      default() {
        return null;
      },
    },
    globalValues: {
      type: Object,
      default() {
        return null;
      },
    },
    attritionsValues: {
      type: Object,
      default() {
        return null;
      },
    },
    augmentationsValues: {
      type: Object,
      default() {
        return null;
      },
    },
    totalsValues: {
      type: Object,
      default() {
        return null;
      },
    },
    defaultScenario: {
      type: String,
      required: false,
      default: () => 'predicted',
    },
    scenarioMetadata: {
      type: Object,
      required: false,
      default: () => {}
    },
  },
  data() {
    return {
      selectedCheck: 'predicted',
      chartDonut,
    };
  },
  computed: {
    chartRepartition() {
      if (this.graphData) {
        const data = this.graphData[this.selectedCheck];
        if (data && data.length) {
          const dataByKey = data.map((c) => {
            const dataSkill = this.findOptionTree(this.skillTree, c.entity, '_id', 'children');
            return {
              name: dataSkill ? dataSkill.names[0].label : 'Autres',
              y: c.score,
            };
          });
          return chartDonut(dataByKey);
        }
        return null;
      }
      return null;
    },
    attritions() {
      if (this.attritionsValues) {
        const data = this.attritionsValues[this.selectedCheck];
        if (data && data.length) {
          const dataMap = data.map((i) => ({
            data: this.findOptionTree(this.skillTree, i, '_id', 'children'),
            entity: this.globalValues[i].entity,
            value: this.globalValues[i][this.selectedCheck],
          }));
          return dataMap;
        }
        return null;
      }
      return null;
    },
    augmentations() {
      if (this.augmentationsValues) {
        const data = this.augmentationsValues[this.selectedCheck];
        if (data && data.length) {
          return data.map((i) => ({
            data: this.findOptionTree(this.skillTree, i, '_id', 'children'),
            entity: this.globalValues[i].entity,
            value: this.globalValues[i][this.selectedCheck],
          }));
        }
        return null;
      }
      return null;
    },
    totals() {
      if (this.totalsValues) {
        const data = this.totalsValues[this.selectedCheck];
        if (data && data.length) {
          return data.map((i) => ({
            data: this.findOptionTree(this.skillTree, i, '_id', 'children'),
            entity: this.globalValues[i].entity,
            value: this.globalValues[i][this.selectedCheck],
          }));
        }
        return null;
      }
      return null;
    },
    maxValueAttrition() {
      const values = this.attritions.map((i) => i.value.employeeCount);
      return Math.max(...values);
    },
    maxValueAugmentation() {
      const values = this.augmentations.map((i) => i.value.employeeCount);
      return Math.max(...values);
    },
    maxValueTotal() {
      const values = this.totals.map((i) => i.value.employeeCount);
      return Math.max(...values);
    },
  },
  methods: {
    updateSelectedCheck(scenarios) {
      // eslint-disable-next-line prefer-destructuring
      this.selectedCheck = scenarios[0];
    },
  },
};
</script>
