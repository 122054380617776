import ConfigService from './config';

class AuthService extends ConfigService {
  login(form) {
    return super.post(`${this.apiUrl}/login`, form);
  }
  logout() {
    return super.delete(`${this.apiUrl}/logout`);
  }
  getConfigurations() {
    return super.get(`${this.apiUrl}/config`);
  }
}

export default new AuthService();
