import ConfigService from './config';

class ScenarioService extends ConfigService {
  getAllScenariosData() {
    return super.get(`${this.apiUrl}/scenario`);
  }

  getAllArchivedScenariosData() {
    return super.get(`${this.apiUrl}/scenario/archived`);
  }

  deleteScenario(scenario) {
    return super.delete(`${this.apiUrl}/scenario?id=${scenario.ID}`);
  }

  duplicateScenario(scenario) {
    return super.get(`${this.apiUrl}/scenario/duplicate?id=${scenario.ID}`);
  }

  saveScenario(payload) {
    return super.post(`${this.apiUrl}/scenario`, payload);
  }

  shareScenario(scenario, users) {
    return super.put(`${this.apiUrl}/scenario/share?id=${scenario.ID}`, users);
  }

  getScenarioTable(payload, type = 'effective') {
    return super.post(`${this.apiUrl}/table?type=${type}`, payload);
  }

  getScenario(id) {
    let url = `${this.apiUrl}/scenario?id=${id}`
    return super.get(url);
  }

  getPredictionYears() {
    return super.get(`${this.apiUrl}/predictedYears`);
  }

  archiveScenario(id) {
    return super.put(`${this.apiUrl}/scenario/archived?id=${id}&archive=1`);
  }

  restoreScenario(id) {
    return super.put(`${this.apiUrl}/scenario/archived?id=${id}&archive=0`);
  }

  getGenericRates(filters = {}) {
    return super.post(`${this.apiUrl}/rates`, filters);
  }

  postGeneric(rates) {
    // return super.post(`${this.apiUrl}/rates`, filters);
    localStorage.setItem('rates', JSON.stringify(rates))
    return{
      status: 200,
    }
  }

  getGeneric() {
    // return super.post(`${this.apiUrl}/rates`, filters);
    let data = localStorage.getItem('rates')
    return data
  }

  convertValuesScenarioTable(payload) {
    return super.post(`${this.apiUrl}/convert`, payload);
  }

  getAssumptionsByCost() {
    return super.get(`${this.apiUrl}/budgets`);
  }
}

export default new ScenarioService();
