const state = {
  loader: false,
  dataLabels: false,
};

const getters = {
  loadingScreen({ loader }) {
    return loader;
  },
  showDataLabels({ dataLabels }) {
    return dataLabels;
  },
};

const mutations = {
  // eslint-disable-next-line
  setLoaderStatus(state, val) {
    state.loader = val;
  },
  // eslint-disable-next-line no-shadow
  setDataLabels(state, val) {
    state.dataLabels = val;
  },
};

const actions = {
  // eslint-disable-next-line
  setLoaderStatus({ commit }, val) {
    commit('setLoaderStatus', val);
  },
  setDataLabels({ commit }, val) {
    commit('setDataLabels', val);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
