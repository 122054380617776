<template>
  <form>
    <div class="row">
      <div class="col-sm-6">
        <b-form-group label-for="input-one">
          <div slot="label">
            <b>Structure de coût</b>
          </div>
          <filter-selector
            :disabled="readonly"
            key-option="costCentres"
            :object-value="false"
            :selection="selection.costCentres"
            @selected="selectCostCentres"
          />
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label-for="input-one">
          <div slot="label">
            <b>Entités</b>
          </div>
          <unit-selector
            :disabled="readonly"
            :selection="selection.units"
            :object-value="false"
            :allowed="allowed.units"
            :limit="limit"
            :limit-text="unitSelectorTreeSelectLimitText"
            @selected="selectUnit"
          />
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label-for="input-one">
          <div slot="label">
            <b>Zones géographiques</b>
          </div>
          <filter-tree-selector
            :disabled="readonly"
            :selection="selection.sites"
            :options="siteTree"
            :object-value="false"
            :multi="true"
            @selected="selectSites"
          />
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label-for="input-one">
          <div slot="label">
            <b>Compétences</b>
            <span id="p-analyse-competences" class="ml-1" style="position: relative; top: -5px">
              <i class="fal fa-question-circle" />
            </span>
            <b-popover target="p-analyse-competences" triggers="hover">
              Filtrer sur les collaborateurs qui maîtrisent des compétences.<br>
              Si vous sélectionnez une catégorie de compétences, vous obtenez les collaborateurs qui
              maîtrisent au moins une compétence de la catégorie.
            </b-popover>
          </div>
          <skill-selector
            :disabled="readonly"
            :selection="selection.skills"
            :object-value="false"
            :exact="true"
            @selected="selectSkills"
          />
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label-for="input-one">
          <div slot="label">
            <b>Métiers</b>
          </div>
          <job-selector
            :disabled="readonly"
            :selection="selection.jobs"
            :object-value="false"
            @selected="selectJobs"
          />
        </b-form-group>
      </div>
      <div class="col-sm-6">
        <b-form-group label-for="input-one">
          <div slot="label">
            <b>Type de contrat</b>
          </div>
          <type-contract-selector
            :disabled="readonly"
            key-option="contractType"
            :object-value="false"
            :selection="selection.contractType"
            @selected="selectContractType"
          />
        </b-form-group>
      </div>
    </div>
    <slot />
  </form>
</template>
<script>
import FilterSelector from '@/components/selectors/FilterSelector.vue';
import UnitSelector from '@/components/selectors/UnitSelector.vue';
import FilterTreeSelector from '@/components/selectors/FilterTreeSelector.vue';
import SkillSelector from '@/components/selectors/SkillSelector.vue';
import JobSelector from '@/components/selectors/JobSelector.vue';
import TypeContractSelector from '@/components/selectors/TypeContractSelector.vue';
import { unitSelectorTreeSelectLimitText } from '@/services/helpers'

export default {
  name: 'PerimeterFilters',
  components: {
    FilterSelector,
    UnitSelector,
    FilterTreeSelector,
    SkillSelector,
    JobSelector,
    TypeContractSelector,
  },
  props: {
    selection: {
      type: Object,
      required: false,
      default: () => {},
    },
    allowed: {
      type: Object,
      required: false,
      default: () => {},
    },
    readonly: {
      type: Boolean,
      required: false,
      default: () => false,
    },
    limit: {
      type: Number,
      required: false,
      default: () => 3
    },
  },
  data() {
    return {
      filters: {
        units: [],
        skills: [],
        contractTypes: [],
        jobs: [],
        costCentres: [],
        sites: [],
      },
      unitSelectorTreeSelectLimitText,
    };
  },
  watch: {
    filters: {
      handler() {
        this.updateFilters();
      },
      deep: true,
    },
  },
  methods: {
    updateFilters() {
      const filters = { ...this.filters };
      // eslint-disable-next-line
      for (const ft in filters) if (!filters[ft]?.length) delete filters[ft];
      // send notification to parent component
      this.$emit('update:filters', filters);
    },
    selectCostCentres(centres) {
      this.filters.costCentres = centres;
    },
    selectUnit(units) {
      this.filters.units = units;
    },
    selectSites(sites) {
      this.filters.sites = sites;
    },
    selectSkills(skills) {
      this.filters.skills = skills;
    },
    selectContractType(contracts) {
      this.filters.contractTypes = contracts;
    },
    selectJobs(jobs) {
      this.filters.jobs = jobs;
    },
  },
};
</script>
