import ScenarioService from '@/providers/services/scenario'
import Auth from '@/store/modules/auth'

const defaultScenario = {
  uniqueID: 0,
  ID: 0,
  label: 'Scénario par défaut',
  isFinalized: true,
  isReady: true,
  createdAt: new Date().toLocaleDateString('en'),
  updatedAt: new Date().toLocaleDateString('en'),
  default: true,
  status: 'Partagé',
  owner: null,
};

const buildDefaultScenario = () => {
  const user = Auth.getters.currentUser()
  const scenario = defaultScenario
  if (!user.scenariocreator) {
    scenario.label = 'Tendances'
  }
  return scenario
}

const state = {};
const getters = {};
const mutations = {};
const actions = {
  async fetchScenarios() {
    const data = await ScenarioService.getAllScenariosData();
    return [buildDefaultScenario(), ...data];
  },
  async fetchArchivedScenarios() {
    const data = await ScenarioService.getAllArchivedScenariosData();
    return data;
  },
  async fetchScenarioById(context, { id }) {
    const data = await ScenarioService.getScenario(id);
    return data;
  },
  async shareScenario(context, { scenario, users }) {
    await ScenarioService.shareScenario(scenario, users);
  },
  async deleteScenario(context, scenario) {
    await ScenarioService.deleteScenario(scenario);
  },
  async duplicateScenario(context, scenario) {
    await ScenarioService.duplicateScenario(scenario);
  },
  async archiveScenario(context, scenario) {
    await ScenarioService.archiveScenario(scenario);
  },
  async restoreScenario(context, scenario) {
    await ScenarioService.restoreScenario(scenario);
  },
  async saveScenario(context, payload) {
    await ScenarioService.saveScenario(payload);
    return { ...payload };
  },
  async getScenarioTable(context, { payload, type }) {
    return ScenarioService.getScenarioTable(payload, type);
  },
  async convertValuesScenarioTable(context, payload) {
    return ScenarioService.convertValuesScenarioTable(payload);
  },
  async getAssumptionsByCost(context) {
    return ScenarioService.getAssumptionsByCost();
  },
  async getPredictionYears() {
    // eslint-disable-next-line no-return-await
    return await ScenarioService.getPredictionYears();
  },
  async fetchGenericRates(context, { type, filters }) {
    const rates = await ScenarioService.getGenericRates(filters);
    return {
      current: { ...rates?.conjunctural } || [],
      target: { ...rates?.[type] } || [],
      all: { ...rates }
    };
  },
  async fetchGenericRates2(context, { type, filters }) {
    const rates = await ScenarioService.postGeneric(filters);
    return {
      current: { ...rates?.conjunctural } || [],
      target: { ...rates?.[type] } || [],
      all: { ...rates }
    };
  },
  async fetchGenericRates3() {
    const rates = await ScenarioService.getGeneric();
    return rates
  }
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
