export default {
  bind: (el, binding) => {
    el.addEventListener('input', () => {
      let value = parseFloat(el.value)
      if (binding.value === 'positive') {
        value = Math.abs(value)
      } else if (binding.value === 'negative') {
        value = -Math.abs(value)
      }
      el.value = value
    })
  }
}